import { useEffect, useState, useRef } from "react";

// Charts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Material UI
import { Card, Box } from "@mui/material";

import Swal from "sweetalert2";

// Hooks
import useWindowDimensions from "@hooks/useWindowDimensions";

import MovilizacionService from "@services/MovilizationServices";

const ProgressIndicator = (props) => {
  const { openDrawer, filtered, setLoading } = props;

  const [localTitle, setLocalTitle] = useState("Folios Asignados (Acumulado)");

  const chartRef = useRef(null);

  const { height, width } = useWindowDimensions();

  const [dataCard, setDataCard] = useState({
    Avance: 0,
    Total: 0,
  });

  const ApiResponse = async (value) => {
    try {
      setLoading(true)
      let values = {
        filtered: value.filtered || [{
          id: "idRegion",
          filter: "=",
          value: 1,
        },]
      };
      const result = await MovilizacionService.getBookletProgress(values);
      const {results, message, response} = result;
      if(results){
        setDataCard({
          Avance: response.data.CuadernillosFinalizados,
          Total: response.data.TotalCuadernillos
        })
      }else{
        throw new Error(message)
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" })
    } finally {
      setLoading(false)
    }
  };

  useEffect(()=>{
    if(filtered.ambitoAgrupador !== ""){
      ApiResponse(filtered)
    }
  },[filtered])

  const options = {
    title: {
      text: `${localTitle}`,
      align: "center",
      /* style: {
        color: "#999",
        fontSize: width > 1440 ? width * 0.009 : "18px",
      }, */
    },
    chart: {
      type: "solidgauge",
      height: width > 1440 ? height * 0.35 : "auto",
    },
    pane: {
      center: ["50%", "60%"],
      size: "100%",
      startAngle: -90,
      endAngle: 90,
      background: {
        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "#EEE",
        innerRadius: "60%",
        outerRadius: "100%",
        shape: "arc",
      },
    },
    exporting: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    yAxis: {
      min: 0,
      max: dataCard.Total,
      stops: [
        [0.1, "#DF5353"], // green
        [0.5, "#DDDF0D"], // yellow
        [0.9, "#55BF3B"], // red
      ],
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickAmount: 2,
      title: {
        y: -70,
      },
      labels: {
        y: 16,
        style: {
          fontSize: "12px",
        },
        formatter: function () {
          if (this.isFirst) {
            return "0";
          }
          if (this.isLast) {
            return `<span>${dataCard.Total}</span>`;
          }
          return this.value;
        },
      },
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: -20,
          borderWidth: 0,
          useHTML: true,
        },
      },
    },
    series: [
      {
        name: "Speed",
        data: [parseInt(dataCard.Avance)],
        dataLabels: {
          format:
            '<div style="text-align:center">' +
            '<span style="font-size:40px">{y}</span><br/>' +
            '<span style="font-size:12px;opacity:0.4">Cuadernillos Finalizados</span>' +
            "</div>",
        },
        tooltip: {
          valueSuffix: " km/h",
        },
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return (
    <Box component={Card} variant="outlined" borderRadius={2} padding={2} minHeight={300} height={"100%"} elevation={0}>
      <Box key={openDrawer}>
        <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
      </Box>
    </Box>
  );
};

export default ProgressIndicator;

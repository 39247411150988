import { useState, useEffect } from "react";

// Material UI
import {
  Card,
  CardContent,
  Typography,
  Stack,
  Box,
  Divider,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { AccountCircle, LocalPhone, LocationOn, Cake } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

import DiaDServices from "@services/DiaDServices";

//UTILS
import { isEmptyOrNullObject, isEmptyOrInvalidArray } from "@utils/validations";

import Swal from "sweetalert2";

const UserInfo = (props) => {
  const { params, setLoading, permisos } = props;
  const [data, setData] = useState([]);
  const handleConfirm = (item) => {
    Swal.fire({
      title: "¿Estás seguro de que quieres eliminar?",
      text: "No podrás revertir esto.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminarlo",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) handleDelete(item);
    });
  };

  const getPermisos = async (idUser) => {
    try {
      const result = await DiaDServices.getResponsabilities(idUser);
      const { results, message, response } = result;
      if (results) {
        setData(response.data);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    }
  };

  const handleDelete = async (values) => {
    try {
      setLoading(true);
      const {id, idUsuario} = values
      const result = await DiaDServices.deleteResponsability({id, idUsuario});
      const { results, message } = result;
      if (results){ 
        Swal.fire({ title: message, icon: "success" });
        getPermisos(params.id)
    }
      else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(()=>{
    if(params.id){
        getPermisos(params.id)
    }
  },[params, permisos])

  return (
    <Card className="card-primary" sx={{ height: "100%", marginBottom: "0 !important" }}>
      <CardContent>
        <Box textAlign="center" mt={1}>
          <Typography component={"h6"} variant="h6" fontWeight={800} fontSize={18}>
            DATOS DEL USUARIO
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />
        {!isEmptyOrNullObject(params) ? (
          <>
            <Box mb={2}>
              <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                <AccountCircle fontSize="large" style={{ color: grey[500] }} />
                <Typography fontWeight={600} variant="h6">
                  {params.NombreCompleto} {params.Paterno} {params.Materno}
                </Typography>
              </Stack>
            </Box>
            <Box mb={2}>
              {params.Username && (
                <Stack direction="row" spacing={2} alignItems="center" ml="4vh" mb={2}>
                  <LocalPhone />
                  <Typography fontWeight={400} fontSize={15}>
                    {params.Username}
                  </Typography>
                </Stack>
              )}
              {params.Municipio && (
                <Stack direction="row" spacing={2} alignItems="center" ml="4vh" mb={2}>
                  <LocationOn />
                  <Typography fontWeight={400} fontSize={15}>
                    {params.Municipio}
                  </Typography>
                </Stack>
              )}
              {params.Edad && (
                <Stack direction="row" spacing={2} alignItems="center" ml="4vh">
                  <Cake />
                  <Typography fontWeight={400} fontSize={15}>
                    {params.Edad} años
                  </Typography>
                </Stack>
              )}
            </Box>
            <Divider sx={{ mt: 1 }} />
            {!isEmptyOrInvalidArray(data) ? (
              <Box style={{ maxHeight: "400px", overflowY: "auto" }}>
                <List dense>
                  {data.map((item, index) => (
                    <ListItem disablePadding key={index}>
                      <ListItemIcon sx={{ width: "100%" }}>
                        <IconButton color="error" onClick={(e) => handleConfirm(item)}>
                          <Icon>delete</Icon>
                        </IconButton>
                        <ListItemText
                          primary={
                            <Tooltip title={item.label} disableInteractive arrow>
                              <Box
                                sx={{
                                  width: "100%",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "noWrap",
                                  fontWeight: 700,
                                }}
                              >
                                Responsabilidad: {item.ResponsabilidadEtiqueta}
                              </Box>
                            </Tooltip>
                          }
                          secondary={
                            <span>
                              <Tooltip title={item.responsable} disableInteractive arrow>
                                <Box
                                  sx={{
                                    width: "100%",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "noWrap",
                                  }}
                                >
                                  {item.AsignadoPor} {item.FechaCreo}
                                </Box>
                              </Tooltip>
                              {item.fecha}
                            </span>
                          }
                        />
                      </ListItemIcon>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ) : (
              <Typography fontWeight={600} fontSize={15} align="center" mt={2}>
                SIN PERMISOS ASIGNADOS
              </Typography>
            )}
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: 2,
              p: 2,
            }}
          >
            <Icon fontSize="large">info</Icon>
            <Typography variant="body1" align="center">
              Sin Información. Seleccione un usuario.
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default UserInfo;

import { useState } from "react";

// Material UI y estilos
import { Box } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "@src/theme/index";

// Componentes
import AdminNavbar from "./AdminNavbar";
import AdminSidebar from "./AdminSidebar";
// import Footer from "./Footer";

const AdminLayout = (props) => {
  const { children, sx = {}, delPadding = false } = props;
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [refresh, setRefresh] = useState(true);

  const handleToggle = (open) => {
    if (open !== undefined) setSidebarOpen(open);
    else setSidebarOpen((prevOpen) => !prevOpen);
  };

  const margin = { ml: { xs: 0, sm: 7.4 }, mt: { xs: 7, sm: 8 }, mb: 0 };
  const padding = delPadding ? { ...margin, p: 0 } : { ...margin, p: { xs: 0, sm: 1 }, pt: { xs: 1 } };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <CssBaseline />
        <AdminNavbar handleToggle={handleToggle} title={"Inicio"} setRefresh={setRefresh} />
        <AdminSidebar handleToggle={handleToggle} open={isSidebarOpen} refresh={refresh} />
        <Box sx={{ ...padding, ...sx }}>{children}</Box>
        {/* <Box sx={{ ml: { xs: 0, sm: 8 } }}> <Footer /> </Box> */}
      </Box>
    </ThemeProvider>
  );
};

export default AdminLayout;

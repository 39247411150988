import { useRef, useState } from "react";
import {
  Box,
  Icon,
  TableCell,
  TableRow,
  Typography,
  TableBody as TableBodyMui,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { handleKeyPress } from "@data/schemas/MovilizationSchemas";
import { deepOrange } from "@mui/material/colors";
import { Clear, Warning } from "@mui/icons-material";

// import Swal from "sweetalert2";
import middleware from "@middlewares/middleware";

const TableBody = ({
  data,
  dataFound,
  handleEditMovilizados,
  enableEdit,
  allowDuplicate,
  handleDelete
}) => {
  const inputRefs = useRef([]);
  const [canDelete] = useState(middleware.checkMenuAction('Eliminar'));
  //const [alertShown, setAlertShown] = useState(false);
  //const [invalidNumbers, setInvalidNumbers] = useState([]);

  const handleKeyPressEnter = (event, index) => {
    const isEnterKey = event.key === "Enter";
    const isTabKey = event.key === "Tab";
    if (event.type === "keydown" && (isEnterKey || isTabKey)) {
      event.preventDefault();

      // handleMouseClick();

      const nextIndex = index + 1;
      if (nextIndex < inputRefs.current.length) {
        inputRefs.current[nextIndex].focus();
      }
    }
  };

  // const handleMouseClick = () => {
  //   const folioBingos = inputRefs.current?.map((ref) => ref?.value?.trim()).filter(Boolean) || [];
  //   const newInvalidNumbers = folioBingos
  //     .filter((folioBingo, index) => {
  //       const prevValue = folioBingos[index - 1];
  //       const nextValue = folioBingos[index + 1];
  //       return (prevValue && Number(folioBingo) < Number(prevValue)) || (nextValue && Number(folioBingo) > Number(nextValue));
  //     })
  //     .filter((num) => !invalidNumbers.includes(num));
  //   if (newInvalidNumbers.length > 0) {
  //     setInvalidNumbers((prevInvalidNumbers) => [...prevInvalidNumbers, ...newInvalidNumbers]);
  //     Swal.fire({
  //       title: "Error",
  //       text: "Los valores ingresados no pueden ser menores que los valores ingresados anteriormente o mayores que los siguientes",
  //       icon: "error",
  //     });
  //   }
  // };

  /*   useEffect(() => {
    setAlertShown(false);
  }, [data]);

  useEffect(() => {
    setInvalidNumbers([]);
  }, [dataFound]); */

  return (
    <TableBodyMui>
      {data.length > 0 ? (
        data.map((row, index) => (
          <TableRow key={index}>
            <TableCell align="right">{row.Seccion}</TableCell>
            <TableCell>{row.NombreCasilla}</TableCell>
            <TableCell>{row.NombreCompleto}</TableCell>
            <TableCell align="center">
              <TextField
                key={index}
                inputRef={(el) => (inputRefs.current[index] = el)}
                size="small"
                disabled={!enableEdit}
                value={
                  data.find((user) => user.id === row.id)?.FolioBingo ?? ""
                }
                onChange={(e) => handleEditMovilizados(row.id, e.target.value)}
                onKeyPress={handleKeyPress}
                onKeyDown={(e) => {
                  handleKeyPressEnter(e, index);
                }}
                // onClick={() => {
                //   handleMouseClick(index);
                // }}
                sx={{ width: "50%" }}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      {((row.FolioBingo &&
                        row.FolioBingo !== "" &&
                        data.filter(
                          (user) =>
                            user.FolioBingo === row.FolioBingo &&
                            user?.NombreCasilla === row?.NombreCasilla
                        ).length > 1) ||
                        (row.idEstatusFolioBingo === 2 &&
                          row.FolioBingo !== "" &&
                          data.filter(
                            (user) =>
                              user.FolioBingo === row.FolioBingo &&
                              user?.NombreCasilla === row?.NombreCasilla
                          ).length > 1) ||
                        Number(row.FolioBingo) > 750) &&
                        !allowDuplicate &&
                        row.NombreCasilla === null && (
                          <InputAdornment position="end">
                            <Warning style={{ color: deepOrange[500] }} />
                          </InputAdornment>
                        )}
                    </>
                  ),
                }}
              />
                <IconButton 
                  size="small" 
                  onClick={() => handleDelete(row)} 
                  sx={{
                    '&:hover': {
                      color: '#ff0000',
                    },
                    visibility: (canDelete && row?.NombreCasilla && row?.idEstatusFolioBingo === 1) ? 'visible' : 'hidden'
                  }}
                >
                  <Tooltip title='Click para eliminar el Folio'>
                    <Clear  />
                  </Tooltip>
                </IconButton>
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={4}>
            <Box
              display={"flex"}
              alignContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <Icon sx={{ fontSize: "5rem", color: grey[500] }}>
                {dataFound ? "person_search" : "search_off"}
              </Icon>
              <Typography fontWeight={600} variant="h6" marginTop={1}>
                {dataFound
                  ? "Datos No Disponible"
                  : "No se econtraron resultados"}
              </Typography>
              <Typography variant="caption" marginTop={1}>
                {dataFound
                  ? " Utiliza el filtro a tu izquierda para realizar una búsqueda y obtener los resultados deseados."
                  : "Por favor, intenta realizar la búsqueda nuevamente, ya que no se encontraron datos con los criterios actuales"}
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      )}
    </TableBodyMui>
  );
};

export default TableBody;

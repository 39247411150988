import React, { useEffect, useState } from "react";

import { Stack } from "@mui/material";

import AttendanceCard from "@components/Attendance/AttendanceCard";

import movilizacion from "@services/MovilizationServices";
import Swal from "sweetalert2";

const StatisticalCards = ({ params, refresh, setRefresh }) => {
  const [attendanceData, setAttendanceData] = useState({
    Poligonales: {
      contentData: "",
      leftFooterData: "",
      rightFooterData: "",
    },
    Seccionales: {
      contentData: "",
      leftFooterData: "",
      rightFooterData: "",
    },
    Manzaneras: {
      contentData: "",
      leftFooterData: "",
      rightFooterData: "",
    },
  });

  const getSummaryAttendance = async () => {
    try {
      const { results, message, response } =
        await movilizacion.getSummaryAttendance({ filtered: params });

      if (!results) throw new Error(message);

      setAttendanceData({
        Poligonales: {
          contentData: response.PAvancePoligonales,
          leftFooterData: response.Poligonales,
          rightFooterData: response.PoligonalesReportados,
        },
        Seccionales: {
          contentData: response.PAvanceSeccionales,
          leftFooterData: response.Seccionales,
          rightFooterData: response.SeccionalesReportados,
        },
        Manzaneras: {
          contentData: response.PAvanceManzaneras,
          leftFooterData: response.Manzaneras,
          rightFooterData: response.ManzanerasReportados,
        },
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.message,
      });
    } finally {
      setRefresh(false);
    }
  };

  useEffect(() => {
    getSummaryAttendance(params);
  }, [params, refresh]);

  const renderAttendanceCard = (titlecontent, data) => (
    <AttendanceCard
      titlecontent={titlecontent}
      data={data}
      config={{
        leftFooterTitle: "Meta",
        rightFooterTitle: "Avance",
      }}
    />
  );

  return (
    <Stack direction="row" spacing={2}>
      {Object.entries(attendanceData).map(([key, value]) =>
        renderAttendanceCard(key, {
          contentData: value.contentData,
          leftFooterData: value.leftFooterData,
          rightFooterData: value.rightFooterData,
        })
      )}
    </Stack>
  );
};

export default StatisticalCards;

import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Swal } from "@utils/alerts";

// Material UI y estilos
import { Card, CardContent, Grid, Typography, Button, Icon, Box, CircularProgress } from "@mui/material";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import BasicListDoble from "@components/Lists/BasicListDoble";

// Servicios
import UserService from "@services/UserServices";
import EventsServices from "@services/EventsServices";

// Utils
import { isTypePhone, isEmptyOrNullObject } from "@utils/validations";

const StaffEvent = () => {
  const { id } = useParams();

  const [event, setEvent] = useState({});
  const [user, setUser] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEvent, setLoadingEvent] = useState(false);

  useEffect(() => {
    if (id) getEventosData(); // eslint-disable-next-line
  }, [id]);

  const getEventosData = useCallback(async () => {
    const data = {
      page: 0,
      pageSize: 20,
      filtered: [{ id: "eventos.id", filter: "=", value: id }],
      sorted: [{ id: "eventos.FechaInicio", value: "asc" }],
    };
    try {
      setLoadingEvent(true);

      const result = await EventsServices.getMyRecords(data);
      const { results, response, message } = result;

      if (results) setEvent(response.data[0]);
      else throw new Error(message);
    } catch (e) {
      setEvent({});
      Swal.fire({ title: e.message, icon: "warning" });
    } finally {
      setLoadingEvent(false);
    } // eslint-disable-next-line
  }, []);

  const handleAdd = async () => {
    try {
      setIsLoading(true);

      const params = {
        idEvento: event.id,
        idUsuario: user.id,
      };

      const result = await EventsServices.setOrganizador(params);
      const { results, message } = result;

      if (results) Swal.fire({ title: message, icon: "success" });
      else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: "warning" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUser = (e) => setUser(e);
  const handleClear = () => setUser({});

  return (
    <AdminLayout key={id}>
      <ActionHeader title="Staff del Evento" />

      <LoadingForm
        loadinMessage={"Añadiendo Staff..."}
        successMessage={"Staff añadido con exito!"}
        isLoading={isLoading}
        isOpen={isLoading}
        setIsOpen={() => {}}
      />

      <Card className="card-primary">
        <CardContent>
          {isLoadingEvent ? (
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
              <CircularProgress size={50} />
              Cargando...
            </Box>
          ) : !event?.id ? (
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <h2 style={{ textAlign: "center" }}>
                No se encontró el evento, o no formas parte del staff. <br /> <br /> Vuelve a intentarlo
              </h2>
            </Box>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <BasicListDoble
                  id="id_Usuarios"
                  API={UserService.getUsers}
                  APIKeys={[
                    { id: ["usuarios.Nombre", "usuarios.Paterno", "usuarios.Materno"], filter: "LIKE" },
                    { id: "usuarios.Username", filter: "=" },
                  ]}
                  header={""}
                  handleClick={handleUser}
                  handleClear={handleClear}
                  label={"Encuentra y elige al usuario"}
                  labelNote={
                    "Nota: Para realizar una búsqueda debe ingresar un nombre o por el contrario un número de celular."
                  }
                  finder
                  clearData
                  doubleSearch
                  inputValidation={{ input2: isTypePhone }}
                  lengthValidation={{ input2: { filter: "=", value: 10 } }}
                  breaks={{ sm: 12, md: 6 }}
                  buttonsBreaks={{ sm: 12, md: 4 }}
                  config={{ height: 400 }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Box sx={{ mt: 4, px: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                  <Typography>
                    <strong>Evento:</strong> {event?.Actividad ?? "No encontrado, debes ser staff."}
                  </Typography>
                  <Typography>
                    <strong>Usuario:</strong> {user?.NombreCompleto}
                  </Typography>

                  <Box>
                    <Button
                      size="small"
                      color="success"
                      variant="contained"
                      startIcon={<Icon>add</Icon>}
                      onClick={handleAdd}
                      disabled={isEmptyOrNullObject(event) || isEmptyOrNullObject(user)}
                    >
                      Añadir a Staff
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </AdminLayout>
  );
};

export default StaffEvent;

import { ApiExecDecrypted } from "@utils/ApiExec";

class MapService {
  getShapes = (params) => {
    return ApiExecDecrypted(params, "map/advance_social/get-shapes", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getShapesAll = (params) => {
    return ApiExecDecrypted(params, "map/advance_social/get-shapes-all", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
}

const map = new MapService();
export default map;

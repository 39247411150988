import { forwardRef, useState } from "react";
import { useFormik } from "formik";
import { useCatalogs } from "@hooks/useCatalogs";

import { ActaEscrutinioInterface } from "@data/interfaces/PrepInterface";
import { ActaEscrutinioSchema } from "@data/schemas/PrepSchema";
import { isEmptyOrInvalidString } from "@utils/validations";
import Swal from "sweetalert2";
import NoData from "@assets/img/NoData.svg";

//Components
import InputSelect from "@components/Selects/BasicSelect";
import ListParties from "@components/Prep/ListParties";
import UploadSingleFile from "@components/Files/UploadSingleFile";
import LoadingForm from "@components/LinearProgress/LoadingForm";

//Mui
import {
  Alert as MuiAlert,
  Box,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
  Typography,
  FormHelperText
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

import sije from "@services/SijeService";
import prep from "@services/PrepServices";
import { convertBase64 } from "@utils/Utilities";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MinuteEscrutinio = () => {
  const catalogsOptions = [
    { id: "tipo_eleccion" },
  ];

  const { catalogs, load } = useCatalogs({
    catalogsOptions,
    putDefaultOption: false
  });

  const [loading1, setLoading1] = useState(false);
  const [actImage, setActImage] = useState(null);
  const [sheetImage, setSheetImage] = useState(null);
  const [totalVotos, setTotalVotos] = useState(0);
  const [pollingStations, setPollingStations] = useState([]); // Casillas
  const [politicalParties, setPoliticalParties] = useState([]);
  const [error, setError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [isSuccessFormSave] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [loadinMessage, setLoadingMessage] = useState('Consultando acta...');

  const formik = useFormik({
    initialValues: ActaEscrutinioInterface,
    validationSchema: ActaEscrutinioSchema,
    onSubmit: (values) => handleGetPlanillas(values),
  });

  const handleChangeSection = async (e) => {
    const section = e.target.value;
    setDisabledBtn(true);
    try {
      setLoading1((prevState) => !prevState);
      const result = await sije.getCasillasBySeccion({ Seccion: section });
      const { response, results } = await result;
      if (results) {
        const casillas = response.data.map((item) => {
          return {
            label: item.NombreCasilla,
            value: item.idCasilla,
          };
        });
        setPollingStations(casillas);
      } else {
        setPollingStations([]);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
      setPollingStations([]);
    } finally {
      setLoading1((prevState) => !prevState);
      formik.setValues({
        ...formik.values,
        Seccion: section,
        idCasilla: ''
      });
    }
  };

  const handleGetPlanillas = async (values) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    setLoadingMessage('Consultando acta...');

    try {
      setPoliticalParties([]);

      const params = {
        idTipoEleccion: values.idTipoEleccion,
        idCasilla: values.idCasilla
      }
      const result = await prep.getForm(params);
      const { response, results, message } = await result;

      if (results) {
        let _politicalParties = response.data.map((item) => ({
          ...item,
          Votos: "",
        }));
        _politicalParties = [
          ..._politicalParties,
          { id: "NoReg", Votos: "", RutaLogo: "prep_partidos/noreg.png", siglas: "No reg" },
          { id: "Nulos", Votos: "", RutaLogo: "prep_partidos/nulos.png", siglas: "Nulos" },
        ];
        setPoliticalParties(_politicalParties);
      } else {
        Swal.fire({
          title: message,
          icon: "warning",
        });
      }

    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  };

  const handleSetValueMinute = async () => {
    const validate = validateVotes();
    if (!validate) {
      return
    }

    let files = [];
    if (actImage) {
      files = [...files, {
        classificationId: 1,
        file: await convertBase64(actImage)
      }];
    }

    if (sheetImage) {
      files = [...files, {
        classificationId: 2,
        file: await convertBase64(sheetImage)
      }];
    }

    const votes = formatResponse(politicalParties);
    const params = {
      idTipoEleccion: formik.values.idTipoEleccion,
      idCasilla: formik.values.idCasilla,
      Resultados: votes,
      Origen: 'web',
      files: files
    };

    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    setLoadingMessage('Enviando...');

    try {
      const result = await prep.saveForm(params);
      const { results, message } = await result;

      if (results) {
        Swal.fire({
          title: message,
          icon: "success",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((res) => {
          if (res.isConfirmed) {
            setTotalVotos(0);
            setPoliticalParties([]);
            setActImage(null);
            setSheetImage(null);
          }
        });
      } else {
        Swal.fire({
          title: message,
          icon: "warning",
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  }

  const handleVotes = (e, idx) => {
    const value = e.target.value;
    const _politicalParties = parseJson(politicalParties, value, idx);
    setPoliticalParties(_politicalParties);
    calculateVotes(_politicalParties);
  };

  const parseJson = (obj, value, idx) => {
    let _politicalParties = JSON.parse(JSON.stringify(obj));
    _politicalParties[idx].Votos = value;

    return _politicalParties;
  };

  const calculateVotes = (_politicalParties) => {
    const value = _politicalParties
      .map((item) => {
        return isNaN(item.Votos) || item.Votos.length === 0
          ? 0
          : parseInt(item.Votos);
      })
      .reduce((accum, current) => {
        return accum + current;
      });

    setTotalVotos(value);
  };

  const validateVotes = () => {
    let hasError = false;

    const emptyVotes = politicalParties.filter(
      (item) => item.Votos < 0 || item.Votos === ""
    );

    if (emptyVotes.length > 0) {
      setError("Debe poner la cantidad de votos para todos los partidos");
      setOpenSnackbar(true);
      hasError = true;
    }
    return !hasError;
  };

  const formatResponse = (_politicalParties) => {
    let votosFormated = [];
    _politicalParties.forEach((item) => {
      if (item.id !== "NoReg" && item.id !== "Nulos") {
        votosFormated = [
          ...votosFormated,
          {
            field: `Dato${item.id}`,
            value: parseInt(item.Votos)
          }
        ];
      } else if (item.id !== "NoReg") {
        votosFormated = [...votosFormated, { field: 'NoReg', value: parseInt(item.Votos) }];
      } else {
        votosFormated = [...votosFormated, { field: 'Nulos', value: parseInt(item.Votos) }];
      }
    });

    return votosFormated;
  };

  const handleChangeElectionType = (event) => {
    formik.handleChange(event);
  }

  const handleOnKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleChangeSection(event);
    }
  };

  const handleChangeBox = (e) => {
    formik.handleChange(e);
    if (e.target.value !== '') {
      setDisabledBtn(false);
    }
  }

  return (
    <Box>
      <LoadingForm
        loadinMessage={loadinMessage}
        successMessage="¡Consultado con éxito!"
        isLoading={isLoadigForm}
        success={isSuccessFormSave}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
      />

      <Box component={Card} className="card-primary">
        <Box component={CardContent}>
          <Grid2 container spacing={2} alignItems="center">
            <Grid2 xs={12} md={3}>
              <InputSelect
                label="Tipo de elección"
                name="idTipoEleccion"
                value={formik.values.idTipoEleccion}
                options={catalogs.tipo_eleccion}
                onChange={handleChangeElectionType}
                error={
                  formik.touched.idTipoEleccion &&
                  !isEmptyOrInvalidString(formik.errors.idTipoEleccion)
                }
                errorMessage={formik.errors.idTipoEleccion}
                sx={{ width: "100%" }}
                isLoading={load}
              />
              {formik.values.idTipoEleccion === '' && < FormHelperText > Seleccione el tipo de elección</FormHelperText>}
            </Grid2>
            <Grid2 xs={12} md={3}>
              <TextField
                label="Sección"
                id="Seccion"
                name="Seccion"
                size="small"
                value={formik.values.Seccion}
                onChange={formik.handleChange}
                onBlur={handleChangeSection}
                onKeyUp={handleOnKeyPress}
                error={
                  formik.touched.Seccion && (!isEmptyOrInvalidString(formik.errors.Seccion) || formik.values.Seccion === '')
                }
                helperText={
                  formik.touched.Seccion ||
                  formik.errors.Seccion ||
                  isEmptyOrInvalidString(formik.values.Seccion)
                }
                sx={{ width: "100%" }}
              />
              {formik.values.Seccion === '' && <FormHelperText>Ingrese una sección válida</FormHelperText>}
            </Grid2>
            <Grid2 xs={12} md={3}>
              <InputSelect
                label="Casilla"
                name="idCasilla"
                value={formik.values.idCasilla}
                options={pollingStations}
                onChange={e => handleChangeBox(e)}
                error={
                  formik.touched.idCasilla &&
                  (!isEmptyOrInvalidString(formik.errors.idCasilla)
                    || formik.values.idCasilla === '')
                }
                errorMessage={formik.errors.idCasilla}
                sx={{ width: "100%" }}
                isLoading={loading1}
              />
            </Grid2>
            <Grid2 xs={12} md={3}>
              <Button
                variant="contained"
                color="primaryDark"
                onClick={formik.handleSubmit}
                disabled={disabledBtn}
              >
                Obtener Acta
              </Button>
              {formik.values.idCasilla === '' && <FormHelperText>Seleccione una casilla</FormHelperText>}
            </Grid2>
          </Grid2>
        </Box>
      </Box>

      {politicalParties.length > 0 ? (
        <>
          <Box>
            <Grid2 container spacing={2}>
              <Grid2 xs={12} md={6}>
                <Box component={Card} className="card-primary">
                  <Box component={CardContent}>
                    <Typography variant="body2" fontWeight={600}>
                      PARTIDOS
                    </Typography>
                    <ListParties
                      politicalParties={politicalParties}
                      totalVotos={totalVotos}
                      handleVotes={handleVotes}
                    />
                  </Box>
                </Box>
              </Grid2>
              <Grid2 xs={12} md={6}>
                <Box component={Card} className="card-primary">
                  <Box component={CardContent}>
                    <Typography
                      variant="body2"
                      fontWeight={600}
                      marginBottom={2}
                    >
                      SUBIR IMAGEN DEL ACTA
                    </Typography>
                    <UploadSingleFile
                      file={actImage}
                      setFile={setActImage}
                      accept="image/*"
                    />
                  </Box>
                </Box>
                <Box component={Card} className="card-primary">
                  <Box component={CardContent}>
                    <Typography
                      variant="body2"
                      fontWeight={600}
                      marginBottom={2}
                    >
                      SUBIR IMAGEN DE LA SÁBANA
                    </Typography>
                    <UploadSingleFile
                      file={sheetImage}
                      setFile={setSheetImage}
                      accept="image/*"
                    />
                  </Box>
                </Box>

                <Stack
                  marginTop={2}
                  direction="row"
                  justifyContent={"center"}
                >
                  <Button
                    variant="contained"
                    color="primaryDark"
                    onClick={handleSetValueMinute}
                    disabled={politicalParties.some((item) => item.Votos > 2000)}
                    sx={{ width: "100%" }}
                  >
                    Guardar Acta
                  </Button>
                </Stack>
              </Grid2>
            </Grid2>
          </Box>
        </>
      ) : (
        <Box
          display={"flex"}
          flexDirection="column"
          alignItems={"center"}
          justifyContent="center"
          height={"60vh"}
        >
          <Box component="img" alt="no-data" src={NoData} width={200} />
          <Typography variant="subtitle2" marginTop={3} fontWeight={600}>
            Aplica los filtros para obtener los partidos.
          </Typography>
        </Box>
      )}

      <Snackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        variant="filled"
      >
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </Box>
  );
};

export default MinuteEscrutinio;

import React, { useEffect, useState } from "react";

//MUI
import {
  Box,
  Button,
  Collapse,
  Icon,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

// Middleware
import middleware from "@middlewares/middleware";

const FilterCollapse = ({
  children,
  expand,
  effect,
  handleExport = false,
  handleImport = false,
  fileImport = ".xlsx, .xls",
  isLoading = false,
}) => {
  //States
  const [checked, setChecked] = useState(expand ? expand : false);

  useEffect(() => {
    setChecked(expand ? expand : false);
    //eslint-disable-next-line
  }, [effect]);

  //Functions and Handlers
  const handleChecked = () => {
    setChecked((prevState) => !prevState);
  };

  // Exportar e Importar
  const canExport = middleware.checkMenuAction("Exportar");
  const canImport = middleware.checkMenuAction("Importar");

  // Limpiar el input de Importar
  const clearInput = () => {
    const input = document.getElementById("excel-upload");
    if (input) input.value = null;
  };

  return (
    <>
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        justifyContent="right"
        flexWrap="wrap"
      >
        <Box display="flex" gap={1} alignItems="center">
          <IconButton
            onClick={handleChecked}
            sx={{
              width: "32px",
              height: "32px",
              backgroundColor: checked ? "none" : "rgba(0, 0, 0, 0.04)",
              border: checked ? "1px solid #0041a0" : "none",
              borderRadius: "20%",
            }}
          >
            <Icon sx={{ color: checked && "#0041a0" }}>
              {checked
                ? "keyboard_double_arrow_up"
                : "keyboard_double_arrow_down"}
            </Icon>
          </IconButton>

          <Typography
            variant="subtitle2"
            fontWeight={700}
            color={checked && "#0041a0"}
          >
            FILTROS
          </Typography>
        </Box>

        <Box sx={{ flex: 1 }} />

        {handleImport && canImport && (
          <Box sx={{ width: { xs: "100%", sm: "auto" } }}>
            <input
              type="file"
              id="excel-upload"
              accept={fileImport}
              style={{ display: "none" }}
              disabled={isLoading}
              onChange={isLoading ? () => { } : handleImport}
              onClick={clearInput}
            />
            <label htmlFor="excel-upload">
              <Button
                component="span"
                variant="outlined"
                startIcon={
                  <Icon sx={{ color: isLoading ? "gray" : "green" }}>
                    upload
                  </Icon>
                }
                disabled={isLoading}
                sx={{
                  backgroundColor: "white",
                  color: "green",
                  border: isLoading ? "1px solid gray" : "1px solid green",
                  width: { xs: "100%", sm: "auto" },
                  height: "32px",
                  py: 0,
                }}
              >
                Importar
              </Button>
            </label>
          </Box>
        )}

        {handleExport && canExport && (
          <Button
            variant="outlined"
            startIcon={
              <Icon sx={{ color: isLoading ? "gray" : "blue" }}>download</Icon>
            }
            disabled={isLoading ? true : false}
            sx={{
              backgroundColor: "white",
              color: "blue",
              border: isLoading ? "1px solid gray" : "1px solid blue",
              width: { xs: "100%", sm: "auto" },
              height: "32px",
              py: 0,
            }}
            onClick={isLoading ? () => { } : handleExport}
          >
            Exportar
          </Button>
        )}
      </Stack>

      {checked && (
        <Box marginTop={"1rem"}>
          <Collapse in={checked}>
            <div>{children}</div>
          </Collapse>
        </Box>
      )}
    </>
  );
};

export default FilterCollapse;

import { PrivilegedRoute, ProtectedRoute } from "@middlewares";
import RollCall from "@pages/Bingos/RollCall";
import MovilizationCapture from "@pages/Bingos/MovilizationCapture";
import DashboardMobilization from "@pages/Bingos/DashboardMobilization";
import DashboardMobilizationTerritorial from "@pages/Bingos/DashboardMobilizationTerritorial";
import DashboardMobilizationElectoral from "@pages/Bingos/DashboardMobilizationElectoral";
import DownloadFileStructure from "@pages/Bingos/DownloadFileStructure";
import MobilizationAssign from "@pages/Bingos/MobilizationAssign";
import MovilizationCaptureSimulacro from "@pages/Bingos/Simulacro/MovilizationCapture";

const ActivationRoute = [
  {
    path: "/activacion-pase-lista",
    element: (
      <PrivilegedRoute>
        <RollCall />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/captura-movilizacion",
    element: (
      <PrivilegedRoute>
        <MovilizationCapture />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/dashboard-movilizacion",
    element: (
      <ProtectedRoute>
        <DashboardMobilization />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard-movilizacion-territorial",
    element: (
      <ProtectedRoute>
        <DashboardMobilizationTerritorial />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard-movilizacion-electoral",
    element: (
      <ProtectedRoute>
        <DashboardMobilizationElectoral />
      </ProtectedRoute>
    ),
  },
  {
    path: "/impresion-listados",
    element: (
      <PrivilegedRoute>
        <DownloadFileStructure />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/asignar-movilizadores",
    element: (
      <ProtectedRoute>
        <MobilizationAssign />
      </ProtectedRoute>
    ),
  },
  {
    path: "/simulacro-captura-movilizacion",
    element: (
      <PrivilegedRoute>
        <MovilizationCaptureSimulacro />
      </PrivilegedRoute>
    ),
  }
];

export default ActivationRoute;

import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// Material UI
import { Box, Grid, Typography, IconButton, Icon, Stack, Divider, Tooltip, Link } from "@mui/material";

// Middleware
import middleware from "@middlewares/middleware";

// Utils
import { SwalQR } from "@utils/alerts";

const CardEvent = (props) => {
  const { post, setType = () => {}, handleDelete = () => {}, canModify = false, URL = "" } = props;

  const navigate = useNavigate();

  const handleShare = () => {
    window.open(post.URL, "_blank", "noopener,noreferrer");
  };

  const hoy = new Date();
  const fechaHoy = new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate());

  const fechaFin = new Date(post.FechaFin);
  const fechaPost = new Date(fechaFin.getFullYear(), fechaFin.getMonth(), fechaFin.getDate());

  const YaPasoDia = fechaPost < fechaHoy;
  const YaPasoHora = fechaFin < hoy;

  return (
    <Grid container spacing={2} textAlign={"center"}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography gutterBottom variant="h5" component="div">
          {post.Actividad}
        </Typography>
      </Grid>

      {post.Descripcion && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
            <Icon>notes</Icon>
            <Typography variant="body1">{post.Descripcion}</Typography>
          </Stack>
        </Grid>
      )}

      {post.FechaInicio && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
            <Icon>today</Icon>
            <Typography variant="h6">{post.FechaInicio}</Typography>
          </Stack>
        </Grid>
      )}

      {post.NombreLugar && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
            <Icon>location_on</Icon>
            <Typography variant="h6">{post.NombreLugar}</Typography>
          </Stack>
        </Grid>
      )}

      {post.LigaVirtual && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
            <Icon>link</Icon>
            <Link href={post.LigaVirtual} target="_blank" rel="noopener noreferrer" underline="none">
              <Typography variant="h6" align="left">
                {post.LigaVirtual}
              </Typography>
            </Link>
          </Stack>
        </Grid>
      )}

      <Grid
        item
        xs={12}
        sx={{ display: "flex", gap: 2, justifyContent: "space-between", flexWrap: "wrap", px: 2 }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2" color="text.secondary">
            <strong>Creado:</strong> {post.FechaCreo}
          </Typography>
        </Box>

        <Box sx={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "end", gap: 2 }}>
          {canModify && (
            <>
              {!YaPasoDia && (
                <>
                  <Tooltip title="Ver QR del evento" disableInteractive arrow>
                    <IconButton
                      size="small"
                      aria-label="edit"
                      color="primary"
                      onClick={() => SwalQR({ title: `QR de ${post.Actividad}`, QR: post.UUID })}
                    >
                      <Icon>qr_code_scanner</Icon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Asistencia Manual" disableInteractive arrow>
                    <IconButton
                      size="small"
                      aria-label="edit"
                      color="info"
                      onClick={() => navigate(`${URL}pase-lista/${post.id}`)}
                    >
                      <Icon>front_hand</Icon>
                    </IconButton>
                  </Tooltip>
                </>
              )}

              {middleware.checkMenuAction("Agregar") && !YaPasoDia && (
                <Tooltip title="Agregar Staff" disableInteractive arrow>
                  <IconButton
                    size="small"
                    aria-label="edit"
                    color="secondary"
                    onClick={() => navigate(`${URL}staff/${post.id}`)}
                  >
                    <Icon>groups</Icon>
                  </IconButton>
                </Tooltip>
              )}

              {middleware.checkMenuAction("Eliminar") && !YaPasoHora && (
                <Tooltip title="Eliminar evento" disableInteractive arrow>
                  <IconButton size="small" aria-label="edit" color="error" onClick={() => handleDelete(post)}>
                    <Icon>delete</Icon>
                  </IconButton>
                </Tooltip>
              )}

              {middleware.checkMenuAction("Editar") && !YaPasoHora && (
                <Tooltip title="Editar evento" disableInteractive arrow>
                  <IconButton size="small" aria-label="edit" color="success" onClick={() => setType(3)}>
                    <Icon>edit</Icon>
                  </IconButton>
                </Tooltip>
              )}

              {middleware.checkMenuAction("Agregar") && !YaPasoDia && !post.TienePreregistro && (
                <Tooltip title="Agregar invitados" disableInteractive arrow>
                  <IconButton
                    size="small"
                    aria-label="edit"
                    color="secondary"
                    onClick={() => navigate(`${URL}invitados/${post.id}`)}
                  >
                    <Icon>add</Icon>
                  </IconButton>
                </Tooltip>
              )}

              {post.TienePreregistro === 1 && !YaPasoDia && (
                <Tooltip
                  title={post.TienePreregistro ? "Compartir Preregistro" : "No tiene Preregistro"}
                  disableInteractive
                  arrow
                >
                  <IconButton
                    size="small"
                    aria-label="shares"
                    onClick={post.TienePreregistro ? handleShare : () => {}}
                    disabled={!post.TienePreregistro}
                  >
                    <Icon>share</Icon>
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

CardEvent.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default CardEvent;

import React, { useState, useEffect } from "react";

//MUI
import { Box, Card, Grid, IconButton } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { Edit } from "@mui/icons-material";

import Swal from "sweetalert2";

//COMPONENTS
import CustomTable from "@components/Tables/CustomTable";
import RegisterForm from "@components/ppm/Register_form";

//SERVICES
import ppmServices from "@services/PpmServices";

//MIDDLEWARE
import middleware from "@middlewares/middleware";

//UTILS
import { convertBase64 } from "@utils/Utilities";

const Table_Registros = (props) => {
  const { setIsLoading, refresh } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page] = useState(0);
  const [pageSize] = useState(50);
  const [openModal, setOpenModal] = useState(false);
  const [params, setParams] = useState({});
  const hasEditPermission = middleware.checkMenuAction("Editar");

  const baseColumns = [
    { id: "NombreCompleto", label: "Nombre", align: "left" },
    { id: "Responsabilidad", label: "Responsabilidad", align: "left" },
    { id: "Ubicacion", label: "Ubicacion", align: "left" },
    { id: "image", label: "Imagen", align: "left" },
    ...(hasEditPermission ? [{ id: "Accion", label: "", align: "center" }] : []),
  ];

  const WhiteTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      color: "#000",
      boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
      fontSize: 14,
    },
  });

  const mapData = (data) =>
    data.map((item, index) => ({
      ...(hasEditPermission
        ? {
            Accion: (
              <IconButton onClick={() => handleEditClick(item)} sx={{ m: 0, p: 0 }} color="primaryDark">
                <Edit />
              </IconButton>
            ),
          }
        : {}),
      image: (
        <WhiteTooltip title={<img src={item.imagePath} alt={item.imageName} style={{ maxWidth: 200, maxHeight: 200 }} />} placement="right">
          <a href={item.imagePath} target="_blank" rel="noopener noreferrer">
            {item.imageName}
          </a>
        </WhiteTooltip>
      ),
      ...item,
    }));

  const handleEditClick = (value) => {
    setParams(value);
    setOpenModal(true);
  };

  const handleUpdateData = async (value) => {
    try {
      setIsLoading(true);
      const file = value.files[0]?.file;
      if (file) {
        if (value.comparar === file) {
          ["comparar", "files"].forEach((prop) => {
            if (value.hasOwnProperty(prop)) {
              delete value[prop];
            }
          });
        } else {
          const base64File = await convertBase64(file);
          value.files[0].file = base64File;
        }
      }
      ["imageName", "comparar"].forEach((prop) => {
        if (value.hasOwnProperty(prop)) {
          delete value[prop];
        }
      });
      const result = await ppmServices.updatePersonInfo(value);
      const { results, message } = result;
      if (results) {
        await ApiResponse()
        Swal.fire({
          title: message,
          icon: "success",
          customClass: {
            container: "modal-alert",
          },
        });
        setOpenModal(false);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
        customClass: {
          container: "modal-alert",
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const ApiResponse = async () => {
    try {
      setLoading(true);
      const result = await ppmServices.personInfo();
      const { results, response, message } = result;
      if (results) {
        const sortedData = response.data.sort((a, b) => {
          if (a.idResponsabilidad < b.idResponsabilidad) return -1;
          if (a.idResponsabilidad > b.idResponsabilidad) return 1;
          return 0;
        });

        setData(mapData(sortedData));
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
        customClass: {
          container: "modal-alert",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    ApiResponse();
    //eslint-disable-next-line
  }, [refresh]);

  return (
    <Box component={Card} variant="outlined" borderRadius={2} padding={2} minHeight={100} elevation={0}>
      <Grid container>
        <Grid item xs={12}>
          <CustomTable
            rows={data}
            columns={baseColumns}
            total={data.length}
            page={page}
            pageSize={pageSize}
            stickyHeader={true}
            isLoading={loading}
            maxHeight={600}
            disableCardType
          />
        </Grid>
      </Grid>
      {openModal && <RegisterForm setModalOpen={setOpenModal} modalOpen={openModal} params={params} setData={handleUpdateData} showInut={false} />}
    </Box>
  );
};

export default Table_Registros;

import { useState } from "react";

// Material UI
import { Grid, Button, Typography } from "@mui/material";

// Componentes
import AdaptiveCard from "@components/Card/AdaptiveCard";

const AdaptiveCardExamples = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAdjust, setIsAdjust] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  const handleLoading = () => setIsLoading(!isLoading);
  const handleAdjust = () => setIsAdjust(!isAdjust);
  const handleFixed = () => setIsFixed(!isFixed);

  return (
    <Grid container spacing={2} sx={{ p: 1 }}>
      <Grid item xs={12} sm={4}>
        <Button fullWidth variant="outlined" onClick={handleLoading}>
          {isLoading ? "No Loading" : "Loading"}
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button fullWidth variant="outlined" onClick={handleAdjust}>
          {isAdjust ? "No Ajustar" : "Ajustar"}
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button fullWidth variant="outlined" onClick={handleFixed}>
          {isFixed ? "No Decimal" : "Decimal"}
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3" align="center" sx={{ fontWeight: 800 }}>
          Circular
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard title="Titulo del grafico" icon="people" loading={isLoading} adjust={isAdjust} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          title="Titulo del grafico"
          icon="people"
          config={{ title: "Parte central", data: 43000 }}
          fixed={isFixed}
          loading={isLoading}
          adjust={isAdjust}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          title="Titulo del grafico"
          icon="people"
          config={{ title: "Parte central", data: 43000, percent: 10 }}
          fixed={isFixed}
          loading={isLoading}
          adjust={isAdjust}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          title="Titulo del grafico"
          icon="people"
          config={{ title: "Parte central", data: 43000, percent: 10 }}
          leftConfig={{ title: "Izquierda", data: 26000, percent: 30 }}
          fixed={isFixed}
          loading={isLoading}
          adjust={isAdjust}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          title="Titulo del grafico"
          icon="people"
          config={{ title: "Parte central", data: 43000, percent: 10 }}
          rightConfig={{ title: "Derecha", data: 17000, percent: 50 }}
          fixed={isFixed}
          loading={isLoading}
          adjust={isAdjust}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          title="Titulo del grafico"
          icon="people"
          config={{ title: "Parte central", data: 43000, percent: 50 }}
          leftConfig={{ title: "Izquierda", data: 26000, percent: 70 }}
          rightConfig={{ title: "Derecha", data: 17000, percent: 90 }}
          fixed={isFixed}
          loading={isLoading}
          adjust={isAdjust}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          title="Titulo del grafico"
          icon="people"
          leftConfig={{ title: "Izquierda", data: 0, percent: 0 }}
          rightConfig={{ title: "Derecha", data: 17000, percent: 90 }}
          fixed={isFixed}
          loading={isLoading}
          adjust={isAdjust}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          title="Titulo del grafico"
          icon="people"
          rightConfig={{ title: "Derecha", data: 17000, percent: 90 }}
          fixed={isFixed}
          loading={isLoading}
          adjust={isAdjust}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          title="Titulo del grafico"
          icon="people"
          leftConfig={{ title: "Izquierda", data: 0, percent: 0 }}
          fixed={isFixed}
          loading={isLoading}
          adjust={isAdjust}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          leftConfig={{ title: "Izquierda", data: 2000, percent: 60 }}
          fixed={isFixed}
          loading={isLoading}
          adjust={isAdjust}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          rightConfig={{ title: "Derecha", data: 17000, percent: 90 }}
          leftConfig={{ title: "Izquierda", data: 2000, percent: 60 }}
          fixed={isFixed}
          loading={isLoading}
          adjust={isAdjust}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          config={{ title: "Parte central", data: 43000, percent: 50 }}
          rightConfig={{ title: "Derecha", data: 17000 }}
          leftConfig={{ title: "Izquierda", data: 2000 }}
          fixed={isFixed}
          loading={isLoading}
          adjust={isAdjust}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          config={{ title: "Parte central", data: 43000, percent: 50 }}
          rightConfig={{ title: "Derecha", data: 17000, percent: 90 }}
          leftConfig={{ title: "Izquierda", data: 2000, percent: 60 }}
          fixed={isFixed}
          loading={isLoading}
          adjust={isAdjust}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3" align="center" sx={{ fontWeight: 800 }}>
          Linear
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          title="Titulo del grafico"
          icon="people"
          config={{ title: "Parte central", data: 43000, percent: 10, type: "linear" }}
          fixed={isFixed}
          loading={isLoading}
          adjust={isAdjust}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          title="Titulo del grafico"
          icon="people"
          config={{ title: "Parte central", data: 43000, percent: 10, type: "linear" }}
          leftConfig={{ title: "Izquierda", data: 26000, percent: 30, type: "linear" }}
          fixed={isFixed}
          loading={isLoading}
          adjust={isAdjust}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          title="Titulo del grafico"
          icon="people"
          config={{ title: "Parte central", data: 43000, percent: 10, type: "linear" }}
          rightConfig={{ title: "Derecha", data: 17000, percent: 50, type: "linear" }}
          fixed={isFixed}
          loading={isLoading}
          adjust={isAdjust}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          title="Titulo del grafico"
          icon="people"
          config={{ title: "Parte central", data: 43000, percent: 50, type: "linear" }}
          leftConfig={{ title: "Izquierda", data: 26000, percent: 70, type: "linear" }}
          rightConfig={{ title: "Derecha", data: 17000, percent: 90, type: "linear" }}
          fixed={isFixed}
          loading={isLoading}
          adjust={isAdjust}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          title="Titulo del grafico"
          icon="people"
          leftConfig={{ title: "Izquierda", data: 0, percent: 0, type: "linear" }}
          rightConfig={{ title: "Derecha", data: 17000, percent: 90, type: "linear" }}
          fixed={isFixed}
          loading={isLoading}
          adjust={isAdjust}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          title="Titulo del grafico"
          icon="people"
          rightConfig={{ title: "Derecha", data: 17000, percent: 90, type: "linear" }}
          fixed={isFixed}
          loading={isLoading}
          adjust={isAdjust}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          title="Titulo del grafico"
          icon="people"
          leftConfig={{ title: "Izquierda", data: 0, percent: 0, type: "linear" }}
          fixed={isFixed}
          loading={isLoading}
          adjust={isAdjust}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          leftConfig={{ title: "Izquierda", data: 2000, percent: 60, type: "linear" }}
          fixed={isFixed}
          loading={isLoading}
          adjust={isAdjust}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          rightConfig={{ title: "Derecha", data: 17000, percent: 90, type: "linear" }}
          leftConfig={{ title: "Izquierda", data: 2000, percent: 60, type: "linear" }}
          fixed={isFixed}
          loading={isLoading}
          adjust={isAdjust}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          config={{ title: "Parte central", data: 43000, percent: 50, type: "linear" }}
          rightConfig={{ title: "Derecha", data: 17000, type: "linear" }}
          leftConfig={{ title: "Izquierda", data: 2000, type: "linear" }}
          fixed={isFixed}
          loading={isLoading}
          adjust={isAdjust}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          config={{ title: "Parte central", data: 43000, percent: 50, type: "linear" }}
          rightConfig={{ title: "Derecha", data: 17000, percent: 90, type: "linear" }}
          leftConfig={{ title: "Izquierda", data: 2000, percent: 60, type: "linear" }}
          fixed={isFixed}
          noHover
          loading={isLoading}
          adjust={isAdjust}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3" align="center" sx={{ fontWeight: 800 }}>
          Linear y Circular
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          config={{ title: "Parte central", data: 43000, percent: 50, type: "linear" }}
          rightConfig={{ title: "Derecha", data: 17000, percent: 90 }}
          leftConfig={{ title: "Izquierda", data: 2000, percent: 60 }}
          fixed={isFixed}
          loading={isLoading}
          adjust={isAdjust}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          config={{ title: "Parte central", data: 43000, percent: 50 }}
          rightConfig={{ title: "Derecha", data: 17000, percent: 90, type: "linear" }}
          leftConfig={{ title: "Izquierda", data: 2000, percent: 60 }}
          fixed={isFixed}
          loading={isLoading}
          adjust={isAdjust}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          config={{ title: "Parte central", data: 43000, percent: 50 }}
          rightConfig={{ title: "Derecha", data: 17000, percent: 90 }}
          leftConfig={{ title: "Izquierda", data: 2000, percent: 60, type: "linear" }}
          fixed={isFixed}
          loading={isLoading}
          adjust={isAdjust}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3" align="center" sx={{ fontWeight: 800 }}>
          como Attendance
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          title="Titulo del grafico"
          icon="people"
          config={{ data: 43000, percent: 50 }}
          rightConfig={{ title: "Derecha", data: 17000 }}
          leftConfig={{ title: "Izquierda", data: 2000 }}
          fixed={isFixed}
          noHover
          adjust={isAdjust}
          loading={isLoading}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3" align="center" sx={{ fontWeight: 800 }}>
          Variantes de Cards
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          title="Grafico outlined"
          icon="people"
          config={{ data: 43000, percent: 50 }}
          rightConfig={{ title: "Derecha", data: 17000 }}
          leftConfig={{ title: "Izquierda", data: 2000 }}
          fixed={isFixed}
          adjust={isAdjust}
          loading={isLoading}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          title="Grafico outlined sin card"
          icon="people"
          config={{ data: 43000, percent: 50 }}
          rightConfig={{ title: "Derecha", data: 17000 }}
          leftConfig={{ title: "Izquierda", data: 2000 }}
          fixed={isFixed}
          adjust={isAdjust}
          loading={isLoading}
          variant="outlined"
          disableCardType
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdaptiveCard
          title="Grafico sin outlined ni card"
          icon="people"
          config={{ data: 43000, percent: 50 }}
          rightConfig={{ title: "Derecha", data: 17000 }}
          leftConfig={{ title: "Izquierda", data: 2000 }}
          fixed={isFixed}
          adjust={isAdjust}
          loading={isLoading}
          variant=""
          disableCardType
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AdaptiveCard
          title="Grafico con avatar"
          icon="people"
          config={{ data: 43000, percent: 50 }}
          rightConfig={{ title: "Derecha", data: 17000 }}
          leftConfig={{ title: "Izquierda", data: 2000 }}
          fixed={isFixed}
          adjust={isAdjust}
          loading={isLoading}
          avatar
        />
      </Grid>
    </Grid>
  );
};

export default AdaptiveCardExamples;

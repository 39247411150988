import { useState, useEffect } from "react";

import { Box, IconButton, ButtonGroup } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";

import Swal from "sweetalert2";

import BasicTable from "@components/Tables/BasicTable";
import FilterDataTable from "@components/Metas/FilterDataTable";

import metas from "@services/MetasServices";
import { numberWithCommas } from "@utils/Utilities/";
import middleware from "@middlewares/middleware";

const ElectoralTable = (props) => {
    const { catalogs, load, loading, setLoading, counter } = props;
    // TABLA
    const titulos = [
        { id: "accion", label: "Accion" },
        { id: "Perfil", label: "Perfil" },
        { id: "Municipio", label: "Municipio" },
        { id: "DistritoFederal", label: "DF" },
        { id: "Meta", label: "Meta", align: "right" },
    ];
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [params, setParams] = useState({
        page: 0,
        pageSize: 25,
        filtered: [],
        sorted: []
    });

    useEffect(() => {
        const fetchApi = async () => {
            if (params) {
                await fetchElectoralGoals(params);
            }
        }

        fetchApi();
        //eslint-disable-next-line
    }, [params]);

    useEffect(() => {
        const fetchApi = async () => {
            if (counter > 0) {
                await fetchElectoralGoals(params);
            }
        }

        fetchApi();
        //eslint-disable-next-line
    }, [counter]);

    //Mostrar registros en la tabla con paginado
    const handleChangePagination = (pagination) => {
        setParams({ ...params, ...pagination });
    }

    const handleChangeSort = (e) => {
        //Mientras no sea accion, se hará la llamada a la API
        if (e.orderBy !== "accion") {
            setParams({
                ...params, sorted: [{
                    id: e.orderBy,
                    value: e.order,
                }]
            });
        }
    }

    const handleFilter = async (action, values) => {
        let filter = [];
        if (action !== "clear") {
            if (values.perfil) {
                filter.push({
                    id: "idPerfil",
                    filter: "=",
                    value: values.perfil,
                });
            }
            if (values.municipio) {
                filter.push({
                    id: "idMunicipioReportes",
                    filter: "=",
                    value: values.municipio,
                });
            }
            if (values.df) {
                filter.push({
                    id: "idDF",
                    filter: "=",
                    value: values.df,
                });
            }
        }

        setParams({ ...params, page: 0, filtered: filter, sorted: [] });
    }


    const fetchElectoralGoals = async (params) => {
        setLoading({
            isLoading: true,
            isOpen: true,
            isSuccess: false,
            loadingMessage: "Consultando...",
            successMessage: "Consultado con éxito!",
        });

        try {
            const result = await metas.getElectoralGoals(params);
            const { message, results, response } = result;

            if (results) {
                setData(response.data.map((item) => ({
                    ...item,
                    accion: (
                        <ButtonGroup>
                            {middleware.checkMenuAction("Eliminar") &&
                                (
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => {
                                            deleteGoal(item.idPerfil, item.idMunicipioReportes, item.idDF);
                                        }}
                                    >
                                        <DeleteIcon aria-label="delete" color="error" />
                                    </IconButton>
                                )}
                            {/* <IconButton
                                aria-label="edit"
                                onClick={() => {
                                    deleteGoal(item.idPerfil, item.idMunicipioReportes, item.idDF);
                                }}
                            >
                                <EditIcon aria-label="edit" color="info" />
                            </IconButton> */}
                        </ButtonGroup>
                    ),
                    Meta: numberWithCommas(item.Meta),
                })))
                setTotal(response.total)
            } else {
                Swal.fire({
                    title: message,
                    icon: "warning",
                });
                setData([]);
                setTotal(0);
            }
        } catch (error) {
            setData([]);
            setTotal(0);
        } finally {
            setLoading({
                isLoading: false,
                isOpen: false,
                isSuccess: true,
                loadingMessage: "Consultando...",
                successMessage: "Consultado con éxito!",
            });

        }
    }

    const deleteGoal = async (idPerfil, idMunicipioReportes, idDF) => {
        Swal.fire({
            title: "Advertencia",
            text: "¿Está seguro que desea eliminar el registro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, eliminar",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading({
                    isLoading: true,
                    isOpen: true,
                    isSuccess: false,
                    loadingMessage: "Eliminando...",
                    successMessage: "Eliminado con éxito!",
                });

                try {
                    const _params = {
                        idPerfil,
                        idMunicipioReportes,
                        idDF,
                    };
                    const result = await metas.deleteElectoralGoal(_params);
                    const { message, success } = result;

                    if (success) {
                        Swal.fire({
                            title: message,
                            icon: "success",
                        });

                        await fetchElectoralGoals(params);
                    } else {
                        Swal.fire({
                            title: message,
                            icon: "warning",
                        });
                    }
                } catch (error) {
                    Swal.fire({
                        title: error.message,
                        icon: 'warning'
                    });

                } finally {
                    setLoading({
                        ...loading,
                        isLoading: false,
                        isOpen: false,
                    });
                }
            }
        });
    };

    return (
        <Box mt={2}>
            <FilterDataTable
                catalogs={catalogs}
                load={load}
                isLoading={loading.isLoading}
                handleFilter={handleFilter}
            />

            <BasicTable
                pageSize={params.pageSize}
                pageProp={params.page}
                handleChangePagination={handleChangePagination}
                handleManualSort={handleChangeSort}
                rows={data}
                hcolumns={titulos}
                total={total}
                stickyHeader={true}
                isLoading={loading.isLoading}
                handleSelectItem={() => { }}
                showPagination={true}
                manual={false}
                cardType={false}
            />
        </Box>
    )
}

export default ElectoralTable;
import { useState, useEffect } from "react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    Button,
    Box,
    Table,
    TableContainer,
    TableBody,
    TableCell,
    TableRow,
    TableFooter
} from "@mui/material";
import Swal from "sweetalert2";
import { QRCode } from "react-qrcode-logo";
import link from "@assets/img/connection.png";
import { URL_PUBLICA_FRONT, ClipboardCopy } from "../../utils/global";

import UserServices from "@services/UserServices";

const UserInfo = (props) => {
    const {data} = props;
    const [shortUrl, setShortUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([
        {
          ResponsabilidadEtiqueta: "Sin Responsabilidad",
          AsignadoPor: "Sian GTO",
          idUsuarioCreo: 451,
          FechaCreo: "2024-01-29 17:02:30",
        },
      ]);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(()=>{

        if(data?.id){
            setShortUrl(`${URL_PUBLICA_FRONT}invitado/${data.UUID}`);
            getResponsibilities(data.id);
        }

    },[data]);


    const getResponsibilities = (id) => {
        setLoading(true);
    
        UserServices.getResponsibilities(id)
          .then((res) => {
            if (res.results) {
              let list_responsibilities = res.response.data;
              setRows(list_responsibilities);
            }
          })
          .catch((error) =>
            Swal.fire({
              title: error,
              icon: "warning",
            })
          )
          .finally(() => setLoading(false));
      };

    return  (
    <Grid2 container>
        <Grid2 item lg={4} xs={12} md={12}>
        <Card
            className="card-primary"
            sx={{
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            }}
        >
            <CardMedia
            component={() => (
                <QRCode
                value={shortUrl}
                size={256}
                fgColor="#1865B8"
                style={{
                    display: "block",
                    margin: "auto",
                }}
                logoImage={link}
                />
            )}
            alt="QR Code"
            title="QR Code"
            sx={{ marginBottom: "20px" }}
            />
            <CardContent
            sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                border: "10px",
            }}
            >
            <Typography
                variant="body1"
                align="center"
                sx={{
                marginBottom: "20px",
                whiteSpace: "pre-wrap",
                }}
            >
                Copia el siguiente link para invitar a los
                familiares y amigos de {data.Nombre} {data.Paterno} para formar
                parte de su red para defender a Guanajuato.
            </Typography>
            <Button
                variant="text"
                href={shortUrl}
                target="_blank"
                sx={{
                minWidth: "150px",
                width: "80%",
                maxWidth: "300px",
                fontSize: "0.9rem",
                }}
            >
                {shortUrl}
            </Button>
            <ClipboardCopy
                copyText={`Hola, te invito a defender a Guanajuato, sé parte de la red de ${data.Nombre} ${data.Paterno}  registrándote en ${shortUrl}`}
            />
            </CardContent>
        </Card>
        </Grid2>
        <Grid2 item lg={8} xs={12} md={12}>
        <Box sx={{ p: 1}}>
            {
              rows.length > 0 && <Typography gutterBottom variant="body2">
              Responsabilidades:
            </Typography>
            }
            <TableContainer component={Card} >
              <Table>
                <TableBody>
                  {(rowsPerPage > 0
                    ? rows.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : rows
                  ).map((row) => (
                    <TableRow key={row.name}>
                      <TableCell
                        style={{ minWidth: "50px", textAlign: "center" }}
                      >
                        {row.ResponsabilidadEtiqueta}
                      </TableCell>
                      <TableCell style={{ minWidth: "200px" }}>
                          {row.AsignadoPor}
                      </TableCell>
                      <TableCell /* style={{ minWidth: "50px",textAlign:"center" }} */
                      >
                        {row.FechaCreo}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter></TableFooter>
              </Table>
            </TableContainer>
          </Box>
        </Grid2>
    </Grid2>);
}
export default UserInfo;
import { useState, useEffect } from "react";

// Material UI
import {
  Box,
  Grid,
  Typography,
  Divider,
  FormLabel,
  FormControl,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormGroup,
  Checkbox,
  OutlinedInput,
  Button,
  Icon,
  CircularProgress,
} from "@mui/material";

// Utilidades
import { isNullOrUndefined } from "@utils/validations";

const PreguntasRespuestasEventos = (props) => {
  const {
    titulo,
    subtitulo = "registro",
    preguntas = [],
    handleChange = () => {},
    buttonOptions = {},
    justify = "center",
    loading = false,
  } = props;
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    const answersNew = preguntas.map((item) => ({
      idPregunta: item.idPregunta,
      idTipoRespuesta: item.idTipoRespuesta,
      idRespuesta: [],
    }));

    setAnswers(answersNew);
    // eslint-disable-next-line
  }, [preguntas]);

  const handleChangeRadio = ({ value }, { idPregunta, idTipoRespuesta }) => {
    const filterAnswers = answers.filter((item) => item.idPregunta !== idPregunta);
    const selectedAnswer = parseInt(value);

    const answerObject = { idPregunta, idTipoRespuesta, idRespuesta: [selectedAnswer] };
    setAnswers([...filterAnswers, answerObject]);
  };

  const handleAnswerChange = ({ value, checked }, { idPregunta, idTipoRespuesta }) => {
    const filterAnswers = answers.filter((item) => item.idPregunta !== idPregunta);
    const findAnswer = answers.find((item) => item.idPregunta === idPregunta);
    const checkedValue = parseInt(value);

    if (checked) {
      // Agregar respuesta seleccionada
      const answerObject = {
        idPregunta,
        idTipoRespuesta,
        idRespuesta: [...findAnswer?.idRespuesta, checkedValue],
      };
      setAnswers([...filterAnswers, answerObject]);
    } else {
      // Eliminar respuesta deseleccionada
      const idNewRespuesta = findAnswer?.idRespuesta?.filter((fil) => fil !== checkedValue);
      const answerObject = { idPregunta, idTipoRespuesta, idRespuesta: idNewRespuesta };
      setAnswers([...filterAnswers, answerObject]);
    }
  };

  const handleChangeString = ({ value }, { idPregunta, idTipoRespuesta }) => {
    const filterAnswers = answers.filter((item) => item.idPregunta !== idPregunta);

    const answerObject = { idPregunta, idTipoRespuesta, idRespuesta: [], ValorRespuesta: value };
    setAnswers([...filterAnswers, answerObject]);
  };

  const handleValidateAll = () => {
    const validate = preguntas.some((item) => handleValidate(item) === true);
    return validate;
  };

  const handleValidate = ({ idPregunta, idTipoRespuesta, Requerido }) => {
    if (!Requerido) return false;
    if (!answers.length) return false;

    const { idRespuesta, ValorRespuesta } = answers?.filter((item) => item.idPregunta === idPregunta)[0];

    if ([1, 2].includes(idTipoRespuesta)) {
      if (idRespuesta.length === 0) return true;
    } else if (idTipoRespuesta === 3) {
      if (isNullOrUndefined(ValorRespuesta) || ValorRespuesta.trim() === "") return true;
    }

    return false;
  };

  return (
    <Box>
      <Typography
        variant="h5"
        color="primary"
        fontWeight="bold"
        align="center"
        sx={{ fontFamily: "initial" }}
      >
        {titulo}
      </Typography>

      <Typography variant="body1" color="text" mb={3} align="center">
        {`Conteste las siguientes preguntas para realizar su ${subtitulo}.`}
      </Typography>

      <Divider variant="middle" sx={{ border: 1, color: "blue" }} />

      <Grid container sx={{ mt: 3, px: 2 }} spacing={4}>
        {preguntas.map((question) => (
          <Grid item xs={12}>
            {question.idTipoRespuesta === 1 ? (
              <FormControl required={question.Requerido} error={handleValidate(question)}>
                <FormLabel id="pg_opcion">{question.Pregunta}</FormLabel>
                <RadioGroup
                  aria-labelledby="pg_opcion"
                  name="Una opcion"
                  value={
                    answers.length > 0
                      ? answers.find((a) => a.idPregunta === question.idPregunta).idRespuesta[0]
                      : ""
                  }
                  onChange={(e) => handleChangeRadio(e.target, question)}
                >
                  {question.Respuestas.map(({ idRespuesta, Respuesta }) => (
                    <FormControlLabel
                      key={idRespuesta}
                      value={idRespuesta}
                      control={<Radio />}
                      label={Respuesta}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            ) : question.idTipoRespuesta === 2 ? (
              <FormControl
                component="fieldset"
                required={question.Requerido}
                error={handleValidate(question)}
              >
                <FormLabel component="legend" id="pg_multiple">
                  {question.Pregunta}
                </FormLabel>
                <FormGroup
                  aria-label="position"
                  aria-labelledby="pg_multiple"
                  onChange={(e) => handleAnswerChange(e.target, question)}
                >
                  {question.Respuestas.map(({ idRespuesta, Respuesta }) => (
                    <FormControlLabel
                      key={idRespuesta}
                      value={idRespuesta}
                      control={<Checkbox />}
                      label={Respuesta}
                      labelPlacement="end"
                    />
                  ))}
                </FormGroup>
              </FormControl>
            ) : (
              <FormControl
                fullWidth
                variant="standard"
                required={question.Requerido}
                error={handleValidate(question)}
              >
                <FormLabel htmlFor="pg_abierta">{question.Pregunta}</FormLabel>
                <OutlinedInput
                  sx={{ mt: 1 }}
                  id="pg_abierta"
                  placeholder="Respuesta"
                  multiline
                  onChange={(e) => handleChangeString(e.target, question)}
                />
              </FormControl>
            )}
          </Grid>
        ))}

        <Grid item xs={12} sx={{ display: "flex", justifyContent: justify }}>
          <Button
            size="small"
            color="primary"
            onClick={() => handleChange(answers)}
            variant={buttonOptions?.variant ?? "contained"}
            disabled={handleValidateAll()}
            endIcon={
              buttonOptions?.icon ? (
                loading ? (
                  <CircularProgress size={18} />
                ) : (
                  <Icon>{buttonOptions?.icon}</Icon>
                )
              ) : undefined
            }
          >
            {buttonOptions?.title ?? "Registrar Asistencia"}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PreguntasRespuestasEventos;

import { useState, useEffect } from "react";
import Swal from "sweetalert2";

// Material UI
import { Grid, Button, Stack } from "@mui/material";

// Componentes
import BasicSelect from "@components/Selects/BasicSelect";

// Services y Utils
import CatalogService from "@services/CatalogServices";
import { setVars, getVars } from "@utils/global";

const Filter = (props) => {
  const { catalogs, loadingCatalogs, handleFilter, region, municipio, poligono, seccion, width = "300px" } = props;

  const [catMunicipiosReportes, setCatMunicipiosReportes] = useState([]);
  const [catPoligonos, setCatPoligonos] = useState([]);
  const [catSecciones, setCatSecciones] = useState([]);

  const [isLoadingCatMunicipiosReportes, setIsLoadingMunicipiosReportes] = useState(false);
  const [isLoadingCatPoligonos, setIsLoadingCatPoligonos] = useState(false);
  const [isLoadingCatSecciones, setIsLoadingCatSecciones] = useState(false);

  const [filter, setFilter] = useState({
    idEstructuraSocial: "",
    idRegion: "",
    idMunicipio: "",
    idPoligono: "",
    Seccion: "",
  });

  const getCatalogsWithStored = () => {
    const filterSavedMapMunicipio = getVars("filterSavedMapMunicipio", []);
    const filterSavedMapPoligono = getVars("filterSavedMapPoligono", []);
    const filterSavedMapSeccion = getVars("filterSavedMapSeccion", []);
    setCatMunicipiosReportes(filterSavedMapMunicipio);
    setCatPoligonos(filterSavedMapPoligono);
    setCatSecciones(filterSavedMapSeccion);
  };

  useEffect(() => {
    const filter_saved = getVars("filterSavedMap", {
      idEstructuraSocial: "",
      idRegion: "",
      idMunicipio: "",
      idPoligono: "",
      Seccion: "",
    });
    if (
      filter_saved.idEstructuraSocial !== "" ||
      filter_saved.idRegion !== "" ||
      filter_saved.idMunicipio !== "" ||
      filter_saved.idPoligono !== "" ||
      filter_saved.Seccion !== ""
    ) {
      setFilter(filter_saved);
      getCatalogsWithStored();
    }
    // eslint-disable-next-line
  }, []);

  const loadCatalogsByRegion = (idRegion = null) => {
    let catalogsParams = [
      {
        id: "municipios_mujeres",
        getAll: false,
        filtered: [],
      },
    ];
    if (idRegion) {
      catalogsParams = [
        {
          id: "municipios_mujeres",
          getAll: false,
          filtered: [
            {
              id: "cat_municipios_mujeres.idRegion",
              filter: "=",
              value: idRegion,
            },
          ],
        },
      ];
    }

    setIsLoadingCatPoligonos(true);
    setIsLoadingCatSecciones(true);
    setIsLoadingMunicipiosReportes(true);
    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.success && res.results) {
          setCatMunicipiosReportes([{ value: 0, label: "TODOS" }].concat(res.response.catalogs.municipios_mujeres));
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingCatPoligonos(false);
        setIsLoadingCatSecciones(false);
        setIsLoadingMunicipiosReportes(false);
      });
  };
  const loadCatalogsByMunicipio = (idMunicipio = null) => {
    let catalogsParams = [
      {
        id: "poligonos",
        getAll: false,
        filtered: [{ id: "cat_secciones.idRegion", filter: "=", value: filter.idRegion }],
      },
      {
        id: "secciones",
        getAll: false,
        filtered: [{ id: "cat_secciones.idRegion", filter: "=", value: filter.idRegion }],
      },
    ];
    if (idMunicipio) {
      catalogsParams = [
        {
          id: "poligonos",
          getAll: false,
          filtered: [
            {
              id: "cat_secciones.idMunicipio",
              filter: "=",
              value: idMunicipio,
            },
          ],
        },
        {
          id: "secciones",
          getAll: false,
          filtered: [
            {
              id: "cat_secciones.idMunicipio",
              filter: "=",
              value: idMunicipio,
            },
          ],
        },
      ];
    }
    setIsLoadingCatPoligonos(true);
    setIsLoadingCatSecciones(true);

    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.success && res.results) {
          setCatPoligonos([{ value: 0, label: "TODOS" }].concat(res.response.catalogs.poligonos));
          setCatSecciones([{ value: 0, label: "TODAS" }].concat(res.response.catalogs.secciones));
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingCatPoligonos(false);
        setIsLoadingCatSecciones(false);
      });
  };
  const loadCatalogsByPoligono = (idPoligono = null) => {
    let filter_temp = [];
    if (filter.idRegion !== "") {
      filter_temp.push({ id: "cat_secciones.idRegion", filter: "=", value: filter.idRegion });
    }
    if (filter.idMunicipio !== "") {
      filter_temp.push({ id: "cat_secciones.idMunicipio", filter: "=", value: filter.idMunicipio });
    }
    let catalogsParams = [
      {
        id: "secciones",
        getAll: false,
        filtered: filter_temp,
      },
    ];
    if (idPoligono) {
      catalogsParams = [
        {
          id: "secciones",
          getAll: false,
          filtered: [{ id: "cat_secciones.idPoligono", filter: "=", value: idPoligono }],
        },
      ];
    }

    setIsLoadingCatSecciones(true);

    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.success && res.results) {
          setCatSecciones([{ value: 0, label: "TODAS" }].concat(res.response.catalogs.secciones));
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingCatSecciones(false);
      });
  };

  const handleChangeEstructura = (event) => {
    const idEstructuraSocial = event.target.value;
    const idRegion = catalogs?.regiones?.length === 1 ? catalogs.regiones[0].value : 0;
    if (idRegion === 0) loadCatalogsByRegion();

    setFilter({
      idEstructuraSocial: idEstructuraSocial,
      idRegion: idRegion,
      idMunicipio: "",
      idPoligono: "",
      Seccion: "",
    });
  };

  const handleChangeRegion = (event) => {
    const idRegion = event.target.value;
    setFilter({
      idEstructuraSocial: filter.idEstructuraSocial,
      idRegion: idRegion,
      idMunicipio: "",
      idPoligono: "",
      Seccion: "",
    });
    if (idRegion > 0) {
      loadCatalogsByRegion(idRegion);
    } else {
      loadCatalogsByRegion();
    }
  };

  const handleChangeMunicipio = (e) => {
    const value = e.target.value;
    setFilter({
      idEstructuraSocial: filter.idEstructuraSocial,
      idRegion: filter.idRegion,
      idMunicipio: value,
      idPoligono: "",
      Seccion: "",
    });
    setVars("filterSavedMap", {
      idEstructuraSocial: filter.idEstructuraSocial,
      idRegion: filter.idRegion,
      idMunicipio: value,
      idPoligono: "",
      Seccion: "",
    });
    if (value > 0) {
      loadCatalogsByMunicipio(value);
    } else {
      loadCatalogsByMunicipio();
    }
  };
  const handleChangePoligono = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
      Seccion: "",
    });
    loadCatalogsByPoligono(e.target.value);
  };
  const handleChangeSeccion = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const handleClick = () => {
    let filter_data = [];

    if (filter.idEstructuraSocial !== "") {
      filter_data.push({
        id: "mms.idEstructuraSocial",
        filter: "=",
        value: filter.idEstructuraSocial,
      });
    }
    if (filter.idRegion !== "") {
      filter_data.push({
        id: "cat_secciones.idRegion",
        filter: filter.idRegion === 0 ? "IN" : "=",
        value: filter.idRegion === 0 ? [1, 2, 3, 4, 5, 6, 7, 8] : filter.idRegion,
      });
    }
    if (filter.idMunicipio !== "") {
      filter_data.push({
        id: "cat_secciones.id",
        filter: "=",
        value: filter.idMunicipio,
      });
    }
    if (filter.idPoligono !== "") {
      if (filter.idPoligono !== 0)
        filter_data.push({
          id: "cat_secciones.idPoligono",
          filter: "=",
          value: filter.idPoligono,
        });
    }
    if (filter.Seccion !== "") {
      if (filter.Seccion !== 0)
        filter_data.push({
          id: "cat_secciones.Seccion",
          filter: "=",
          value: filter.Seccion,
        });
    }

    handleFilter(filter_data);

    setVars("filterSavedMapMunicipio", catMunicipiosReportes);
    setVars("filterSavedMapPoligono", catPoligonos);
    setVars("filterSavedMapSeccion", catSecciones);
    setVars("filterSavedMap", {
      idEstructuraSocial: filter.idEstructuraSocial,
      idRegion: filter.idRegion,
      idMunicipio: filter.idMunicipio,
      idPoligono: filter.idPoligono,
      Seccion: filter.Seccion,
    });
  };

  const handleClear = () => {
    let filter_data = [];

    const idEstructuraSocial = catalogs?.estructuras?.length === 1 ? catalogs.estructuras[0].value : "";
    const idRegion = catalogs?.regiones?.length === 1 ? catalogs.regiones[0].value : 0;

    if (filter.idEstructuraSocial !== "") {
      filter_data.push({
        id: "mms.idEstructuraSocial",
        filter: "=",
        value: idEstructuraSocial,
      });
    }
    if (filter.idRegion !== "") {
      filter_data.push({
        id: "cat_secciones.idRegion",
        filter: "=",
        value: idRegion,
      });
    }

    handleFilter(filter_data);

    setVars("filterSavedMapMunicipio", catMunicipiosReportes);
    setVars("filterSavedMapPoligono", catPoligonos);
    setVars("filterSavedMapSeccion", catSecciones);
    setVars("filterSavedMap", {
      idEstructuraSocial: idEstructuraSocial,
      idRegion: idRegion,
      idMunicipio: "",
      idPoligono: "",
      Seccion: "",
    });
  };

  // Llena inicialmente al tener solo 1 registro
  useEffect(() => {
    if (catalogs) {
      const params = getVars("filterSavedMap", {
        idEstructuraSocial: "",
        idRegion: "",
        idMunicipio: "",
        idPoligono: "",
        Seccion: "",
      });

      if (
        params.idEstructuraSocial === "" &&
        params.idRegion === "" &&
        params.idMunicipio === "" &&
        params.idPoligono === "" &&
        params.Seccion === ""
      ) {
        params.idEstructuraSocial = catalogs?.estructuras?.length === 1 ? catalogs.estructuras[0].value : "";
        params.idRegion = catalogs?.regiones?.length === 1 ? catalogs.regiones[0].value : 0;
        params.idMunicipio = catalogs?.municipios?.length === 1 ? catalogs.municipios[0].value : "";
        params.idPoligono = catalogs?.poligonos?.length === 1 ? catalogs.idPoligono[0].value : "";
        params.Seccion = catalogs?.secciones?.length === 1 ? catalogs.Seccion[0].value : "";

        setFilter(params);

        // Llama la API de municipios
        if (params.idRegion > 0) loadCatalogsByRegion(params.idRegion);
        else loadCatalogsByRegion();
      }
    }

    // eslint-disable-next-line
  }, [catalogs]);

  return (
    <>
      <Grid container rowSpacing={1} sx={{ width: { xs: "100%", sm: width } }}>
        <Grid item xs={12}>
          <BasicSelect
            label="Estructura Social"
            name="estructura"
            options={catalogs.estructuras}
            value={filter.idEstructuraSocial}
            onChange={handleChangeEstructura}
            isLoading={loadingCatalogs}
            sx={{ width: "100%" }}
          />
        </Grid>
        {region ? (
          <Grid item xs={12}>
            <BasicSelect
              label="Región"
              name="region"
              options={catalogs.regiones}
              value={filter.idRegion}
              onChange={handleChangeRegion}
              isLoading={loadingCatalogs}
              sx={{ width: "100%" }}
            />
          </Grid>
        ) : (
          <></>
        )}

        {municipio ? (
          <Grid item xs={12}>
            <BasicSelect
              name="idMunicipio"
              label="Muncipio"
              options={catMunicipiosReportes}
              value={filter.idMunicipio}
              onChange={handleChangeMunicipio}
              isLoading={loadingCatalogs || isLoadingCatMunicipiosReportes}
              sx={{ width: "100%" }}
            />
          </Grid>
        ) : (
          <></>
        )}

        {poligono ? (
          <Grid item xs={12}>
            <BasicSelect
              name="idPoligono"
              label="Poligono"
              options={catPoligonos}
              value={filter.idPoligono}
              onChange={handleChangePoligono}
              isLoading={loadingCatalogs || isLoadingCatPoligonos}
              sx={{ width: "100%" }}
            />
          </Grid>
        ) : (
          <></>
        )}

        {seccion ? (
          <Grid item xs={12}>
            <BasicSelect
              name="Seccion"
              label="Sección"
              options={catSecciones}
              value={filter.Seccion}
              onChange={handleChangeSeccion}
              isLoading={loadingCatalogs || isLoadingCatSecciones}
              sx={{ width: "100%" }}
            />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      <br></br>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <Button
          size="small"
          style={{ borderRadius: "20px", alignSelf: "center" }}
          variant="outlined"
          disabled={filter.idEstructuraSocial === "" || (filter.idRegion === "" && filter.idMunicipio === "")}
          onClick={handleClick}
        >
          Filtrar
        </Button>
        <Button
          size="small"
          style={{ borderRadius: "20px", alignSelf: "center" }}
          variant="outlined"
          disabled={filter.idEstructuraSocial === "" || (filter.idRegion === "" && filter.idMunicipio === "")}
          onClick={handleClear}
        >
          Limpiar
        </Button>
      </Stack>
    </>
  );
};

export default Filter;

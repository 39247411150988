import { PrivilegedRoute, ProtectedRoute } from "@middlewares";

import Eventos from "@pages/Events/Eventos";
import PreguntasPost from "@pages/Events/PreguntasPost";
import Invitados from "@pages/Events/Invitados";
import InvitadosEvent from "@pages/Events/InvitadosEvent";
import Staff from "@pages/Events/Staff";
import StaffEvent from "@pages/Events/StaffEvent";
import EventosPaseLista from "@pages/Events/EventosPaseLista";
import EventosPaseListaInfo from "@pages/Events/EventosPaseListaInfo";
import Noticias from "@pages/Noticias/Noticias";
import Publicaciones from "@pages/Foca/Publicaciones";

import { PAGINA_FOCA } from "@data/constants/Noticias";

const ids = [7, 8, 9, 10, 11];

const FocaRoutes = [
  {
    path: "/foca/eventos",
    element: (
      <PrivilegedRoute>
        <Eventos idsTipoActividad={ids} id="foca" />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/foca/eventos/preguntas",
    element: (
      <ProtectedRoute>
        <PreguntasPost idsTipoActividad={ids} id="foca" />
      </ProtectedRoute>
    ),
  },
  {
    path: "/foca/eventos/invitados",
    element: (
      <PrivilegedRoute>
        <Invitados idsTipoActividad={ids} id="foca" />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/foca/eventos/invitados/:id",
    element: (
      <ProtectedRoute>
        <InvitadosEvent />
      </ProtectedRoute>
    ),
  },
  {
    path: "/foca/eventos/staff",
    element: (
      <PrivilegedRoute>
        <Staff idsTipoActividad={ids} id="foca" />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/foca/eventos/staff/:id",
    element: (
      <ProtectedRoute>
        <StaffEvent />
      </ProtectedRoute>
    ),
  },
  {
    path: "/foca/eventos/pase-lista",
    element: (
      <PrivilegedRoute>
        <EventosPaseLista idsTipoActividad={ids} />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/foca/eventos/pase-lista/:id",
    element: (
      <ProtectedRoute>
        <EventosPaseListaInfo />
      </ProtectedRoute>
    ),
  },
  {
    path: "/foca/noticias",
    element: (
      <PrivilegedRoute>
        <Noticias page={PAGINA_FOCA} />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/foca/publicaciones",
    element: (
      <PrivilegedRoute>
        <Publicaciones />
      </PrivilegedRoute>
    ),
  },
];

export default FocaRoutes;

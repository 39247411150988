import { useState } from "react";

// Material UI
import { Card, CardContent } from "@mui/material";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import CustomTabs from "@components/Tabs/CustomTabs";
import Form from "@components/Foca/PublicacionesForm";
import View from "@components/Foca/PublicacionesView";

// Middleware
import middleware from "@middlewares/middleware";

const Publicaciones = () => {
  const add = middleware.checkMenuAction("Agregar");
  const editable = middleware.checkMenuAction("Editar") || middleware.checkMenuAction("Eliminar");

  const [tab, setTab] = useState(add ? 0 : 1);

  return (
    <AdminLayout>
      <ActionHeader title="Publicaciones" />

      <Card className="card-primary">
        <CardContent>
          <CustomTabs
            value={tab}
            setValue={setTab}
            tabs={[
              {
                value: 0,
                label: "Agregar",
                icon: "add",
                sxContent: { pt: 0 },
                component: <Form />,
                display: add,
              },
              {
                value: 1,
                label: editable ? "Editar" : "Ver",
                icon: editable ? "edit" : "visibility",
                sxContent: { pt: 0 },
                component: <View />,
              },
            ]}
          />
        </CardContent>
      </Card>
    </AdminLayout>
  );
};

export default Publicaciones;

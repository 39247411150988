import { ProtectedRoute } from "@middlewares";
import ListMecanismos from "../../pages/cde/ListMecanismos";

const CDERoute = [
  {
    path: "/cde-mecanismos",
    element: (
      <ProtectedRoute>
        <ListMecanismos />
      </ProtectedRoute>
    ),
  }
];

export default CDERoute;

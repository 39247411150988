import { Fragment, useEffect, useState } from "react";
import {
    Box,
    TextField,
    FormHelperText,
    InputAdornment,
    Divider,
    Button,
    Icon,
    CircularProgress,
    Alert,
    AlertTitle
} from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2";
import {
    isValidSection,
    isEmptyOrInvalidString
} from "@utils/validations";
import BasicSelect from "@components/Selects/BasicSelect";

export const SelectFunction = (props) => {
    const { label, values, touched, handleBlur, errors, options, handleChange } = props;
    return (
        <Grid2 container spacing={2}>
            <Grid2 item xs={12} sm={12} md={6} lg={6}>
                <BasicSelect
                    error={
                        touched.idPerfil &&
                        !isEmptyOrInvalidString(errors.idPerfil)
                    }
                    label={`Función ${label} a desempeñar`}
                    name="idPerfil"
                    value={values.idPerfil}
                    options={options}
                    onChange={(e) => handleChange(e.target.value)}
                    onBlur={handleBlur}
                    size="small"
                    sx={{ width: "100%" }}
                    errorMessage={errors.idPerfil}
                />
            </Grid2>
        </Grid2>
    )
}

export const ValidateSection = (props) => {
    const {
        children,
        catalogs,
        handleChange,
        setBtnDisabled,
        sectionValid,
        setSectionValid,
        sectionValue,
        setSectionValue
    } = props;

    const [loadingStatus, setLoadingStatus] = useState(false);
    const [statusValidated, setStatusValidated] = useState(false);
    const [sectionData, setSectionData] = useState({
        value: "",
        label: "",
        idPoligono: "",
        idDF: "",
        idDL: "",
        idRegion: "",
        idMunicipio: "",
        idMunicipioReportes: "",
        Municipio: "",
        Poligono: "",
    });

    useEffect(() => {
        if (sectionValue === "") {
            setStatusValidated(false);
            setSectionValid(false);
            setLoadingStatus(false);
        }
        //eslint-disable-next-line
    }, [sectionValue])

    const handleOnKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            validateSection(sectionValue);
        }
    };

    const validateSection = (value) => {
        const validationPromise = new Promise((resolve, reject) => {
            let section = catalogs.secciones.find(
                (item) => item.value === parseInt(value)
            );
            if (!section) {
                reject({
                    success: false,
                    message: "Sección inválida",
                });
            } else {
                let idMun = catalogs.municipios_dl.find(
                    (item) => item.value === section.idMunicipioReportes
                ).value;
                handleChange({ target: { name: "idMunicipio", value: idMun } });
                const data = {
                    ...section,
                    Municipio: catalogs.municipios.find(
                        (item) => item.value === section.idMunicipio
                    ).label,
                    Poligono: catalogs.poligonos.find(
                        (item) => item.value === section.idPoligono
                    ).label,
                };

                resolve({
                    success: true,
                    data,
                });
            }
        });

        setStatusValidated(false);
        setLoadingStatus(true);
        setBtnDisabled(true);

        validationPromise
            .then((res) => {
                setBtnDisabled(false);
                setSectionValid(true);
                setSectionData(res.data);
            })
            .catch((error) => {
                setBtnDisabled(true);
                setSectionValid(false);
            })
            .finally(() => {
                setStatusValidated(true);
                setLoadingStatus(false);
            });
    };



    return (
        <Fragment>
            <Grid2 xs={12} sm={12} md={6} lg={6}>
                <TextField
                    required
                    label="Sección"
                    type={"text"}
                    name="sectionValue"
                    size="small"
                    color="info"
                    className="fixed-input"
                    placeholder="Validar Sección"
                    value={sectionValue}
                    onChange={(e) => {
                        setSectionValue(
                            isValidSection(e.target.value)
                                ? e.target.value
                                : sectionValue
                        );
                        setBtnDisabled(true);
                        setStatusValidated(false);
                        setSectionValid(false);
                    }}
                    onKeyUp={handleOnKeyPress}
                    InputProps={{
                        maxLength: 4,
                        endAdornment: (
                            <InputAdornment position="end">
                                <Divider
                                    sx={{ height: 28, m: 0.5 }}
                                    orientation="vertical"
                                />
                                <Button
                                    color="warning"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        validateSection(sectionValue);
                                    }}
                                >
                                    <Icon>search</Icon>
                                    Validar
                                </Button>
                            </InputAdornment>
                        ),
                    }}
                    error={!sectionValid}
                />
                <FormHelperText error>
                    {!sectionValid && "Ingrese una sección válida"}
                </FormHelperText>
                <br />
                {children}
            </Grid2>
            <Grid2 xs={12} sm={12} md={6} lg={6}>
                {loadingStatus ? (
                    <CircularProgress color="info" />
                ) : (
                    statusValidated && (

                        <Alert severity={sectionValid ? "success" : "error"}>
                            {sectionValid ? (
                                <>
                                    <AlertTitle>Sección válida</AlertTitle>
                                    Municipio:{" "}
                                    <strong>{sectionData.Municipio}</strong> <br />
                                    Polígono: <strong>{sectionData.Poligono}</strong>
                                </>
                            ) : (
                                <AlertTitle>Sección inválida</AlertTitle>
                            )}
                        </Alert>
                    )
                )}
            </Grid2>
        </Fragment>
    )
}

export const FormButtons = (props) => {
    const { submitForm, handleClearForm, btnDisabled } = props;

    return (
        <Grid2 xs={12} sm={12} md={12} lg={12}>
            <Box sx={{ width: "100%", display: "flex" }}>
                <Button
                    type="button"
                    color="error"
                    variant="contained"
                    size="small"
                    onClick={handleClearForm}
                    startIcon={<Icon>cancel</Icon>}
                >
                    Cancelar
                </Button>
                <Button
                    type="button"
                    color="primaryDark"
                    variant="contained"
                    size="small"
                    onClick={submitForm}
                    startIcon={<Icon>save</Icon>}
                    disabled={btnDisabled}
                >
                    Guardar
                </Button>
            </Box>
        </Grid2>
    )
}
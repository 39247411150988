import { useState } from "react";

import Swal from "sweetalert2";
import sije from "../../services/SijeService";
import movilizacion from "../../services/MovilizationServices";
import compromiso from "../../services/PrepServices";

export const useMobileServices = () => {
  const [snackbar, setSnackbar] = useState({
    show: false,
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [loadingGet, setLoadingGet] = useState(false);

  /* Pase Lista */
  const setPaseListaMasivo = async (params) => {
    try {
      setLoading(true);
      const { results, success, message } = await sije.setPaseListaMasivoMobile(
        params
      );

      if (!results) throw new Error(message);

      if (results && success) {
        setSnackbar({ show: true, message: message });
        return true;
      } else {
        return false;
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoading(false);
    }
  };

  /* Instalacion Casillas */
  const setInstalacionCasillas = async (params) => {
    try {
      setLoading(true);
      const { results, success, message } =
        await sije.setInstalacionCasillasMobile(params);

      if (!results) throw new Error(message);

      if (results && success) {
        setSnackbar({ show: true, message: message });
        return true;
      } else {
        return false;
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoading(false);
    }
  };

  /* Apertura Casilla */
  const setAperturaCasillas = async (params) => {
    try {
      setLoading(true);
      const { results, success, message } = await sije.setAperturaCasillaMobile(
        params
      );

      if (!results) throw new Error(message);

      if (results && success) {
        setSnackbar({ show: true, message: message });
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoading(false);
    }
  };

  /* Bingo  */
  const setBingo = async (params) => {
    try {
      setLoading(true);
      const { results, success, message } = await movilizacion.setParamsBingo(
        params
      );

      if (!results) throw new Error(message);

      if (results && success) {
        setSnackbar({ show: true, message: message });
        return true;
      } else {
        return false;
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoading(false);
    }
  };

  const getBingosList = async (params) => {
    try {
      setLoadingGet(true);
      const { results, success, message, response } =
        await movilizacion.getBingosList(params);

      if (!results) throw new Error(message);

      if (results && success) {
        return response;
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoadingGet(false);
    }
  };

  /* Incidencias  */
  const setIncidencia = async (params) => {
    try {
      setLoading(true);
      const { results, success, message } = await sije.setSijeIncidenciasMobile(
        params
      );

      if (!results) throw new Error(message);

      if (results && success) {
        setSnackbar({ show: true, message: message });
        return true;
      } else {
        return false;
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoading(false);
    }
  };

  /* Cierre Casilla */

  const setCierreCasilla = async (params) => {
    try {
      setLoading(true);
      const { results, success, message } = await sije.setCierreCasillaMobile(
        params
      );

      if (!results) throw new Error(message);

      if (results && success) {
        setSnackbar({ show: true, message: message });
        return true;
      } else {
        return false;
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoading(false);
    }
  };

  /* Fin de votación */
  const setFinVotacion = async (params) => {
    try {
      setLoading(true);
      const { results, success, message } = await sije.setCierreVotacionMobile(
        params
      );

      if (!results) throw new Error(message);

      if (results && success) {
        setSnackbar({ show: true, message: message });
        return true;
      } else {
        return false;
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoading(false);
    }
  };

  /* Reporte Resultados (PREP) */
  const getForm = async (params) => {
    try {
      setLoadingGet(true);
      const { results, success, message, response } = await compromiso.getForm(
        params
      );

      if (!results) throw new Error(message);

      if (results && success) {
        return response;
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoadingGet(false);
    }
  };

  const setForm = async (params) => {
    try {
      setLoading(true);
      const { results, success, message } = await compromiso.saveForm(params);

      if (!results) throw new Error(message);

      if (results && success) {
        setSnackbar({ show: true, message: message });
        return true;
      } else {
        return false;
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoading(false);
    }
  };

  /* Transmisión */
  const setTransmision = async (params) => {
    try {
      setLoading(true);
      const { results, success, message } = await sije.setTransmision(params);

      if (!results) throw new Error(message);

      if (results && success) {
        setSnackbar({ show: true, message: message });
        return true;
      } else {
        return false;
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoading(false);
    }
  };

  return {
    snackbar,
    loading,
    loadingGet,
    setSnackbar,
    setPaseListaMasivo,
    setInstalacionCasillas,
    setAperturaCasillas,
    setBingo,
    getBingosList,
    setIncidencia,
    setCierreCasilla,
    setFinVotacion,
    getForm,
    setForm,
    setTransmision,
  };
};

import { ApiExecDecrypted } from "@utils/ApiExec";

class MapService {
  getShapes = (params) => {
    return ApiExecDecrypted(params, "map/prep/get-shapes", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getColoresPartidos = (params) => {
    return ApiExecDecrypted(params, "map/prep/get-colores-partidos", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
}

const map = new MapService();
export default map;

import { useEffect, useState } from "react";
import { useCatalogs } from "@hooks/useCatalogs";
import { CAT_SOLVED, CAT_STATUS } from "@data/constants/Sije";
import { isTypePhone } from "@utils/validations";

import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Button, Stack, TextField } from "@mui/material";

import FilterCollapse from "@components/Filters/FilterCollapse";
import BasicSelect from "@components/Selects/BasicSelect";
import AdvancedSelect from "@components/Selects/AdvancedSelect";

const Filter = ({ onChangeFilter }) => {
  //CONSTANTS
  const catalogsOptions = [
    { id: "df", getAll: false },
    { id: "secciones", getAll: false },
    { id: "dl", getAll: false },
    { id: "municipios_reportes", getAll: false },
    { id: "mpio_dl", getAll: false },
    { id: "mpio_df", getAll: false },
    { id: "incidencias", getAll: false },
  ];
  const defaultOption = { value: 0, label: "TODOS" };

  //States
  const [catalogsFiltered, setCatalogFiltered] = useState({});
  const [filterData, setFilterData] = useState({
    DttoLocal: 0,
    DttoFederal: 0,
    Municipio: 0,
    Seccion: 0,
    TelRG: "",
    Casilla: "",
    RG: "",
    Estatus: 0,
    isResuelto: "",
    Incidencia: 0,
  });
  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsOptions,
    putDefaultOption: true,
  });

  const handleChangeFilter = ({ name, value }) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const handleChangeDttoFederal = (e) => {
    const idDttoFederal = e.value;

    if (idDttoFederal > 0) {
      setCatalogFiltered((prevState) => ({
        ...prevState,
        municipios_reportes: [defaultOption].concat(
          catalogs.mpio_df
            .filter((item) => item.idDF === idDttoFederal)
            .map((item) => ({
              value: item.idMunicipioReportes,
              label: item.Municipio,
            }))
        ),
        secciones: [defaultOption].concat(
          catalogs.secciones.filter((item) => item.idDF === idDttoFederal)
        ),
      }));

      setFilterData((prevState) => ({
        ...prevState,
        Municipio: 0,
        Seccion: 0,
      }));
    } else {
      setCatalogFiltered((prevState) => ({
        ...prevState,
        municipios_reportes: catalogs.municipios_reportes,
        secciones: catalogs.secciones,
      }));
      setFilterData((prevState) => ({
        ...prevState,
        Municipio: 0,
        Seccion: 0,
      }));
    }
  };

  const handleChangeDttoLocal = (e) => {
    const idDttoLocal = e.value;

    if (idDttoLocal > 0) {
      setCatalogFiltered((prevState) => ({
        ...prevState,
        municipios_reportes: [defaultOption].concat(
          catalogs.mpio_dl
            .filter((item) => item.idDL === idDttoLocal)
            .map((item) => ({
              value: item.idMunicipioReportes,
              label: item.Municipio,
            }))
        ),
        secciones: [defaultOption].concat(
          catalogs.secciones.filter((item) => item.idDL === idDttoLocal)
        ),
      }));
      setFilterData((prevState) => ({
        ...prevState,
        Municipio: 0,
        Seccion: 0,
      }));
    } else {
      setCatalogFiltered((prevState) => ({
        ...prevState,
        municipios_reportes: catalogs.municipios_reportes,
        secciones: catalogs.secciones,
      }));
      setFilterData((prevState) => ({
        ...prevState,
        Municipio: 0,
        Seccion: 0,
      }));
    }
  };

  const handleChangeMunicipality = (e) => {
    //const idMunicipality = e.target.value;
    const idMunicipality = e.value;

    if (idMunicipality > 0) {
      setCatalogFiltered((prevState) => ({
        ...prevState,
        secciones: [defaultOption].concat(
          catalogs.secciones.filter(
            (item) => item.idMunicipioReportes === idMunicipality
          )
        ),
      }));
      setFilterData((prevState) => ({
        ...prevState,
        Seccion: 0,
      }));
    } else {
      setCatalogFiltered((prevState) => ({
        ...prevState,
        secciones: catalogs.secciones,
      }));
      setFilterData((prevState) => ({
        ...prevState,
        Seccion: 0,
      }));
    }
  };

  const handleSearch = () => {
    let filtered = [];
    if (filterData.DttoFederal > 0) {
      filtered = [
        ...filtered,
        {
          id: "DFED",
          filter: "=",
          value: filterData.DttoFederal,
        },
      ];
    }

    if (filterData.DttoLocal > 0) {
      filtered = [
        ...filtered,
        {
          id: "DLOC",
          filter: "=",
          value: filterData.DttoLocal,
        },
      ];
    }
    if (filterData.Municipio > 0) {
      filtered = [
        ...filtered,
        {
          id: "idMunicipioReportes",
          filter: "=",
          value: filterData.Municipio,
        },
      ];
    }
    if (filterData.Seccion > 0) {
      filtered = [
        ...filtered,
        {
          id: "Seccion",
          filter: "=",
          value: filterData.Seccion,
        },
      ];
    }
    if (filterData.TelRG.length > 0) {
      filtered = [
        ...filtered,
        {
          id: "TelefonoRG",
          filter: "=",
          value: filterData.TelRG,
        },
      ];
    }
    if (filterData.Casilla.length > 0) {
      filtered = [
        ...filtered,
        {
          id: "NombreCasilla",
          filter: "=",
          value: filterData.Casilla.toUpperCase(),
        },
      ];
    }
    if (filterData.RG.length > 0) {
      filtered = [
        ...filtered,
        {
          id: "RG",
          filter: "LIKE",
          value: filterData.RG.toUpperCase(),
        },
      ];
    }
    if (filterData.Estatus > 0) {
      filtered = [
        ...filtered,
        {
          id: "idEstatus",
          filter: "=",
          value: filterData.Estatus,
        },
      ];
    }
    if (filterData.isResuelto !== 9999) {
      if (filterData.isResuelto === 0 || filterData.isResuelto === 1) {
        filtered = [
          ...filtered,
          {
            id: "esResuelto",
            filter: "=",
            value: filterData.isResuelto,
          },
        ];
      }
    }
    if (filterData.Incidencia > 0) {
      filtered = [
        ...filtered,
        {
          id: "idIncidencia",
          filter: "=",
          value: filterData.Incidencia,
        },
      ];
    }

    onChangeFilter(filtered);
  };

  const handleClearFilter = () => {
    const cleanFileds = {
      DttoLocal: 0,
      DttoFederal: 0,
      Municipio: 0,
      Seccion: 0,
      TelRG: "",
      Casilla: "",
      RG: "",
      Estatus: 0,
      isResuelto: "",
      Incidencia: 0,
    };
    setCatalogFiltered(catalogs);
    setFilterData(cleanFileds);
    onChangeFilter([]);
  };

  /*   useEffect(() => {
    getCatalogs();
    // eslint-disable-next-line
  }, []); */

  useEffect(() => {
    if (!load) setCatalogFiltered(catalogs);
  }, [load]);

  return (
    <FilterCollapse expand>
      <Grid2 container spacing={2}>
        <Grid2 xs={12} md={3} lg={3}>
          <AdvancedSelect
            name="DttoFederal"
            label="Distrito Federal"
            options={catalogsFiltered.df}
            value={filterData.DttoFederal}
            onChange={(e) => {
              handleChangeFilter({
                name: e.name,
                value: e.value,
              });
              handleChangeDttoFederal(e);
            }}
            isSearchable
            isLoading={load}
            sx={{ width: "100%" }}
          />
        </Grid2>
        <Grid2 xs={12} md={3} lg={3}>
          <AdvancedSelect
            name="DttoLocal"
            label="Distrito Local"
            options={catalogsFiltered.dl}
            value={filterData.DttoLocal}
            onChange={(e) => {
              handleChangeFilter({ name: e.name, value: e.value });
              handleChangeDttoLocal(e);
            }}
            isSearchable
            isLoading={load}
            sx={{ width: "100%" }}
          />
        </Grid2>
        <Grid2 xs={12} md={3} lg={3}>
          <AdvancedSelect
            name="Municipio"
            label="Municipio"
            options={catalogsFiltered.municipios_reportes}
            value={filterData.Municipio}
            onChange={(e) => {
              handleChangeFilter({ name: e.name, value: e.value });
              handleChangeMunicipality(e);
            }}
            isSearchable
            isLoading={load}
            sx={{ width: "100%" }}
          />
        </Grid2>
        <Grid2 xs={12} md={3} lg={3}>
          <AdvancedSelect
            name="Seccion"
            label="Sección"
            options={catalogsFiltered.secciones?.map((item) => {
              return { value: item.value, label: String(item.label) };
            })}
            value={filterData.Seccion}
            onChange={(e) => {
              handleChangeFilter({ name: e.name, value: e.value });
            }}
            isSearchable
            isLoading={load}
            sx={{ width: "100%" }}
          />
        </Grid2>
      </Grid2>

      <Grid2 container spacing={2}>
        <Grid2 xs={12} md={3} lg={3}>
          <TextField
            label="Tel RG"
            name="TelRG"
            size="small"
            sx={{ width: "100%" }}
            value={filterData.TelRG}
            onChange={(e) =>
              handleChangeFilter({
                name: e.target.name,
                value: isTypePhone(e.target.value)
                  ? e.target.value
                  : filterData.TelRG,
              })
            }
          />
        </Grid2>
        <Grid2 xs={12} md={3} lg={3}>
          <TextField
            label="Casilla"
            name="Casilla"
            size="small"
            sx={{ width: "100%" }}
            value={filterData.Casilla.toUpperCase()}
            onChange={(e) => {
              handleChangeFilter({
                name: e.target.name,
                value: e.target.value,
              });
            }}
          />
        </Grid2>
        <Grid2 xs={12} md={3} lg={3}>
          <TextField
            label="RG"
            name="RG"
            size="small"
            sx={{ width: "100%" }}
            value={filterData.RG.toUpperCase()}
            onChange={(e) => {
              handleChangeFilter({
                name: e.target.name,
                value: e.target.value,
              });
            }}
          />
        </Grid2>
        <Grid2 xs={12} md={3} lg={3}>
          <BasicSelect
            name="Estatus"
            label="Estatus"
            options={[defaultOption, ...CAT_STATUS]}
            value={filterData.Estatus}
            onChange={(e) => {
              handleChangeFilter({
                name: e.target.name,
                value: e.target.value,
              });
            }}
            isLoading={load}
            sx={{ width: "100%" }}
          />
        </Grid2>
      </Grid2>

      <Grid2 container spacing={2}>
        <Grid2 xs={12} md={3} lg={3}>
          <BasicSelect
            name="isResuelto"
            label="Solucionado"
            options={CAT_SOLVED}
            value={filterData.isResuelto}
            onChange={(e) => {
              handleChangeFilter({
                name: e.target.name,
                value: e.target.value,
              });
            }}
            isLoading={load}
            sx={{ width: "100%" }}
          />
        </Grid2>
        <Grid2 xs={12} md={9} lg={9}>
          <AdvancedSelect
            name="Incidencia"
            label="Incidencia"
            options={catalogsFiltered.incidencias}
            value={filterData.Incidencia}
            onChange={(e) => {
              handleChangeFilter({ name: e.name, value: e.value });
            }}
            isSearchable
            isLoading={load}
            sx={{ width: "100%" }}
          />
        </Grid2>
      </Grid2>
      <Stack
        direction={"row"}
        marginTop={2}
        justifyContent={"flex-end"}
        spacing={2}
      >
        <Button onClick={handleClearFilter} color="primaryDark">
          Limpiar
        </Button>
        <Button variant="contained" color="primaryDark" onClick={handleSearch}>
          Filtrar
        </Button>
      </Stack>
    </FilterCollapse>
  );
};

export default Filter;

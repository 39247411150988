import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { CircularProgress } from "@mui/material";

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
    numericSymbols: null,
  },
});

const BasicLine = (props) => {
  const {
    categories,
    series,
    title,
    xName,
    loading = false,
    height,
    lineColor = "#666666",
    legend = {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },
    initialPosition
  } = props;
  const [data, setData] = useState(null);

  useEffect(() => {
    setData({
      chart: {
        type: "spline",
        height: height,
        scrollablePlotArea: {
          minWidth: categories.length * 100,
          scrollPositionX: initialPosition ? 0 : 1,
        },
      },
      title: {
        text: title.primary,
        align: "left",
      },
      subtitle: {
        text: title.sub,
        align: "center",
      },
      yAxis: {
        title: {
          text: xName,
        },
      },
      xAxis: {
        categories: categories,
        accessibility: {
          description: "",
        },
      },
      legend: legend,
      credits: {
        enabled: false,
      },
      plotOptions: {
        spline: {
          marker: {
            radius: 4,
            lineColor: lineColor,
            lineWidth: 1,
          },
          dataLabels: {
            enabled: true,
            // format: "{y}",
          },
        },
      },
      series: series,
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
              },
            },
          },
        ],
      },
    });
    // eslint-disable-next-line
  }, [series, title]);

  return data !== null || loading ? (
    <HighchartsReact highcharts={Highcharts} options={data} />
  ) : (
    <CircularProgress />
  );
};

export default BasicLine;

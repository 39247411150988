import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import moment from "moment";
import MovilizacionService from "@services/MovilizationServices";
import Swal from "sweetalert2";
import { Box } from "@mui/material";

const CurrentProgressChart = (props) => {
  const { openDrawer, filtered } = props;
  const [data, setData] = useState({
    title: "",
    date: "",
    categories: [],
    series: [],
  });
  const ApiResponse = async () => {
    try {
      let params = {
        filtered: filtered.filtered2,
      };
      const result = await MovilizacionService.getCapturistAdvanceToday(params);
      const { results, message, response } = result;
      if (results) {
        setData({
          title: response.title,
          date: response.date,
          categories: response.categories,
          series: response.series.map((item) => ({
            name: item.name,
            data: item.data.map(Number),
          })),
        });
      } else {
        setData({
          ...data,
          categories: [],
          series: [],
        });
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
    }
  };

  useEffect(() => {
    if (filtered.filtered2) {
      ApiResponse();
    }
  }, [filtered]);

  const options = {
    chart: {
      type: "bar",
      scrollablePlotArea: {
        minHeight: data.categories.length * 40,
        scrollPositionY: 0,
      },
    },
    title: {
      text: data.title,
      align: "center",
    },
    subtitle: {
      text: moment(data.date).format("DD/MM/YYYY"),
      align: "center",
    },
    xAxis: {
      categories: data.categories,
      title: {
        text: null,
      },
      scrollbar: {
        enabled: true,
        showFull: true,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
        align: "",
      },
      labels: {
        overflow: "justify",
      },
      gridLineWidth: 0,
    },
    tooltip: {
      
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        dataLabels: {
          enabled: true,
        },
        pointWidth: 20,
        groupPadding: 0.1,
        borderWidth: 1,
        borderColor: "gray",
      },
    },
    legend: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    series: data.series,
  };

  return (
    <Box key={openDrawer}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Box>
);
};

export default CurrentProgressChart;

import { useState, useEffect, useCallback } from "react";
import Swal from "sweetalert2";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import Map from "@components/Maps/CustomComponents/GenericMap";
import Filter from "@components/MapsModules/Cartography/Filter";
import Colors from "@components/Maps/InternalComponents/Cards/Colors";
import {
  onEachFeature,
  highlightFeature,
  cardInfoComponent,
  elements,
} from "@components/MapsModules/Cartography/GlobalFunctions";

// Servicios y utilidades
import CatalogService from "@services/CatalogServices";
import CartographyService from "@services/MapServices/CartographyServices";
import { setVars } from "@utils/global";

// Estilos de mapas
import { style_original_data, style_original_sub_data } from "@components/Maps/Auxiliars/ColorFeature";

const colorManzana = [
  { title: "Prioridad 1", color: "#B2EBF0" },
  { title: "Prioridad 2", color: "#FDF8C0" },
  { title: "Prioridad 3", color: "#D4D4D4" },
];

const Maps = () => {
  const [polygons, setPolygons] = useState({ type: "FeatureCollection", features: [] });
  const [polygonsBlocks, setPolygonsBlocks] = useState(null);

  const [isLoadingCatalogs, setIsLoadingCatalogs] = useState(true);
  const [catalogs, setCatalogs] = useState({ regiones: [], municipios: [] });

  const getEmptyMap = () => setPolygons({ type: "FeatureCollection", features: [] });

  const handleFilterExect = (_filter) => {
    const SeccionFiltrada = _filter.filter((item) => item.id === "cat_secciones.Seccion");
    if (SeccionFiltrada.length === 0) {
    }
    handleGetShape(_filter /* {Seccion:_filter[0].value} */);
  };

  const loadCatalogs = useCallback(() => {
    let catalogsParams = [
      { id: "regiones", getAll: false },
      { id: "municipios_dl", getAll: false },
    ];

    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.results) {
          if (res.response.errors.length > 0) {
            Swal.fire({
              title: "Algunos catálogos NO pudieron ser cargados. Contacte al administrador",
              icon: "warning",
            });
          }

          const catalogsResponse = res.response.catalogs;
          setCatalogs((prevState) => {
            return {
              ...prevState,
              municipios: catalogsResponse.municipios_dl
                ? [{ value: 0, label: "TODOS" }].concat(catalogsResponse.municipios_dl)
                : [],
              regiones: catalogsResponse.regiones
                ? [{ value: 0, label: "TODAS" }].concat(catalogsResponse.regiones)
                : [],
            };
          });
        } else Swal.fire({ title: res.message, icon: "warning" });
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingCatalogs(false);
      });
  }, []);

  useEffect(() => {
    setVars("filterSavedMap", {
      idRegion: "",
      idMunicipioReportes: "",
      idPoligono: "",
      Seccion: "",
    });
    getEmptyMap();
    loadCatalogs();
    // eslint-disable-next-line
  }, []);

  const FilterComponent = (
    <Filter
      catalogs={catalogs}
      loadingCatalogs={isLoadingCatalogs}
      handleFilter={handleFilterExect}
      region={1}
      municipio={1}
      poligono={1}
      seccion={1}
    />
  );

  const handleGetShape = (e) => {
    let data_parameters = {
      shp: "seccion",
      color: 1,
      page: 0,
      pageSize: 10,
      filtered: e, //[{id:"cat_secciones.Seccion", filter:"=", value: e.Seccion}],
    };
    const data_parameters_secc = {
      shp: "seccion",
      color: 0,
      page: 0,
      pageSize: 10,
      filtered: e, //[{id:"cat_secciones.Seccion", filter:"=", value: e.Seccion}],
    };

    setPolygonsBlocks(null);
    CartographyService.getBlocks(data_parameters)
      .then((res) => {
        if (res.results) setPolygonsBlocks(res.response.data);
        else Swal.fire({ title: res.message, icon: "warning" });
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      });

    setPolygons(null);
    CartographyService.getSections(data_parameters_secc)
      .then((res) => {
        if (res.results) setPolygons(res.response.data);
        else Swal.fire({ title: res.message, icon: "warning" });
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      });
  };

  const handleDownloadFile = (e) => {
    CartographyService.downloadShapeCartography({ hashCode: e.HashCode, type: "SM" })
      .then((res) => {
        if (res.success) Swal.fire({ title: "¡Descarga exitosa!", icon: "success" });
        else Swal.fire({ title: res.error.message, icon: "warning" });
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      });
  };

  const cardComponent = {
    component: <Colors elements={colorManzana} height={24} spacing={1} />,
    title: "Colores",
  };

  return (
    <AdminLayout delPadding>
      <Map
        data={polygons}
        subData={polygonsBlocks}
        drawerLeft={{ open: true, component: FilterComponent, width: { sm: 300, md: 350 } }}
        cardInfo={{
          initialValues: { title: "INFORMACIÓN", elements: elements["seccion"] },
          button: { title: "Descargar PDF", handleClick: handleDownloadFile },
          component: { component: cardInfoComponent, validShp: ["manzana"] },
        }}
        cardComponent={cardComponent}
        highlightFeature={highlightFeature}
        onEachFeature={(a, b, c, d) => onEachFeature(a, b, c, d, elements["seccion"][0].name)}
        centerByFeatures
        styleProp={{
          data: style_original_data,
          subData: style_original_sub_data,
          highlightFeature: style_original_data,
        }}
      />
    </AdminLayout>
  );
};

export default Maps;

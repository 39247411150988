import { useEffect, useState } from "react";
import moment from "moment";
import Swal from "sweetalert2";
//Mui
import {
    Box,
    Card,
    CardContent,
    Container,
    FormControlLabel,
    Radio,
    RadioGroup,
    Skeleton,
    Grid,
    ButtonGroup,
    Button
} from "@mui/material";

//Components
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import PrepFilter from "@components/Prep/PrepSamplingFilter";
import PrepSamplingHeaders from "@components/Prep/PrepSamplingHeaders";
import Chart from "@components/Prep/ChartColumComparison";
import DrawerRelative from "@components/Containers/DrawerRelative";
import FloatingButton from "@components/Button/FloatingButton";
import PrepConteoRapidoMuestreo from "@pages/Prep/Prep/PrepConteoRapidoMuestreo";

import prep from "@services/PrepServices";

const PrepMuestreo = () => {
    const [series, setSeries] = useState([]);
    const [vista, setVista] = useState(1);
    const [loadingChart, setLoadingChart] = useState(true);
    const [loadingFilter, setLoadingFilter] = useState(true);

    const [sampling, setSampling] = useState(true);
    const [params, setParams] = useState([])

    const [openDrawer, setOpenDrawer] = useState(true);

    const [summaryData, setSummaryData] = useState({
        TipoEleccion: "",
        TotalActas: 0,
        ActasRecibidas: 0,
        TotalVotos: 0,
        LNom: 0,
        FechaHora: moment().format('DD/MM/YYYY HH:mm'),
        PActas: 0,
        Participacion: 0,
        Ambito: ""
    })

    useEffect(()=> {
        setVista(1)
    }, [sampling])

    const handleChangeRadio = (event) => {
        setVista(event.target.value);
    };

    const handleChangeFilter = (filtered) => {
        let params = {
            ...filtered,
            idTipoVista: parseInt(vista),
            Muestra: sampling ? 1 : 0
        };

        setParams(params)

        getChart(params);
    }

    const getChart = (params) => {
        setLoadingChart(true);
        setLoadingFilter(true);
        prep.getSamplingChart(params).then(res => {
            const { results, response, message } = res;
            if (results) {
                setSeries(response.data.series);
                setSummaryData(response.data.summary);
            } else {
                setSeries([]);
                setSummaryData({
                    TipoEleccion: "",
                    TotalActas: 0,
                    ActasRecibidas: 0,
                    TotalVotos: 0,
                    LNom: 0,
                    FechaHora: moment().format('DD/MM/YYYY HH:mm'),
                    PActas: 0,
                    Participacion: 0,
                    Ambito: ""
                });
                Swal.fire({
                    title: message,
                    icon: "warning",
                });
            }
        }).catch(error => {
            setSeries([]);
            setSummaryData({
                TipoEleccion: "",
                TotalActas: 0,
                ActasRecibidas: 0,
                TotalVotos: 0,
                LNom: 0,
                FechaHora: moment().format('DD/MM/YYYY HH:mm'),
                PActas: 0,
                Participacion: 0,
                Ambito: ""
            });
            Swal.fire({
                title: error.message,
                icon: "warning",
            });
        }).finally(() => {
            setLoadingChart(false);
            setLoadingFilter(false);
        });
    }

    const handleRefresh = () => {
        getChart(params)
    };


    return (
        <AdminLayout>
            <Container maxWidth={false}>
                <ActionHeader title="PREP (Muestreo)" />
            </Container>

            <Grid container spacing={2} marginTop={2} justifyContent={'center'}>
                <ButtonGroup size="large" variant="outlined">
                    <Button
                        color="info"
                        key={'fastcount'}
                        onClick={e => setSampling(true)}
                        variant={sampling ? 'contained' : 'outlined'}
                        sx={{
                            borderWidth: '1px',
                            borderRadius: '30px',
                            pr: '23 px',
                            pl: '23 px'
                        }}
                    >
                        Conteo Rápido
                    </Button>
                    <Button
                        color="info"
                        key={'machinelearning'}
                        onClick={e => setSampling(false)}
                        variant={!sampling ? 'contained' : 'outlined'}
                        sx={{
                            borderWidth: '1px',
                            borderRadius: '30px',
                            pr: '23 px',
                            pl: '23 px'
                        }}
                    >
                        Machine Learning
                    </Button>
                </ButtonGroup>
            </Grid>

            {!sampling ?
                <Grid
                    container
                    spacing={2}
                    sx={{ flexDirection: { xs: "column-reverse", md: "row" }, mt: 2 }}
                >
                    <Grid item xs={12} md={openDrawer ? 9 : 12}>

                        <PrepSamplingHeaders
                            sampling={sampling}
                            setSampling={setSampling}
                            summaryData={summaryData}
                            loadingCards={loadingChart}
                            handleRefresh={handleRefresh}
                            openDrawer={openDrawer}
                        />

                        <Box component={Card} className="card-primary">
                            <Box component={CardContent}>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={vista}
                                    onChange={handleChangeRadio}
                                    sx={{ mb: 2, display: "flex", justifyContent: "center" }}
                                >
                                    <FormControlLabel
                                        value={1}
                                        control={<Radio />}
                                        label="Vista por candidatos"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value={2}
                                        control={<Radio />}
                                        label="Vista por partidos"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>

                                {
                                    loadingChart ? (
                                        <Skeleton animation="wave" variant="rounded" width={"100%"} height={400} />
                                    ) : (
                                        <Chart
                                            Series={series}
                                            tipoVista={vista}
                                            loading={loadingChart}
                                            percent={true}
                                            openDrawer={openDrawer}
                                        />
                                    )
                                }
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <DrawerRelative
                            anchor="right"
                            open={openDrawer}
                            setOpen={setOpenDrawer}
                            title={"Filtrar"}
                            icon="person_search"
                            titleComponentPlace="under"
                            className="card-primary"
                            sxPaper={{ borderRadius: 3 }}
                            isSticky
                        >
                            <PrepFilter onChangeFilter={handleChangeFilter} disabledButtons={loadingFilter} />
                        </DrawerRelative>
                    </Grid>
                </Grid>
                :
                <PrepConteoRapidoMuestreo />
            }


            {!openDrawer && (
                <FloatingButton
                    onClick={() => setOpenDrawer(true)}
                    label="Mostrar Filtros"
                />
            )}
        </AdminLayout>
    )
}

export default PrepMuestreo;
import { Card, CardContent, Typography, CircularProgress, Box, Modal, Button } from "@mui/material";
import { useEffect, useState } from "react";
import BasicLine from "@components/Charts/BasicLine";
import movilizacion from "@services/MovilizationServices";
import Swal from "sweetalert2";
import { blue } from "@mui/material/colors";


const ChartDesiredProgressModal = ({ dataModal, openModal, handleCloseModal }) => {
  const [data, setData] = useState({
    series: [],
    categories: [],
    title: "",
  });
  
  const [loadingModal, setLoadingModal] = useState(null)

  const movilizedChartTerritorialDesiredProgresss = async (params) => {
    setLoadingModal(true)

    try {
      const res = await movilizacion.getMovilizedChartTerritorialDesiredProgress(params);
      const { results, message, response } = res;
      if (results) {
        setdesired(response);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
        customClass: {
          container: "modal-alert",
        },
      });
      setdesired([])
    } finally {
      setLoadingModal(false)
    }
  };

  useEffect(() => {
    if (dataModal.filtered.length > 0) {
      const { id, value } = dataModal.filtered[0]
      if (id && value) {
        movilizedChartTerritorialDesiredProgresss(dataModal)
      }
    }
    //eslint-disable-next-line
  }, [dataModal]);

  const [desired, setdesired] = useState([]);


  useEffect(() => {
    setData({
      series: desired.series,
      categories: desired.categories,
      title: desired.title,
    });
  }, [desired]);

  return (

    <Modal
    open={openModal}
    onClose={handleCloseModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Card sx={{ width: 1200, bgcolor: 'background.paper', boxShadow: 24, p: 2, borderRadius: 3 }}>
      <CardContent>
      <Card className="card-primary">
        <CardContent>
          {loadingModal ? (
            <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
              <Typography sx={{fontWeight:600, variant:"subtitle2", marginY:1}} >
                Cargando...
              </Typography>
            </Box>
          ) : data.series && data.categories && data.series.length > 0 && data.categories.length > 0 ? (
            <BasicLine
              title={{
                primary: data.title
              }}
              legend={{
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
              }}
              series={data.series}
              categories={data.categories}
              initialPosition
            />
          ) : (
            <Typography>No hay datos disponibles</Typography>
          )}
        </CardContent>
      </Card>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
          <Button onClick={handleCloseModal} variant="contained" size="small" sx={{
            width: '100%',
            backgroundColor: blue[900],
          }}>
            Cerrar
          </Button>
        </Box>
      </CardContent>
    </Card>
  </Modal>



  );
};

export default ChartDesiredProgressModal;

import { useState } from "react";
import { Box, Button, TextField, Icon } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import Alert from "@mui/material/Alert";

import { limpiarTelefono } from "@utils/global";
import * as Validate from "@data/schemas/UserSchemas";

const UsersActivationSearch = (props) => {
  const { handleFilter } = props;

  const [params, setParams] = useState({
    Nombre: "",
    Celular: "",
  });

  const [errorMessage, setErrorMessage] = useState("");

  const phoneRegex =
    /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/;

  const handleChangeNombre = (event) => {
    event.preventDefault();
    const nombreValue = event.target.value;
    setParams({
      ...params,
      Nombre: nombreValue,
    });
  };

  const handleChangeCelular = (event) => {
    const celularValue = event.target.value;
    setParams({
      ...params,
      Celular: limpiarTelefono(celularValue),
    });
  };

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      handleValidat();
    }
  };

  const handleValidat = () => {
    if (!params.Nombre && !phoneRegex.test(params.Celular)) {
      setErrorMessage("Debe ingresar al menos un criterio de búsqueda.");
    } else {
      setErrorMessage("");
      handleFilter("filter", params);
    }
  };

  const handleSearchClick = () => {
    handleValidat();

    // Si no hay errores y al menos uno de los campos tiene valor, realizar la búsqueda
    if (!errorMessage && (params.Nombre || params.Celular)) {
      handleFilter("filter", params);
    }
  };

  const handleClearClick = () => {
    setParams({
      Nombre: "",
      Celular: "",
    });
    handleFilter("clear");
  };

  /* useEffect(() => {
    const hasError = !params.Nombre && !phoneRegex.test(params.Celular);

    if (hasError) {
      setErrorMessage("Debe ingresar al menos un criterio de búsqueda.");
    } else {
      if (errorMessage && (params.Nombre || params.Celular)) {
        setErrorMessage("");
      }
    }
  }, [params]); */

  /* useEffect(() => {
    handleFilter("clear");
  }, []); */

  return (
    <Box sx={{ marginY: 2 }}>
      <Alert
        variant="outlined"
        severity="info"
        sx={{ mb: 2, borderColor: "info.main" }}
      >
        Nota: Para realizar una búsqueda debe ingresar un nombre o por el
        contrario un número de celular.
      </Alert>

      <Grid2 container spacing={2} alignItems="center">
        <Grid2 item xs={12} sm={6} lg={4}>
          <TextField
            label="Nombre"
            variant="outlined"
            margin="none"
            size="small"
            fullWidth
            onKeyPress={(e) => {
              Validate.handleKeyPress2(e);
              handleEnterPress(e);
            }}
            value={params.Nombre.toUpperCase()}
            onChange={handleChangeNombre}
          />
        </Grid2>
        <Grid2 item xs={12} sm={6} lg={4}>
          <TextField
            label="Número de Celular"
            variant="outlined"
            margin="none"
            size="small"
            fullWidth
            onKeyPress={(e) => {
              Validate.handleKeyPress(e);
              handleEnterPress(e);
            }}
            value={params.Celular}
            onChange={handleChangeCelular}
          />
        </Grid2>
        <Grid2 item xs={12} lg={4}>
          <Box sx={{ display: "flex", gap: { xs: 1, sm: 2 } }}>
            <Button
              onClick={handleSearchClick}
              startIcon={<Icon>search</Icon>}
              variant="contained"
              sx={{ paddingX: { xs: 6, sm: "auto" } }}
              color="primaryDark"
              fullWidth
            >
              Buscar
            </Button>
            <Button
              onClick={handleClearClick}
              sx={{ paddingX: { xs: 6, sm: "auto" } }}
              variant="outlined"
              fullWidth
            >
              Limpiar
            </Button>
          </Box>
        </Grid2>
      </Grid2>
      {errorMessage && (
        <Box mt={2}>
          <Alert variant="outlined" severity="error">
            {errorMessage}
          </Alert>
        </Box>
      )}
    </Box>
  );
};

export default UsersActivationSearch;

import React, { useEffect, useState, useRef, memo } from "react";
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import { useNavigate } from "react-router-dom";

//Componente
import ExportRCSList from "../../components/Electoral/ExportRCSList";
import DashbordCerificacionINE from "../../components/Electoral/DashbordCerificacionINE";

import {
  Grid, 
  Card,
  Button,
  Container,
  CardContent,
  ButtonGroup
} from "@mui/material";
import middleware from "@middlewares/middleware";

const ExportRCS = () => {
  const navigate = useNavigate();
  const [tabButton, setTabButton] = useState(1);

  const handleCalendarioClick = (e) => setTabButton(e);


  const buttons = [
    {
      id: 1,
      button: (
        <Button key="Dash" onClick={() => handleCalendarioClick(1)}>
          Dashboard
        </Button>
      ),
      container: <DashbordCerificacionINE/> ,
    },
    {
      id: 2,
      button: (
        <Button key="Exportar" onClick={() => handleCalendarioClick(2)}>
          Exportar TXT
        </Button>
      ),
      container: <ExportRCSList />,
    },
    /* {
      id: 3,
      button: (
        <Button key="ListadoAsistencia" onClick={() => handleCalendarioClick(3)}>
          Listado Asistencia
        </Button>
      ),
      container: <ListEventosAsistencia />,
      filter: (
        <FilterEvento
          vista={false}
          flagCategoria={false}
          responsable={true}
          checkResponsabilidades={true}
          onChangeFilter={(e) => {
            console.log(e);
          }}
        />
      ),
    }, */
  ];

  return (
    <AdminLayout>
      <Container maxWidth="false">
     {/*    <ActionHeader
          title={`Exportación RCS`}
          handleclick={() => navigate(`/export-rcs`)}
        /> */}
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign={"center"}>
                <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                  <ButtonGroup
                    color="secondary"
                    aria-label="Medium-sized button group"
                  >
                    {buttons.map((item) => item.button)}
                  </ButtonGroup>
                </div>
              </Grid>
              <Grid item xs={12}>
                {buttons.map((item, index) => {
                  return (
                    <Grid container spacing={2} key={index}>
                      <Grid item xs={12}>
                        {tabButton === item.id && item.container}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};
export default ExportRCS;

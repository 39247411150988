import { PrivilegedRoute } from "@middlewares";

import Compromisos from "@pages/Compromisos";
import CompromisosMuncipiosPrioritarios from "@pages/Compromisos/CompromisosMunicipiosPrioritarios";
import CompromisosExtranjero from "@pages/Compromisos/CompromisosExtranjero";
import CompromisosExtranjeroAdd from "@pages/Compromisos/CompromisosExtranjeroAdd";
import RegisterForm from "@pages/Compromisos/RegisterForm";
import FrenteAmplioRegisterForm from "@pages/Compromisos/FrenteAmplioRegisterForm";
import History from "@pages/Compromisos/History";
import CompromisosTypeRegister from "@pages/Compromisos/CompromisosTypeRegister";
import HistoryForeigner from "@pages/Compromisos/HistoryForeigner";
import AuditoriaOrigen from "@pages/Compromisos/OrigenCaptura"

const CompromisosRoutes = [
	{
		path: "/compromisos",
		element: (
			<PrivilegedRoute>
				<Compromisos />
			</PrivilegedRoute>
		),
	},
	{
		path: "/compromisos-prioritarios",
		element: (
			<PrivilegedRoute>
				<CompromisosMuncipiosPrioritarios />
			</PrivilegedRoute>
		),
	},
	{
		path: "/compromisos-ext",
		element: (
			<PrivilegedRoute>
				<CompromisosExtranjero />
			</PrivilegedRoute>
		),
	},
	{
		path: "/historial-captura",
		element: (
			<PrivilegedRoute>
				<History />
			</PrivilegedRoute>
		),
	},
	{
		path: "/compromisos-tipo-registro",
		element: (
			<PrivilegedRoute>
				<CompromisosTypeRegister />
			</PrivilegedRoute>
		),
	},
	{
		path: "/compromisos-registro",
		element: (
			<PrivilegedRoute>
				<RegisterForm />
			</PrivilegedRoute>
		),
	},
	{
		path: "/frenteamplio-registro",
		element: (
			<PrivilegedRoute>
				<FrenteAmplioRegisterForm />
			</PrivilegedRoute>
		),
	},
	{
		path: "/compromisos-ext-registro",
		element: (
			<PrivilegedRoute>
				<CompromisosExtranjeroAdd />
			</PrivilegedRoute>
		),
	},
	{
		path: "/historial-ext-captura",
		element: (
			<PrivilegedRoute>
				<HistoryForeigner />
			</PrivilegedRoute>
		),
	},
	{
		path: "/origen-captura",
		element: (
		  <PrivilegedRoute>
			<AuditoriaOrigen />
		  </PrivilegedRoute>
		),
	  },
];

export default CompromisosRoutes;

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

// Material UI
import { Container, CardContent, Chip, Button, Icon } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import Filter from "@components/DiaD/RoutesMRT/Filter";
import AssignMecanismo from "@components/DiaD/RoutesMRT/AssignMecanismo";
import BasicTable from "@components/Tables/BasicTable";
import CardStats from "@components/Card/CardStats";
import Card from "@components/Card/Card";

// Middleware y Utilidades
import middleware from "@middlewares/middleware";
import { isNullOrUndefined, isEmptyOrNullObject } from "@utils/validations";
import { useCatalogs } from "@hooks/useCatalogs";
import { getVars } from "@utils/global";

// Servicios
import DiaDServices from "@services/DiaDServices";

const RoutesMRT = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingCharts, setLoadingChart] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const [chartData, setChartData] = useState({
    Total: 0,
    TotalSinRuta: 0,
    TotalConRuta: 0,
    TotalRuta: 0,
  });
  const [tableData, setTableData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
  });
  const [paramsChart, setParamsChart] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
  });
  const [openAssing, setOpenAssing] = useState(false);
  const [dataRuta, setDataRuta] = useState({});
  const [filtrosModal, setFiltrosModal] = useState({
    idRegion: 0,
    idMunicipio: 0,
    Seccion: 0,
  });

  var today = new Date();

  const [columns, setColumns] = useState([
    { id: "Region", label: "Región" },
    { id: "MunicipioReportes", label: "Municipio" },
    { id: "Ruta", label: "Ruta" },
    { id: "Seccion", label: "Sección" },
    { id: "NombreCasilla", label: "Casilla" },
  ]);

  const user = getVars("Token")?.user;

  const catalogsParams = [
    { id: "regiones", getAll: false },
    { id: "municipios_dl", getAll: false },
    { id: "secciones", getAll: false },
    {
      id: "rutas_mrt",
      getAll: false,
      filtered: [{ id: "rutas_mrt.idPartido", filter: "=", value: user.idPartido }],
    },
  ];

  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsParams,
    putDefaultOption: false,
  });

  const handleDelete = async (data) => {
    let user = "P1";
    if (data.idPropietario === 3) user = "P2";

    const nombre = data[user]?.split("-").map((i) => i.trim())[1];

    Swal.fire({
      title: "Eliminar",
      html: `<p>Esta seguro de Eliminar la Ruta ${data.ruta} al ${data.tipo} ${nombre}</p>`,
      showCancelButton: true,
      icon: "question",
      confirmButtonText: `Continuar`,
      showLoaderOnConfirm: true,
      allowEscapeKey: false,
      preConfirm: async () => {
        try {
          let user = "idUsuarioP1";
          if (data.idPropietario === 3) user = "idUsuarioP2";

          const newData = { idUsuario: data[user], idRuta: data.idRuta, idPropietario: data.idPropietario };
          const response = await DiaDServices.removeMecanismoRoute(newData);

          const { results } = response;
          if (!results) {
            return Swal.showValidationMessage(`${JSON.stringify(response)}`);
          }
          return response;
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value.results) {
          Swal.fire({ title: result.value.message, icon: "success", timer: 1500, showConfirmButton: false });
          getList();
        }
      }
    });
  };

  const getMecanismo = (props) => {
    const { item, tipo, key, idPropietario } = props;

    const usuario = item[`idUsuario${key}`] ?? null;
    const label = item[key] ?? "";
    const send = { ...item, tipo, idPropietario, ruta: item.Ruta };

    return /* middleware.checkMenuAction("Autorizar") */ true ? (
      !isNullOrUndefined(item.idRuta) ? (
        !isNullOrUndefined(usuario) ? (
          <Chip variant="outlined" onDelete={() => handleDelete(send)} size="small" label={`${label}`} />
        ) : (
          <Button
            variant="contained"
            size="small"
            sx={{ bgcolor: "#f73378" }}
            onClick={() => OnClickHandle(send)}
            endIcon={<Icon>group_add</Icon>}
          >
            Asignar
          </Button>
        )
      ) : (
        "SIN RUTA"
      )
    ) : !isNullOrUndefined(item.idRuta) ? (
      !isNullOrUndefined(usuario) ? (
        `${label}`
      ) : (
        ""
      )
    ) : (
      ""
    );
  };

  const getList = () => {
    DiaDServices.getCasillasInformationMRT(tableData)
      .then((res) => {
        if (res.results) {
          const tipo = "Mecanismo de recolección";

          setData(
            res.response.data.map((item) => {
              return {
                ...item,
                Ruta: (
                  <Chip
                    label={item.Ruta ? item.Ruta : "Sin Ruta"}
                    variant="outlined"
                    color={item.Ruta ? "success" : "error"}
                    size="small"
                    sx={{
                      color: item.Ruta ? "green" : "red",
                      fontWeight: item.Ruta ? "700" : "0",
                      width: "100px",
                    }}
                  />
                ),

                Propietario1: getMecanismo({ item, tipo, key: "P1", idPropietario: 1 }),
                Propietario2: getMecanismo({ item, tipo, key: "P2", idPropietario: 3 }),
              };
            })
          );
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => setLoading(false));
  };

  const getTotalCompromiso = () => {
    DiaDServices.getCasillasInformationTotalsMRT(paramsChart)
      .then((res) => {
        if (res.results) setChartData(res.response.data[0]);
        else Swal.fire({ title: res.message, icon: "warning" });
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => setLoadingChart(false));
  };

  useEffect(() => {
    if (sortedData.length > 0) {
      let data = {
        ...tableData,
        sorted: sortedData,
      };

      setTableData(data);
    }
    // eslint-disable-next-line
  }, [sortedData]);

  useEffect(() => {
    setLoading(true);
    setLoadingChart(true);
    setColumns([
      { id: "tipo", label: "Tipo" },
      { id: "Region", label: "Región" },
      { id: "MunicipioReportes", label: "Municipio" },
      { id: "Ruta", label: "Ruta" },
      { id: "Seccion", label: "Sección" },
      { id: "NombreCasilla", label: "Casilla" },
      { id: "Propietario1", label: "Mecanismo Asignado 1" },
      { id: "Propietario2", label: "Mecanismo Asignado 2" },
    ]);
    getList();
    getTotalCompromiso();
    // eslint-disable-next-line
  }, [tableData]);

  const handleChangePagination = (pagination) => {
    setTableData({ ...tableData, ...pagination });
  };

  const handleChangeFilter = (type) => {
    const { Region, Municipio, Seccion, Ruta, RutaId } = type;

    let filtered = [];
    let filteredChart = [];

    if (Region > 0) {
      filtered = [...filtered, { id: "cat_secciones.idRegion", filter: "=", value: Region }];

      filteredChart = [...filteredChart, { id: "cat_secciones.idRegion", filter: "=", value: Region }];
    }
    if (Municipio > 0) {
      filtered = [...filtered, { id: "cat_secciones.idMunicipioReportes", filter: "=", value: Municipio }];

      filteredChart = [
        ...filteredChart,
        { id: "cat_secciones.idMunicipioReportes", filter: "=", value: Municipio },
      ];
    }
    if (RutaId > 0) {
      const RutaLabel = catalogs.rutas_mrt.find((item) => (item.value === RutaId))?.label;
      filtered = [...filtered, { id: "rutas_mrt.Ruta", filter: "=", value: RutaLabel }];
    }
    if (Seccion.length > 0) {
      filtered = [...filtered, { id: "cat_secciones.Seccion", filter: "IN", value: Seccion }];
    }
    if (Ruta > 0) {
      filtered = [...filtered, { id: "RC.idRuta", filter: Ruta === 1 ? "IS" : "IS NOT", value: "NULL" }];
    }

    setFiltrosModal({
      idRegion: Region,
      idMunicipio: Municipio,
      Seccion: Seccion,
    });

    setTableData({
      ...tableData,
      filtered: filtered,
    });

    setParamsChart({
      ...paramsChart,
      filtered: filteredChart,
    });
  };

  const OnClickHandle = (info) => {
    setOpenAssing(true);
    setDataRuta({ ...info });
  };

  const setOpenModal = (e) => {
    getList();
    setOpenAssing(e);
  };

  const handleSorted = (dataSorted) => {
    const fields = {
      Region: "cat_secciones.idRegion",
      MunicipioReportes: "cat_MR.Municipio",
      Ruta: "rutas_mrt.Ruta",
      Seccion: "cat_secciones.Seccion",
      NombreCasilla: "cat_casillas.NombreCasilla",
      tipo: "rutas_mrt.idTipo",
      Propietario1: "p1.P1",
      Propietario2: "p2.P2",
    };

    let sorted = [];

    if (!isEmptyOrNullObject(dataSorted)) {
      sorted.push({
        id: fields[dataSorted.orderBy],
        value: dataSorted.order,
      });
    }

    return sorted;
  };

  const handleRequestSort = (sortData) => {
    if (total > 0) setSortedData(handleSorted(sortData));
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title="Listado Rutas" handleclick={() => navigate("/lista-rutas")} />
        {openAssing && (
          <AssignMecanismo
            open={openAssing}
            title={dataRuta}
            filtro={filtrosModal}
            setOpen={setOpenModal}
            catalogs={catalogs}
            load={load}
          />
        )}
        <Grid container spacing={2}>
          <Grid xs={12} sm={6} lg={3}>
            <CardStats
              icon={"list_alt"}
              title={"Casillas"}
              total={chartData.Total}
              subIcon={"calendar_today"}
              subtitle={today.toLocaleDateString()}
              loading={loadingCharts}
            />
          </Grid>
          <Grid xs={12} sm={6} lg={3}>
            <CardStats
              icon={"playlist_remove"}
              title={"Casillas sin ruta"}
              total={chartData.TotalSinRuta}
              subIcon={"calendar_today"}
              subtitle={today.toLocaleDateString()}
              loading={loadingCharts}
            />
          </Grid>
          <Grid xs={12} sm={6} lg={3}>
            <CardStats
              icon={"playlist_add_check"}
              title={"Casillas con rutas"}
              total={chartData.TotalConRuta}
              subIcon={"calendar_today"}
              subtitle={today.toLocaleDateString()}
              loading={loadingCharts}
            />
          </Grid>
          <Grid xs={12} sm={6} lg={3}>
            <CardStats
              icon={"roundabout_right"}
              title={"Rutas"}
              total={chartData.TotalRuta}
              subIcon={"calendar_today"}
              subtitle={today.toLocaleDateString()}
              loading={loadingCharts}
            />
          </Grid>
        </Grid>

        <Card className="card-primary">
          <CardContent>
            <Filter onChangeFilter={handleChangeFilter} catalogs={catalogs} load={load} />
          </CardContent>
        </Card>

        <Card className="card-primary">
          <CardContent>
            <BasicTable
              rows={data}
              hcolumns={columns}
              pageProp={tableData.page}
              pageSize={tableData.pageSize}
              total={total}
              handleChangePagination={handleChangePagination}
              isLoading={loading}
              stickyHeader={true}
              handleManualSort={handleRequestSort}
            />
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default RoutesMRT;

import React, { useState, useEffect } from "react";
import { useFormik } from "formik";

import { Box, TextField, Button, Card, CardContent } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Swal from "sweetalert2";

import BasicSelect from "@components/Selects/BasicSelect";
import movilizacion from "@services/MovilizationServices";
import { Movilizacion } from "@data/interfaces/MovilizationInterfaces";
import { AddMovilizacionName, MovilizacionFilter, handleKeyPress, handleKeyPress2 } from "@data/schemas/MovilizationSchemas";
import { isEmptyOrInvalidString } from "@utils/validations";
import ResponsableList from "@components/Movilizacion/ResponsableList";

const CompromisosFilter = (props) => {
  const { handleSetBox, TabValue, casillaValue } = props;
  const formik = useFormik({
    initialValues: Movilizacion,
    validationSchema: TabValue === 1 ? AddMovilizacionName : MovilizacionFilter,
    onSubmit: (values, actions) => {
      handleFiltered(values);
    },
  });

  const [validationResult, setValidationResult] = useState(null);
  const [municipio, setMunicipio] = useState("");
  const [mensaje, setMenssage] = useState("");
  const [casillaOptions, setCasillaOptions] = useState([]);
  const [loadingBox, setLoadingBox] = useState(false);
  const messageAlert = {
    infoUser: "Por favor, ingresar el nombre iniciando por apellido paterno, materno y por último el nombre.",
  };

  useEffect(() => {
    if (validationResult === "exitosa") {
      const timeout = setTimeout(() => {
        setValidationResult(null);
      }, 1500);
      return () => clearTimeout(timeout);
    } else if (validationResult === "incorrecta") {
      const timeout = setTimeout(() => {
        setValidationResult(null);
      }, 1500);
      return () => clearTimeout(timeout);
    }
  }, [validationResult]);

  const handleValidateSection = async (section) => {
    setLoadingBox(true);
    setCasillaOptions([]);
    setMunicipio("");
    formik.setFieldValue("idCasilla", "");

    try {
      const result = await movilizacion.validateSection(section);
      const { results, response, message } = result;
      setMenssage(message);

      if (results) {
        setValidationResult("exitosa");
        setMunicipio(response.Municipio);
        const uniqueCasillaOptions = new Set();
        response.casillas.forEach((item) => {
          uniqueCasillaOptions.add(item.idCasilla);
        });

        // Convertir el conjunto de valores únicos a un array de objetos
        const options = Array.from(uniqueCasillaOptions).map((idCasilla) => {
          const casilla = response.casillas.find((item) => item.idCasilla === idCasilla);
          return {
            value: casilla.idCasilla,
            label: casilla.NombreCasilla,
          };
        });

        setCasillaOptions(options);
      } else {
        setValidationResult("incorrecta");
      }
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: error.message,
      });
    } finally {
      setLoadingBox(false);
    }
  };

  const handleKeyDown = (event) => {
    const isEnterKey = event.key === "Enter";
    const isTabKey = event.key === "Tab";
    if (event.type === "keydown" && (isEnterKey || isTabKey)) {
      const value = event.target.value;
      if (!isEmptyOrInvalidString(value)) {
        handleValidateSection(value);
      }
    }
  };

  const handleChangeSection = (e) => {
    const value = e.target.value;
    formik.setValues((prevState) => ({
      ...prevState,
      Seccion: value,
      idCasilla: "",
    }));
    setCasillaOptions([]);
    handleSetBox();
  };

  const handleFilter = (event) => {
    const isEnterKey = event.key === "Enter";
    const isTabKey = event.key === "Tab";

    if (event.type === "keydown" && (isEnterKey || isTabKey)) {
      formik.handleSubmit();
      event.preventDefault();
    }
  };

  const handleChangeBox = async (e) => {
    const value = e.target.value;
    formik.setValues((prevState) => ({
      ...prevState,
      idCasilla: value,
    }));
    casillaValue(value);
  };

  const handleClean = () => {
    setCasillaOptions([]);
    setMenssage([]);
    setMunicipio([]);
    handleSetBox();
    formik.resetForm();
  };

  const handleFiltered = (values) => {
    let filter = {};

    if (values.Nombre) {
      filter = {
        ...filter,
        Nombre: formik.values.Nombre,
      };
    }
    if (values.idCasilla) {
      filter = {
        ...filter,
        idCasilla: formik.values.idCasilla,
      };
    }
    if (values.Seccion) {
      filter = {
        ...filter,
        Seccion: formik.values.Seccion,
      };
      if (values.idUsuarioReporteMov) {
        filter = {
          ...filter,
          idUsuarioReporteMov: formik.values.idUsuarioReporteMov,
        };
      }
      filter = {
        ...filter,
        RangoInicial: values.RangoInicial?.toUpperCase(),
        RangoFinal: values.RangoFinal?.toUpperCase(),
      };
    }

    handleSetBox(filter);
  };

  return (
    <Card className="card-primary">
      <CardContent>
        <Grid2 container spacing={2}>
          <Grid2 item xs={12} md={TabValue !== 1 ? 3 : null}>
            <ResponsableList values={formik.values} setValues={formik.setValues} />
          </Grid2>
          <Grid2 item xs={12} md={TabValue !== 1 ? 3 : null}>
            <TextField
              id="Seccion"
              name="Seccion"
              variant="outlined"
              label="Sección"
              size="small"
              sx={{ width: "100%" }}
              value={formik.values.Seccion}
              onKeyDown={handleKeyDown}
              onKeyPress={handleKeyPress}
              onChange={handleChangeSection}
              error={Boolean(formik.errors.Seccion)}
              helperText={formik.errors.Seccion}
              required
            />
          </Grid2>
          <Grid2 item xs={12} md={TabValue !== 1 ? 3 : null}>
            <BasicSelect
              disabled={isEmptyOrInvalidString(formik.values.Seccion) && validationResult !== "exitosa"}
              name="idCasilla"
              label="Seleccione una casilla"
              options={casillaOptions}
              value={formik.values.idCasilla}
              onChange={handleChangeBox}
              size="small"
              sx={{ width: "100%" }}
              isLoading={loadingBox}
              error={Boolean(formik.errors.idCasilla)}
              helperText={formik.errors.idCasilla}
            />
          </Grid2>
          {TabValue === 1 && (
            <>
              <Grid2 item xs={12}>
                <TextField
                  name="RangoInicial"
                  label="Rango alfabeto inicial"
                  size="small"
                  value={formik.values.RangoInicial?.toUpperCase()}
                  onChange={formik.handleChange}
                  onKeyPress={handleKeyPress2}
                  error={Boolean(formik.errors.RangoInicial)}
                  helperText={formik.errors.RangoInicial}
                  sx={{ width: "100%", mb: 3 }}
                />
              </Grid2>
              <Grid2 item xs={12}>
                <TextField
                  name="RangoFinal"
                  label="Rango alfabeto final"
                  size="small"
                  value={formik.values.RangoFinal?.toUpperCase()}
                  onChange={formik.handleChange}
                  onKeyPress={handleKeyPress2}
                  error={Boolean(formik.errors.RangoFinal)}
                  helperText={formik.errors.RangoFinal}
                  sx={{ width: "100%", mb: 3 }}
                />
              </Grid2>
              <Grid2 item xs={12}>
                <TextField
                  fullWidth
                  id="Nombre"
                  name="Nombre"
                  variant="outlined"
                  label="Nombre"
                  placeholder="Paterno Materno Nombre"
                  size="small"
                  sx={{ width: "100%" }}
                  value={formik.values.Nombre}
                  onKeyPress={handleKeyPress2}
                  onKeyDown={handleFilter}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.Nombre)}
                  helperText={formik.errors.Nombre}
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid2>
            </>
          )}
          {TabValue === 1 && (
            <Alert severity="info" sx={{ mb: 2 }}>
              <strong>Formato de Nombre:</strong> {messageAlert.infoUser}
            </Alert>
          )}
          <Grid2 item xs={12} md={TabValue !== 1 ? 3 : null}>
            <Box display="flex">
              <Button variant="outlined" size="small" onClick={handleClean}>
                Limpiar
              </Button>
              <Button variant="contained" size="small" color="primaryDark" onClick={formik.submitForm} sx={{ ml: 1 }}>
                Filtrar
              </Button>
            </Box>
          </Grid2>
          <Grid2 item xs={12} sx={{ mt: 1 }}>
            {validationResult === "exitosa" && (
              <Alert severity="success">
                <AlertTitle>{mensaje}</AlertTitle>
                Municipio: <strong>{municipio}</strong>
              </Alert>
            )}
            {validationResult === "incorrecta" && (
              <Alert severity="error">
                <AlertTitle>{mensaje}</AlertTitle>
              </Alert>
            )}
          </Grid2>
        </Grid2>
      </CardContent>
    </Card>
  );
};

export default CompromisosFilter;

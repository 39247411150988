import React, { useState, useEffect } from "react";
import { useFormik } from "formik";

import {
  Box,
  Divider,
  TextField,
  Button,
  InputAdornment,
  Card,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import SendIcon from "@mui/icons-material/Send";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Swal from "sweetalert2";
import moment from "moment/moment";

import BasicSelect from "@components/Selects/BasicSelect";
import movilizacion from "@services/MovilizationServices";
import { AddBingoInterface } from "@data/interfaces/MovilizationInterfaces";
import { AddBingoSchema, handleKeyPress } from "@data/schemas/MovilizationSchemas";
import { isEmptyOrInvalidString } from "@utils/validations";
import { getVars } from "@utils/global";

const BingoForm = (props) => {
  const { handleSetBox, getLastInsert } = props;
  const formik = useFormik({
    initialValues: AddBingoInterface,
    validationSchema: AddBingoSchema,
    enableReinitialize: false,
    onSubmit: (values, actions) => {
      handleSendBingo(values, actions);
    },
  });

  const [validationResult, setValidationResult] = useState(null);
  const [municipio, setMunicipio] = useState("");
  const [mensaje, setMenssage] = useState("");
  const [casillaOptions, setCasillaOptions] = useState([]);
  const [loadingBox, setLoadingBox] = useState(false);

  useEffect(() => {
    if (validationResult === "exitosa") {
      const timeout = setTimeout(() => {
        setValidationResult(null);
      }, 1500);
      return () => clearTimeout(timeout);
    } else if (validationResult === "incorrecta") {
      const timeout = setTimeout(() => {
        setValidationResult(null);
      }, 1500);
      return () => clearTimeout(timeout);
    } else if (validationResult === "registrado") {
      const timeout = setTimeout(() => {
        setValidationResult(null);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [validationResult]);

  const handleValidateSection = async (section) => {
    setLoadingBox(true);
    setCasillaOptions([]);
    setMunicipio("");
    formik.setFieldValue("idCasilla", "");

    try {
      const result = await movilizacion.validateSection(section);
      const { results, response, message } = result;
      setMenssage(message);

      if (results) {
        setValidationResult("exitosa");
        setMunicipio(response.Municipio);
        const uniqueCasillaOptions = new Set();
      response.casillas.forEach((item) => {
        uniqueCasillaOptions.add(item.idCasilla);
      });

      const options = Array.from(uniqueCasillaOptions).map((idCasilla) => {
        const casilla = response.casillas.find((item) => item.idCasilla === idCasilla);
        return {
          value: casilla.idCasilla,
          label: casilla.NombreCasilla,
        };
      });
      setCasillaOptions(options)
      } else {
        setValidationResult("incorrecta");
      }
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: error.message,
      });
    } finally {
      setLoadingBox(false);
    }
  };

  const handleKeyDown = (event) => {
    let value = event.target.value;
    if (event.key === "Enter") {
      if (!isEmptyOrInvalidString(value)) handleValidateSection(value);
    }
  };

  const handleChangeSection = (e) => {
    const value = e.target.value;
    formik.setValues((prevState) => ({
      ...prevState,
      Seccion: value,
      idCasilla: "",
      FolioBingo: "",
    }));
  };

  const handleBlurSection = (e) => {
    const value = e.target.value;
    if (!isEmptyOrInvalidString(value)) handleValidateSection(value);
  };

  const handleChangeBox = async (e) => {
    const value = e.target.value;
    formik.setValues((prevState) => ({
      ...prevState,
      idCasilla: value,
      FolioBingo: "",
    }));

    handleSetBox(value);
  };

  const handleSetBingo = (event) => {
    if (event.key === "Enter") {
      formik.handleSubmit();
      event.preventDefault();
    }
  };

  const handleSendBingo = async (values, actions) => {
    try {
      const params = {
        idTipoBingo: 1,
        Seccion: values.Seccion,
        idCasilla: values.idCasilla,
        FolioBingo: values.FolioBingo,
        OrigenCaptura: "web",
      };

      const { user } = getVars("Token");
      const result = await movilizacion.setParamsBingo(params);
      const { results, message } = result;

      if (results) {
        setMenssage(message);
        setValidationResult("registrado");
        getLastInsert({
          idCasilla: values.idCasilla,
          FolioBingo: values.FolioBingo,
          NumHoja: null,
          Hora: moment().format("DD/MM/YYYY HH:mm"),
          Seccion: values.Seccion,
          NombreCasilla: casillaOptions.find(
            (item) => item.value === values.idCasilla
          )?.label,
          Usuario: `${user.Nombre} ${user.Paterno} ${user.Materno}`,
        });
        formik.setValues((prevState) => ({ ...prevState, FolioBingo: "" }));
      } else {
        getLastInsert(null);
        Swal.fire({
          icon: "warning",
          title: message,
        });
      }
    } catch (error) {
      getLastInsert(null);
      Swal.fire({
        icon: "warning",
        title: error.message,
      });
    }
  };

  return (
    <Box
      component={Card}
      variant="outlined"
      borderRadius={2}
      padding={2}
      minHeight={300}
      elevation={0}
      //className="card-primary"
    >
      <Grid2 container spacing={1} sx={{ mt: 1 }}>
        <Grid2 container spacing={2}>
          <Grid2 item xs={12}>
            <Typography variant="caption">
              Escribe la sección para seleccionar la casilla
            </Typography>
            <TextField
              id="Seccion"
              name="Seccion"
              variant="outlined"
              label="Sección"
              size="small"
              sx={{ width: "100%", mt: 1 }}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              value={formik.values.Seccion}
              onKeyPress={handleKeyPress}
              onKeyDown={handleKeyDown}
              onChange={handleChangeSection}
              onBlur={handleBlurSection}
            />
          </Grid2>

          <Grid2 item xs={12} sx={{ mt: 1 }}>
            <BasicSelect
              error={
                formik.touched.idCasilla &&
                !isEmptyOrInvalidString(formik.errors.idCasilla)
              }
              errorMessage={formik.errors.idCasilla}
              disabled={isEmptyOrInvalidString(formik.values.Seccion)}
              name="idCasilla"
              label="Seleccione una casilla"
              options={casillaOptions}
              value={formik.values.idCasilla}
              onChange={handleChangeBox}
              size="small"
              sx={{ width: "100%" }}
              isLoading={loadingBox}
            />
          </Grid2>

          <Grid2 item xs={12} sx={{ mt: 1 }}>
            <Typography variant="caption">
              Escribe el número de bingo
            </Typography>
            <TextField
              fullWidth
              id="FolioBingo"
              name="FolioBingo"
              variant="outlined"
              label="Bingo"
              size="small"
              sx={{ width: "100%", mt:1 }}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              value={formik.values.FolioBingo}
              onKeyDown={handleSetBingo}
              onKeyPress={handleKeyPress}
              onChange={formik.handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ pr: 0, mr: 0, pl: 0, ml: 0 }}
                  >
                    <Divider sx={{ height: 28 }} orientation="vertical" />
                    <Button
                      color="primaryDark"
                      onClick={formik.handleSubmit}
                      disabled={
                        !isEmptyOrInvalidString(formik.errors.FolioBingo) ||
                        isEmptyOrInvalidString(formik.values.FolioBingo)
                      }
                      sx={{ mr: 0, pr: 0, ml: 0, pl: 0 }}
                    >
                      <SendIcon sx={{ ml: 0, mr: 0, pr: 0 }} />
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </Grid2>

          <Grid2 item xs={12} sx={{ mt: 1 }}>
            {validationResult === "exitosa" && (
              <Alert severity="success">
                <AlertTitle>{mensaje}</AlertTitle>
                Municipio: <strong>{municipio}</strong>
              </Alert>
            )}

            {validationResult === "incorrecta" && (
              <Alert severity="error">
                <AlertTitle>{mensaje}</AlertTitle>
              </Alert>
            )}

            {validationResult === "registrado" && (
              <Alert severity="success">{mensaje}</Alert>
            )}
          </Grid2>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default BingoForm;

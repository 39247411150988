import { useEffect, useState } from "react";

import { Card, CardContent, Typography } from "@mui/material";
//import { blue } from "@mui/material/colors";

import BasicBarColumn from "@components/Charts/BasiBarColumn";

import movilizacion from "@services/MovilizationServices";

import Swal from "sweetalert2";
//import Highcharts from "highcharts";

/* const generateMonochrome = (colorBase, Total) => {
  let colors = [],
    i;
  for (i = 0; i < Total; i += 1) {
    colors.push(
      Highcharts.color(colorBase)
        .brighten((i - 3) / 7)
        .get()
    );
  }
  return colors;
}; */

const ChartBingo = ({ body, setLoading }) => {
  const [dataChart, setDataChart] = useState({
    categories: [],
    series: [],
    title: "",
  });

  const getMobilizedChartByView = async (body) => {
    setLoading((prevState) => ({
      ...prevState,
      isLoadigForm: true,
      openLinearProgress: true,
    }));

    try {
      const res = await movilizacion.getMobilizedChartByView(body);

      const { results, message, response } = res;

      if (results) {
        setDataChart({
          categories: response.categories,
          series: response.series.map((item) => ({
            ...item,
            data: item.data.map((value) => parseInt(value)),
          })),
          title: response.title.toUpperCase(),
        });
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        isLoadigForm: false,
        openLinearProgress: false,
      }));
    }
  };

  useEffect(() => {
    getMobilizedChartByView(body);
    // eslint-disable-next-line
  }, [body]);

  return (
    <Card className="card-primary">
      <CardContent>
        <Typography fontWeight={600} variant="subtitle2" marginBottom={2}>
          {dataChart.title}
        </Typography>
        <BasicBarColumn
          series={dataChart.series}
          categories={dataChart.categories}
        />
      </CardContent>
    </Card>
  );
};

export default ChartBingo;

import { Box, Typography } from "@mui/material";
import React from "react";

const InfoDrive = ({ data }) => {
  return (
    <Box>
      <Typography variant="subtitle1" fontWeight={800} marginBottom={2}>
        {data.label}
      </Typography>
      {data.sections && data.sections.length > 0 && (
        <Box marginBottom={2}>
          <Typography variant="body2" fontWeight={600}>
            Secciones
          </Typography>
          <Box display={"flex"} gap={1} flexWrap={"wrap"}>
            {data.sections &&
              data.sections.map((child) => (
                <Typography
                  variant="body2"
                  key={child.id}
                  fontWeight={400}
                  color={"text.secondary"}
                >
                  {child.label}
                </Typography>
              ))}
          </Box>
        </Box>
      )}
      <Typography variant="body2" fontWeight={600}>
        Compromisos capturados
      </Typography>
      <Typography variant="body2" fontWeight={400} color={"text.secondary"}>
        20
      </Typography>
    </Box>
  );
};

export default InfoDrive;

export const NoticiasInterface = {
  idTipo: 1,
  Titulo: "",
  Descripcion: "",
  Contenido: "",
  files: [],
  FileName: "",
  FechaPublicacion: "",
};

export const EditNoticiasInterface = {
  id: 0,
  idTipo: 1,
  Titulo: "",
  Descripcion: "",
  Contenido: "",
  files: [],
  FileName: "",
  FileURL: "",
  FechaPublicacion: "",
};

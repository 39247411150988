import { useEffect, useState } from "react";
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import MessageAlert from "@components/Downloads/MessageAlert";
import { useNavigate } from "react-router-dom";
import BasicTable from "@components/Tables/BasicTable";
import Card from "@components/Card/Card";
import CardCommitments from "@components/Card/StatesCard";
import CardIconColor from "@components/Card/CardIconColor";
import Grid from "@mui/material/Unstable_Grid2";
import SocialService from "@services/SocialServices";
import Filter from "@components/Structure/Filter";
import { getVars } from "@utils/global";
import Download from "@components/Downloads/Download";
import { isEmptyOrNullObject } from '@utils/validations';
import AttendanceCard from "@components/Attendance/AttendanceCard";
import { numberWithCommas } from '@utils/Utilities';

import Swal from "sweetalert2";
import {
  Container,
  CardContent,
  Box,
  Button,
  Icon,
  Typography
} from "@mui/material";

const SocialStructure = () => {
  const navigate = useNavigate();
  const { user } = getVars("Token");
  var today = new Date();
  const [data, setData] = useState({
    rows: [],
    columns: [],
    subClumns: [],
    columnsDiarias: [],
    subClumnsDiario: [],
  });
  const [columns, setColumns] = useState({ head: [], sub: [] });
  // const [total /* , setTotal */] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState({
    type: 1,
    filtered: [],
    sorted:[{id: "cat_municipios.Municipio", value: "asc"},{id: "cat_social.NombreSocial", value: "asc"}]
  });
  const [cardData, setCardData] = useState({
    type: 1,
    filtered: [],
  });
  const [value, setValue] = useState(1);
  const [mujeres, setMujeres] = useState(false);
  const [dataChart, setDataChart] = useState({
    TotalEstructuras: 0,
    TotalCompromisos: 0,
    compromisos: {
      TotalCompromisos: 0,
      TotalMasculino: 0,
      TotalFemenino: 0,
      TotalBinario: 0,
    },
    responsables: {
      TotalResponsables: 0,
      TotalMasculino: 0,
      TotalFemenino: 0,
      TotalBinario: 0,
    },
    enlaces: {
      TotalEnlaces: 0,
      TotalMasculino: 0,
      TotalFemenino: 0,
      TotalBinario: 0,
    },
  });
  const [loadingChart, setLoadingChart] = useState(false);
  const [stats,setStats] = useState({});
  const [totales, setTotales] = useState({
    Meta: 0,
    Avance: 0,
    Porcentaje: 0,
    AvanceEsperado: 0,
  });
  const [dataPie, setDataPie] = useState({
    height: 300,
    distance: -10,
    loading: true,
    options: {
      title: "Avance Estatal",
      subtitle: `${100} de ${200}`,
    },
    
    pieColors: ["#9fc5e8", "#36A2EB"],
    series: [
      {
        name: "Avances",
        colorByPoint: true,
        data: [
          {
            name: "Avance",
            y: 30483,
            sliced: true,
            selected: true,
          },
          {
            name: "Meta",
            y: 500000 - 30483,
            sliced: true,
            selected: true,
          },
        ],
      },
    ],
  });

  const [showMessage, setShowMessage] = useState(false);
  const [successDownload, setSuccessDownload] = useState(false);
  const [isDownload, setIsDownloading] = useState(false);
  const [sortedData, setSortedData] = useState([{id: "cat_municipios.Municipio", value: "asc"}]);

  useEffect(() => {
    if (sortedData.length > 0) {
      let params = {
        ...tableData,
        sorted: sortedData
      };

      setTableData(params)
    }
    // eslint-disable-next-line
  }, [sortedData]);

  const getList = () => {
    if (loading) {
      return true;
    }

    setLoading(true);
    SocialService.getTableResumen(tableData)
      .then((res) => {
        if (res.results) {
          setData(res.response);
          let datos = res.response.rows;
          setStats(res.response.totales[0]);
          if (user.ambito_perfil.find((item) => item.idPerfil === 174)){
            // Inicializar objetos para almacenar totales
            const totalGlobal = { Meta: 0, Avance: 0, AvanceEsperado: 0 };

            // Calcular totales
            for (let i = 0; i < datos.length; i++) {
              const objeto = datos[i];
                  const id = objeto.idEstructuraSocial // Extraer los últimos tres caracteres como identificador
                  totalGlobal.Meta += parseInt(objeto[`Meta${id}`].replace(/,/g, ''));
                  totalGlobal.Avance += parseInt(objeto[`AvanceTotal${id}`]);
                  totalGlobal.AvanceEsperado += parseInt(objeto[`AvanceEsperado${id}`]);
            }

            const porcentajeAvanceGlobal = (parseInt(totalGlobal.Avance) / parseInt(totalGlobal.Meta)) * 100;
            const porcentajeEsperadoGlobal =  (parseInt(totalGlobal.Avance) / (parseInt(totalGlobal.AvanceEsperado) > parseInt(totalGlobal.Meta) ? parseInt(totalGlobal.Meta) :parseInt(totalGlobal.AvanceEsperado))) * 100 ;
            totalGlobal.PorcentajeAvance = parseFloat(porcentajeAvanceGlobal.toFixed(1));
            totalGlobal.PorcentajeEsperado =parseFloat(porcentajeEsperadoGlobal.toFixed(1));
           
            setTotales(totalGlobal);
          }
          setColumns({
            head: res.response.columns,
            sub: res.response.subClumns,
          });
          if(res.response.subClumns.length === 2 && res.response.subClumns[1].id === 113){
            setMujeres(true);
          }
          //setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => setLoading(false));
  };

  const getCharts = () => {
    SocialService.getTotalesSocial(cardData)
      .then((res) => {
        if (res.results) {
          setDataChart(res.response);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => setLoadingChart(false));
  };

  useEffect(() => {
    setLoadingChart(true);
    getList();
    if (!user.ambito_perfil.find((item) => item.idPerfil === 174)) {
      getCharts();
    }
    // eslint-disable-next-line
  }, [tableData]);

  const handleChangePagination = (pagination) => {
    setTableData({ ...tableData, ...pagination });
  };

  const handleChangeFilter = (type) => {
    const { Region, Municipio, Segmento } = type;

    let filtered = [];
    //let filteredResponsable = [];

    if (Region > 0) {
      filtered = [
        ...filtered,
        { id:(mujeres ? "cat_municipios_mujeres.idRegion" : "cat_municipios.idRegion"), filter: "=", value: Region },
      ];
    }
    if (Municipio > 0) {
      filtered = [
        ...filtered,
        { id:(mujeres ? "cat_municipios_mujeres.id" :"cat_municipios.id"), filter: "=", value: Municipio },
      ];
    }
    if (Segmento.length > 0) {
      setCardData({
        ...cardData,
        filtered: filtered,
        idEstructura:Segmento
      });
      filtered = [
        ...filtered,
        { id: "cat_social.id", filter: "IN", value: Segmento },
      ];
    }else{
      setCardData({
        ...cardData,
        filtered: filtered,
      });
    }

    setTableData({
      ...tableData,
      filtered: filtered,
      sorted:sortedData
    });

    /*    setChartCompromisos({
      compromisos:{filtered: filtered},
      responsable:{filtered: filteredResponsable}
    }) */
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    setTableData({ ...tableData, type: parseInt(e.target.value) });
  };

  const handleDownload = () => {
    let params = tableData;
    delete params.page;
    delete params.pageSize;

    setIsDownloading(true);
    SocialService.exportReportMetas(params)
      .then((res) => {
        if (res.success) {
          setShowMessage(true);
          setSuccessDownload(true);
        } else {
          setShowMessage(true);
          setSuccessDownload(false);
        }
      })
      .catch((error) => {
        setShowMessage(true);
        setSuccessDownload(false);
      })
      .finally(() => setIsDownloading(false));
  };


  const handleSorted = (dataSorted) => {
    const fields = {
      Municipio:"cat_municipios.Municipio",
      MetaMunicipio:"meta_municipio_segmento.Meta",
      Avance: "meta_municipio_segmento.Avance",
      Segmento: "meta_municipio_segmento.idEstructuraSocial",
      NombreCompleto: 'CONCAT_WS( " ", compromisos_unicos.Nombre, compromisos_unicos.Paterno )',
      AvanceEsperado:"meta_municipio_segmento.AvanceEsperado",
    };

    let sorted =  [];

   let flag = false;
  
    if(dataSorted.orderBy.includes("Esperado")){
      dataSorted = {orderBy: 'AvanceEsperado', order: dataSorted.order};
    }else if(dataSorted.orderBy.includes("Avance")){
      flag=true;
      dataSorted=[
        {"orderBy": 'Segmento', "order": "asc"},
        {"orderBy": 'Avance', "order": dataSorted.order},
      ]
    }

    if (!isEmptyOrNullObject(dataSorted)) {
      console.log(typeof dataSorted);
      if(flag){
        dataSorted.forEach(element => {
          sorted.push({
            id: fields[element.orderBy],
            value: element.order
          });
        });
      }else{
        sorted.push({
          id: fields[dataSorted.orderBy],
          value: dataSorted.order
        });
      }
  
    }

    return sorted;
  }

  const handleRequestSort = (sortData) => {
     setSortedData(handleSorted(sortData))
  }

  function formatearFechaHora(fechaString) {
    // Crear un objeto de fecha a partir de la cadena
    var fecha = new Date(fechaString);
  
    // Obtener los componentes de la fecha
    var dia = fecha.getDate();
    var mes = fecha.getMonth() + 1; // Los meses van de 0 a 11, por lo que sumamos 1
    var año = fecha.getFullYear();
    var horas = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
  
    // Agregar ceros iniciales si es necesario
    dia = dia < 10 ? '0' + dia : dia;
    mes = mes < 10 ? '0' + mes : mes;
    horas = horas < 10 ? '0' + horas : horas;
    minutos = minutos < 10 ? '0' + minutos : minutos;
    segundos = segundos < 10 ? '0' + segundos : segundos;
  
    // Construir el formato deseado (puedes ajustar esto según tu preferencia)
    var formato = dia + '/' + mes + '/' + año + ' ' + horas + ':' + minutos + ':' + segundos;
  
    return formato;
  }
  

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader
          title="Compromisos"
          handleclick={() => navigate("/socialcompromisos")}
        />
        {user.ambito_perfil.find((item) => item.idPerfil === 174) ? (
          <Grid  xs={12} sm={12} md={4} lg={4}>
            <CardCommitments
              icon={"groups"}
              title={"Meta"}
              total={`${totales.Avance} ${
                totales.Meta ? `de ${totales.Meta}` : ""
              }`}
              subtitle1={"Porcentaje Avance"}
              subtotal1={`${totales.PorcentajeAvance} %`}
              subtitle2={"Avance Esperado"}
              subtotal2={totales.AvanceEsperado}
              subtitle3={"Porcentaje Esperado"}
              subtotal3={`${totales.PorcentajeEsperado} %`}
              subIcon={"update"}
              subFooter={today.toLocaleDateString()}
              loading={loading}
            />
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid  xs={12} sm={12} md={2} lg={2}>
              <CardIconColor
                title="Total Estruturas"
                total={dataChart.TotalEstructuras}
                color="info"
                icon={"schema"}
                sx={{ height: "215px" }}
              />
            </Grid>
            <Grid  xs={12} sm={12} md={3} lg={4}>
            <AttendanceCard
              data={{
                contentData: String(stats.porcentaje),
                leftFooterData: String(numberWithCommas(stats.Avance)),
                rightFooterData: String(numberWithCommas(stats.MetaMunicipio)),
              }}
              config={{
                leftFooterTitle: "Avance",
                rightFooterTitle: "Meta",
              }}
              loading ={loading}
              titlecontent={formatearFechaHora(stats.FechaActualizo)}
            />
            </Grid>
            <Grid  xs={12} sm={12} md={3} lg={3}>
              <CardCommitments
                icon={"perm_identity"}
                title={"Total Responsables"}
                total={dataChart.responsables.TotalResponsables}
                subtitle1={"Femenino"}
                subtotal1={dataChart.responsables.TotalFemenino}
                subtitle2={"Masculino"}
                subtotal2={dataChart.responsables.TotalMasculino}
                subtitle3={"Binario"}
                subtotal3={dataChart.responsables.TotalBinario}
                subIcon={"update"}
                subFooter={today.toLocaleDateString()}
                loading={loadingChart}
              />
            </Grid>
            <Grid  xs={12} sm={12} md={3} lg={3}>
              <CardCommitments
                icon={"record_voice_over"}
                title={"Total Enlaces"}
                total={dataChart.enlaces.TotalEnlaces}
                subtitle1={"Femenino"}
                subtotal1={dataChart.enlaces.TotalFemenino}
                subtitle2={"Masculino"}
                subtotal2={dataChart.enlaces.TotalMasculino}
                subtitle3={"Binario"}
                subtotal3={dataChart.enlaces.TotalBinario}
                subIcon={"update"}
                subFooter={today.toLocaleDateString()}
                loading={loadingChart}
              />
            </Grid>
          </Grid>
        )}

        <Card className="card-primary">
          <CardContent>
            <Filter
              onChangeFilter={(e) => handleChangeFilter(e)}
              filterCompromiso={false}
              poligono={false}
              seccion={false}
              structure={value === 1 || value === "1" ? true : false}
              mujeres={mujeres}
              fathers={true}
            />
          </CardContent>
        </Card>

        <Card className="card-primary">
          <CardContent>
               {isDownload ? (
              <Download format={"xlsx"} isDownload={isDownload} />
            ) : (
              <Box display={"flex"} justifyContent={"flex-end"}>
               
              </Box>
            )} 
                {showMessage && (
              <Box marginTop={2}>
                <MessageAlert
                  showMessage={showMessage}
                  successDownload={successDownload}
                  setShowMessage={setShowMessage}
                />
              </Box>
            )}
          {" "}
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
      {/*         <Grid item>
                <FormControl>
                  <FormLabel id="show">Vista de Avance</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="show"
                    name="show-radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="General"
                    />
                    <FormControlLabel
                      value={2}
                      control={<Radio />}
                      label="Vinculación"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid> */}
     {/*             <Grid item>
                <Button
                  variant="outlined"
                  color="primaryDark"
                  startIcon={<Icon>download</Icon>}
                  size="small"
                  onClick={handleDownload}
                  sx={{ borderRadius: "10px" }}
                >
                  Exportar
                </Button>
              </Grid>  */}
            </Grid>
            {/*   {showMessage && (
              <Box marginTop={2}>
                <MessageAlert
                  showMessage={showMessage}
                  successDownload={successDownload}
                  setShowMessage={setShowMessage}
                />
              </Box>
            )} */}
            <BasicTable
              rows={data.rows}
              hcolumns={columns.head}
              hSubColumns={columns.sub}
              /*  pageProp={tableData.page}
              pageSize={tableData.pageSize} */
              /* total={total} */
              showPagination={false}
              handleChangePagination={handleChangePagination}
              isLoading={loading}
              stickyHeader={true}
              handleManualSort={handleRequestSort}
            />
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};
export default SocialStructure;

import React, { useEffect } from "react";
import { useFormik } from "formik";

//MUI
import { Box, TextField, Grid, Modal, Divider, Typography, IconButton, Button } from "@mui/material";
import { Close } from "@mui/icons-material";
import { red } from "@mui/material/colors";

//COMPONENTS
import BasicSelect from "@components/Selects/BasicSelect";
import UploadSingleFile from "@components/Files/UploadSingleFile";

//VALIDATION & INTERFACE
import { RegistrarPPM } from "@data/schemas/ppmSchema";
import { registrarInterface } from "@data/interfaces/ppmInterface";

const Register_form = (props) => {
  const { setModalOpen, modalOpen, params = {}, title = "Actualización de información", setData, showInut = true } = props;

  const SelectOptions = [
    { value: 0, label: "Secretaria Estatal" },
    { value: 1, label: "Coordinadora" },
    { value: 2, label: "Secretaria Municipal" },
  ];

  const formik = useFormik({
    initialValues: registrarInterface,
    validationSchema: RegistrarPPM,
    enableReinitialize: false,
    onSubmit: (values, actions) => {
      setData(values);
    },
  });

  const resetParams = () => {
    formik.resetForm();
    setModalOpen(false);
  };

  const handleEditModalClose = () => {
    setModalOpen(false);
  };

  const handleInputChange = (fieldName) => (e) => {
    const { value } = e.target;
    formik.setValues((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleNombre = handleInputChange("NombreCompleto");
  const handleResponsabilidad = handleInputChange("idResponsabilidad");
  const handleUbicacion = handleInputChange("Ubicacion");
  const handleNombreImg = handleInputChange("imageName");
  const handleFiles = (e) => {
    formik.setValues((prev) => ({
      ...prev,
      files: [{ file: e }],
    }));
  };

  useEffect(() => {
    if (Object.keys(params).length > 0) {
      formik.setValues({
        idUnico: params.idUnico || "",
        NombreCompleto: params.NombreCompleto || "",
        idResponsabilidad: params.idResponsabilidad,
        Ubicacion: params.Ubicacion,
        imageName: params.imageName,
        files: [{ file: params.imagePath }],
        comparar: params.imagePath,
      });
    }
    //eslint-disable-next-line
  }, [params]);

  return (
    <Modal open={modalOpen} disableEscapeKeyDown aria-labelledby="modal-edit-title" aria-describedby="modal-edit-description">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 1100,
          bgcolor: "background.paper",
          border: "2px solid #000",
          borderRadius: 4,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography id="modal-edit-title" variant="h6" component="h2">
            {title}
          </Typography>
          <IconButton onClick={handleEditModalClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider />
        <Grid container spacing={1} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <TextField
              id="Nombre"
              variant="outlined"
              label="Nombre Completo"
              size="small"
              sx={{ width: "100%", mt: 1 }}
              value={formik.values.NombreCompleto}
              onChange={handleNombre}
              error={Boolean(formik.errors.NombreCompleto)}
              helperText={formik.errors.NombreCompleto}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4}>
            <BasicSelect
              error={Boolean(formik.errors.idResponsabilidad)}
              errorMessage={formik.errors.idResponsabilidad}
              name="idResponsabilidad"
              label="Responsabilidad"
              options={SelectOptions}
              value={formik.values.idResponsabilidad}
              onChange={handleResponsabilidad}
              size="small"
              sx={{ width: "100%", mt: 1 }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4}>
            <TextField
              id="ubicacion"
              variant="outlined"
              label="Ubicacion"
              size="small"
              sx={{ width: "100%", mt: 1 }}
              value={formik.values.Ubicacion}
              onChange={handleUbicacion}
              error={Boolean(formik.errors.Ubicacion)}
              helperText={formik.errors.Ubicacion}
            />
          </Grid>

          {showInut && (
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <TextField
                id="image"
                variant="outlined"
                label="Nombre de imagen"
                size="small"
                sx={{ width: "100%", mt: 1 }}
                value={formik.values.imageName}
                onChange={handleNombreImg}
                error={Boolean(formik.errors.imageName)}
                helperText={formik.errors.imageName}
              />
            </Grid>
          )}

          <Grid container spacing={2} mt={2} justifyContent="center">
            <Grid item xs={8}>
              <UploadSingleFile
                file={formik.values.files && formik.values.files.length > 0 ? formik.values.files[0].file : params.imagePath}
                setFile={handleFiles}
                accept="image/*"
              />
              {formik.errors?.files && Array.isArray(formik.errors.files) && formik.errors.files[0]?.file && (
                <Typography color="error">{formik.errors.files[0].file}</Typography>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={2} justifyContent="center">
            <Grid item>
              <Button variant="contained" size="small" onClick={resetParams} style={{ backgroundColor: red[600], color: "#fff" }}>
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primaryDark" size="small" onClick={formik.submitForm}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default Register_form;

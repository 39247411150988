import { Container, Icon, IconButton, Stack, Typography } from "@mui/material";

import { getVars } from "@utils/global";
import { useNavigate } from "react-router-dom";

const Navbar = ({
  title,
  screenBack,
  isScreen,
  setTypeScreen,
  color = "white",
}) => {
  const {
    user: { Nombre, Paterno, Materno },
  } = getVars("Token");

  const navigate = useNavigate();

  const handleGoToHome = () => {
    navigate("/Welcome");
  };

  const handleGoToBack = (screen) => {
    setTypeScreen(screen);
  };

  return (
    <Container>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
        color={color}
      >
        {!isScreen ? (
          <Typography variant="body2" fontWeight={600}>
            Hola {Nombre} {Paterno} {Materno}
          </Typography>
        ) : (
          <IconButton onClick={() => handleGoToBack(screenBack)}>
            <Icon sx={{ color: color }}>keyboard_arrow_left</Icon>{" "}
            <Typography color={color}>{title}</Typography>
          </IconButton>
        )}
        <IconButton onClick={handleGoToHome}>
          <Icon sx={{ color: color }}>home</Icon>
        </IconButton>
      </Stack>
    </Container>
  );
};

export default Navbar;

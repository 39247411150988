import { useState, useEffect } from "react";
import {
  Modal,
  Box,
  IconButton,
  Button,
  Icon,
  Card,
  CardHeader,
  CardContent,
  Stack,
  Typography,
  Chip,
  Grid,
  LinearProgress,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import BasicList from "../Lists/BasicList";
import CloseIcon from "@mui/icons-material/Close";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import electoral from "../../services/ElectoralServices";
import Swal from "sweetalert2";
import RGListItem from "./RGsListItem";
import NotPermission from "@assets/img/NotPermission.jpg";
import middleware from "@middlewares/middleware";
import { useCatalogs } from "@hooks/useCatalogs";
import InputSelect from "@components/Selects/BasicSelect";
import Yup from "@utils/Yupi18n";

const AssignRG = ({ open, setOpen, filtro, title = { tipo: "RG", idTipo: 0, ruta: "" } }) => {
  const selectStyles = {
    menuPortal: (base) => ({ ...base /*  zIndex: "9999999 !important", */ }),
    option: (defaultStyles, state) => ({
      ...defaultStyles,
    }),
    menuList: (styles) => ({
      ...styles,
      height: "90px",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };
  const catalogsParams = [
    { id: "regiones", getAll: false },
    { id: "municipios_dl", getAll: false },
    { id: "secciones", getAll: false },
  ];
  const filtroTodos = [{ label: "TODOS", value: 0 }];
  const [volunteer, setVolunteer] = useState({ NombreCompleto: "" });
  const [flagVolunter, setFlagVolunter] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [statusValidated, setStatusValidated] = useState(false);
  const [filtered, setFiltered] = useState([]);
  const [resultAssing, setResultAssing] = useState({
    success: false,
    message: "",
  });
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsParams,
    putDefaultOption: false,
  });
  const [municipio, setMunicipio] = useState([]);
  const [seccion, setSeccion] = useState([]);
  const formik = useFormik({
    initialValues: {
      idTipo: title.idTipo,
      idUsuarioRG: null,
      idUsuarioAbogado: null,
      idRuta: null,
    },
    validationSchema: Yup.object().shape({
      idTipo: Yup.number().required(),
      idUsuarioRG: Yup.number().when("idTipo", {
        is: (idTipo) => idTipo === 0,
        then: Yup.number().required(),
        otherwise: Yup.number().nullable(),
      }),
      idUsuarioAbogado: Yup.number().when("idTipo", {
        is: (idTipo) => idTipo === 1,
        then: Yup.number().required(),
        otherwise: Yup.number().nullable(),
      }),
      idRuta: Yup.number().required(),
    }),
    onSubmit: (values) => {
      // Aquí manejas la lógica de envío del formulario
      onSubmit(values);
    },
  });
  const [dataFilter, setDataFilter] = useState({
    idRegion: filtro.idRegion,
    idMunicipio: filtro.idMunicipio,
    Seccion: filtro.Seccion,
  });

  useEffect(() => {
    if (!load) {
      setMunicipio(filtroTodos.concat(catalogs.municipios_dl));
      setSeccion(catalogs.secciones);
    }
    // eslint-disable-next-line
  }, [load]);

  useEffect(() => {
    setDataFilter({
      idRegion: filtro.idRegion,
      idMunicipio: filtro.idMunicipio,
    });
  }, [filtro]);

  useEffect(() => {
    if (open) {
      let newValues = { ...formik.values, idRuta: title.idRuta };

      formik.setValues({ ...newValues });
    }
    // eslint-disable-next-line
  }, [title]);

  useEffect(() => {
    if (open) handleSearch("");
    // eslint-disable-next-line
  }, [dataFilter]);

  const getList = (data) => {
    if (loading) {
      return true;
    }
    setLoading(true);

    if (title.idTipo === 0) {
      electoral
        .getListRG({
          page: data.page,
          pageSize: data.pageSize,
          filtered: data.filtered,
        })
        .then((res) => {
          if (res.results) {
            if (res.response.total > 0) {
              const volunteers = res.response.data.map((volunteer) => {
                return {
                  ...volunteer,
                  NombreCompleto: `${volunteer.Nombre} ${volunteer.Paterno}`,
                };
              });

              setData(volunteers);
              setTotal(res.response.total);
            }

            if (pageSize >= parseInt(res.response.total)) {
              setHasMore(false);
            }
          } else {
            Swal.fire({ title: res.message, icon: "warning" });
            setHasMore(false);
          }
        })
        .catch((e) => {
          setHasMore(false);
          Swal.fire({ title: e.message, icon: "warning" });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      electoral
        .getListAbogado({
          page: data.page,
          pageSize: data.pageSize,
          filtered: data.filtered,
        })
        .then((res) => {
          if (res.results) {
            if (res.response.total > 0) {
              const volunteers = res.response.data.map((volunteer) => {
                return {
                  ...volunteer,
                  NombreCompleto: `${volunteer.Nombre} ${volunteer.Paterno}`,
                  ruta: volunteer.RutasAsignadas ? (
                    <>
                      <Typography variant="body2" display="block" gutterBottom>
                        Ruta:{" "}
                      </Typography>
                      {volunteer.RutasAsignadas.split(",").map((item) => {
                        return <Chip label={item} color="success" variant="outlined" />;
                      })}
                    </>
                  ) : (
                    ""
                  ),
                };
              });

              setData(volunteers);
              setTotal(res.response.total);
            }

            if (pageSize >= parseInt(res.response.total)) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
          } else {
            Swal.fire({ title: res.message, icon: "warning" });
            setHasMore(false);
          }
        })
        .catch((e) => {
          setHasMore(false);
          Swal.fire({ title: e.message, icon: "warning" });
        })
        .finally(() => {
          setLoading(false);
        });
    }

    // eslint-disable-next-line
  };

  /*   useEffect(()=>{},[sectionValid]) */

  const fetchHasMore = () => {
    if (title.idTipo === 0) {
      electoral
        .getListRG({
          page: page + 1,
          pageSize: pageSize,
          filtered: filtered,
        })
        .then((res) => {
          if (res.results) {
            const volunteers = res.response.data.map((volunteer) => {
              return {
                ...volunteer,
                NombreCompleto: `${volunteer.Nombre} ${volunteer.Paterno}`,
              };
            });
            setData(data.concat(volunteers));
            let end = (page + 1) * pageSize;
            if (end >= res.response.total) {
              setHasMore(false);
            }
          } else {
            Swal.fire({ title: res.message, icon: "warning" });
            setHasMore(false);
          }
        })
        .catch((e) => {
          setHasMore(false);
          Swal.fire({ title: e.message, icon: "warning" });
        });
    } else {
      electoral
        .getListAbogado({
          page: page + 1,
          pageSize: pageSize,
          filtered: filtered,
        })
        .then((res) => {
          if (res.results) {
            const volunteers = res.response.data.map((volunteer) => {
              return {
                ...volunteer,
                NombreCompleto: `${volunteer.Nombre} ${volunteer.Paterno}`,
              };
            });
            setData(data.concat(volunteers));
            let end = (page + 1) * pageSize;
            if (end >= res.response.total) {
              setHasMore(false);
            }
          } else {
            Swal.fire({ title: res.message, icon: "warning" });
            setHasMore(false);
          }
        })
        .catch((e) => {
          setHasMore(false);
          Swal.fire({ title: e.message, icon: "warning" });
        });
    }
    setPage(page + 1);
  };

  const handleClickData = (data) => {
    setStatusValidated(false);
    setFlagVolunter(true);
    if (formik.values.idTipo === 0) {
      formik.handleChange({
        target: {
          name: "idUsuarioAbogado",
          value: null,
        },
      });
      formik.handleChange({
        target: {
          name: "idUsuarioRG",
          value: data.id,
        },
      });
    } else {
      formik.handleChange({
        target: {
          name: "idUsuarioRG",
          value: null,
        },
      });
      formik.handleChange({
        target: {
          name: "idUsuarioAbogado",
          value: data.id,
        },
      });
    }

    setVolunteer(data);
  };

  const onSubmit = async (values) => {
    setStatusValidated(false);
    const res = await Swal.fire({
      title: "Asignar!",
      html: `<p>Esta Seguro de asignar la Ruta ${title.ruta} al ${title.tipo} ${volunteer.NombreCompleto}</p>`,
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: `Continuar`,
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        container: "modal-alert",
      },
    });

    if (res.isConfirmed) {
      setIsLoadingForm(true);
      let newData = {
        idUsuarioAbogado: values.idUsuarioAbogado,
        idUsuarioRG: values.idUsuarioRG,
        idRuta: values.idRuta,
      };

      if (values.idTipo === 0) {
        delete newData.idUsuarioAbogado;
      } else {
        delete newData.idUsuarioRG;
      }

      try {
        if (values.idTipo === 0) {
          const result = await electoral.asignarRGRuta(newData);
          const { results, message } = result;
          if (results) {
            setResultAssing({ success: true, message: message });
            setOpen(false);
          } else throw new Error(message);
        } else {
          const result = await electoral.asignarAbogadoRuta(newData);
          const { results, message } = result;
          if (results) {
            setResultAssing({ success: true, message: message });
            setOpen(false);
          } else throw new Error(message);
        }
      } catch (error) {
        Swal.fire({
          title: error.message,
          icon: "warning",
          customClass: { container: "modal-alert" },
        });
        setResultAssing({ success: false, message: error.message });
      } finally {
        setStatusValidated(true);
        setIsLoadingForm(false);
      }
    }
  };

  const evalInputSearch = (text) => {
    let out_text = text.trim();
    let type = "text";
    // const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    const phoneRegex = /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/;

    if (phoneRegex.test(out_text)) {
      out_text = out_text.replace(/\D/g, "").slice(-10);
      type = "phone";
    } else {
      out_text = text.replace(/\s+/g, "%");
    }

    return {
      type,
      text: out_text,
    };
  };

  const handleSearch = (searchInput) => {
    let filtro = [];
    if (searchInput === "") {
      filtro = [];
    } else {
      const { type, text } = evalInputSearch(searchInput);
      if (type === "phone") {
        filtro = [
          {
            id: `usuarios.Celular`,
            filter: "=",
            value: `${text}`,
            inheritFilterType: null,
          },
        ];
      } else {
        filtro = [
          {
            id: `CONCAT_WS('', usuarios.Nombre, usuarios.Paterno, usuarios.Materno)`,
            filter: "LIKE",
            value: `%${text}%`,
            inheritFilterType: null,
          },
        ];
      }
    }

    if (dataFilter.idRegion > 0) {
      filtro.push({ id: "cat_municipios_reportes.idRegion", value: dataFilter.idRegion, filter: "=" });
    }

    if (dataFilter.idMunicipio > 0) {
      filtro.push({ id: "cat_municipios_reportes.id", value: dataFilter.idMunicipio, filter: "=" });
    }
    /* 
    if(dataFilter.Seccion.length > 0){
      let seccion = dataFilter.Seccion.map(item=> {return item.value});
      filtro.push({id:"cat_secciones.Seccion",value:seccion,filter:"="});
    } */

    setData([]);
    setFiltered(filtro);
    setPage(0);
    getList({ page: 0, pageSize: pageSize, filtered: filtro });
  };

  const handleChangeRegion = (event) => {
    const idRegion = event.target.value;
    setDataFilter({
      ...dataFilter,
      idRegion: idRegion,
      idMunicipio: 0,
      Seccion: [],
    });

    if (idRegion > 0) {
      setMunicipio(filtroTodos.concat(catalogs.municipios_dl.filter((item) => item.idRegion === idRegion)));

      setSeccion(catalogs.secciones.filter((item) => item.idRegion === idRegion));
    } else {
      setMunicipio(filtroTodos.concat(catalogs.municipios_dl));
      setSeccion(filtroTodos.concat(catalogs.secciones));
    }
  };

  const handleChangeMunicipio = (event) => {
    const idMunicipio = event.target.value;
    setDataFilter({
      ...dataFilter,
      idMunicipio: idMunicipio,
      Seccion: [],
    });

    if (idMunicipio > 0) {
      setSeccion(catalogs.secciones.filter((item) => item.idMunicipio === idMunicipio));
    } else {
      setSeccion(filtroTodos.concat(catalogs.secciones));
    }
  };

  const handleChangeSeccion = (event) => {
    setDataFilter({
      ...dataFilter,
      Seccion: event,
    });
  };

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            borderRadius: "8px",
            boxShadow: 24,
            p: 2,
            width: { xs: "100%", sm: "90%", md: "80%", lg: "50%" },
            overflowY: "auto",
            maxHeight: "90%",
          }}
        >
          <IconButton
            aria-label="cancel"
            onClick={() => setOpen(false)}
            sx={{ position: "fixed", left: "3%", top: "2%", color: "#FFFFFF" }}
          >
            <Icon>cancel</Icon>
          </IconButton>
          {middleware.checkMenuAction("Autorizar") ? (
            <Card elevation={0} sx={{ overflow: "auto" }}>
              <Grid container spacing={2} marginBottom={"1rem"} marginTop={"0.5rem"}>
                <Grid item xs={12} md={6}>
                  <InputSelect
                    label="Región"
                    options={!load ? filtroTodos.concat(catalogs.regiones) : []}
                    name="region"
                    value={dataFilter.idRegion}
                    onChange={handleChangeRegion}
                    disabled={!load && catalogs.regiones.length === 1 ? true : false}
                    sx={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputSelect
                    label="Municipio"
                    options={municipio}
                    name="municipio"
                    value={dataFilter.idMunicipio}
                    onChange={handleChangeMunicipio}
                    disabled={!load && catalogs.municipios_dl.length === 1 ? true : false}
                    sx={{ width: "100%" }}
                  />
                </Grid>
                {/*           <Grid item xs={12} md={4}>
                  <Select
                    placeholder="Sección"
                    className="basic-single"
                    classNamePrefix="select"
                    styles={selectStyles}
                    closeMenuOnSelect={false}
                    value={dataFilter.Seccion}
                     onChange={handleChangeSeccion}
                    isMulti
                    options={seccion}
                    isDisabled={!load && catalogs.secciones.length === 1 ? true : false}
                  />
                </Grid> */}
              </Grid>
              <CardHeader
                title={`Asignar ${title.tipo}`}
                subheader={`Ruta ${title.ruta}`}
                titleTypographyProps={{
                  align: "center",
                  variant: "h5",
                  color: "white",
                }}
                subheaderTypographyProps={{ align: "center", color: "white" }}
                sx={{ backgroundColor: "#152A9E", pt: "25px" }}
              />
              <CardContent>
                <Grid2 container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid2 xs={12} sm={12} md={12} lg={12}>
                    {loading && <LinearProgress color="secondary" />}
                    <Stack direction="column" justifyContent="center" alignItems="center" spacing={0.5}>
                      <BasicList
                        Header={false}
                        data={data}
                        fetchHasMore={fetchHasMore}
                        totalItems={total}
                        itemComponent={RGListItem}
                        hasMore={hasMore}
                        finder={true}
                        search={handleSearch}
                        handleClickItem={(e) => handleClickData(e)}
                        idTipo={title.idTipo}
                      />
                    </Stack>
                  </Grid2>
                </Grid2>
              </CardContent>
            </Card>
          ) : (
            <Card elevation={0}>
              <CardHeader
                title={`Asignar ${title.tipo}`}
                subheader={`Ruta ${title.ruta}`}
                titleTypographyProps={{
                  align: "center",
                  variant: "h5",
                  color: "white",
                }}
                subheaderTypographyProps={{ align: "center", color: "white" }}
                sx={{ backgroundColor: "#152A9E", pt: "25px" }}
              />
              <CardContent>
                <Grid2 container justifyContent="center" rowSpacing={1}>
                  <Grid2 item xs={12} sm={12} md={12} lg={12}>
                    <Typography
                      style={{
                        fontFamily: "Open Sans, sans-serif",
                        fontSize: "25px",
                        color: "red",
                        textAlign: "center", // Centra el texto
                      }}
                    >
                      No tiene los permisos necesarios. Contacte al administrador
                    </Typography>
                  </Grid2>
                  <Grid2 item xs={12} sm={12} md={12} lg={12}>
                    <img src={NotPermission} alt="permission" style={{ width: "100%", height: "100%" }} />
                  </Grid2>
                </Grid2>
              </CardContent>
            </Card>
          )}
          <div>
            <Grid2 container spacing={1} justifyContent={"flex-end"}>
              <Grid2 item>
                <LoadingButton
                  variant="contained"
                  size="small"
                  loading={isLoadigForm}
                  endIcon={<Icon>save</Icon>}
                  loadingPosition="end"
                  onClick={formik.submitForm}
                >
                  Asignar
                </LoadingButton>
              </Grid2>
              <Grid2 item>
                <Button
                  size="small"
                  variant="contained"
                  color="error"
                  onClick={() => setOpen(false)}
                  sx={{ borderRadius: "10px" }}
                  endIcon={<CloseIcon />}
                >
                  Cerrar
                </Button>
              </Grid2>
            </Grid2>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AssignRG;

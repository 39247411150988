import {
  Box,
  Icon,
  TableCell,
  TableRow,
  Typography,
  TableBody as TableBodyMui,
  TextField,
  Stack,
  Button,
  CircularProgress,
} from "@mui/material";
import { grey } from "@mui/material/colors";

import BasicSelect from "@components/Selects/BasicSelect";
import ContactsStack from "@components/ContactsStack/ContactsStack";
import { useState } from "react";

const TableBody = ({
  data,
  dataFound,
  catCasillas,
  rowIdx,
  loadingCasillas,
  loading,
  handleChangeData,
  handleKeyPressSearch,
  handleSaveBingo,
}) => {
  const [rowValues, setRowValues] = useState({}); // Estado para almacenar los valores de cada fila

  const handleChange = (e, rowId) => {
    const { name, value } = e.target;
    setRowValues((prevState) => ({
      ...prevState,
      [rowId]: {
        ...prevState[rowId],
        [name]: value,
      },
    }));
  };

  return (
    <TableBodyMui>
      {loading ? (
        <TableRow>
          <TableCell colSpan={4}>
            <CircularProgress />
          </TableCell>
        </TableRow>
      ) : data.length > 0 ? (
        data.map((row, index) => (
          <TableRow key={index}>
            <TableCell>
              <Stack direction={"column"}>
                <Typography variant="body2">{row.NombreCompleto}</Typography>
                <Typography variant="caption">
                  {row.CalleVive} {row.NumExtVive} {row.ColoniaVive}{" "}
                  {row.Municipio}
                </Typography>
              </Stack>
            </TableCell>
            <TableCell>
              <ContactsStack
                nombre={row.NombreCompleto}
                whatsapp={row.Celular}
                telefono={row.Telefono}
                telefono_mensaje={row.TelMensajes}
              />
            </TableCell>
            <TableCell>
              <TextField
                name="Seccion"
                label="Sección"
                value={rowValues[row.id]?.Seccion || ""}
                size="small"
                onChange={(e) => {
                  handleChangeData(e, row.id);
                  handleChange(e, row.id);
                }}
                onKeyDown={(e) => {
                  handleKeyPressSearch(e, row.id);
                }}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
            </TableCell>
            <TableCell>
              <BasicSelect
                name="Casilla"
                label="Casilla"
                value={rowValues[row.id]?.Casilla || ""}
                options={row.id === rowIdx ? catCasillas : []}
                isLoading={loadingCasillas[row.id]}
                onChange={(e) => {
                  handleChangeData(e, row.id);
                  handleChange(e, row.id);
                }}
                disabled={loadingCasillas[row.id] ? true : false}
              />
            </TableCell>
            <TableCell align="center">
              <TextField
                name="Folio"
                label="Folio"
                size="small"
                value={rowValues[row.id]?.Folio || ""}
                onChange={(e) => {
                  handleChangeData(e, row.id);
                  handleChange(e, row.id);
                }}
              />
            </TableCell>
            <TableCell align="center">
              <Button
                variant="contained"
                color="primaryDark"
                size="small"
                startIcon={<Icon>save</Icon>}
                onClick={(e) => handleSaveBingo(row.id)}
              >
                Guardar
              </Button>
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={6}>
            <Box
              display={"flex"}
              alignContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <Icon sx={{ fontSize: "5rem", color: grey[500] }}>
                {dataFound ? "person_search" : "search_off"}
              </Icon>
              <Typography fontWeight={600} variant="h6" marginTop={1}>
                {dataFound
                  ? "Datos No Disponible"
                  : "No se econtraron resultados"}
              </Typography>
              <Typography variant="caption" marginTop={1}>
                {dataFound
                  ? " Utiliza el filtro  para realizar una búsqueda y obtener los resultados deseados."
                  : "Por favor, intenta realizar la búsqueda nuevamente, ya que no se encontraron datos con los criterios actuales"}
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      )}
    </TableBodyMui>
  );
};

export default TableBody;

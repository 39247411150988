import ApiExec, { downloadFile }from "@utils/ApiExec";

class TerritorialServices {

  getProgressCompromisos = (params) => {
    return ApiExec(params, "territorial/get-pledges-compromisos", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getProgressRegion = (params) => {
    return ApiExec(params, "territorial/get-pledges-region", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getProgressMunicipality = (params) => {
    return ApiExec(params, "territorial/get-pledges-municipality", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getProgressDistritales = (params) => {
    return ApiExec(params, "territorial/get-pledges-distritales", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };


  getProgressPolygon = (params) => {
    return ApiExec(params, "territorial/get-pledges-polygon", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getProgressSection = (params) => {
    return ApiExec(params, "territorial/get-pledges-section", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getProgressBlock = (params) => {
    return ApiExec(params, "territorial/get-pledges-block", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getStatsCompromisos = (params) => {
    return ApiExec(params, "territorial/get-stats-compromisos", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getStatsStruct = (params) => {
    return ApiExec(params, "territorial/get-stats-struct", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getDownloadPermissions = (params) => {
    return ApiExec(params, "territorial/get-fco-responsibility", "GET")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  exportFormulario = (params,token) => {
    return downloadFile({
      api: "territorial/get-pdf-fco",
      method: "POST",
      data: params
    })
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  getStreetLocation= (params) => {
    return ApiExec(params, "street/get", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  setStreetLocation = (params) => {
    return ApiExec(params, `street/set-street`, "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  deleteStreetLocation = (params) => {
    return ApiExec(params, `street/delete-street`, "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };


  getResumenMetas= (params) => {
    return ApiExec(params, "territorial/get-resumen-metas", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getListResponsableDia = (params) => {
    return ApiExec(params, "territorial/get-responsables-metas", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };


}
const TerritorialService = new TerritorialServices();
export default TerritorialService;

import Yup from "@utils/Yupi18n";
import moment from 'moment'

import { AMBITO_MZA, idPropietario, PERFIL_RC, PERFIL_RG, PERFIL_OBSERVER, PERFIL_ABOGADO, PERFIL_FiL, GUARDIAN_SECCION, LOGISTICA_ALIMENTOS } from "@data/constants";

const ANIOS_VIGENCIA_MAX = 10;
const ANIOS_VIGENCIA_MIN = 12;
const VIGENCIA_MAX_INE = parseInt(moment().format('YYYY')) + ANIOS_VIGENCIA_MAX;
const VIGENCIA_MIN_INE = parseInt(moment().format('YYYY')) - ANIOS_VIGENCIA_MIN;

const AddResponsibilitySchema = Yup.object({
  idUsurioAutorizo: Yup.number().required(),
  idCompromisoUnico: Yup.number().required(),
  idPerfil: Yup.number().required().label("Seleccione una responsabilidad"),
  idAmbitoCargo: Yup.number().required(),
  AmbitoValorCargo: Yup.number().required(),
  ResponsabilidadEtiqueta: Yup.string().required(),
  Prioridad: Yup.number().when('idAmbitoCargo', {
    is: AMBITO_MZA,
    then: Yup.number().required(),
    otherwise: Yup.number().optional().nullable()
  }),
  VigenciaINE: Yup.number().when("idPerfil", {
    is: val => ([PERFIL_RC, PERFIL_RG, PERFIL_OBSERVER, PERFIL_FiL].includes(val)),
    then: Yup.number().required().typeError('Ingrese una Vigencia INE válida')
      .max(VIGENCIA_MAX_INE, `la Vigencia no puede ser mayor al año ${VIGENCIA_MAX_INE}`)
      .min(VIGENCIA_MIN_INE, `la Vigencia no puede ser menor al año ${VIGENCIA_MIN_INE}`),
    otherwise: Yup.number().optional().nullable(true),
  }).label('Vigencia INE'),
  idPropietario: Yup.number().when('idPerfil', {
    is: (value) => idPropietario.includes(value),
    then: Yup.number().required(),
    otherwise: Yup.number().optional().nullable()
  }).label('Propietario'),
  TieneAuto: Yup.number().when("idPerfil", {
    is: val => ([PERFIL_RC, PERFIL_RG, PERFIL_OBSERVER, PERFIL_ABOGADO, GUARDIAN_SECCION, LOGISTICA_ALIMENTOS].includes(val)),
    then: Yup.number().required(),
    otherwise: Yup.number().optional().nullable(true),
  }).label('Tiene Auto'),
  TieneExperiencia: Yup.number().when("idPerfil", {
    is: val => ([PERFIL_RC, PERFIL_RG, PERFIL_OBSERVER, PERFIL_ABOGADO, GUARDIAN_SECCION, LOGISTICA_ALIMENTOS].includes(val)),
    then: Yup.number().required(),
    otherwise: Yup.number().optional().nullable(true),
  }).label('Tiene Experiencia'),
  AceptaCuidarOtra: Yup.number().when("idPerfil", {
    is: val => ([PERFIL_RC].includes(val)),
    then: Yup.number().required(),
    otherwise: Yup.number().optional().nullable(true),
  }).label('Acepta cuidar otra casilla'),
});

export { AddResponsibilitySchema }
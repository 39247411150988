import { useState, useEffect } from "react";

import {
  Button,
  ButtonGroup,
  Container,
  IconButton,
  Tooltip,
  Typography,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  KeyboardDoubleArrowRight,
  KeyboardDoubleArrowLeft,
} from "@mui/icons-material";

import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import Filter from "@components/Activation/RollCall/Filter";
import ListMobilization from "@components/Activation/RollCall/ListMobilization";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import ListPending from "@components/Activation/Capture/ListReport";
//import Tabs from "@components/Tabs/Tabs";
import StatisticalCards from "@components/Activation/RollCall/StatisticalCards";
import DrawerRelative from "@components/Containers/DrawerRelative";

import movilizacion from "@services/MovilizationServices";
import middleware from "@middlewares/middleware";
import Swal from "sweetalert2";
import { ACTIONS_SPEED } from "@data/constants/Mobilization";
import moment from "moment";
import { getVars } from "@utils/global";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const RollCall = () => {
  const { user } = getVars("Token");
  const [dataMovilization, setDataMovilization] = useState([]);
  const [users, setUsers] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [dataFound, setDataFound] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [selectTab, setSelectTab] = useState(
    middleware.checkMenuAction("Agregar") ? 0 : 1
  );
  //const [tabsInfo, setTabsInfo] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [hour, setHour] = useState(null);
  const [load, setLoad] = useState(false);
  const [catProfile, setCatProfile] = useState([]);
  const [pendingRecords, setPendingRecords] = useState([]);
  const [reportedRecords, setReportedRecords] = useState([]);
  //moment().format("YYYY-MM-DD HH:mm")

  const getMovilization = async ({ body }) => {
    try {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      const result = await movilizacion.getMovilization({ filtered: body });
      const { results, response, message, success } = result;

      if (!success) throw new Error(message);

      if (results) {
        if (selectTab === 1) {
          setPendingRecords(response.data);
          setReportedRecords([]);
          setDataMovilization([]);
        } else if (selectTab === 2) {
          setPendingRecords([]);
          setReportedRecords(response.data);
          setDataMovilization([]);
        } else {
          setPendingRecords([]);
          setReportedRecords([]);

          const tranformedData = response.data.map((item) => {
            return {
              ...item,
              Contacto: (
                <ButtonGroup>
                  {ACTIONS_SPEED.map(
                    (action) =>
                      item[action.key] && (
                        <Tooltip
                          key={action.key}
                          title={item[action.key]}
                          placement="left"
                        >
                          <IconButton
                            size="small"
                            href={
                              action.link &&
                              `${action.link}${item[action.key]}&text=Hola%20*${
                                item.NombreCompleto
                              }*`
                            }
                            target="_blank"
                          >
                            {action.icon}
                          </IconButton>
                        </Tooltip>
                      )
                  )}
                </ButtonGroup>
              ),
            };
          });
          setDataMovilization(tranformedData);
        }

        setDataFound(response.results);
      } else {
        setPendingRecords([]);
        setReportedRecords([]);
        setDataMovilization([]);
        setDataFound(results);
      }
    } catch (error) {
      setPendingRecords([]);
      setReportedRecords([]);
      setDataMovilization([]);
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  };

  const setMovilization = async ({ body }) => {
    if (users.length <= 0) return;

    try {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      const result = await movilizacion.setMovilization({
        FechaHora: hour ? moment(hour).format("HH:mm") : null,
        usuarios: body,
      });
      const { results, message, success } = result;

      if (!results && !success) throw new Error(message);

      if (results && success) {
        Swal.fire({
          title: message,
          icon: "success",
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            getMovilization({ body: filtered });
            setUsers([]);
            setSelectAll(false);
            setHour(null);
          }
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
      setRefresh(true);
    }
  };

  const handleChangeTab = (event, newValue) => {
    setSelectTab(newValue);
  };

  const handleChangeHour = (time) => setHour(time);

  const onChangeFilter = ({ filtereds }) => {
    if (filtereds.length > 0) {
      setFiltered(filtereds);
      getMovilization({ body: filtereds });
    } else {
      setFiltered([]);
    }
  };

  const onClearFilter = () => {
    setDataMovilization([]);
    setDataFound(true);
    setUsers([]);
    setFiltered([]);
  };

  const getProfiles = async (id) => {
    setLoad(true);
    try {
      const result = await movilizacion.getProfiles(id);
      const { results, response, message } = result;

      if (results) {
        setCatProfile(response.data);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    getProfiles(user.ambito_perfil[0].idUsuario);
    // eslint-disable-next-line
  }, []);

  return (
    <AdminLayout>
      <LoadingForm
        loadinMessage={"Cargando Datos..."}
        isLoading={isLoadigForm}
        success={isSuccessForm}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
      />
      <Container maxWidth={false}>
        <ActionHeader title={"Pase de lista"} isCustom={true}>
          <Button
            onClick={() => setOpenDrawer(!openDrawer)}
            aria-label="open Drawer"
            endIcon={
              openDrawer ? (
                <KeyboardDoubleArrowRight />
              ) : (
                <KeyboardDoubleArrowLeft />
              )
            }
          >
            <Typography sx={{ fontWeight: 500 }}>FILTROS</Typography>
          </Button>
        </ActionHeader>
        <StatisticalCards
          params={filtered}
          refresh={refresh}
          setRefresh={setRefresh}
        />
        <Grid2
          container
          spacing={openDrawer ? 2 : 0}
          sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
        >
          <Grid2 xs={12} md={3} lg={3}>
            <DrawerRelative
              anchor="left"
              open={openDrawer}
              setOpen={setOpenDrawer}
              title={"Filtros"}
              icon="filter_list"
              titleComponentPlace="under"
              className="card-primary"
              sxPaper={{ borderRadius: 3 }}
              isSticky
            >
              <Filter
                onChange={onChangeFilter}
                onClear={onClearFilter}
                loadProfiles={load}
                catProfile={catProfile}
                selectTab={selectTab}
              />
            </DrawerRelative>
          </Grid2>
          <Grid2 xs={12} md={openDrawer ? 9 : 12}>
            {/* <Tabs
              data={tabsInfo}
              handleClick={(e) => setSelectTab(e)}
              centered
            /> */}
            <Tabs
              value={selectTab}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
            >
              {middleware.checkMenuAction("Agregar") && (
                <Tab label="CAPTURA PASE DE LISTA" value={0} />
              )}
              <Tab label="PENDIENTES POR REPORTAR" value={1} />
              <Tab label="REPORTADOS" value={2} />
            </Tabs>
            {middleware.checkMenuAction("Agregar") && (
              <CustomTabPanel value={selectTab} index={0}>
                <ListMobilization
                  data={dataMovilization}
                  users={users}
                  hour={hour}
                  dataFound={dataFound}
                  selectAll={selectAll}
                  setUsers={setUsers}
                  setMovilization={setMovilization}
                  setSelectAll={setSelectAll}
                  handleChangeHour={handleChangeHour}
                />
              </CustomTabPanel>
            )}
            <CustomTabPanel value={selectTab} index={1}>
              <ListPending
                title="PENDIENTES POR REPORTAR"
                rows={pendingRecords}
                filterDefault="pendingReport"
                dataFound={dataFound}
                isChip={false}
              />
            </CustomTabPanel>
            <CustomTabPanel value={selectTab} index={2}>
              <ListPending
                title="REPORTADOS"
                rows={reportedRecords}
                filterDefault="reports"
                dataFound={dataFound}
              />
            </CustomTabPanel>
          </Grid2>
        </Grid2>
      </Container>
    </AdminLayout>
  );
};

export default RollCall;

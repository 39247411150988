import { PrivilegedRoute } from "@middlewares";
/* 
import { ProtectedRoute } from "@middlewares"; */

import ProgressRegion from "../../pages/Territorial/ProgressRegion";
import ProgressMunicipality from "../../pages/Territorial/ProgressMunicipality";
import ProgressDistritales from "../../pages/Territorial/ProgressDistrital";
import ProgressPolygon from "../../pages/Territorial/ProgressPolygon";
import ProgressSections from "../../pages/Territorial/ProgressSections";
import FCO from "../../pages/Territorial/FCO";
import StreetLocation from "../../pages/Territorial/ StreetLocation";
import Responsability from "../../pages/Territorial/Responsibility";
import AvanceResponsabilidades from "../../pages/Territorial/AvanceResponsabilidades";

const TerritorialRoutes = [
    {
        path: '/avance_compromisos',
        element: <PrivilegedRoute><ProgressRegion /></PrivilegedRoute>
    },
    {
        path: '/compromisos_municipio',
        element: <PrivilegedRoute><ProgressMunicipality /></PrivilegedRoute>
    },
    {
        path: '/compromisos_distritales',
        element: <PrivilegedRoute><ProgressDistritales /></PrivilegedRoute>
    },
    {
        path: '/compromisos_poligonos',
        element: <PrivilegedRoute><ProgressPolygon /></PrivilegedRoute>
    },
    {
        path: '/compromiso_seccion',
        element: <PrivilegedRoute><ProgressSections /></PrivilegedRoute>
    },
    {
        path: '/avance-estructura-p',
        element: <PrivilegedRoute><Responsability /></PrivilegedRoute>
    },
    {
        path: '/descarga_plantilla',
        element: <PrivilegedRoute><FCO /></PrivilegedRoute>
    },
    {
        path: '/ubicacion_manzana',
        element: <PrivilegedRoute><StreetLocation /></PrivilegedRoute>
    },
    {
        path: '/directorio_territorial',
        element: <PrivilegedRoute><AvanceResponsabilidades /></PrivilegedRoute>
    }
   
];

export default TerritorialRoutes;
import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import bbox from "geojson-bbox";

// Mapas
import { MapContainer, TileLayer, GeoJSON, ZoomControl, Pane } from "react-leaflet";
import L from "leaflet";
import "leaflet.fullscreen/Control.FullScreen.js";
import "leaflet.fullscreen/Control.FullScreen.css";

// Componentes
import CardInfo from "@components/Maps/InternalComponents/Cards/CardInfo";
import MapViewType from "@components/Maps/InternalComponents/Cards/MapViewType";
import ControlFilter from "@components/Maps/InternalComponents/Cards/ControlFilter";
import CardComponent from "@components/Maps/InternalComponents/Cards/CardComponent";
import CardDrawerTop from "@components/Maps/InternalComponents/Cards/CardDrawerTop";
import DrawerLeft from "@components/Maps/InternalComponents/Cards/ControlDrawerLeft";
import CardGeneralInfo from "@components/Maps/InternalComponents/Cards/CardGeneralInfo";
import ControlDrawerTop from "@components/Maps/InternalComponents/Cards/ControlDrawerTop";
import ControlNavigation from "@components/Maps/InternalComponents/Cards/ControlNavigation";
import ControlCenterView from "@components/Maps/InternalComponents/Cards/ControlCenterView";

// Estilos
import "@components/Maps/Styles/styleTooltip.css";
import { MagicSpinner } from "react-spinners-kit";
import { style_spinner } from "@components/Maps/Styles/Spinners";
import { style_original } from "@components/Maps/Auxiliars/ColorFeature";

// Hooks
import useWindowDimensions from "@hooks/useWindowDimensions";

const Map = (props) => {
  const {
    data = null,
    subData = null,
    polygonsOLD = null,
    isHidden = false,
    styleProp = style_original,
    zoom = 7,
    position = [20.903422106141, -101.011696980887],
    height = null,
    drawerTop = null,
    drawerLeft = null,
    cardInfo = null,
    cardComponent = null,
    filterComponent = null,
    infoGeneral = null,
    centerFlag = true,
    fullscreenFlag = true,
    zoomControlsFlag = true,
    changeMapFlag = true,
    navigateFlag = true,
    navigate = true,
    centerByFeatures = false, // Centra el feature
    handleClickFeature = () => {}, // Click en un poligono
    order = ["oldData", "data", "subData"], // Orden de los poligonos
    highlightFlag = true, // Permite que al dar click se seleccione
    highlightFeature = null,
    onEachFeature = null,
  } = props;

  const geoJsonRef = useRef();
  const geoJsonBlocksRef = useRef();

  const { height: screenHeight } = useWindowDimensions();

  // Valores por default (props)
  const cardInfoInitialValues = cardInfo?.initialValues ?? {
    title: "INFORMACIÓN",
    elements: [{ title: "Seccion:", name: "seccion", color: "#000", type: "text" }],
  };

  const styleFlag = typeof styleProp === "object";

  // Si es un objeto pone los valores del objeto, sino, pone el normal para todos
  const styleData = styleFlag ? styleProp?.data ?? style_original : styleProp;
  const styleSubData = styleFlag ? styleProp?.subData ?? style_original : styleProp;
  const stylePolygonsOLD = styleFlag ? styleProp?.polygonsOLD ?? style_original : styleProp;
  const styleOnEachFeature = styleFlag ? styleProp?.onEachFeature ?? style_original : styleProp;
  const styleHighlightFeature = styleFlag ? styleProp?.highlightFeature ?? style_original : styleProp;

  /* Ejemplo de estucturas de styleProp

    En caso de requerir solo un estilo para todos:
    styleProp = { estilo }

    En caso de requerir distintos estilos:
    styleProp = {{
      data:
      subData:
      polygonsOLD:
      onEachFeature:
      highlightFeature:
    }}
    ( si no se pone alguno se pondra el por defecto: style_original ) 
  */

  const [flagShowOldShape, setFlagShowOldShape] = useState(false);

  const [_data, setData] = useState(null);
  const [_subData, setSubData] = useState(null);
  const [feature, setFeature] = useState(null);
  const [subFeature, setSubFeature] = useState(null);

  const mapRef = useRef();
  const [viewMap, setViewMap] = useState({
    url: "https://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}&s=Ga",
    attribution: "",
    maxZoom: 20,
    subdomains: ["mt0", "mt1", "mt2", "mt3"],
  });

  let selectedFeature = null;
  let selectedSubFeature = null;

  const setSelectedFeature = (e) => (selectedFeature = e);
  const setSelectedSubFeature = (e) => (selectedSubFeature = e);

  // Resetea los datos
  useEffect(() => {
    setFlagShowOldShape(false);
    setFeature(null);
    setSubFeature(null);
    setData(data);
    setSubData(subData);

    if (geoJsonRef.current) {
      geoJsonRef.current.clearLayers(); // remove old data
      geoJsonRef.current.addData(data); // might need to be geojson.features
    }
    if (geoJsonBlocksRef.current) {
      geoJsonBlocksRef.current.clearLayers(); // remove old data
      geoJsonBlocksRef.current.addData(subData); // might need to be geojson.features
    }

    // Lo posiciona con los valores que tenga en el momento de renderizar nuevamente
    if (mapRef.current && centerByFeatures) handleCenter();
    else if (mapRef.current) mapRef.current.flyTo(position, zoom);
    // eslint-disable-next-line
  }, [data, subData]);

  // Organiza los eventos del GeoJson.
  const onEachFeatureDefault = (feature, layer, clickFeature) => {
    if (feature.properties[feature.properties.shp] !== undefined) {
      // Estilos y acciones
      layer.setStyle(styleOnEachFeature);
      layer.on({ click: clickFeature });
    }
  };

  // Deselecciona al Feature
  const resetHighlight = (e) => {
    const layer = e.target;
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) layer.bringToFront();
    layer.setStyle(styleHighlightFeature(layer.feature));
  };

  const globalParams = {
    //  Feature
    data,
    setFeature,
    selectedFeature,
    setSelectedFeature,
    geoJsonRef,
    //  SubFeature
    subData,
    setSubFeature,
    selectedSubFeature,
    setSelectedSubFeature,
    geoJsonBlocksRef,
    //  Estilos
    resetHighlight,
    L,
  };

  const onEachFeatureSelect = onEachFeature ?? onEachFeatureDefault;

  // Datos de los GeoJSON
  const geojson =
    _data !== null ? (
      <GeoJSON
        ref={geoJsonRef}
        onEachFeature={(a, b) => onEachFeatureSelect(a, b, clickFeature, globalParams)}
        style={styleData}
        key={"geojson"}
        data={_data}
      />
    ) : null;

  const geojson_blocks =
    _subData !== null ? (
      <GeoJSON
        ref={geoJsonBlocksRef}
        onEachFeature={(a, b) => onEachFeatureSelect(a, b, clickFeature, globalParams)}
        style={styleSubData}
        key={"geojson_blocks"}
        data={_subData}
      />
    ) : null;

  const geojson_ex_pol =
    polygonsOLD !== null ? (
      <GeoJSON onEachFeature={() => {}} style={stylePolygonsOLD} key={"geojson_ex_pol"} data={polygonsOLD} />
    ) : null;

  // Sobresalta el Feature seleccionado
  const highlightFeatureDefault = (e, params) => {
    const layer = e.target;

    // Al clickear el mismo feature se deselecciona (en SubData)
    if (params.sameSubFeature) {
      setSubFeature(null);
      setSelectedSubFeature(null);
      resetHighlight(e);
      return true;
    }

    // Al clickear el mismo feature se deselecciona (en Data)
    if (params.sameFeature) {
      setFeature(null);
      setSelectedFeature(null);
      resetHighlight(e);
      return true;
    }

    if (selectedFeature) resetHighlight(selectedFeature);

    // Selecciona un SubData
    if (geoJsonBlocksRef.current) {
      if (selectedSubFeature) resetHighlight(selectedSubFeature);
      setSubFeature(layer.feature);
      setSelectedSubFeature(e);
    } else {
      // Selecciona un Data
      setFeature(layer.feature);
      setSelectedFeature(e);
    }

    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) layer.bringToFront();
    layer.setStyle(styleHighlightFeature(layer.feature, true));
  };

  // Funcion al hacer click en algun Feature
  const clickFeature = (e) => {
    // Verifica si da dos clicks en la misma Feature
    const sameFeature = selectedFeature?.target?.feature?.id === e.target.feature.id;
    const sameSubFeature = selectedSubFeature?.target?.feature?.id === e.target.feature.id;

    const params = {
      //  Feature
      setFeature,
      sameFeature,
      selectedFeature,
      setSelectedFeature,
      geoJsonRef,
      //  SubFeature
      setSubFeature,
      sameSubFeature,
      selectedSubFeature,
      setSelectedSubFeature,
      geoJsonBlocksRef,
      //  Estilos
      resetHighlight,
      L,
    };

    const paramsDefault = {
      sameFeature,
      sameSubFeature,
    };

    // Verifica si se usara el highlightFeature por defecto o uno nuevo
    if (highlightFlag && highlightFeature) highlightFeature(e, params);
    else if (highlightFlag) highlightFeatureDefault(e, paramsDefault);

    handleClickFeature(e, params); // Se usa si es necesario configurarlo desde fuera.
  };

  // Centra el mapa (reutilizable)
  const centerFeature = (map, extent) => {
    map.flyToBounds([
      [extent[1], extent[0]],
      [extent[3], extent[2]],
    ]);
  };

  // Cuando carga el mapa se ejecuta esta funcion para centrarlo
  const whenCreatedExecute = (mapInstance) => {
    const map = mapInstance.target;
    if (!data || data.features.length === 0) return;
    const extent = bbox(data);
    centerFeature(map, extent);
  };

  // Centrar el mapa con respecto al Data traido (usando el boton)
  const handleCenter = () => {
    if (data?.features?.length) {
      const extent = bbox(data);
      centerFeature(mapRef.current, extent);
    } else if (subData?.features?.length) {
      const extent = bbox(subData);
      centerFeature(mapRef.current, extent);
    } else mapRef.current.flyTo([20.903422106141, -101.011696980887], 7); // Si no tiene poligonos centra normal
  };

  // Cambia el tipo de vista del mapa
  const handleViewMapType = () => {
    if (viewMap.url === "https://tile.openstreetmap.org/{z}/{x}/{y}.png") {
      setViewMap({
        attribution: "",
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      });
    } else if (
      viewMap.url ===
      "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
    ) {
      setViewMap({
        url: "https://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}&s=Ga",
        attribution: "",
        maxZoom: 20,
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
      });
    } else {
      setViewMap({
        attribution: "",
        url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
      });
    }
  };

  // Centra el mapa a la posicion y zoom deseado
  useEffect(() => {
    const map = mapRef.current;
    if (data !== null && map && !centerByFeatures) {
      // En caso de que el zoom sea distinto
      if (map._zoom !== zoom) map.flyTo(position, zoom);
      else map.flyTo(position);
    }
    // eslint-disable-next-line
  }, [position, zoom]);

  // Cambia el estado para la navegación en el mapa
  const [isNavigate, setIsNavigate] = useState(navigate);

  const handleNavigation = () => {
    const map = mapRef.current;
    if (data !== null && map) {
      if (!isNavigate) navigationAction("enable", !isNavigate);
      else navigationAction("disable");
    }
    setIsNavigate(!isNavigate);
  };

  // DrawerTop
  const [drawerOpen, setDrawerOpen] = useState(drawerTop?.open || false);
  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);

  // Ajustar width cuando se abre el DrawerLeft
  const [width, setWidth] = useState(false);

  // Habilita o deshabilita la navegacion del mapa
  const navigationAction = (action, localNav = false) => {
    const map = mapRef.current;
    if (map) {
      if ((isNavigate || localNav) && action === "enable") {
        map.dragging.enable();
        map.touchZoom.enable();
        map.doubleClickZoom.enable();
        map.scrollWheelZoom.enable();
      } else if (action === "disable") {
        map.dragging.disable();
        map.touchZoom.disable();
        map.doubleClickZoom.disable();
        map.scrollWheelZoom.disable();
      }
    }
  };

  // Orden de los poligonos (para que este alguno encima de otro como prioridad)
  const getOrder = (values) => {
    const dataPane = (
      <Pane name="map_data" style={styleData}>
        {geojson}
      </Pane>
    );

    const subDataPane = (
      <Pane name="map_subData" style={styleSubData}>
        {geojson_blocks}
      </Pane>
    );

    const oldDataPane = (
      <Pane name="map_oldData" style={stylePolygonsOLD}>
        {flagShowOldShape ? geojson_ex_pol : null}
      </Pane>
    );

    const order = values;
    const defaultOrder = ["oldData", "data", "subData"];

    // Agregar el orden por defecto
    if (order.length === 0) order.push(...defaultOrder);
    // Agregar los dos que faltan en el orden predeterminado
    else if (order.length === 1) order.push(...defaultOrder.filter((item) => item !== order[0]));
    // Agregar el que falta al final
    else if (order.length === 2) order.push(defaultOrder.find((item) => !order.includes(item)));

    return (
      <>
        {order[2] === "data" ? dataPane : order[2] === "subData" ? subDataPane : oldDataPane}
        {order[1] === "data" ? dataPane : order[1] === "subData" ? subDataPane : oldDataPane}
        {order[0] === "data" ? dataPane : order[0] === "subData" ? subDataPane : oldDataPane}
      </>
    );
  };

  return (
    <>
      {data !== null && !isHidden ? (
        <>
          {drawerTop !== null && <CardDrawerTop properties={drawerTop} open={drawerOpen} />}
          <MapContainer
            ref={mapRef}
            dragging={isNavigate}
            scrollWheelZoom={isNavigate}
            doubleClickZoom={isNavigate}
            center={position}
            zoom={zoom}
            zoomControl={false}
            style={{ width: "100%", height: height ?? screenHeight - 65 }}
            whenReady={whenCreatedExecute}
            fullscreenControl={fullscreenFlag}
            fullscreenControlOptions={
              fullscreenFlag
                ? {
                    position: "topright", // cambiar de posicion en el mapa
                    title: "¡Mirar en pantalla completa!", // texto al acercar el mouse
                    titleCancel: "¡Salir de pantalla completa!", // texto al acercar el mouse en pantalla completa
                    content: null, // contenido del button, puede ser html, por defecto es null
                    forceSeparateButton: true, // separar del boton de zoom
                    forcePseudoFullscreen: false, // pantalla completa del ordenador o del navegador (si está en false toma pantalla del ordenador)
                    fullscreenElement: false, // Elemento Dom para renderizar en pantalla completa,  (si está en false recurre el map container)
                  }
                : {}
            }
          >
            <TileLayer {...viewMap} />
            {zoomControlsFlag && <ZoomControl position="topright" />}

            {navigateFlag && (
              <ControlNavigation isNavigate={isNavigate} handleNavigation={handleNavigation} width={width} />
            )}

            {drawerLeft !== null && (
              <DrawerLeft properties={drawerLeft} setWidth={setWidth} navigationAction={navigationAction} />
            )}
            {drawerTop !== null && <ControlDrawerTop handleToggle={handleDrawerToggle} open={drawerOpen} />}

            {getOrder(order)}

            {cardInfo && (
              <CardInfo
                values={
                  cardInfo?.values
                    ? cardInfo.values === "data"
                      ? feature?.properties
                      : subFeature?.properties
                    : feature?.properties ?? subFeature?.properties ?? null
                }
                initialValues={cardInfoInitialValues}
                button={cardInfo?.button}
                component={cardInfo?.component}
                lineFlag={cardInfo?.lineFlag ?? true}
              />
            )}

            {infoGeneral && (
              <CardGeneralInfo
                handleShowOldShape={
                  infoGeneral?.oldShapeFlag ? () => setFlagShowOldShape(!flagShowOldShape) : null
                }
                values={infoGeneral?.values}
                initialValues={infoGeneral?.initialValues}
                compromismos={infoGeneral?.compromisos ?? 1}
                button={infoGeneral?.button}
                component={infoGeneral?.component}
                lineFlag={infoGeneral?.lineFlag ?? true}
                width={width}
              />
            )}

            {cardComponent && (
              <CardComponent
                component={cardComponent?.component || <></>}
                title={cardComponent?.title || ""}
              />
            )}

            {filterComponent && <ControlFilter component={filterComponent} title={"Filtro"} />}

            {centerFlag && <ControlCenterView handleClickEvent={handleCenter} width={width} />}

            {changeMapFlag && <MapViewType handleClickEvent={handleViewMapType} width={width} />}
          </MapContainer>
        </>
      ) : (
        <div style={{ ...style_spinner, height: height ?? screenHeight - 65 }}>
          <MagicSpinner size={70} color={"#008000"} loading={true}></MagicSpinner>
        </div>
      )}
    </>
  );
};

Map.propTypes = {
  data: PropTypes.object,
  subData: PropTypes.object,
  polygonsOLD: PropTypes.object,
  isHidden: PropTypes.bool,
  zoom: PropTypes.number,
  position: PropTypes.array,
  drawerTop: PropTypes.shape({
    open: PropTypes.bool,
    title: PropTypes.string,
    component: PropTypes.node,
    height: PropTypes.number,
  }),
  drawerLeft: PropTypes.shape({
    open: PropTypes.bool,
    title: PropTypes.string,
    component: PropTypes.node,
    width: PropTypes.object,
  }),
  cardInfo: PropTypes.shape({
    values: PropTypes.oneOf(["data", "subData"]),
    initialValues: PropTypes.shape({
      title: PropTypes.string.isRequired,
      elements: PropTypes.array.isRequired,
    }),
    button: PropTypes.shape({
      title: PropTypes.string,
      handleClick: PropTypes.func,
      validShp: PropTypes.array,
      invalidShp: PropTypes.array,
      component: PropTypes.func, // Al mandar un componente, se deshabilita el por defecto
    }),
    component: PropTypes.shape({
      component: PropTypes.func.isRequired,
      validShp: PropTypes.array,
      invalidShp: PropTypes.array,
    }),
    lineFlag: PropTypes.bool,
  }),
  cardComponent: PropTypes.shape({
    title: PropTypes.string,
    component: PropTypes.node,
  }),
  filterComponent: PropTypes.node,
  infoGeneral: PropTypes.shape({
    values: PropTypes.object,
    initialValues: PropTypes.shape({
      title: PropTypes.string.isRequired,
      elements: PropTypes.array.isRequired,
    }),
    oldShapeFlag: PropTypes.bool,
    compromisos: PropTypes.number,
    button: PropTypes.shape({
      title: PropTypes.string,
      handleClick: PropTypes.func,
      validShp: PropTypes.array,
      invalidShp: PropTypes.array,
      component: PropTypes.func, // Al mandar un componente, se deshabilita el por defecto
    }),
    component: PropTypes.shape({
      component: PropTypes.func.isRequired,
      validShp: PropTypes.array,
      invalidShp: PropTypes.array,
    }),
    lineFlag: PropTypes.bool,
  }),
  centerFlag: PropTypes.bool,
  fullscreenFlag: PropTypes.bool,
  zoomControlsFlag: PropTypes.bool,
  changeMapFlag: PropTypes.bool,
  navigateFlag: PropTypes.bool,
  navigate: PropTypes.bool,
  centerByFeatures: PropTypes.bool,
  order: PropTypes.arrayOf(PropTypes.oneOf(["data", "subData", "oldData"])),
  highlightFlag: PropTypes.bool,
  handleClickFeature: PropTypes.func,
  highlightFeature: PropTypes.func,
  onEachFeature: PropTypes.func,
};

export default Map;

import ApiExec from "@utils/ApiExec";
import { encryptVars, DEBUG } from "@utils/global";

class AuditoriaOrigenCaptura {
  getInfo = (Value) => {
    const value = DEBUG ? Value : encryptVars(Value);
    return ApiExec({}, `compromisos/get-info/${value}`, "GET")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getCaptureSource = (Value) => {
    const value = DEBUG ? Value : encryptVars(Value);
    return ApiExec({}, `compromisos/get-capture-source/${value}`, "GET")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getUserInfo = (Value) => {
    const value = DEBUG ? Value : encryptVars(Value);
    return ApiExec({}, `users/get-user-info/${value}`, "GET")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getUserParents = (Value) => {
    const value = DEBUG ? Value : encryptVars(Value);
    return ApiExec({}, `compromisos/get-user-parents/${value}`, "GET")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getUserParentsSecond = (Value) => {
    const value = DEBUG ? Value : encryptVars(Value);
    return ApiExec({}, `users/get-parents/${value}`, "GET")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
}

const auditoria = new AuditoriaOrigenCaptura();
export default auditoria;

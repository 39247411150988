import { useEffect, useState } from "react";
import { useCatalogs } from "@hooks/useCatalogs";
import {
  Box,
  Button,
  CardContent,
  Icon,
  TextField,
  FormHelperText,
  FormControl,
} from "@mui/material";

import BasicSelect from "@components/Selects/BasicSelect";
//import SelectSearch from "@components/Selects/SelectSearch";
//import AutoComplete from "@components/Selects/Autocomplete";
import AdvancedSelect from "@components/Selects/AdvancedSelect";

const Filter = ({
  onChange,
  onClear,
  loadProfiles = false,
  catProfile = [],
  selectTab,
}) => {
  //CONSTANTS
  const catalogsOptions = [
    { id: "regiones", getAll: false },
    { id: "municipios_reportes", getAll: false },
    { id: "poligonos", getAll: false },
    { id: "secciones", getAll: false },
  ];
  const min_len = 4;
  const eval_len = (str) =>
    str &&
    str.replace(/\s/g, "").length > 0 &&
    str.replace(/\s/g, "").length < min_len;
  //States
  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsOptions,
    putDefaultOption: false,
  });
  const [catalogsFiltered, setCatalogFiltered] = useState({});
  const [filterData, setFilterData] = useState({
    perfil: "",
    region: "",
    municipio: "",
    poligono: "",
    seccion: "",
    nombreCelular: "",
  });
  const [showWarning, setShowWarning] = useState(false);

  const handleChangeFilter = ({ name, value }) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const handleCatalogFilterChange = ({
    filterKey,
    filterValue,
    filterType,
  }) => {
    const _filteredData = catalogs[filterKey].filter(
      (item) => item[filterType] === filterValue
    );

    setCatalogFiltered((prevState) => ({
      ...prevState,
      [filterKey]: _filteredData,
    }));
  };

  const handleChangeProfile = (e) => {
    const profileId = e.target.value;

    if (![13, 14].includes(profileId)) {
      setShowWarning(false);
    }
  };

  const handleChangeRegion = (e) => {
    const _idRegion = e.target.value;

    handleCatalogFilterChange({
      filterKey: "municipios_reportes",
      filterType: "idRegion",
      filterValue: _idRegion,
    });
    handleCatalogFilterChange({
      filterKey: "poligonos",
      filterType: "idRegion",
      filterValue: _idRegion,
    });
    handleCatalogFilterChange({
      filterKey: "secciones",
      filterType: "idRegion",
      filterValue: _idRegion,
    });

    setFilterData((prevState) => ({
      ...prevState,
      municipio: "",
      poligono: "",
      seccion: "",
      nombreCelular: "",
    }));
  };

  const handleChangeMunicipality = (e) => {
    const _idMunicipality = e.target.value;
    if (_idMunicipality !== "") {
      setShowWarning(false);
    }

    handleCatalogFilterChange({
      filterKey: "poligonos",
      filterType: "idMunicipioReportes",
      filterValue: _idMunicipality,
    });
    handleCatalogFilterChange({
      filterKey: "secciones",
      filterType: "idMunicipioReportes",
      filterValue: _idMunicipality,
    });

    setFilterData((prevState) => ({
      ...prevState,
      poligono: "",
      seccion: "",
      nombreCelular: "",
    }));
  };

  const handleChangePolygon = (e) => {
    const _idPolygon = e.value;

    handleCatalogFilterChange({
      filterKey: "secciones",
      filterType: "idPoligono",
      filterValue: _idPolygon,
    });

    setFilterData((prevState) => ({
      ...prevState,
      seccion: "",
      nombreCelular: "",
    }));
  };

  const handleSearch = () => {
    const filters = [
      {
        id: "idPerfil",
        key: "perfil",
      },
      {
        id: "Seccion",
        key: "seccion",
      },
      {
        id: "idMunicipioReportes",
        key: "municipio",
      },
      {
        id: "idPoligono",
        key: "poligono",
      },
      {
        id: "idRegion",
        key: "region",
      },
      {
        id: ["Username", "Nombre", "Paterno", "Materno"],
        key: "nombreCelular",
      },
    ];

    let filtered = filters
      .filter((filter) => {
        const value = filterData[filter.key];
        return (
          value !== undefined &&
          value !== null &&
          (typeof value === "string" ? value.length > 0 : value > 0)
        );
      })
      .map((filter) => ({
        id: filter.id,
        filter: filter.key === "nombreCelular" ? "LIKE" : "=",
        value: filterData[filter.key],
      }));

    if (selectTab === 1) {
      filtered.push({
        id: "esPaseLista",
        filter: "IS",
        value: null,
      });
    } else if (selectTab === 2) {
      filtered.push({
        id: "esPaseLista",
        filter: "=",
        value: 1,
      });
    }

    if (!eval_len(filterData.nombreCelular) || filterData.seccion !== "") {
      onChange({ filtereds: filtered });
    } else {
      if (filterData.perfil !== "") {
        if (![13, 14].includes(filterData.perfil)) {
          onChange({ filtereds: filtered });
        } else {
          if (filterData.municipio === "") {
            setShowWarning(true);
          } else {
            onChange({ filtereds: filtered });
          }
        }
      }
    }
  };

  const handleShowWarning = (value) => {
    if (value !== "") {
      setShowWarning(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleClear = () => {
    setCatalogFiltered(catalogs);
    setFilterData((prevState) => ({
      ...prevState,
      perfil: "",
      region: catalogs.regiones.length === 1 ? catalogs.regiones[0].value : "",
      municipio:
        catalogs.municipios_reportes.length === 1
          ? catalogs.municipios_reportes[0].value
          : "",
      poligono:
        catalogs.poligonos.length === 1 ? catalogs.poligonos[0].value : "",
      seccion: "",
      nombreCelular: "",
    }));
    onClear();
  };

  useEffect(() => {
    if (load === false) {
      setCatalogFiltered(catalogs);
      setFilterData((prevState) => ({
        ...prevState,
        region:
          catalogs.regiones.length === 1 ? catalogs.regiones[0].value : "",
        municipio:
          catalogs.municipios_reportes.length === 1
            ? catalogs.municipios_reportes[0].value
            : "",
        poligono:
          catalogs.poligonos.length === 1 ? catalogs.poligonos[0].value : "",
      }));
    }
    // eslint-disable-next-line
  }, [load]);

  return (
    <CardContent>
      <Box component={"div"} marginBottom={2}>
        <FormControl sx={{ width: "100%", mb: 2 }}>
          <BasicSelect
            name="perfil"
            label="Tipo Perfil"
            options={catProfile}
            value={filterData.perfil}
            onChange={(e) => {
              handleChangeFilter({
                name: e.target.name,
                value: e.target.value,
              });
              handleChangeProfile(e);
            }}
            isLoading={loadProfiles}
            sx={{ width: "100%" }}
          />
          {filterData.perfil.length < 1 &&
            filterData.nombreCelular === "" &&
            filterData.seccion === "" && (
              <FormHelperText error>{"Seleccione un perfil"}</FormHelperText>
            )}
        </FormControl>

        <FormControl sx={{ width: "100%", mb: 3 }}>
          <BasicSelect
            name="region"
            label="Región"
            options={catalogsFiltered.regiones}
            value={
              catalogsFiltered.regiones?.length === 1
                ? catalogsFiltered.regiones[0].value
                : filterData.region
            }
            disabled={catalogsFiltered.regiones?.length === 1}
            onChange={(e) => {
              handleChangeFilter({
                name: e.target.name,
                value: e.target.value,
              });
              handleChangeRegion(e);
            }}
            isLoading={load}
            sx={{ width: "100%" }}
          />
          {filterData.region.length < 1 &&
            filterData.nombreCelular === "" &&
            filterData.seccion === "" && (
              <FormHelperText error>{"Seleccione una región"}</FormHelperText>
            )}
        </FormControl>

        <FormControl sx={{ width: "100%", mb: 3 }}>
          <BasicSelect
            name="municipio"
            label="Municipio"
            options={catalogsFiltered.municipios_reportes}
            value={
              catalogsFiltered.municipios_reportes?.length === 1
                ? catalogsFiltered.municipios_reportes[0].value
                : filterData.municipio
            }
            disabled={catalogsFiltered.municipios_reportes?.length === 1}
            onChange={(e) => {
              handleChangeFilter({
                name: e.target.name,
                value: e.target.value,
              });
              handleChangeMunicipality(e);
            }}
            isLoading={load}
            sx={{ width: "100%" }}
          />
          {/* showWarning  */}
          {filterData.municipio.length < 1 &&
            filterData.nombreCelular === "" &&
            filterData.seccion === "" && (
              <FormHelperText error>{"Seleccione un municipio"}</FormHelperText>
            )}
        </FormControl>

        <AdvancedSelect
          name="poligono"
          label="Polígono"
          options={catalogsFiltered.poligonos}
          value={
            catalogsFiltered.poligonos?.length === 1
              ? catalogsFiltered.poligonos[0].value
              : filterData.poligono
          }
          onChange={(e) => {
            handleChangeFilter({ name: e.name, value: e.value });
            handleChangePolygon(e);
          }}
          isSearchable
          isLoading={load}
          disabled={catalogsFiltered.poligonos?.length <= 1}
          sx={{ width: "100%", mb: 3 }}
        />

        <AdvancedSelect
          name="seccion"
          label="Sección"
          value={
            catalogsFiltered.secciones?.length === 1
              ? catalogsFiltered.secciones[0].value
              : filterData.seccion
          }
          isSearchable
          isLoading={load}
          disabled={catalogsFiltered.secciones?.length <= 1}
          options={catalogsFiltered.secciones}
          onChange={(e) => {
            handleChangeFilter({ name: e.name, value: e.value });
            handleShowWarning(e.value);
          }}
          onKeyDown={handleKeyPress}
          sx={{ width: "100%", mb: 3 }}
        />

        <TextField
          name="nombreCelular"
          label="Nombre o Celular"
          size="small"
          value={
            filterData.nombreCelular
              ? filterData.nombreCelular.toUpperCase()
              : ""
          }
          onChange={(e) => {
            handleChangeFilter({
              name: e.target.name,
              value: e.target.value,
            });
            handleShowWarning(e.target.value);
          }}
          onKeyDown={handleKeyPress}
          sx={{ width: "100%", mb: 1 }}
        />
        {eval_len(filterData.nombreCelular) && (
          <FormHelperText error>
            {"Ingrese al menos 4 carácteres para la búsqueda"}
          </FormHelperText>
        )}
      </Box>
      <Box marginBottom={2}>
        <Button
          variant="contained"
          color="primaryDark"
          size="small"
          startIcon={<Icon>search</Icon>}
          onClick={handleSearch}
          disabled={
            (filterData.perfil.length < 1 ||
              filterData.region.length < 1 ||
              filterData.municipio.length < 1) &&
            filterData.nombreCelular === ""
          } //&&  filterData.nombreCelular === '' && filterData.seccion === ''
          sx={{ width: "100%", mb: 1 }}
        >
          buscar
        </Button>
        <Button
          variant="outlined"
          color="primaryDark"
          size="small"
          startIcon={<Icon>search_off</Icon>}
          onClick={handleClear}
          sx={{ width: "100%" }}
        >
          Limpiar
        </Button>
      </Box>
    </CardContent>
  );
};

export default Filter;

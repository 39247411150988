export const PublicacionesInterface = {
  idTipo: 1,
  Nombre: "",
  Descripcion: "",
  Sinopsis: "",
  files: [],
  fileName: "",
  FechaPublicacion: "",
};

export const EditPublicacionesInterface = {
  id: 0,
  idTipo: 1,
  Nombre: "",
  Descripcion: "",
  Sinopsis: "",
  files: [],
  fileName: "",
  fileURL: "",
  FechaPublicacion: "",
};

import { useState, useEffect } from "react";
//MUI
import { Card, Box, Button, Grid, Icon } from "@mui/material";
import Swal from "sweetalert2";

import { Search, Clear } from "@mui/icons-material";

//COMPONENTS
import AdvancedSelect from "@components/Selects/AdvancedSelect";
import { useCatalogs } from "@hooks/useCatalogs";
import MovServices from "@services/MovilizationServices";

const CompromisosMovilizacion = (props) => {
  const { filtered, reset } = props;
  const [catalogsFiltered, setCatalogFiltered] = useState({});
  const [loading, setLoading] = useState(false);
  const [secciones, setSecciones] = useState([]);
  const [seccionesFiltered, setSeccionesFiltered] = useState([]);
  const [params, setParams] = useState({
    DL: "",
    Municipio: "",
    Seccion: "",
  });
  const catalogSeccion = [{ id: "usuarios" }, { id: "secciones" }];
  const catalogsOptions = [{ id: "dl" }, { id: "municipios_dl" }];

  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsOptions,
    putDefaultOption: false,
  });

  const handleChangeDLs = (e) => {
    const _idDL = e.value;
    setSeccionesFiltered((prev) => ({
      ...prev,
      secciones: secciones.secciones.filter(({ idDL }) => idDL === _idDL),
    }));
    setParams({
      ...params,
      DL: e.value,
      Municipio: "",
      Seccion: "",
    });
  };

  const handleChangeMunicipio = (e) => {
    const _Municipio = e.value;
    setSeccionesFiltered((prev) => ({
      ...prev,
      secciones: secciones.secciones.filter(({ idMunicipio }) => idMunicipio === _Municipio),
    }));
    setParams({
      ...params,
      Municipio: e.value,
      Seccion: "",
    });
  };

  const handleChangeSeccion = (e) => {
    setParams({
      ...params,
      Seccion: e.value,
    });
  };

  const handleReset = () => {
    setParams({
      DF: "",
      Municipio: "",
      Seccion: "",
    });
    setCatalogFiltered(catalogs);
    setSeccionesFiltered(secciones);
    reset();
  };

  const handleFilter = () => {
    const { DL, Municipio, Seccion } = params;
    let values;
    if (DL) {
      values = {
        ...values,
        id: "idDL",
        filter: "=",
        value: DL,
      };
    }
    if (Municipio) {
      values = {
        ...values,
        id: "idMunicipioReportes",
        filter: "=",
        value: Municipio,
      };
    }
    if (Seccion) {
      values = {
        ...values,
        id: "Seccion",
        filter: "=",
        value: Seccion,
      };
    }
    console.log(values);
    filtered(values ? [values] : []);
  };

  const getSecciones = async () => {
    try {
      setLoading(true);
      const result = await MovServices.getCatalogsForStats(catalogSeccion);
      const { results, message, response } = result;
      if (results) {
        setSecciones(response.catalogs);
        setSeccionesFiltered(response.catalogs);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (load === false) {
      setCatalogFiltered(catalogs);
      getSecciones();
    }
    // eslint-disable-next-line
  }, [load]);

  return (
    <Box component={Card} variant="outlined" borderRadius={2} padding={2} elevation={0}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <AdvancedSelect
            name="DL"
            label="DL"
            value={params.DL}
            options={catalogsFiltered?.dl}
            onChange={handleChangeDLs}
            isSearchable
            isLoading={load}
            sx={{ width: "100%", mb: 3, mt: 2 }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <AdvancedSelect
            name="Municipio"
            label="Municipio"
            value={params.Municipio}
            options={catalogsFiltered?.municipios_dl}
            onChange={handleChangeMunicipio}
            isSearchable
            isLoading={load}
            sx={{ width: "100%", mb: 3, mt: 2 }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <AdvancedSelect
            name="Seccion"
            label="Sección"
            value={params.Seccion}
            options={seccionesFiltered?.secciones}
            onChange={handleChangeSeccion}
            isSearchable
            isLoading={loading}
            sx={{ width: "100%", mb: 3, mt: 2 }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Box height={"100%"} sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: "center", gap: "8px" }}>
            <Button
              variant="outlined"
              color="primaryDark"
              size="small"
              startIcon={<Icon as={Clear} />}
              sx={{ width: "200px" }}
              onClick={handleReset}
            >
              Limpiar
            </Button>
            <Button
              variant="contained"
              color="primaryDark"
              size="small"
              startIcon={<Icon as={Search} />} 
              sx={{ width: "200px" }}
              onClick={handleFilter}
            >
              Buscar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompromisosMovilizacion;

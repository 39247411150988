import { Fragment, useEffect, useState } from "react";
import { useFormik } from "formik";

import {
  Box,
  FormControlLabel,
  Radio,
  Stack,
  TextField,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormHelperText,
  List,
  ListItem,
  Avatar,
  ListItemText,
  Typography,
  Paper,
  CardHeader,
  Tooltip
} from "@mui/material";
import { Cancel, Edit, Save } from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Swal from "sweetalert2";

import UploadProfilePicture from "@components/Files/UploadProfilePicture";
import ColorPicker from "@components/Prep/ColorPicker";
import CoalitionPartiesForm from "@components/Prep/CoalitionPartiesForm";
import LoadingForm from "@components/LinearProgress/LoadingForm";

import { AddPartyInterface } from "@data/interfaces/PrepInterface";
import { AddPartySchema } from "@data/schemas/PrepSchema";
import prep from "@services/PrepServices";

import { isEmptyOrInvalidString } from "@utils/validations";
import { convertBase64 } from "@utils/Utilities";
// import middleware from "@middlewares/middleware";
import { URL_IMAGES } from "@utils/global"

const PartyForm = props => {
  const { data4Edit } = props;
  const initColor = '#b71c1c';
  const [color, setColor] = useState(initColor);
  const [photo, setPhoto] = useState(null);
  const [coalitionParties, setCoalitionParties] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [isSuccessFormSave] = useState(false);

  const formik = useFormik({
    initialValues: AddPartyInterface,
    validationSchema: AddPartySchema,
    onSubmit: async (values, actions) => await handleSave(values, actions),
  });

  useEffect(() => {
    handleClearForm();
    // eslint-disable-next-line
  }, [data4Edit]);

  useEffect(() => {
    formik.setFieldValue('Color', color, false);
    // eslint-disable-next-line
  }, [color]);

  const handleChangeRadio = (e) => {
    const name = e.target.name;
    const value = parseInt(e.target.value);

    if (name === 'EsCoalicion' && !value) {
      formik.setValues({
        ...formik.values,
        [name]: value,
        EsIndependiente: 0
      });
    } else {
      formik.setValues({
        ...formik.values,
        [name]: value
      });
    }
  };

  const handleSelected = (data) => {
    if (data.length > 0) {
      setCoalitionParties(data);
      formik.setFieldValue('EsCoalicion', 1, false);
    } else {
      setCoalitionParties([]);
    }
  }

  const handleSave = async (values, actions) => {
    delete values.Coalicion;
    delete values.RutaLogo;
    delete values.TotalPartidos;

    if (values.EsCoalicion && coalitionParties.length < 3) {
      return Swal.fire({
        title: 'Una coalición debe contener al menos 3 combinaciones.',
        icon: 'warning',
        customClass: {
          container: "modal-alert",
        },
      });
    }

    let files = [];
    if (photo instanceof File) {
      files = [{
        file: await convertBase64(photo)
      }];
    }

    const params = {
      ...values,
      Partido: values.Partido.toUpperCase(),
      Siglas: values.Siglas.toUpperCase(),
      PartidosCoalicion: values.EsCoalicion ? coalitionParties.map(item => item.value) : [],
      files: files
    }

    if (values.id) {
      updateParty(params, actions);
    } else {
      saveParty(params, actions);
    }
  }

  const saveParty = (params, actions) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);

    prep.saveParty(params).then(res => {
      if (res.results) {
        Swal.fire({
          title: res.message,
          icon: "success",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(r => {
          actions.resetForm();
          setPhoto(null);
        });
      } else {
        Swal.fire({
          title: res.message,
          icon: "warning",
        });
      }
    }).catch(error => {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    }).finally(() => {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    })
  }

  const updateParty = (params, action) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);

    prep.updateParty(params).then(res => {
      if (res.results) {
        Swal.fire({
          title: res.message,
          icon: "success",
        });
      } else {
        Swal.fire({
          title: res.message,
          icon: "warning",
        });
      }
    }).catch(error => {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    }).finally(() => {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    });
  }

  const handleClearForm = () => {

    if (data4Edit) {
      formik.setValues(data4Edit);

      if (data4Edit.Color) {
        setColor(data4Edit.Color);
      }
      if (data4Edit.EsCoalicion) {
        setCoalitionParties(data4Edit.Coalicion.map(item => ({ ...item, value: item.idPartido })));
      }
      if (data4Edit.RutaLogo) {
        setPhoto(`${URL_IMAGES}/${data4Edit.RutaLogo}`);
      }
    } else {
      setColor(initColor);
      formik.setValues({
        Partido: '',
        Siglas: '',
        Color: initColor,
        EsIndependiente: 0,
        EsCoalicion: 0,
      });
    }

  }

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <LoadingForm
        loadinMessage={'Enviando'}
        successMessage="¡Consultado con éxito!"
        isLoading={isLoadigForm}
        success={isSuccessFormSave}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
      />

      <Grid2 container spacing={2}>
        <Grid2 item xs={12} sm={12} md={6} >
          <Stack
            spacing={2}
            direction={'column'}
            justifyContent={'center'}
            sx={{ overflow: 'scroll', p: 2 }}
          >

            <UploadProfilePicture
              file={photo}
              setFile={setPhoto}
              accept="image/*"
            />

            <TextField
              label="Nombre del Partido"
              id="Partido"
              name="Partido"
              // size="small"
              value={formik.values.Partido && formik.values.Partido.length > 0 && formik.values.Partido.toUpperCase()}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.Partido && (!isEmptyOrInvalidString(formik.errors.Partido) || formik.values.Partido === '')
              }
              helperText={
                formik.touched.Partido ||
                formik.errors.Partido ||
                isEmptyOrInvalidString(formik.values.Partido)
              }
              sx={{ width: "100%" }}
            />

            <TextField
              label="Siglas del Partido"
              id="Siglas"
              name="Siglas"
              // size="small"
              value={formik.values.Siglas && formik.values.Siglas.length > 0 && formik.values.Siglas.toUpperCase()}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.Siglas && (!isEmptyOrInvalidString(formik.errors.Siglas) || formik.values.Siglas === '')
              }
              helperText={
                formik.touched.Siglas ||
                formik.errors.Siglas ||
                isEmptyOrInvalidString(formik.values.Siglas)
              }
              sx={{ width: "100%" }}
            />

            <FormControl>
              <FormLabel id="EsCoalicion">¿Es una coalición?</FormLabel>
              <RadioGroup
                row
                aria-labelledby="EsCoalicion"
                name="EsCoalicion"
                value={formik.values.EsCoalicion}
                onChange={handleChangeRadio}
              >
                <FormControlLabel value={1} checked={formik.values.EsCoalicion === 1 ? true : false} control={<Radio />} label="SI" />
                <FormControlLabel value={0} checked={formik.values.EsCoalicion === 0 ? true : false} control={<Radio />} label="NO" />
              </RadioGroup>
              {formik.errors.EsCoalicion && <FormHelperText error>{formik.errors.EsCoalicion}</FormHelperText>}
            </FormControl>

            {
              !formik.values.EsCoalicion ? (
                <FormControl>
                  <FormLabel id="EsIndependiente">¿Es un partido independiente?</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="EsIndependiente"
                    name="EsIndependiente"
                    value={formik.values.EsIndependiente}
                    onChange={handleChangeRadio}
                  >
                    <FormControlLabel value={1} checked={formik.values.EsIndependiente === 1 ? true : false} control={<Radio />} label="SI" />
                    <FormControlLabel value={0} checked={formik.values.EsIndependiente === 0 ? true : false} control={<Radio />} label="NO" />
                  </RadioGroup>
                  {formik.errors.EsIndependiente && <FormHelperText error>{formik.errors.EsIndependiente}</FormHelperText>}
                </FormControl>
              ) :
                (
                  <Fragment>
                    <CardHeader
                      title={<Typography variant="div" fontWeight={700} fontSize={15}>Partidos de la Coalición</Typography>}
                      action={
                        <Tooltip title="Click para editar los partidos de la coalición">
                          <Button
                            color="primaryDark"
                            size="medium"
                            variant="contained"
                            onClick={(e) => { setOpenModal(true) }}
                            startIcon={<Edit />}
                          >
                            Coalición
                          </Button>
                        </Tooltip>
                      }
                    />

                    <Paper sx={{ overflow: 'scroll', height: 300 }}>
                      <List dense={true}>
                        {
                          coalitionParties && coalitionParties.length > 0 && coalitionParties.map(item => (
                            <ListItem key={item.idPartido}>
                              <Avatar src={`${URL_IMAGES}/${item.RutaLogo}`} />

                              <ListItemText
                                sx={{ ml: 2 }}
                                primary={
                                  <Typography variant="div" fontWeight={700} fontSize={15}>
                                    {item.Partido}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          ))
                        }
                      </List>
                    </Paper>
                  </Fragment>
                )
            }
          </Stack>
        </Grid2>
        <Grid2 item xs={12} sm={12} md={6} >
          <ColorPicker initColor={color} getColor={setColor} title={'Establezca un color para el partido'} />
        </Grid2>
      </Grid2>

      <Grid2 container>
        <Box
          sx={{
            mt: 2,
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="button"
            color="error"
            variant="contained"
            size="small"
            onClick={e => handleClearForm()}
            startIcon={<Cancel />}
          >
            Cancelar
          </Button>
          <Button
            type="button"
            color="primaryDark"
            variant="contained"
            size="small"
            onClick={formik.submitForm}
            startIcon={<Save />}
          >
            Guardar
          </Button>
        </Box>
      </Grid2>

      <CoalitionPartiesForm
        openModal={openModal}
        setOpenModal={setOpenModal}
        selected={coalitionParties}
        handleSelected={handleSelected}
      />
    </Box>
  )
}

export default PartyForm;
import { useState } from "react";
//Mui
import {
  Box,
  TextField,
  Button,
  Stack,
  Card,
  CardContent,
  Typography,
  Icon,
  Alert,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

//Components
import BasicList from "@components/Lists/BasicList";
import ItemUser from "./ItemUser";

//Services
//import UserServices from "@services/UserServices";
import sije from "@services/SijeService";
import { isTypePhone } from "@utils/validations";
import Swal from "sweetalert2";

const UserSearchForm = ({
  title,
  page,
  total,
  filterData,
  setFilterData,
  setSelected = () => {},
  options = [],
  setOptions,
  setUser,
  setPage,
  setTotal,
  handleClickItem,
}) => {
  const config = {
    title: "Usuarios",
    icon: "people",
    height: 400,
    endMessage: "No hay más registros para mostrar.",
  };
  const pageSize = 20;

  /* const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0); */
  const [hasMore, setHasMore] = useState(false);
  const [errors, setErrors] = useState({
    Celular: "",
    Nombre: "",
    Paterno: "",
    Materno: "",
  });

  const handleChangeFilter = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "Celular") {
      setFilterData((prevState) => ({
        ...prevState,
        [name]: isTypePhone(value) ? value : filterData[name],
      }));
    } else {
      setFilterData((prevState) => ({
        ...prevState,
        [name]: value.length > 0 ? value.toUpperCase() : "",
      }));
    }
  };

  const handleFilter = () => {
    const filters = [
      {
        id: "usuarios.Nombre",
        key: "Nombre",
      },
      {
        id: "usuarios.Paterno",
        key: "Paterno",
      },
      {
        id: "usuarios.Materno",
        key: "Materno",
      },
      {
        id: "usuarios.Celular",
        key: "Celular",
      },
    ];

    const filtered = filters
      .filter((filter) => filterData[filter.key].length > 0)
      .map((filter) => ({
        id: filter.id,
        filter: "LIKE",
        value: filterData[filter.key].trim(),
      }));

    return filtered;
  };

  const customValidate = () => {
    let valid = false;

    if (filterData.Celular.length > 0) {
      if (filterData.Celular.length < 10 || filterData.Celular.length > 10) {
        setErrors((prevState) => ({
          ...prevState,
          Celular: "Ingrese un número de celular a 10 dígitos",
        }));
        valid = false;
      } else {
        setErrors({
          Celular: "",
          Nombre: "",
          Paterno: "",
          Materno: "",
        });
        valid = true;
      }
    } else {
      if (filterData.Nombre.length < 1) {
        if (filterData.Paterno.length < 1 && filterData.Materno.length < 1) {
          setErrors((prevState) => ({
            ...prevState,
            Nombre: "El Nombre del usuario es requerido",
            Paterno: "Ingrese al menos un apellido",
            Materno: "Ingrese al menos un apellido",
          }));
          valid = false;
        } else {
          setErrors((prevState) => ({
            ...prevState,
            Nombre: "El Nombre del Responsable del 1xN es requerido",
            Paterno: "",
            Materno: "",
          }));
          valid = false;
        }
      } else {
        if (filterData.Paterno.length < 1 && filterData.Materno.length < 1) {
          setErrors((prevState) => ({
            ...prevState,
            Paterno: "Ingrese al menos un apellido",
            Materno: "Ingrese al menos un apellido",
            Nombre: "",
          }));
          valid = false;
        } else {
          setErrors({
            Celular: "",
            Nombre: "",
            Paterno: "",
            Materno: "",
          });
          valid = true;
        }
      }
    }

    return valid;
  };

  const handleSearch = async () => {
    const filtered = handleFilter();
    const valid = customValidate();
    if (!valid) return;

    try {
      setHasMore(true);
      const result = await sije.getResponsibles({
        page: 0,
        pageSize,
        filtered,
      });
      const { results, message, response } = await result;
      if (results) {
        setOptions(response.data);
        setTotal(response.total);

        if (pageSize >= parseInt(response.total)) {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
        throw new Error(message);
      }
    } catch (error) {
      setHasMore(false);
      Swal.fire({
        title: error.message,
        icon: "warning",
        customClass: {
          container: "modal-alert",
        },
      });
    }finally {
      setHasMore(false);
    }
  };

  const fetchHasMore = async () => {
    const filtered = handleFilter();
    try {
      const result = await sije.getResponsibles({
        page: page + 1,
        pageSize,
        filtered,
      });
      const { response, message, results } = await result;
      if (results) {
        setOptions(options.concat(response.data));
        let end = (page + 1) * pageSize;
        if (end >= response.total) {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
        throw new Error(message);
      }
    } catch (error) {
      setHasMore(false);
      Swal.fire({
        title: error,
        icon: "warning",
        customClass: {
          container: "modal-alert",
        },
      });
    }finally{
      setHasMore(false);
    }

    setPage(page + 1);
  };

  const handleClearSearch = () => {
    setFilterData({
      Nombre: "",
      Paterno: "",
      Materno: "",
      Celular: "",
    });

    setOptions([]);
    setUser({});
    setPage(0);
    setTotal(0);
    setSelected(0);
  };

  const handleOnKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };

  return (
    <Card className="card-primary">
      <CardContent>
        <Box marginBottom={2}>
          <Typography variant="subtitle2" marginBottom={1}>
            {title}
          </Typography>
          <Alert variant="outlined" severity="info">
            Nota: Para realizar una búsqueda debe ingresar un nombre y al menos
            un apellido o por el contrario un número de celular.
          </Alert>
        </Box>

        <Box>
          <Grid2 container spacing={2}>
            <Grid2 xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Nombre"
                value={filterData.Nombre}
                name="Nombre"
                onChange={(e) => handleChangeFilter(e)}
                error={errors.Nombre}
                helperText={errors.Nombre.length > 1 && errors.Nombre}
                sx={{ width: "100%" }}
                type="text"
                variant="outlined"
                size="small"
                className="fixed-input"
                onKeyUp={handleOnKeyPress}
              />
            </Grid2>
            <Grid2 xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Paterno"
                value={filterData.Paterno}
                name="Paterno"
                onChange={(e) => handleChangeFilter(e)}
                error={errors.Paterno}
                helperText={errors.Paterno.length > 1 && errors.Paterno}
                sx={{ width: "100%" }}
                type="text"
                variant="outlined"
                size="small"
                className="fixed-input"
                onKeyUp={handleOnKeyPress}
              />
            </Grid2>
          </Grid2>

          <Grid2 container spacing={2}>
            <Grid2 xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Materno"
                value={filterData.Materno}
                name="Materno"
                onChange={(e) => handleChangeFilter(e)}
                error={errors.Materno}
                helperText={errors.Materno.length > 1 && errors.Materno}
                sx={{ width: "100%" }}
                type="text"
                variant="outlined"
                size="small"
                className="fixed-input"
                onKeyUp={handleOnKeyPress}
              />
            </Grid2>
            <Grid2 xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Celular"
                value={filterData.Celular}
                name="Celular"
                onChange={(e) => handleChangeFilter(e)}
                error={errors.Celular}
                helperText={errors.Celular.length > 1 && errors.Celular}
                sx={{ width: "100%" }}
                type="text"
                variant="outlined"
                size="small"
                className="fixed-input"
                onKeyUp={handleOnKeyPress}
              />
            </Grid2>
          </Grid2>

          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            spacing={1}
            marginTop={2}
          >
            <Button
              variant="outlined"
              color="primaryDark"
              size="small"
              onClick={handleClearSearch}
            >
              Limpiar
            </Button>
            <Button
              variant="contained"
              color="primaryDark"
              startIcon={<Icon>search</Icon>}
              size="small"
              onClick={handleSearch}
            >
              Buscar
            </Button>
          </Stack>
        </Box>

        <Box marginTop={2}>
          <BasicList
            configParams={config}
            data={options}
            fetchHasMore={fetchHasMore}
            totalItems={total}
            itemComponent={ItemUser}
            hasMore={hasMore}
            handleClickItem={handleClickItem}
            finder={false}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default UserSearchForm;

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Chip,
  Icon,
  TextField,
  InputAdornment,
  Divider,
  Button,
  IconButton,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useFormik } from "formik";
import ModalResponsibleList from "./ModalResponsibleList";
import middleware from "@middlewares/middleware";
import { isEmptyOrNullObject } from "@utils/validations";
import SocialService from "@services/SocialServices";
import Yup from "@utils/Yupi18n";
import Swal from "sweetalert2";
import social from "../../services/SocialServices";

const ResponsibleList = (props) => {
  const {
    ticket,
    user,
    values,
    setValues,
    finished,
  } = props;

  const location = useLocation();
  const [openModal1, setOpenModal1] = useState(false);
  const [responsible, setResponsible] = useState(() => {
    return {
      value: user?.value,
      label: `${user?.label}`,
      TieneUsuario: 1,
    };
  });

  const formik = useFormik({
    initialValues: {
      idUsuario:user.idUsuario,
      idEstructuraSocial:user.idEstructura,
      idUsuarioPropietario:user.value,
      idPerfil:user.idPerfil
    },
    validationSchema: Yup.object().shape({
      idUsuario:Yup.number().required(),
      idEstructuraSocial: Yup.number().required(),
      idUsuarioPropietario: Yup.number().required(),
      idPerfil: Yup.number().required()
    }),
    onSubmit: values => {
      // Aquí manejas la lógica de envío del formulario
      handleAsing(values);
    },
  });

  const getFindRecord = location.state ? location.state : null;

  useEffect(() => {
    setResponsible(user);
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    // eslint-disable-next-line
  }, [finished]);

  useEffect(() => {
    if (!isEmptyOrNullObject(getFindRecord)) {
      if (getFindRecord.idUsuarioPropietario !== responsible.idUsuario) {
        const filtered = [
          {
            id:"idPadre",
            filter:"=",
            value:user.idEstructura
          },
          {
            id: "idUsuario",
            filter: "=",
            value: getFindRecord.idUsuarioPropietario,
          },
        ];
        SocialService.getResponsibles({
          page: 0,
          pageSize: 10,
          filtered,
        }).then((res) => {
          if (res.results) {
            let data = res.response.data[0];
            if (data.length > 0) {
              setResponsible(data);
            } else {
              setResponsible(data);
            }
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [getFindRecord]);


  const resetResponsible = () => {
    setResponsible({
      value: user.value,
      idUsuario: user.value,
      label: `${user.label}`,
      TieneUsuario: 1,
    });
  };

  const handleSummit = (e) => {
    if (Object.values(formik.errors).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Revisar",
        html: `<ul>${Object.values(formik.errors)
          .map((item) => `<li>${item}</li>`)
          .join("")}</ul>`,
      });
      return true;
    }

    formik.submitForm(formik.values);
  };

  const handleAsing = async (value) => {

    Swal.fire({
      title: "Asignar!",
      html: `<p>Esta Seguro de cambiar al responsale por  ${responsible.label}</p>`,
      showCancelButton: true,
      confirmButtonText: `Continuar`,
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        try {
         const response = await social.updateResponsible({...value});
          if (!response.success) {
            return Swal.showValidationMessage(`
              ${response.message}
            `);
          }
          return response;
        } catch (error) {
          Swal.showValidationMessage(`
            Request failed: ${error}
          `);
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon:"success",
          title: "Actualizado con éxito",
        });
      
      }
    });
  };


  const handleResponsable = (e) => {

    formik.handleChange({target:{
      name:"idUsuarioPropietario",
      value:e.idUsuario
    }});
    setResponsible({
      ...responsible,
      label:e.NombreCompleto,
      value:e.idUsuario,
      TieneUsuario: 1,
    });
  };
  


  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      {ticket && (
        <Grid2 xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
          <Chip
            icon={<Icon>groups2</Icon>}
            label={`${
              middleware.checkMenuAction("Editar") ? "Seleccione a" : ""
            } Quien Asigno`}
          />
        </Grid2>
      )}
      <Grid2 container spacing={1} sx={{ mt: 1 }}>
        <Grid2 xs={10} sm={10} md={10} lg={10}>
          <TextField
            required={true}
            disabled={true}
            label="Quien Asigno"
            value={responsible.label}
            name="Nombre"
            sx={{ width: "100%" }}
            type="text"
            variant="outlined"
            size="small"
            className="fixed-input"
            InputProps={
              middleware.checkMenuAction("Editar") && {
                startAdornment: (
                  <InputAdornment position="start" sx={{ pl: 0, pr: 0 }}>
                    <Button
                      color="warning"
                      onClick={(e) => resetResponsible()}
                      sx={{ ml: 0, pl: 0 }}
                    >
                      <Icon>restore</Icon>
                    </Button>
                    <Divider sx={{ height: 28 }} orientation="vertical" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Divider

                      sx={{ height: 28, m: 0.5 }}
                      orientation="vertical"
                    />
                    <IconButton
                      color="info"
                      onClick={(e) => setOpenModal1(true)}
                      sx={{ mr: 0, pr: 0 }}
                    >
                      <Icon>search</Icon>
                    </IconButton>
                  </InputAdornment>
                ),
              }
            }
          />
        </Grid2>
        <Grid2 xs={2} sm={2} md={2} lg={2}>
          {
            parseInt(formik.values.idUsuarioPropietario) !== parseInt(user.value) && 
            <Button variant="contained" size="small" endIcon={<Icon>save</Icon>} onClick={handleSummit}>
            Guardar
            </Button>
          }
        </Grid2>
      </Grid2>

      <ModalResponsibleList
        openModal={openModal1}
        setOpenModal={setOpenModal1}
        setResponsible={handleResponsable}
        idEstructura={user.idEstructura}
      />
    </Box>
  );
};

export default ResponsibleList;

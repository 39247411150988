import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isEmptyOrNullObject } from "@utils/validations";

export const useSorted = (props) => {
  const {
    total,
    setData,
    id = "CONCAT_WS(' ', compromisos_unicos.Nombre, compromisos_unicos.Paterno, compromisos_unicos.Materno)",
  } = props;

  const [sortedData, setSortedData] = useState([]);

  const handleSorted = (dataSorted) => {
    let sorted = [];

    if (!isEmptyOrNullObject(dataSorted)) {
      switch (dataSorted.orderBy) {
        case "NombreCompleto":
          sorted = [
            ...sorted,
            {
              id,
              value: dataSorted.order,
            },
          ];

          break;
        default:
          sorted = [
            ...sorted,
            { id: dataSorted.orderBy, value: dataSorted.order },
          ];
          break;
      }
    }

    return sorted;
  };

  const handleRequestSort = (sortData) => {
    if (total > 0) setSortedData(handleSorted(sortData));
  };

  useEffect(() => {
    if (sortedData.length > 0) {
      setData((prevState) => ({ ...prevState, page: 0, sorted: sortedData }));
    }
    //eslint-disable-next-line
  }, [sortedData]);

  return {
    sortedData,
    handleRequestSort,
  };
};

useSorted.propTypes = {
  total: PropTypes.number.isRequired,
  setData: PropTypes.func.isRequired,
};

import { useEffect, useState, useRef, Fragment } from "react";
import isEqual from "lodash/isEqual";

//MUI
import {
  Table,
  TableContainer,
  Card,
  Button,
  CardContent,
  CardActions,
  Divider,
  Stack,
  Container,
  Typography,
  Box,
  Alert,
  Switch,
  Chip,
  FormControlLabel,
  Checkbox,
  Tooltip,
  IconButton,
  Icon,
} from "@mui/material";
import { green } from "@mui/material/colors";

import Swal from "sweetalert2";

//COMPONENTS
import TableBody from "@components/Activation/Bingo/TableBody";
import TableEmpty from "@components/Activation/RollCall/TableEmpty";
import TableHeader from "@components/Activation/Bingo/TableHead";

// Utilidades
import { getVars, setVars } from "@utils/global";

//Middlewares
import middleware from "@middlewares/middleware";

//Services
import MovilizacionServices from "@services/MovilizationServices";
import NameEditing from "./NameEditing";

const BingoFolioTable = (props) => {
  const {
    selected,
    filtered,
    onClean,
    setLoading,
  } = props;
  
  const localData = getVars("EditedData");
  const filterParams = getVars("filter");
  const [editedData, setEditedData] = useState([]);
  const tableRef = useRef(null);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [dataFound, setDataFound] = useState(true);
  const [switchValue, setSwitchValue] = useState(true);
  const [allowDuplicate, setAllowDuplicate] = useState(false);
  const [openNameEditing, setOpenNameEditing] = useState(false);
  const [nameEdit, setNameEdit] = useState({});
  const [reloadNameEdit, setReloadNameEdit] = useState(false);
  const [editingEnabled, setEditingEnabled] = useState(true);

  const resetData = () => {
    setEditedData([]);
    setData([]);
    setTotal(0);
    setDataFound(true);
    sessionStorage.removeItem('EditedData');
    sessionStorage.removeItem('filter');
  };

  const handleEditMovilizados = (idUsuario, newValue) => {
    const parsedValue = newValue !== "" ? parseInt(newValue) : "";
    const isDuplicate = editedData.some(
      (row) => row.FolioBingo === parsedValue && row.id !== idUsuario
    );
    const idEstatus = isDuplicate ? 2 : 1;
    const updatedData = editedData.map((row) => {
      if (row.id === idUsuario) {
        return { ...row, FolioBingo: parsedValue, idEstatus: idEstatus };
      }
      return row;
    });
    if (newValue > 750) {
      validNumber();
    }
    setEditedData(updatedData);
  };

  const parseToData = () => {
    const modifiedData = editedData.filter((editedRow) => {
      const originalRow = data.find((row) => row.id === editedRow.id);
      return (
        originalRow &&
        originalRow.FolioBingo !== editedRow.FolioBingo &&
        editedRow.FolioBingo !== ""
      );
    });

    return modifiedData;
  };

  const isValidateData = async () => {
    const modifiedData = parseToData();
    const folioBingos = modifiedData.map((dato) => dato.FolioBingo);
    const hasDuplicates = modifiedData.some((dato) => dato.idEstatus === 2);
    const greaterThan = folioBingos.some((folioBingo) => folioBingo > 750);

    if (hasDuplicates && !allowDuplicate) {
      await Swal.fire({
        icon: "warning",
        title: "Duplicación de Folios Detectada",
        text: "Le recomendamos revisar los folios asignados, ya que no es posible registrar duplicados.",
      });
      return true;
    } else if (greaterThan) {
      Swal.fire({
        title: "¡Alerta!",
        text: "Hay un valor de FolioBingo mayor a 750. Por favor, corríjalo antes de continuar.",
        icon: "warning",
      });
      return true;
    }
  };

  const handleSave = async () => {
    const modifiedData = parseToData();

    const isValidate = await isValidateData();
    if (isValidate) return;

    if (modifiedData.length === 0) {
      Swal.fire({
        title: "No se han realizado modificaciones para registrar",
        icon: "info",
      });
    } else {
      handleConfirmSave(modifiedData);
    }
  };

  const handleFinishSave = async () => {
    const modifiedData = parseToData();
    const isValidate = await isValidateData();
    if (isValidate) return;

    Swal.fire({
      title: "¿Estás seguro?",
      html: "Se recomienda guardar los cambios antes de continuar.<br><br><strong>Nota:</strong> Los datos no guardados se eliminarán.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, continuar",
      cancelButtonText: "Cancelar",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        setSwitchValue(false);
        if (modifiedData.length > 0) {
          saveModifiedData(modifiedData, true);
        } else {
          updateStatus(2);
        }
      }
    });
  };

  const localStorage = async (modifiedData) => {
    const updatedEditedData = modifiedData.map((row) => {
      return {
        id: row.id,
        FolioBingo: row.FolioBingo,
        idEstatus: row.idEstatus,
      };
    });
    setVars("EditedData", updatedEditedData);
  };

  const validNumber = () => {
    Swal.fire({
      title: "¡Alerta!",
      text: "Hay un valor de FolioBingo mayor a 750. Por favor, corríjalo antes de continuar.",
      icon: "warning",
    });
  };

  const handleConfirmSave = (value) => {
    Swal.fire({
      title: "¡Advertencia!",
      html: `Al confirmar, se almacenarán ${value.length
        } registro(s) en la casilla ${selected.label} - Seccion ${filtered.labelSeccion
          ? filtered.labelSeccion
          : filterParams?.labelSeccion
        }. <br>¿Estás seguro de continuar?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, Confirmar",
      cancelButtonText: "No, Cancelar",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        saveModifiedData(value, false);
      }
    });
  };

  const updateStatus = async (value) => {
    try {
      setLoading(true);
      let params = {
        idCasilla: filtered?.idCasilla,
        idEstatus: value,
      };
      const result = await MovilizacionServices.updateStatusBingo(params);
      const { results, message } = result;
      if (results) {
        Swal.fire({ title: message, icon: "success" });
        // setVars("EditedData", []);
        // setVars("filter", {});
        resetData();
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoading(false);
    }
  };

  const saveModifiedData = async (modifiedData, flagFinish = false) => {
    const paramsToSend = editedData.filter((editedRow) => {
      return modifiedData.some(
        (modifiedRow) => modifiedRow.id === editedRow.id
      );
    });
    try {
      setLoading(true);
      const folios = paramsToSend.map((row) => {
        return {
          idCompromisoUnico: row.id,
          FolioBingo: parseInt(row.FolioBingo),
        };
      });
      
      let params = {
        idCasilla: filtered?.idCasilla,
        Folios: folios,
        allowDuplicate: allowDuplicate,
      };
      localStorage(modifiedData);
      const result = await MovilizacionServices.setBingoNumeration(params);
      const { results, message } = result;
      if (results) {
        Swal.fire({
          title: message,
          icon: "success",
        });
        setData(editedData);
        setAllowDuplicate(false);
        getBingoList(filtered, flagFinish);
        /**
         * Guardar únicamente si se envía la confirmación de guardar y finalizar 
         */
        if(flagFinish){
          updateStatus(2);
        }
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEnableInputs = async () => {
    if (selected?.idCasilla === "") {
      return;
    }
    if (!switchValue) {
      Swal.fire({
        title: "¿Está seguro de habilitar los campos?",
        text: "Esta acción habilitará la edición de los campos que ya tienen un folio asignado.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, Habilitar",
        cancelButtonText: "No, Cancelar",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.isConfirmed) {
          setSwitchValue((prevState) => !prevState);
          updateStatus(1);
        }
      });
    }
  };

  const verifyStatus = async (idCasilla) => {
    try {
      setLoading(true);
      setEditingEnabled(true);
      const result1 = await MovilizacionServices.verifyStatus(idCasilla);
      if(!result1.results){
        setSwitchValue(false);
        setEditingEnabled(false);
      }  
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
      setLoading(false);
      return
    }finally{
      setLoading(false);
    }
  }
  
  const getBingoList = async (value, flag) => {
    if(filtered?.idEstatus === 1 && flag){
      await verifyStatus(value.idCasilla);
    }else if(filtered?.idEstatus === 2 && middleware.checkMenuAction('Editar')){
      setEditingEnabled(false);
    } 

    try {
      setLoading(true);
      let params = {
        Seccion: value.Seccion,
        idEstatus: value.idEstatus,
        RangoInicial: value.RangoInicial.length > 0 ? value.RangoInicial.toUpperCase() : null,
        RangoFinal: value.RangoFinal.length > 0 ? value.RangoFinal.toUpperCase() : null,
      }
      
      if (value.idCasilla) {
        params = {
          ...params,
          idCasilla: value.idCasilla,
        };
      }
      const result = await MovilizacionServices.getCompromisosBingosList(
        params
      );
      const { success, results, message, response } = result;

      if (results) {
        const transformedData = response.data.map((row) => ({
          ...row,
          NombreCompleto: (
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <Tooltip title="Editar Nombre">
                <IconButton
                  size="small"
                  onClick={() => handleModalsNameEdit(row)}
                >
                  <Icon>edit</Icon>
                </IconButton>
              </Tooltip>
              <Typography variant="body2">{row.NombreCompleto}</Typography>
            </Stack>
          ),
        }));
        setData(transformedData);
        setEditedData(transformedData);
        setTotal(response.total);
        setDataFound(true);
      } else {
        setData([]);
        setEditedData([]);
        setDataFound(false);
        setTotal(response.total);
      }
      if (!success) throw new Error(message);
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoading(false);
      setReloadNameEdit(false);
    }
  };

  const handleAllowDuplicates = (e) => {
    setAllowDuplicate(e.target.checked ? true : false);
  };

  const handleModalsNameEdit = (row) => {
    setOpenNameEditing(true);
    setNameEdit(row);
  };

  useEffect(() => {
    resetData();
    //eslint-disable-next-line
  }, [onClean]);

  useEffect(() => {
    if (filtered) {
      getBingoList(filtered, true);
    }
    //eslint-disable-next-line
  }, [filtered]);

  useEffect(() => {
    const updatedData = [...editedData];
    updatedData.forEach((usuario) => {
      if (usuario.idEstatus === 2) {
        const existeCoincidencia = updatedData.some(
          (otroUsuario) =>
            otroUsuario.id !== usuario.id &&
            otroUsuario.FolioBingo === usuario.FolioBingo
        );
        if (!existeCoincidencia) {
          usuario.idEstatus = 1;
        }
      }
    });

    const isDataChanged = !isEqual(editedData, updatedData);

    if (isDataChanged) {
      setEditedData(updatedData);
    }
  }, [editedData]);

  useEffect(() => {
    if (localData.length > 0) {
      const updatedEditedData = editedData.map((row) => {
        const editedItem = localData.find((item) => item.id === row.id);
        if (editedItem) {
          return {
            ...row,
            FolioBingo: editedItem.FolioBingo,
            idEstatus: editedItem.idEstatus,
          };
        }
        return row;
      });
      setEditedData(updatedEditedData);
    }
    const hasElementWithId2 = data.some((row) => row.idEstatusFolioBingo === 2);
    if (hasElementWithId2 && filtered.idEstatus === 2) {
      setSwitchValue(false);
    } else if(filtered?.idEstatus === 1 && !editingEnabled){
      setSwitchValue(false);
    }else {
      setSwitchValue(true);
    }
    //eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (reloadNameEdit) {
      getBingoList(filtered, false);
    }
    //eslint-disable-next-line
  }, [reloadNameEdit]);

  const handleDeleteFolio = async (item) => {
    const res = await Swal.fire({
      title: "¿Está seguro de eliminar el folio?",
      text: "Esta acción eliminará el folio asignado.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, Eliminar",
      cancelButtonText: "No, Cancelar",
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    if (res.isConfirmed) {
      setLoading(true);
      try {
        const result = await MovilizacionServices.deleteAssignedFolio({ id: item.id });
        const { results, message } = result;

        if(results){
          if(localData && Array.isArray(localData) && localData.length){
            const modifiedData = localData.filter(x => x.FolioBingo !== item.FolioBingo );
            setVars("EditedData", modifiedData);
          }

          getBingoList(filtered, false);
          Swal.fire({
            icon: "success",
            title: message,
          });
        }else{
          Swal.fire({
            icon: "warning",
            title: message,
          });
  
        }
      } catch (error) {
        Swal.fire({
          icon: "warning",
          title: error.message,
        });
      }finally{
        setLoading(false);
      }
    }
  }

  return (
    <Fragment>
      <Card className="card-primary">
        
        <Container sx={{ p: 2 }}>
          <Alert severity="info" sx={{ mb: 2 }}>
            Una vez que hayas guardado todos los folios de los bingos, finaliza
            el cuadernillo haciendo clic en el botón{" "}
            <strong>"Finalizar"</strong>.
          </Alert>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            spacing={2}
          >
            <FormControlLabel
              label="Permitir Duplicados"
              control={
                <Checkbox
                  color="primary"
                  onChange={handleAllowDuplicates}
                  checked={allowDuplicate}
                />
              }
            />

            {middleware.checkMenuAction("Editar") &&
              !editingEnabled &&
              data.length > 0 && (
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <Switch
                    checked={switchValue}
                    onChange={handleEnableInputs}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Typography variant="body2">Habilitar Edición</Typography>
                </Stack>
            )}

            {data.length > 0 &&
              filtered?.idEstatus === 2 &&
              switchValue &&
              middleware.checkMenuAction("Editar") && (
                <Chip
                  label="Edición Habilitada"
                  size="small"
                  sx={{ backgroundColor: green[50], color: green[900] }}
                />
            )}

          </Stack>
          <Divider sx={{ mt: 1 }} />
        </Container>

        <CardContent>
          <TableContainer
            ref={tableRef}
            sx={{
              maxHeight: 440,
              overflow: "auto",
              borderRadius: 3,
            }}
          >
            <Table size="small" stickyHeader>
              <TableHeader />
              {data.length === 0 && !dataFound ? (
                <TableEmpty />
              ) : (
                <TableBody
                  data={editedData}
                  dataFound={dataFound}
                  allowDuplicate={allowDuplicate}
                  handleEditMovilizados={handleEditMovilizados}
                  enableEdit={switchValue}
                  handleDelete={handleDeleteFolio}
                />
              )}
            </Table>
          </TableContainer>
          <Box sx={{ mt: 2, textAlign: "center" }}>
            <Typography variant="body2" sx={{ color: "#888888" }}>
              Mostrando {Math.min(editedData.length, total)} de {total}
            </Typography>
          </Box>
        </CardContent>

        {editingEnabled && filtered?.idEstatus === 1 && data.length > 0 && (
          <CardActions sx={{ justifyContent: "center" }}>
            <Button
              variant="contained"
              color="primaryDark"
              onClick={handleSave}
            >
              Guardar avance
            </Button>
            <Button
              variant="outlined"
              color="primaryDark"
              onClick={handleFinishSave}
            >
              Finalizar Cuadernillo
            </Button>
          </CardActions>
        )}

        {
          !editingEnabled && (
            <Alert severity="warning">
              La captura del cuadernillo ya se finalizó. Habilite o solicite que le habiliten la edición del mismo.
            </Alert>
          )
        }
      </Card>

      <NameEditing
        open={openNameEditing}
        row={nameEdit}
        editedData={editedData}
        data={data}
        setOpen={setOpenNameEditing}
        setReloadNameEdit={setReloadNameEdit}
      />
    </Fragment>
  );
};

export default BingoFolioTable;

import { PrivilegedRoute/* ,ProtectedRoute */ } from "@middlewares";
import ResultPartido from "../../pages/Resultados/ResultPartido";

const ResultadosRoutes = [
  {
    path: "/resultado-partido",
    element: (
      <PrivilegedRoute>
        <ResultPartido />
      </PrivilegedRoute>
    ),
  }
];

export default ResultadosRoutes;

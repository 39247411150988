import { useEffect } from "react";

const useScrollToTop = () => {
  useEffect(() => {
    const scrollToTop = () => {
      const scrollStep = -window.scrollY / (400 / 15);
      const scrollInterval = setInterval(() => {
        if (window.scrollY !== 0) {
          window.scrollBy(0, scrollStep);
        } else {
          clearInterval(scrollInterval);
        }
      }, 15);
    };

    scrollToTop();
  }, []);
};

export default useScrollToTop;


import React, {useState} from "react";
import {
    Button,
    Card,
    Container,
    Grid,
    Icon,
    Typography,
    Stack,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    Box
} from "@mui/material";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import BasicSelect from "@components/Selects/BasicSelect";
import { CompromisoFilterHistory } from "@data/interfaces/CompromisoInterfaces";
import { CompromisoFilterHistorySchema } from "@data/schemas/CompromisoSchemas";
import { isEmptyOrInvalidString } from "@utils/validations";
import { isTypePhone } from "@utils/validations";
import { mt } from "date-fns/locale";

const HistoryFilter = ({ onChangeFilter }) => {
    const optionsLabels = [
        { value: 0, label: 'TODOS' },
        { value: 1, label: 'CON ETIQUETA' },
        { value: 2, label: 'SIN ETIQUETA' },
    ];
    
    const [selectedOptionMessage, setSelectedOptionMessage] = useState({
        message: "TODOS",
        color: "rgb(24,118,210)"
    });

    const formik = useFormik({
        initialValues: CompromisoFilterHistory,
        validationSchema: CompromisoFilterHistorySchema,
        onSubmit: (values) => {
            handleFilter(values);
        },
    });

    const handleFilter = (values) => {
        const { celular, nombre, seccion, etiqueta, selectedOption } = values;

        let filtered = [];

        if (formik.values.celular !== "") {
            filtered.push({ id: "compromisos.Celular", filter: "=", value: celular });
        }

        if (formik.values.nombre !== "") {
            filtered.push({
                id: ["compromisos.Nombre", "compromisos.Paterno", "compromisos.Materno"],
                filter: "LIKE",
                value: nombre,
            });
        }

        if (formik.values.seccion !== "") {
            filtered.push({ id: "compromisos.SeccionVota", filter: "=", value: seccion })
        }

        if (formik.values.etiqueta > 0) {
            filtered.push({ id: "TieneEtiqueta", filter: "=", value: etiqueta })
        }

        if (selectedOption > 0) {
            filtered.push({ id: "ErrorSeccion", filter: "=", value: selectedOption })
        }

        onChangeFilter(filtered)

        switch (selectedOption) {
            case 0:
                setSelectedOptionMessage({ message: "TODOS", color: "rgb(24,118,210)" });
                break;
            case 1:
                setSelectedOptionMessage({ message: "La Sección donde Vota no corresponde al estado o posiblemente fue actualizada", color: "rgb(211,48,47)" });
                break;
            case 2:
                setSelectedOptionMessage({ message: "La Sección donde Vota no corresponde al municipio donde reside", color: "rgb(237,109,3)" });
                break;
            default:
                setSelectedOptionMessage({ message: "", color: "" });
        }

      
    };

    const handleOnKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            formik.submitForm();
        }
    };

    const handleClearFilter = () => {
        formik.setValues({
            ...formik,
            celular: "",
            nombre: "",
            seccion: "",
            etiqueta: 0,
            selectedOption: 0
        });

        setSelectedOptionMessage({
            message: "TODOS",
            color: "rgb(24,118,210)"
        });

        onChangeFilter([]);
    };

    const handleRadioChange = (event) => {
        formik.handleChange(event);
        const selectedOption = parseInt(event.target.value);
        handleFilter({ ...formik.values, selectedOption: selectedOption });
    };

    return (
        <Card className="card-primary">
            <Container maxWidth={false} sx={{ p: 2 }}>
                <Stack
                    direction="row"
                    spacing={1}
                    marginBottom={2}
                    alignItems="center"
                >
                    <Icon>tune</Icon>
                    <Typography variant="subtitle1" fontWeight={600}>
                        Filtros
                    </Typography>
                </Stack>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <TextField
                            sx={{ width: "100%" }}
                            label="Celular"
                            type="text"
                            name="celular"
                            onChange={(e) =>
                                formik.handleChange({
                                    target: {
                                        name: "celular",
                                        value: isTypePhone(e.target.value)
                                            ? e.target.value
                                            : formik.values.celular,
                                    },
                                })
                            }
                            onBlur={(e) =>
                                formik.handleChange({
                                    target: {
                                        name: "celular",
                                        value: isTypePhone(e.target.value)
                                            ? e.target.value
                                            : formik.values.celular,
                                    },
                                })
                            }
                            onKeyPress={handleOnKeyPress}
                            value={formik.values.celular}
                            variant="outlined"
                            size="small"
                            className="fixed-input"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <TextField
                            sx={{ width: "100%" }}
                            label="Nombre"
                            type="text"
                            name="nombre"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onKeyPress={handleOnKeyPress}
                            value={formik.values.nombre.toUpperCase()}
                            variant="outlined"
                            size="small"
                            className="fixed-input"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={2}>
                        <TextField
                            sx={{ width: "100%" }}
                            label="Sección"
                            type="text"
                            name="seccion"
                            onChange={(e) =>
                                formik.handleChange({
                                    target: {
                                        name: "seccion",
                                        value: isTypePhone(e.target.value)
                                            ? e.target.value
                                            : formik.values.seccion,
                                    },
                                })
                            }
                            onBlur={(e) =>
                                formik.handleChange({
                                    target: {
                                        name: "seccion",
                                        value: isTypePhone(e.target.value)
                                            ? e.target.value
                                            : formik.values.seccion,
                                    },
                                })
                            }
                            onKeyPress={handleOnKeyPress}
                            value={formik.values.seccion}
                            variant="outlined"
                            size="small"
                            className="fixed-input"
                            inputProps={{
                                maxLength: 4,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={2}>
                        <BasicSelect
                            error={formik.touched.etiqueta && !isEmptyOrInvalidString(formik.errors.etiqueta)}
                            errorMessage={formik.errors.etiqueta}
                            name="etiqueta"
                            label="Seleccione una opción"
                            options={optionsLabels}
                            value={formik.values.etiqueta}
                            onChange={formik.handleChange}
                            size="small"
                            sx={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={1.5} lg={1}>
                        <Button
                            variant="contained"
                            color="primaryDark"
                            onClick={formik.submitForm}
                            fullWidth
                        >
                            Filtrar
                        </Button>
                    </Grid>
                    <Grid item xs={6} sm={6} md={1.5} lg={1}>
                        <Button
                            variant="outlined"
                            color="primaryDark"
                            onClick={handleClearFilter}
                            fullWidth
                        >
                            Limpiar
                        </Button>
                    </Grid>
                </Grid>

                <Box sx={{ display: 'flex', justifyContent: { xs: 'left', md: 'center' }, alignItems: 'center', mt: 4 }}>
                    <RadioGroup
                        aria-labelledby="TieneResultados"
                        sx={{ flexDirection: { xs: 'column', md: 'row' } }}
                        name="selectedOption"
                        value={formik.values.selectedOption}
                        onChange={handleRadioChange} // Changed here
                        row
                    >
                        <FormControlLabel value={0} control={<Radio />} label="TODOS" />
                        <FormControlLabel value={2} control={<Radio color="warning" />} label="SECCIÓN NO CORRESPONDE AL MUNICIPIO" />
                        <FormControlLabel value={1} control={<Radio color="error" />} label="SECCIÓN NO CORRESPONDE AL ESTADO" />
                    </RadioGroup>
                </Box>

                <Typography variant="body2" sx={{ color: selectedOptionMessage.color, mt:2,  display: 'flex', justifyContent:'center'}}>
                        Opción seleccionada: {selectedOptionMessage.message}
                    </Typography>        
                </Container>
        </Card>
    );
};

HistoryFilter.propTypes = {
    onChangeFilter: PropTypes.func.isRequired
};

export default HistoryFilter;
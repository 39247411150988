import { PrivilegedRoute, ProtectedRoute } from "@middlewares";

import UserPassword from "@pages/Support/UserPassword";
import UserStatus from "@pages/Support/UserStatus";
import UserCellphone from "@pages/Support/UserCellphone";
import UserAssignment from "@pages/Support/UserAssignment";
import UserActions from "@pages/Support/UserActions";
import UserPartidos from "@pages/Support/UserPartido"

const SupportRoutes = [
  {
    path: "/soporte/usuarios-accesos",
    element: (
      <PrivilegedRoute>
        <UserPassword />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/soporte/usuarios-estatus",
    element: (
      <PrivilegedRoute>
        <UserStatus />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/soporte/usuarios-celular",
    element: (
      <PrivilegedRoute>
        <UserCellphone />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/soporte/establecer-perfiles",
    element: (
      <ProtectedRoute>
        <UserAssignment />
      </ProtectedRoute>
    ),
  },
  {
    path: "/soporte/historial-acciones",
    element: (
      <ProtectedRoute>
        <UserActions />
      </ProtectedRoute>
    ),
  },
  {
    path: "/soporte/partidos",
    element: (
      <ProtectedRoute>
        <UserPartidos />
      </ProtectedRoute>
    ),
  },
];

export default SupportRoutes;

import React, { useState, useEffect } from "react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  Container,
  Box,
  Card,
  CardContent,
  Divider,
  Chip,
  Typography,
  Icon,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import electoral from "@services/ElectoralServices";
import BasicTable from "@components/Tables/BasicTable";
import { numberWithCommas } from "@utils/Utilities/";
import ColumnStacked from "../Charts/ColumnStacked";
import Swal from "sweetalert2";
import { useCatalogs } from "@hooks/useCatalogs";

const card = {
  overflow: "visible",
  boxShadow: 1,
  borderRadius: 2,
  backgroundColor: "white",
};

const cardContent = {
  padding: 2,
  paddingBottom: "16px !important",
};

const DashbordCerificacionINE = (props) => {
  const catalogsParams = [{ id: "estatus_certificacion_ine", getAll: false }];
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(0);
  const [dataStats, setDataStats] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    type: 1,
  });
  const [dataColumns, setDataColumns] = useState({
    categories: ["DF1", "DF2", "DF3", "DF4"],
    series: [
      {
        name: "En Proceso",
        data: [3, 5, 1, 13],
        color: "#FFC300",
        stack: "P1",
      },
      {
        name: "Certificados",
        data: [14, 8, 8, 12],
        color: "#D2F1D2",
        stack: "P1",
      },
      {
        name: "Con Observación",
        data: [0, 2, 6, 3],
        stack: "P2",
      },
    ],
    title: {
      primary: "RCs",
      sub: "",
      y: "RCS",
    },
  });
  const [dataBarra, setDataBarra] = useState({
    Avance: 1,
    Total: 0,
    PorcentajeAvance: 50.1,
  });
  const [dataEstatus,setDataEstatus] = useState([]);
  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsParams,
    putDefaultOption: false,
  });

  const columnsEstatus = [
    { id: "Estatus", label: "RCS" },
    { id: "Total", label: "", align: "right" },
  ];
  const columns = [
    {
      id: "DistritoFederal",
      label: "Distrito Federal",
      align: "center",
      width: "16px",
    },
    { id: "Municipios", label: "Municipio", align: "center", width: "16px" },
    { id: "Meta", label: "Meta", align: "center", width: "16px" },
    { id: "Avance", label: "RCS Asignados", align: "center", width: "16px" },
    { id: "Exportados", label: "Exportados", align: "center", width: "16px" },
    { id: "Acreditado", label: "Certificado", align: "center", width: "16px" },
    { id: "EnProceso", label: "En Proceso", align: "center", width: "16px" },
    {
      id: "Observacion",
      label: "Con Observación",
      align: "center",
      width: "16px",
    },
    {
      id: "NOAcreditado",
      label: "No Certificado",
      align: "center",
      width: "16px",
    },
  ];

  useEffect(() => {
    if (!load) {
      getList();
    }
  }, [params, load]);

  const getList = () => {
    if (loading) {
      return true;
    }
    setLoading(true);
    electoral
      .getCertificacionIne(params)
      .then((res) => {
        if (res.results) {
          setData(
            res.response.data.map((item) => {
              return {
                ...item,
                EnProceso: (
                  <Typography variant="body1">{item.EnProceso}</Typography>
                ),
              };
            })
          );
          setTotal(res.response.total);
          handleGraph(res.response.data);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setLoading(false);
      });

      electoral
      .getCertificacionIne({...params,type:0})
      .then((res) => {
        if (res.results) {
         // setTotal(res.response.total);
          let newData = res.response.data[0];
          let dataTable = [];
          if(params.type !== 1){
          dataTable.push({
            "Estatus": `RCS Exportados`,
            "P1":newData.ExportadosP1,
            "P2":newData.ExportadosP2,
            "S1":newData.ExportadosS1,
            "S2":newData.ExportadosS2,
          }
        )}
          else{
            dataTable.push({
                "Estatus": `RCS Exportados`,
                "Total":newData.Exportados
              },
              {
                "Estatus": `RCS Asignados`,
                "Total":newData.Avance
              },
              {
                "Estatus": `Meta`,
                "Total":newData.Meta
              }
            )
        }

        console.log(newData);
        setDataStats({
            "Meta":parseInt(newData.Meta),
            "Avance":parseInt(newData.Avance),
            "Acreditados":parseInt(newData.Acreditado),
            "Acreditados%":(parseInt(newData.Acreditado) > 0? (((parseInt(newData.Acreditado)/parseInt(newData.Meta))*100) >= 100? 100 : ((parseInt(newData.Acreditado)/parseInt(newData.Meta))*100).toFixed(2)) : 0),
            "Exportados":parseInt(newData.Exportados),
            "Exportados%":(parseInt(newData.Exportados) > 0? (((parseInt(newData.Exportados)/parseInt(newData.Avance))*100) >= 100? 100 : ((parseInt(newData.Exportados)/parseInt(newData.Avance))*100).toFixed(2)) : 0),
        });

          catalogs.estatus_certificacion_ine.forEach((item) => {
            if(params.type !== 1){
                switch (item.value) {
                    case 2:
                     dataTable.push({
                        "Estatus": `${item.label}`,
                        "P1":newData.EnProcesoP1,
                        "P2": newData.EnProcesoP2,
                        "S1": newData.EnProcesoS1,
                        "S2": newData.EnProcesoS2,
                       // color:"#FFE082"
                      });
                   break;
                   case 3:
                    dataTable.push({
                        "Estatus": `${item.label}`,
                        "P1": newData.AcreditadoP1,
                        "P2": newData.AcreditadoP2,
                        "S1": newData.AcreditadoS1,
                        "S2": newData.AcreditadoS2,
                       // color:"#FFE082"
                      });
                   break;
                   case 4:
                    dataTable.push({
                        "Estatus": `${item.label}`,
                        "P1": newData.NOAcreditadoP1,
                        "P2": newData.NOAcreditadoP2,
                        "S1": newData.NOAcreditadoS1,
                        "S2": newData.NOAcreditadoS2,
                       // color:"#FFE082"
                      });
                   break;
                   case 5:
                    dataTable.push({
                        "Estatus": `${item.label}`,
                        "P1": newData.ObservacionP1,
                        "P2": newData.ObservacionP2,
                        "S1": newData.ObservacionS1,
                        "S2": newData.ObservacionS2,
                       // color:"#FFE082"
                      });
                   break;
                    default:
                      break;
                  }
            }else{
                switch (item.value) {
                    case 2:
                    dataTable.push({
                        "Estatus": `${item.label}`,
                        "Total": newData.EnProceso
                       // color:"#FFE082"
                      });
                   break;
                   case 3:
                    dataTable.push({
                        "Estatus": `${item.label}`,
                        "Total": newData.Acreditado,
                       // color:"#FFE082"
                      });
                   break;
                   case 4:
                    dataTable.push({
                        "Estatus": `${item.label}`,
                        "Total": newData.NOAcreditado,
                       // color:"#FFE082"
                      });
                   break;
                   case 5:
                    dataTable.push({
                        "Estatus": ` ${item.label}`,
                        "Total": newData.Observacion,
                       // color:"#FFE082"
                      });
                   break;
                    default:
                      break;
                  }
            }
           
          });
          setDataEstatus(dataTable);
        } else {

          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getGruop = (datos) => {
    // Objeto donde almacenaremos los datos transformados
    const datosTransformados = {};

    // Iteramos sobre las claves de uno de los objetos para inicializar el objeto datosTransformados
    Object.keys(datos[0]).forEach((key) => {
      datosTransformados[key] = [];
    });

    // Iteramos sobre cada objeto en el array datos
    datos.forEach((objeto) => {
      // Iteramos sobre cada clave del objeto
      Object.keys(objeto).forEach((key) => {
        // Añadimos el valor correspondiente al array en datosTransformados
        let valor;
        if(key === "DistritoFederal"){
             valor = objeto[key];
        }else{
             valor = isNaN(parseFloat(objeto[key])) ? objeto[key] : parseFloat(objeto[key]);
        }
        
        // Añadimos el valor correspondiente al array en datosTransformados
        datosTransformados[key].push(valor);
      });
    });

    // Imprimimos los datos transformados
    return datosTransformados;
  };

  const handleGraph = (event) => {
    if (!load) {
      let Ncategories = [];
      let Nseries = [];

      let newData = getGruop(event);

      catalogs.estatus_certificacion_ine.forEach((item) => {
        Ncategories.push(item.label);
        if(params.type !== 1){
            switch (item.value) {
                case 2:
                  Nseries.push({
                    stack: "P1",
                    name: `P1 - ${item.label}`,
                    data: newData.EnProcesoP1
                   // color:"#FFE082"
                  },
                  {   stack: "P2",
                      name: `P2 - ${item.label}`,
                      data: newData.EnProcesoP2,
                     // color:"#FFE082"
                  },
                  {  stack: "S1",
                      name:`S1 - ${item.label}`,
                      data: newData.EnProcesoS1,
                     // color:"#FFE082"
                  },
                  {   stack: "S2",
                      name: `S2 - ${item.label}`,
                      data: newData.EnProcesoS2,
                     // color:"#FFE082"
                  }
                  );
               break;
               case 3:
                  Nseries.push({
                    name: `P1 - ${item.label}`,
                    data: newData.AcreditadoP1,
                    stack: "P1",
                   // color:"#FFE082"
                  },
                  {
                      name: `P2 - ${item.label}`,
                      data: newData.AcreditadoP2,
                      stack: "P2",
                     // color:"#FFE082"
                  },
                  {
                      name:`S1 - ${item.label}`,
                      data: newData.AcreditadoS1,
                      stack: "S1",
                     // color:"#FFE082"
                  },
                  {
                      name: `S2 - ${item.label}`,
                      data: newData.AcreditadoS2,
                      stack: "S2",
                     // color:"#FFE082"
                  }
                  );
               break;
               case 4:
                  Nseries.push({
                    name: `P1 - ${item.label}`,
                    data: newData.NOAcreditadoP1,
                    stack: "P1",
                   // color:"#FFE082"
                  },
                  {
                      name: `P2 - ${item.label}`,
                      data: newData.NOAcreditadoP2,
                     stack: "P2",
                     // color:"#FFE082"
                  },
                  {
                      name:`S1 - ${item.label}`,
                      data: newData.NOAcreditadoS1,
                      stack: "S1",
                     // color:"#FFE082"
                  },
                  {
                      name: `S2 - ${item.label}`,
                      data: newData.NOAcreditadoS2,
                      stack: "S2",
                     // color:"#FFE082"
                  }
                  );
               break;
               case 5:
                  Nseries.push({
                    name: `P1 - ${item.label}`,
                    data: newData.ObservacionP1,
                    stack: "P1",
                   // color:"#FFE082"
                  },
                  {
                      name: `P2 - ${item.label}`,
                      data: newData.ObservacionP2,
                      stack: "P2",
                     // color:"#FFE082"
                  },
                  {
                      name:`S1 - ${item.label}`,
                      data: newData.ObservacionS1,
                      stack: "S1",
                     // color:"#FFE082"
                  },
                  {
                      name: `S2 - ${item.label}`,
                      data: newData.ObservacionS2,
                      stack: "S2",
                     // color:"#FFE082"
                  }
                  );
               break;
                default:
                  break;
              }
        }else{
            switch (item.value) {
                case 2:
                  Nseries.push({
                    name: `${item.label}`,
                    data: newData.EnProceso
                   // color:"#FFE082"
                  });
               break;
               case 3:
                  Nseries.push({
                    name: `${item.label}`,
                    data: newData.Acreditado,
                   // color:"#FFE082"
                  });
               break;
               case 4:
                  Nseries.push({
                    name: `${item.label}`,
                    data: newData.NOAcreditado,
                   // color:"#FFE082"
                  });
               break;
               case 5:
                  Nseries.push({
                    name: `${item.label}`,
                    data: newData.Observacion,
                   // color:"#FFE082"
                  });
               break;
                default:
                  break;
              }
        }
       
      });

      
      setDataColumns({
        ...dataColumns,
        categories:newData.DistritoFederal,
        series:Nseries
      });

      /*         series: [
            {
              name: "En Proceso",
              data: [3, 5, 1, 13],
              color: "#FFC300",
              stack: "P1",
            },
            {
              name: "Certificados",
              data: [14, 8, 8, 12],
              color: "#D2F1D2",
              stack: "P1",
            },
            {
              name: "Con Observación",
              data: [0, 2, 6, 3],
              stack: "P2",
            },
          ] */
    }
  };

  const handleChangePagination = (pagination) => {
    setParams({ ...params, ...pagination });
  };

  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={12} sm={6} md={5} lg={3}>
        <Card sx={{ ...card, overflow:"auto"}}>
          <CardContent sx={{ ...cardContent, height: "100%" }}>
            <Box>
              <BasicTable
                rows={dataEstatus}
                hcolumns={columnsEstatus}
                isLoading={loading}
                cardType={false}
                stickyHeader={true}
                showPagination={false}
                sx={{ backgroundColor: "#D2E4F1"}}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid2>
      <Grid2 xs={12} sm={6} md={7} lg={9}>
        <Card sx={{ ...card }}>
          <CardContent sx={{ ...cardContent, height: "100%" }}>
            <Box>
              <Chip
                icon={<Icon>verified_user</Icon>}
                label="Avance Certificación"
                sx={{ backgroundColor: "transparent", fontSize: 16 }}
              />
              {loading ? (
                <Grid2
                  container
                  xs={12}
                  height={100}
                  alignItems="center"
                  justifyContent="center"
                >
                  <CircularProgress size={50} />
                </Grid2>
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: { xs: 1, sm: 2 },
                      mt: 1,
                    }}
                  >
                    <Box>
                      <Typography variant="body1" align="right">
                        Inicio
                      </Typography>
                      <Typography variant="body2" mb={1}>
                        0
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body1" align="center">
                        Avance
                      </Typography>
                      <Typography variant="body2" mb={1} align="center">
                        {`${numberWithCommas(dataStats.Acreditados)} (${
                          dataStats['Acreditados%']
                        }%)`}
                      </Typography>{" "}
                      {/* </Tooltip> */}
                    </Box>
                    <Box>
                      <Typography variant="body1" align="right">
                        Meta
                      </Typography>
                      <Typography variant="body2" mb={1} align="right">
                        {numberWithCommas(dataStats.Meta)}
                      </Typography>
                    </Box>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={dataStats['Acreditados%']}
                    sx={{
                      height: "10px",
                      bgcolor: "#D8EAD8",
                      borderRadius: "5px",
                      backgroundImage: `linear-gradient(45deg, transparent 25%, rgba(0, 0, 0, 0.1) 25%, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent 75%, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 0.1))`,
                      backgroundSize: "20px 20px",
                      py: 1,
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#D2F1D2", // Color de la barra de progreso
                      },
                    }}
                  />
                  {/* </Tooltip> */}
                </>
              )}
            </Box>
            <br />
            <br />
            <Box>
              <Chip
                icon={<Icon>file_download</Icon>}
                label="Avance Exportados"
                sx={{ backgroundColor: "transparent", fontSize: 16 }}
              />
              {loading ? (
                <Grid2
                  container
                  xs={12}
                  height={100}
                  alignItems="center"
                  justifyContent="center"
                >
                  <CircularProgress size={50} />
                </Grid2>
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: { xs: 1, sm: 2 },
                      mt: 1,
                    }}
                  >
                    <Box>
                      <Typography variant="body1" align="right">
                        Inicio
                      </Typography>
                      <Typography variant="body2" mb={1}>
                        0
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body1" align="center">
                        Avance
                      </Typography>
                      <Typography variant="body2" mb={1} align="center">
                        {`${numberWithCommas(dataStats.Exportados)} (${
                          dataStats["Exportados%"]
                        }%)`}
                      </Typography>{" "}
                      {/* </Tooltip> */}
                    </Box>
                    <Box>
                      <Typography variant="body1" align="right">
                        Meta
                      </Typography>
                      <Typography variant="body2" mb={1} align="right">
                        {numberWithCommas(dataStats.Avance)}
                      </Typography>
                    </Box>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={dataStats["Exportados%"]}
                    sx={{
                      height: "10px",
                      bgcolor: "#22A8FF",
                      borderRadius: "5px",
                      backgroundImage: `linear-gradient(45deg, transparent 25%, rgba(0, 0, 0, 0.1) 25%, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent 75%, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 0.1))`,
                      backgroundSize: "20px 20px",
                      py: 1,
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#22A8FF", // Color de la barra de progreso
                      },
                    }}
                  />
                  {/* </Tooltip> */}
                </>
              )}
            </Box>
          </CardContent>
        </Card>
      </Grid2>
      <Grid2 xs={12} sm={12} md={12} lg={12}>
        <ColumnStacked {...dataColumns} />
      </Grid2>
      <Grid2 xs={12} sm={12} md={12} lg={12}></Grid2>
      <Grid2 xs={12} sm={12} md={12} lg={12}>
        <BasicTable
          rows={data}
          hcolumns={columns}
          isLoading={loading}
          cardType={false}
          stickyHeader={true}
          sx={{ backgroundColor: "#D2E4F1" }}
          pageProp={params.page}
          pageSize={params.pageSize}
          total={total}
          handleChangePagination={handleChangePagination}
        />
      </Grid2>
    </Grid2>
  );
};
export default DashbordCerificacionINE;

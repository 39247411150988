import { useEffect, useState } from "react";
//import { useSorted } from "@hooks/useSorted";

import { Box, Card, CardContent, Typography } from "@mui/material";

import CustomTable from "@components/Tables/CustomTable";

import { COLUMNS_ADVANCE_MOBILIZATION_TERRITORIAL } from "@data/constants/Mobilization";
import movilizacion from "@services/MovilizationServices";
import Swal from "sweetalert2";

const TableAdavanceMobilization = ({ data }) => {
  const [orderBy, setOrderBy] = useState("");
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(10);
  const [localData, setLocalData] = useState(data);
  const [dataAdvanceMobilization, setDataAdvanceMobilization] = useState([]);

  const getPaseListaTable = async (params) => {
    try {
      setLoading(true);
      const { results, response, message, success } =
        await movilizacion.getPaseListaTable(params);
      if (!results && !success) throw new Error(message);

      if (results && success) {
        setDataAdvanceMobilization(response.data);
        setTotal(response.total);
      } else {
        setDataAdvanceMobilization([]);
        setTotal(0);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data.idAmbitoAgrupador) {
      getPaseListaTable({
        filtered: data.filtered,
        idAmbitoAgrupador: data.idAmbitoAgrupador,
      });
    }
  }, [data]);

  useEffect(() => {
    if (localData.sorted.length) {
      setOrderBy(localData.sorted[0].id);
    } else setOrderBy("");
  }, [localData]);

  return (
    <Card className="card-primary" sx={{ height: "95%" }}>
      <CardContent sx={{ height: "100%" }}>
        <Typography variant="body2" fontWeight={600} marginBottom={2}>
          REPORTE DE PASE DE LISTA (PROMOCIÓN)
        </Typography>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ height: "80%" }}
        >
          <CustomTable
            rows={dataAdvanceMobilization}
            columns={COLUMNS_ADVANCE_MOBILIZATION_TERRITORIAL}
            total={total}
            orderBy={orderBy}
            page={data.page}
            pageSize={data.pageSize}
            isLoading={loading}
            //handlePagination={handleChangePagination}
            //handleSort={handleRequestSort}
            disableCardType
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default TableAdavanceMobilization;

import { PrivilegedRoute, ProtectedRoute } from "@middlewares";

import Map from "@pages/Maps/CreationOfPolygons";
import ListProgress from "@pages/Maps/CreationOfPolygons/ListProgress";
import MapElectoralRoutes from "@pages/Maps/Routes";
import ListElectoralRoutes from "@pages/Maps/Routes/ListProgress";
import MapCompromisos from "@pages/Maps/Compromisos";
import MapStructure from "@pages/Maps/AdvanceStructure";
import MapCartographySM from "@pages/Maps/Cartography/MapCartographySM";
import MapCartographySMF from "@pages/Maps/Cartography/MapCartographySMF";
import MapCartographyPS from "@pages/Maps/Cartography/MapCartographyPS";
import MapCartographyMP from "@pages/Maps/Cartography/MapCartographyMP";
import MapCartographyMS from "@pages/Maps/Cartography/MapCartographyMS";
import MapCartographyRM from "@pages/Maps/Cartography/MapCartographyRM";
import MapCartographyER from "@pages/Maps/Cartography/MapCartographyER";
import MapCartographyDFP from "@pages/Maps/Cartography/MapCartographyDFP";
import MapCartographyDLP from "@pages/Maps/Cartography/MapCartographyDLP";
import MapCartographyDFS from "@pages/Maps/Cartography/MapCartographyDFS";
import MapCartographyDLS from "@pages/Maps/Cartography/MapCartographyDLS";
import MapAdvanceSocial from "@pages/Maps/AdvanceSocial";
import MapAdvanceSocialMunicipio from "@pages/Maps/AdvanceSocial/MapSocial";
import ElectoralMapNational from "@pages/Maps/National/ElectoralMapNational";
import PrepMap from "@pages/Maps/Prep/PrepMap";

const MapRoutes = [
    {
        path: '/mapa-poligonos',
        element: <PrivilegedRoute><Map /></PrivilegedRoute>
    },
    {
        path: '/list-polygons',
        element: <PrivilegedRoute><ListProgress /> </PrivilegedRoute>
    },
    {
        path: '/mapa-rutas',
        element: <PrivilegedRoute><MapElectoralRoutes /> </PrivilegedRoute>
    },
    {
        path: '/lista-rutas',
        element: <PrivilegedRoute><ListElectoralRoutes /> </PrivilegedRoute>
    },
    {
        path: '/mapa-avance-compromisos',
        element: <PrivilegedRoute><MapCompromisos /> </PrivilegedRoute>
    },
    {
        path: '/mapa-avance-estructura',
        element: <PrivilegedRoute><MapStructure /> </PrivilegedRoute>
    },
    {
        path: '/mapa-impresion-sm',
        element: <PrivilegedRoute><MapCartographySM /> </PrivilegedRoute>
    },
    {
        path: '/mapa-impresion-sm-filtros',
        element: <PrivilegedRoute><MapCartographySMF /> </PrivilegedRoute>
    },
    {
        path: '/mapa-impresion-ps',
        element: <PrivilegedRoute><MapCartographyPS /> </PrivilegedRoute>
    },
    {
        path: '/mapa-impresion-mp',
        element: <PrivilegedRoute><MapCartographyMP /> </PrivilegedRoute>
    },
    {
        path: '/mapa-impresion-ms',
        element: <PrivilegedRoute><MapCartographyMS /> </PrivilegedRoute>
    },
    {
        path: '/mapa-impresion-rm',
        element: <PrivilegedRoute><MapCartographyRM /> </PrivilegedRoute>
    },
    {
        path: '/mapa-impresion-rm',
        element: <PrivilegedRoute><MapCartographyRM /> </PrivilegedRoute>
    },
    {
        path: '/mapa-impresion-er',
        element: <PrivilegedRoute><MapCartographyER /> </PrivilegedRoute>
    },
    {
        path: '/mapa-impresion-dlp',
        element: <PrivilegedRoute><MapCartographyDLP /> </PrivilegedRoute>
    },
    {
        path: '/mapa-impresion-dfp',
        element: <PrivilegedRoute><MapCartographyDFP /> </PrivilegedRoute>
    },

    {
        path: '/mapa-impresion-dls',
        element: <PrivilegedRoute><MapCartographyDLS /> </PrivilegedRoute>
    },
    {
        path: '/mapa-impresion-dfs',
        element: <PrivilegedRoute><MapCartographyDFS /> </PrivilegedRoute>
    },
    {
        path: '/mapa-avance-social',
        element: <PrivilegedRoute><MapAdvanceSocial /> </PrivilegedRoute>
    },
    {
        path: '/mapa-social-municipios',
        element: <PrivilegedRoute><MapAdvanceSocialMunicipio /> </PrivilegedRoute>
    },
    {
        path: '/mapa-electoral',
        element: <ProtectedRoute><ElectoralMapNational /> </ProtectedRoute>
    },
    {
        path: '/mapa-prep',
        element: <ProtectedRoute><PrepMap /> </ProtectedRoute>
    },
    
];

export default MapRoutes;
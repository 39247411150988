import { useState } from "react";

// Material UI
import { Grid, ListItem, ListItemText, ListItemButton, Divider } from "@mui/material";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import BasicListDoble from "@components/Lists/BasicListDoble";
import UserInfo from "@components/DiaD/AssignPerfil/UserInfo";

// Utilidades
import { isTypePhone } from "@utils/validations";

// Servicios
import UserService from "@services/UserServices";

const Item = ({ item, index, selected, handleSelected }) => {
  return (
    <>
      <ListItem disablePadding>
        <ListItemButton selected={selected === index} onClick={() => handleSelected(item, index)}>
          <ListItemText primary={`${item.Nombre} ${item.Paterno}`} secondary={item.email} />
        </ListItemButton>
      </ListItem>

      <Divider variant="inset" component="li" />
    </>
  );
};

const AssignPerfil = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Cargando datos...");
  const [userParams1, setUserParams1] = useState({});

  const [userParams2, setUserParams2] = useState({});

  const handleClick1 = (data) => setUserParams1(data);

  const handleClear1 = () => setUserParams1({});

  const handleClick2 = (data) => setUserParams2(data);

  const handleClear2 = () => setUserParams2({});

  return (
    <AdminLayout>
      <LoadingForm
        loadinMessage={loadingMessage}
        isLoading={isLoading}
        isOpen={isLoading}
        setIsOpen={() => {}}
      />

      <ActionHeader title="Usuario Social Espejo" />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4}>
          <BasicListDoble
            id="lista_asignado"
            API={UserService.getUserSocialLibre}
            APIKeys={[
              { id: "CONCAT_WS('', usuarios.Nombre, usuarios.Paterno, usuarios.Materno)", filter: "LIKE" },
              {
                id: "CONCAT_WS('', usuarios.Username, usuarios.Celular, usuarios.Telefono, usuarios.TelMensajes)",
                filter: "LIKE",
              },
            ]}
            header={{ title: "Usuario espejo captura social", icon: "people" }}
            handleClick={handleClick1}
            handleClear={handleClear1}
            label={"Busca a la persona quien va a dar seguimiento a los compromisos"}
            labelNote={
              "Nota: Para realizar una búsqueda debe ingresar un nombre o por el contrario un número de celular."
            }
            itemComponent={Item}
            finder
            inputValidation={{ input2: isTypePhone }}
            doubleSearch
            breaks={{ sm: 6 }}
            buttonsBreaks={{ sm: 3, md: 6 }}
            config={{ height: 250 }}
            clearData
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <BasicListDoble
            id="lista_asignar"
            API={UserService.getUserSocial}
            APIKeys={[
              { id: "CONCAT_WS('', usuarios.Nombre, usuarios.Paterno, usuarios.Materno)", filter: "LIKE" },
              {
                id: "CONCAT_WS('', usuarios.Username, usuarios.Celular, usuarios.Telefono, usuarios.TelMensajes)",
                filter: "LIKE",
              },
            ]}
            /* APIFilter={{
              end: [{ id: " AND usuarios.id in (SELECT idUsuario FROM social_arbol ) ", filterQuery: true }],
            }} */
            header={{ title: "Responsable social", icon: "people" }}
            handleClick={handleClick2}
            handleClear={handleClear2}
            label={"Selecciona al responsable que compartirá sus compromisos"}
            labelNote={
              "Nota: Para realizar una búsqueda debe ingresar un nombre o por el contrario un número de celular."
            }
            itemComponent={Item}
            finder
            inputValidation={{ input2: isTypePhone }}
            doubleSearch
            breaks={{ sm: 6 }}
            buttonsBreaks={{ sm: 3, md: 6 }}
            config={{ height: 250 }}
            clearData
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <UserInfo
            params1={userParams1}
            setParams1={setUserParams1}
            params2={userParams2}
            setParams2={setUserParams2}
            setLoading={setIsLoading}
            setLoadingMessage={setLoadingMessage}
          />
        </Grid>
      </Grid>
    </AdminLayout>
  );
};

export default AssignPerfil;

// Material UI
import { Grid, Box, Button } from "@mui/material";

const ExampleLayout = (props) => {
  const { title, onClick, button, example, page, children } = props;

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={children ? button?.sm : 12}
        md={children ? button?.md : 12}
        lg={children ? button?.lg : 12}
      >
        <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", py: 2 }}>
          <Button variant="outlined" size="large" onClick={onClick} sx={{ fontSize: "18px" }}>
            {title}
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} sm={example?.sm} md={example?.md} lg={example?.lg}>
        {children}
      </Grid>

      <Grid item xs={12}>
        {page}
      </Grid>
    </Grid>
  );
};

export default ExampleLayout;

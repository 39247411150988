import { useState, useEffect } from "react";
import Swal from "sweetalert2";

// Material UI
import { Button, Box, Stack } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

// Componentes
import BasicSelect from "@components/Selects/BasicSelect";
import AdvancedSelect from "@components/Selects/AdvancedSelect";

// Servicios y data
import catalogServ from "@services/CatalogServices";
import { CATALOG_OPTIONS } from "@data/constants/Mobilization";
import { AMBITO_REGIONAL, AMBITO_DF, AMBITO_DL, AMBITO_MPAL } from "@data/constants";

// Utilidades
import { getVars, setVars } from "@utils/global";

const Filter = (props) => {
  const { onFilter, onClear, catView } = props;

  const ambitoUser = catView[0]?.value ?? "";

  const defaultOption = { value: 0, label: "TODOS" };

  const [typeView, setTypeView] = useState(ambitoUser);
  const [catalogMpio, setCatalogMpio] = useState("municipios_reportes");
  const [catalogs, setCatalogs] = useState({});
  const [loadingCatalogs, setLoadingCatalogs] = useState(false);
  const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    Object.keys(CATALOG_OPTIONS).forEach((key) => {
      CATALOG_OPTIONS[key.id] = [];
    });
    return newObject;
  });

  const [filterValue, setFilterValue] = useState({
    region: 0,
    distritoFederal: 0,
    distritoLocal: 0,
    municipio: 0,
    poligono: 0,
    seccion: 0,
  });

  const isIncluded = (value) => value.includes(typeView);

  const getCatalogs = async () => {
    setLoadingCatalogs(true);

    try {
      const result = await catalogServ.getCatalogs(CATALOG_OPTIONS);
      const { results, response, message } = await result;

      if (results) {
        const df = response.catalogs.mpio_df.map((item) => ({
          value: item.idMunicipioReportes,
          label: item.Municipio,
          idDF: item.idDF,
        }));

        const dl = response.catalogs.mpio_dl.map((item) => ({
          value: item.idMunicipioReportes,
          label: item.Municipio,
          idDL: item.idDL,
        }));

        response.catalogs.mpio_df = df;
        response.catalogs.mpio_dl = dl;

        const catalogFilter = concatDefaultOption({ ...catalogsFiltered, ...response.catalogs });

        setCatalogFiltered(catalogFilter);
        setCatalogs(response.catalogs);

        setVars("MobAsignCatalog", response.catalogs);
        setVars("MobAsignCatalogFiltered", catalogFilter);
        setVars("MobAsignFilter", filterValue);
      } else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error, icon: "warning" });
    } finally {
      setLoadingCatalogs(false);
    }
  };

  const concatDefaultOption = (data) => {
    let newObject = {};
    Object.keys(data).forEach((key) => {
      let option = [defaultOption];

      if (data[key]?.length === 1) {
        option = [];
        const value = handleOneCatalog(key);
        setTimeout(() => {
          handleChangeFilter({ name: value, value: value === "seccion" ? data[key][0] : data[key][0].value });
        }, 500);
      }

      return (newObject[key] = [...option, ...data[key]]);
    });
    return newObject;
  };

  const handleChangeFilter = ({ name, value }) => setFilterValue((prev) => ({ ...prev, [name]: value }));

  const handleCatalogFilterChange = ({ filterKey, filterValue, filterType }) => {
    const catalog = catalogs[filterKey].filter((item) => item[filterType] === filterValue);
    const option = catalog.length > 1 ? [defaultOption] : [];

    setCatalogFiltered((prev) => ({ ...prev, [filterKey]: [...option, ...catalog] }));

    if (catalog.length === 1) {
      const key = handleOneCatalog(filterKey);
      setTimeout(() => {
        handleChangeFilter({ name: key, value: key === "seccion" ? catalog[0] : catalog[0].value });
      }, 500);
    }
  };

  const handleCatalogFilterChangePS = ({ filterKey, filterType, filterValue, filterType2, filterValue2 }) => {
    const catalog = catalogs[filterKey].filter(
      (item) => item[filterType] === filterValue && item[filterType2] === filterValue2
    );
    const option = catalog.length > 1 ? [defaultOption] : [];

    setCatalogFiltered((prev) => ({ ...prev, [filterKey]: [...option, ...catalog] }));

    if (catalog.length === 1) {
      setTimeout(() => {
        const key = handleOneCatalog(filterKey);
        handleChangeFilter({ name: key, value: key === "seccion" ? catalog[0] : catalog[0].value });
      }, 500);
    }
  };

  const handleOneCatalog = (key) => {
    switch (key) {
      case "regiones":
        return "region";
      case "df":
        return "distritoFederal";
      case "dl":
        return "distritoLocal";
      case "mpio_df":
      case "mpio_dl":
      case "municipios_reportes":
        return "municipio";
      case "poligonos":
        return "poligono";
      case "secciones":
        return "seccion";
      default:
        return "";
    }
  };

  const handleChangeRegion = (_idRegion) => {
    if (_idRegion > 0) {
      const repeat = { filterType: "idRegion", filterValue: _idRegion };
      handleCatalogFilterChange({ filterKey: "municipios_reportes", ...repeat });
      handleCatalogFilterChange({ filterKey: "poligonos", ...repeat });
      handleCatalogFilterChange({ filterKey: "secciones", ...repeat });

      setFilterValue((prev) => ({ ...prev, municipio: 0, poligono: 0, seccion: 0 }));
    } else {
      setCatalogFiltered(concatDefaultOption({ ...catalogsFiltered, ...catalogs }));
      setFilterValue((prev) => ({ ...prev, municipio: 0, poligono: 0, seccion: 0 }));
    }
  };

  const handleChangeDfs = (_idDfs) => {
    if (_idDfs > 0) {
      setCatalogMpio("mpio_df");
      const repeat = { filterType: "idDF", filterValue: _idDfs };

      handleCatalogFilterChange({ filterKey: "mpio_df", ...repeat });
      handleCatalogFilterChange({ filterKey: "poligonos", ...repeat });
      handleCatalogFilterChange({ filterKey: "secciones", ...repeat });

      setFilterValue((prev) => ({ ...prev, municipio: 0, poligono: 0, seccion: 0 }));
    } else {
      setCatalogMpio("municipios_reportes");
      setCatalogFiltered(concatDefaultOption({ ...catalogsFiltered, ...catalogs }));
      setFilterValue((prev) => ({ ...prev, municipio: 0, poligono: 0, seccion: 0 }));
    }
  };

  const handleChangeDls = (_idDls) => {
    if (_idDls > 0) {
      setCatalogMpio("mpio_dl");
      const repeat = { filterType: "idDL", filterValue: _idDls };

      handleCatalogFilterChange({ filterKey: "mpio_dl", ...repeat });
      handleCatalogFilterChange({ filterKey: "poligonos", ...repeat });
      handleCatalogFilterChange({ filterKey: "secciones", ...repeat });

      setFilterValue((prev) => ({ ...prev, municipio: 0, poligono: 0, seccion: 0 }));
    } else {
      setCatalogMpio("municipios_reportes");
      setCatalogFiltered(concatDefaultOption({ ...catalogsFiltered, ...catalogs }));
      setFilterValue((prev) => ({ ...prev, municipio: 0, poligono: 0, seccion: 0 }));
    }
  };

  const handleChangeMunicipality = (_idMunicipality) => {
    if (_idMunicipality > 0) {
      let repeat = {};
      let isEmpty = false;

      if (ambitoUser !== AMBITO_MPAL) {
        // Verifica si existen
        if (filterValue.region || filterValue.distritoFederal || filterValue.distritoLocal) {
          switch (typeView) {
            case AMBITO_REGIONAL:
              repeat = { filterType: "idRegion", filterValue: filterValue.region };
              break;
            case AMBITO_DF:
              repeat = { filterType: "idDF", filterValue: filterValue.distritoFederal };
              break;
            case AMBITO_DL:
              repeat = { filterType: "idDL", filterValue: filterValue.distritoLocal };
              break;
            default:
              repeat = { filterType: "idRegion", filterValue: filterValue.region };
              break;
          }
        } else isEmpty = true;
      }

      if (isEmpty) {
        // Si es vacio solo filtra por el municipio
        const repeat2 = { filterType: "idMunicipioReportes", filterValue: _idMunicipality };

        handleCatalogFilterChange({ filterKey: "poligonos", ...repeat2 });
        handleCatalogFilterChange({ filterKey: "secciones", ...repeat2 });
      } else {
        // Si NO es vacio solo filtra por ambos
        const repeat2 = { filterType2: "idMunicipioReportes", filterValue2: _idMunicipality };
        handleCatalogFilterChangePS({ filterKey: "poligonos", ...repeat, ...repeat2 });
        handleCatalogFilterChangePS({ filterKey: "secciones", ...repeat, ...repeat2 });
      }
      setFilterValue((prev) => ({ ...prev, poligono: 0, seccion: 0 }));
    } else {
      let repeat = "";
      let filterKey = "municipios_reportes";
      let isEmpty = false;

      if (ambitoUser !== AMBITO_MPAL && typeView !== AMBITO_MPAL) {
        switch (typeView) {
          case AMBITO_REGIONAL:
            repeat = { filterType: "idRegion", filterValue: filterValue.region };
            filterKey = "municipios_reportes";
            isEmpty = filterValue.region === 0;
            break;
          case AMBITO_DF:
            repeat = { filterType: "idDF", filterValue: filterValue.distritoFederal };
            filterKey = "mpio_df";
            isEmpty = filterValue.distritoFederal === 0;
            break;
          case AMBITO_DL:
            repeat = { filterType: "idDL", filterValue: filterValue.distritoLocal };
            filterKey = "mpio_dl";
            isEmpty = filterValue.distritoLocal === 0;
            break;
          default:
            break;
        }

        if (!isEmpty) {
          // Si NO es vacio el ambito filtra por el tipo que corresponda
          handleCatalogFilterChange({ filterKey: filterKey, ...repeat });
          handleCatalogFilterChange({ filterKey: "poligonos", ...repeat });
          handleCatalogFilterChange({ filterKey: "secciones", ...repeat });
        } else {
          // Si es vacio el ambito pone el catalogo inicial
          setCatalogFiltered((prev) => ({
            ...prev,
            [filterKey]: [defaultOption, ...catalogs[filterKey]],
            poligonos: [defaultOption, ...catalogs["poligonos"]],
            secciones: [defaultOption, ...catalogs["secciones"]],
          }));
        }
      } else {
        setCatalogFiltered((prev) => ({
          ...prev,
          [filterKey]: [defaultOption, ...catalogs[filterKey]],
          poligonos: [defaultOption, ...catalogs["poligonos"]],
          secciones: [defaultOption, ...catalogs["secciones"]],
        }));
      }

      setFilterValue((prev) => ({ ...prev, poligono: 0, seccion: 0 }));
    }
  };

  const handleChangePolygon = (_idPolygon) => {
    if (_idPolygon > 0) {
      handleCatalogFilterChange({
        filterKey: "secciones",
        filterType: "idPoligono",
        filterValue: _idPolygon,
      });

      setFilterValue((prev) => ({ ...prev, seccion: 0 }));
    } else {
      let repeat = "";
      let isEmpty = "";

      switch (typeView) {
        case AMBITO_REGIONAL:
          repeat = { filterType: "idRegion", filterValue: filterValue.region };
          isEmpty = filterValue.region === 0;
          break;
        case AMBITO_DF:
          repeat = { filterType: "idDF", filterValue: filterValue.distritoFederal };
          isEmpty = filterValue.distritoFederal === 0;
          break;
        case AMBITO_DL:
          repeat = { filterType: "idDL", filterValue: filterValue.distritoLocal };
          isEmpty = filterValue.distritoLocal === 0;
          break;
        default:
          break;
      }

      // Ve si hay algun ambito o municipio seleccionados
      if (filterValue.municipio > 0 && !isEmpty) {
        // Filtra por ambos
        const repeat2 = { filterType2: "idMunicipioReportes", filterValue2: filterValue.municipio };
        handleCatalogFilterChangePS({ filterKey: "poligonos", ...repeat, ...repeat2 });
        handleCatalogFilterChangePS({ filterKey: "secciones", ...repeat, ...repeat2 });
      } else if (filterValue.municipio > 0) {
        // Filtra solo por municipio
        const repeat2 = { filterType: "idMunicipioReportes", filterValue: filterValue.municipio };
        handleCatalogFilterChange({ filterKey: "poligonos", ...repeat2 });
        handleCatalogFilterChange({ filterKey: "secciones", ...repeat2 });
      } else if (!isEmpty) {
        // Filtra solo por el primer select
        handleCatalogFilterChange({ filterKey: "poligonos", ...repeat });
        handleCatalogFilterChange({ filterKey: "secciones", ...repeat });
      } else {
        // No filtra
        setCatalogFiltered((prev) => ({
          ...prev,
          poligonos: [defaultOption, ...catalogs["poligonos"]],
          secciones: [defaultOption, ...catalogs["secciones"]],
        }));
      }

      setFilterValue((prev) => ({ ...prev, seccion: 0 }));
    }
  };

  const handleChangeSection = (_idSection) => {};

  const handleSearch = () => {
    setVars("MobAsignFilter", filterValue);
    onFilter({ id: "Seccion", filter: "=", value: filterValue.seccion });
  };

  const handleClear = () => {
    const filter = {
      region: 0,
      distritoFederal: 0,
      distritoLocal: 0,
      municipio: 0,
      poligono: 0,
      seccion: 0,
    };

    setFilterValue(filter);
    setVars("MobAsignFilter", filter);
    setCatalogFiltered(concatDefaultOption({ ...catalogsFiltered, ...catalogs }));
    onClear();
  };

  useEffect(() => {
    const filter = getVars("MobAsignFilter", "");

    if (filter === "") getCatalogs();
    else {
      const catalog = getVars("MobAsignCatalog", "");
      const catalogFiltered = getVars("MobAsignCatalogFiltered", "");
      setCatalogs(catalog);
      setCatalogFiltered(catalogFiltered);
      setFilterValue(filter);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (catalogsFiltered?.secciones) setVars("MobAsignCatalogFiltered", catalogsFiltered);
    // eslint-disable-next-line
  }, [catalogsFiltered]);

  useEffect(() => {
    const values = [AMBITO_REGIONAL, AMBITO_DF, AMBITO_DL, AMBITO_MPAL];
    const filtered = catView.filter((item) => values.includes(item.value));

    setTypeView(filtered[0]?.value ?? "");

    if (getVars("MobAsignFilter", "") === "") {
      setFilterValue({
        region: 0,
        distritoFederal: 0,
        distritoLocal: 0,
        municipio: 0,
        poligono: 0,
        seccion: 0,
      });
    }
    // eslint-disable-next-line
  }, [catView]);

  return (
    <Box sx={{ mt: 1, paddingX: 2 }}>
      <Grid2 container marginTop={1} spacing={2}>
        {isIncluded([AMBITO_REGIONAL]) && (
          <Grid2 xs={12} sm={6} md={4} lg={3}>
            <BasicSelect
              name="region"
              label="Región"
              options={catalogsFiltered.regiones}
              value={filterValue.region}
              onChange={(e) => {
                handleChangeFilter({ name: e.target.name, value: e.target.value });
                handleChangeRegion(e.target.value);
              }}
              isLoading={loadingCatalogs}
              sx={{ width: "100%" }}
              disabled={catalogsFiltered.regiones?.length <= 1}
            />
          </Grid2>
        )}

        {isIncluded([AMBITO_DF]) && (
          <Grid2 xs={12} sm={6} md={4} lg={3}>
            <BasicSelect
              name="distritoFederal"
              label="Distrito Federal"
              options={catalogsFiltered.df}
              value={filterValue.distritoFederal}
              onChange={(e) => {
                handleChangeFilter({ name: e.target.name, value: e.target.value });
                handleChangeDfs(e.target.value);
              }}
              isLoading={loadingCatalogs}
              sx={{ width: "100%" }}
              disabled={catalogsFiltered.df?.length <= 1}
            />
          </Grid2>
        )}

        {isIncluded([AMBITO_DL]) && (
          <Grid2 xs={12} sm={6} md={4} lg={3}>
            <BasicSelect
              name="distritoLocal"
              label="Distrito Local"
              options={catalogsFiltered.dl}
              value={filterValue.distritoLocal}
              onChange={(e) => {
                handleChangeFilter({ name: e.target.name, value: e.target.value });
                handleChangeDls(e.target.value);
              }}
              isLoading={loadingCatalogs}
              sx={{ width: "100%" }}
              disabled={catalogsFiltered.dl?.length <= 1}
            />
          </Grid2>
        )}

        {isIncluded([AMBITO_REGIONAL, AMBITO_DF, AMBITO_DL, AMBITO_MPAL]) && (
          <Grid2 xs={12} sm={6} md={4} lg={3}>
            <BasicSelect
              name="municipio"
              label="Municipio"
              options={catalogsFiltered[catalogMpio] ?? []}
              value={filterValue.municipio}
              onChange={(e) => {
                handleChangeFilter({ name: e.target.name, value: e.target.value });
                handleChangeMunicipality(e.target.value);
              }}
              isLoading={loadingCatalogs}
              sx={{ width: "100%" }}
              disabled={catalogsFiltered[catalogMpio]?.length <= 1}
            />
          </Grid2>
        )}

        {isIncluded([AMBITO_REGIONAL, AMBITO_DF, AMBITO_DL, AMBITO_MPAL]) && (
          <Grid2 xs={12} sm={6} md={2} lg={3}>
            <AdvancedSelect
              name="poligono"
              label="Polígono"
              options={catalogsFiltered.poligonos}
              value={filterValue.poligono}
              onChange={(e) => {
                handleChangeFilter({ name: e.name, value: e.value });
                handleChangePolygon(e.value);
              }}
              isSearchable
              isLoading={loadingCatalogs}
              disabled={catalogsFiltered.poligonos?.length <= 1}
            />
          </Grid2>
        )}

        {isIncluded([AMBITO_REGIONAL, AMBITO_DF, AMBITO_DL, AMBITO_MPAL]) && (
          <Grid2 xs={12} sm={6} md={2} lg={3}>
            <AdvancedSelect
              name="seccion"
              label="Sección"
              value={filterValue.seccion}
              options={catalogsFiltered.secciones}
              onChange={(e) => {
                handleChangeFilter({ name: e.name, value: e.value });
                handleChangeSection(e.value);
              }}
              isSearchable
              isLoading={loadingCatalogs}
              disabled={catalogsFiltered.secciones?.length <= 1}
            />
          </Grid2>
        )}
      </Grid2>

      <Stack direction={"row"} spacing={1} marginTop={2} justifyContent={"flex-end"}>
        <Button variant="outlined" color="primaryDark" size="small" onClick={handleClear}>
          Limpiar
        </Button>
        <Button
          variant="contained"
          color="primaryDark"
          size="small"
          onClick={handleSearch}
          disabled={filterValue.seccion === 0 || filterValue.seccion === ""}
        >
          Filtrar
        </Button>
      </Stack>
    </Box>
  );
};

export default Filter;

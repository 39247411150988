import { useState, useEffect } from "react";
import Swal from "sweetalert2";

// Material UI
import {
  Box,
  Button,
  Icon,
  ButtonGroup,
  Tooltip,
  Chip,
  IconButton,
  Alert,
  Typography,
} from "@mui/material";
import { orange } from "@mui/material/colors";

// Componentes
import CustomTable from "@components/Tables/CustomTable";
import Download from "@components/Downloads/Download";

// Servicios, Data y Middleware
import MovilizationServices from "@services/MovilizationServices";
import middleware from "@middlewares/middleware";
import {
  COLUMNS_CAPTURE_MOBILIZATION_REPORTED,
  ACTIONS_SPEED,
} from "@data/constants/Mobilization";

const ListReported = ({ dataParams }) => {
  const canExport = middleware.checkMenuAction("Exportar");

  const [loading, setLoading] = useState(false);
  const [isDownload, setIsDownload] = useState(false);

  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);

  const exportMovSummary = async () => {
    setIsDownload(true);

    const exportParams = {
      filtered: dataParams.filtered,
      sorted: dataParams.sorted,
    };

    try {
      const res = await MovilizationServices.exportMovSummary(exportParams);
      const { results, success, message } = res;

      if (results && success) Swal.fire({ title: message, icon: "success" });
      else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setIsDownload(false);
    }
  };

  const getMovSummary = async (value) => {
    setLoading(true);

    const params = { filtered: value.filtered };

    try {
      const res = await MovilizationServices.getMovSummary(params);
      const { results, message, response, success } = res;

      if (results && success) {
        setRows(
          response.data.map((item) => {
            return {
              ...item,
              Contacto: (
                <ButtonGroup>
                  {ACTIONS_SPEED.map(
                    (action) =>
                      item[action.key] && (
                        <Tooltip
                          key={action.key}
                          title={item[action.key]}
                          placement="left"
                        >
                          <IconButton
                            size="small"
                            href={
                              action.link &&
                              `${action.link}${item[action.key]}&text=Hola%20*${
                                item.NombreCompleto
                              }*`
                            }
                            target="_blank"
                          >
                            {action.icon}
                          </IconButton>
                        </Tooltip>
                      )
                  )}
                </ButtonGroup>
              ),
              Nombre:
                item.TienePaseLista === "SI" ? (
                  item.Nombre.toUpperCase()
                ) : (
                  <Tooltip title="NO TIENE PASE DE LISTA">
                    <Chip
                      size="small"
                      icon={
                        <Icon
                          fontSize="small"
                          sx={{ color: "#e65100 !important" }}
                        >
                          error
                        </Icon>
                      }
                      label={item.Nombre.toUpperCase()}
                      variant="contained"
                      sx={{ color: orange[900], backgroundColor: orange[100] }}
                    />
                  </Tooltip>
                ),
            };
          })
        );
        setTotal(response.total);
      } else throw new Error(message);
    } catch (error) {
      setRows([]);
      setTotal(0);
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const { filtered } = dataParams;
    if (filtered.length && filtered[0]?.id === "idMunicipioReportes") {
      getMovSummary(dataParams);
    }
  }, [dataParams]);

  return (
    <>
      {rows.length > 0 && (
        <>
          {canExport && (
            <Alert
              severity="error"
              color="warning"
              sx={{
                display: "flex",
                alignItems: "center",
                display:
                  rows.length > 0 &&
                  rows.some((item) => item.TienePaseLista === "NO")
                    ? "flex"
                    : "none",
              }}
            >
              Advertencia. Si el responsable tiene el símbolo{" "}
              {
                <Icon fontSize="small" color="error">
                  error
                </Icon>
              }{" "}
              aún no ha realizado el pase de lista.
            </Alert>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: canExport ? "column" : "column-reverse",
                sm: "row",
              },
              justifyContent: "space-between",
              alignItems: { xs: "center", sm: "end" },
              gap: 2,
              px: 2,
              my: 1,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 0.4 }}>
              <Typography fontWeight={600} variant="subtitle2">
                REPORTADOS
              </Typography>

              <Chip
                label={total}
                color="primary"
                size="small"
                icon={<Icon>check_circle</Icon>}
              />
            </Box>

            {!canExport && (
              <Alert
                severity="error"
                color="warning"
                sx={{ display: "flex", alignItems: "center" }}
              >
                Advertencia. Si el responsable tiene el símbolo{" "}
                {
                  <Icon fontSize="small" color="error">
                    error
                  </Icon>
                }{" "}
                aún no ha realizado el pase de lista.
              </Alert>
            )}

            {canExport && (
              <Button
                onClick={exportMovSummary}
                variant="outlined"
                endIcon={<Icon>download</Icon>}
                sx={{
                  minWidth: { xs: "100%", sm: "fit-content" },
                  height: "fit-content",
                  px: 2,
                }}
              >
                Exportar
              </Button>
            )}
          </Box>
        </>
      )}

      {isDownload && (
        <Box marginBottom={2}>
          <Download format={"xlsx"} isDownload={isDownload} />
        </Box>
      )}

      <CustomTable
        rows={rows}
        columns={COLUMNS_CAPTURE_MOBILIZATION_REPORTED}
        total={total}
        pageSize={9999999999}
        isLoading={loading}
        stickyHeader
        maxHeight={"calc(100vh - 180px)"}
        showPagination={false}
      />
    </>
  );
};

export default ListReported;

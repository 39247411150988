import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useCatalogs } from "@hooks/useCatalogs";

import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Icon,
  Typography,
  TextField,
} from "@mui/material";

import BasicSelect from "@components/Selects/BasicSelect";
import AdvancedSelect from "@components/Selects/AdvancedSelect";

import mov from "@services/MovilizationServices";
import Swal from "sweetalert2";

import { BingoFolioInterface } from "@data/interfaces/MovilizationInterfaces";
import {
  BingoFolioSchema,
  handleKeyPress,
  handleKeyPress2,
  UpdateInfo,
} from "@data/schemas/MovilizationSchemas";
import { isEmptyOrInvalidString } from "@utils/validations";

// Utilidades
import { getVars, setVars } from "@utils/global";

const BingoFolioFilter = ({
  onChange,
  setPassCasilla,
  onClear = () => {},
  showAll = true,
}) => {
  const localStorage = getVars("filter");

  //CONSTANTS
  const catalogsOptions = [
    { id: "municipios_reportes"},
    { id: "poligonos"},
    { id: "secciones"},
  ];

  const StatesOptions = [
    // { value: 0, label: "TODOS" },
    { value: 1, label: "Captura" },
    { value: 2, label: "Finalizado" },
  ];

  const formik = useFormik({
    initialValues: BingoFolioInterface,
    validationSchema: showAll ? BingoFolioSchema : UpdateInfo,
    onSubmit: (values, actions) => {
      onChange({ ...values, Seccion: parseInt(values.Seccion) });
      setVars("filter", {
        Seccion: parseInt(values.Seccion),
        idCasilla: parseInt(values.idCasilla),
        labelSeccion: values.labelSeccion,
        idEstatus: values.idEstatus,
        RangoInicial: values.RangoInicial.toUpperCase(),
        RangoFinal: values.RangoFinal.toUpperCase(),
      });
    },
  });
  //States
  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsOptions,
    putDefaultOption: false,
  });
  const [catalogsFiltered, setCatalogFiltered] = useState({});
  const [loadingCasillas, setLoadingCasillas] = useState(false);
  const [casillasOptions, setCasillasOptions] = useState([]);

  const validateSeccion = async (value) => {
    try {
      setLoadingCasillas(true);
      const result = await mov.validateSection(value);
      const { results, message, response } = result;
      if (results) {
        let casillas = response.casillas.map((item) => ({
          value: item.idCasilla,
          label: item.NombreCasilla,
        }));
        setCasillasOptions(casillas);
        setPassCasilla(casillas);

        if (
          Object.keys(localStorage).length > 0 &&
          localStorage.Seccion === value && casillas.some(item => item.value === localStorage.idCasilla)
        ) {
          const selected = localStorage.idCasilla;
          formik.setFieldValue('idCasilla', selected);
          onChange({ ...formik.values, ...localStorage });
        }
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        iconc: "warning",
      });
    } finally {
      setLoadingCasillas(false);
    }
  };

  const handleChangeMunicipality = (e) => {
    const _idMunicipality = e.target.value;
    setCatalogFiltered((prevState) => ({
      ...prevState,
      Poligono: catalogs.poligonos.filter(
        ({ idMunicipioReportes }) => idMunicipioReportes === _idMunicipality
      ),
      secciones: catalogs.secciones.filter(
        ({ idMunicipio }) => idMunicipio === _idMunicipality
      ),
    }));
    formik.setValues((prevState) => ({
      ...prevState,
      Municipio: _idMunicipality,
      Seccion: "",
      Poligono: "",
      idCasilla: "",
    }));
  };

  const handleChangePolygon = (e) => {
    const _idPolygon = e.target.value;
    setCatalogFiltered((prevState) => ({
      ...prevState,
      secciones: catalogs.secciones.filter(
        ({ idPoligono }) => idPoligono === _idPolygon
      ),
    }));
    formik.setValues((prevState) => ({
      ...prevState,
      Poligono: _idPolygon,
      Seccion: "",
      idCasilla: "",
    }));
  };

  const handleChangeSeccion = (e) => {
    const value = e.value;
    const label = e.label;
    formik.setValues((prevState) => ({
      ...prevState,
      Seccion: value,
      labelSeccion: String(label),
      idCasilla: "",
    }));
    if (!isEmptyOrInvalidString(value)) {
      validateSeccion(value);
    }
  };

  const handleChangeBox = async (e) => {
    const value = e.target.value;
    formik.setValues((prevState) => ({
      ...prevState,
      idCasilla: value,
      //idEstatus: 2,
    }));
  };

  const handleChangeNombre = (e) => {
    const value = e.target.value;
    formik.setValues((prev) => ({
      ...prev,
      Nombre: value,
    }));
  };

  const handleChangeState = (e) => {
    const value = e.target.value;
    formik.setValues((prev) => ({
      ...prev,
      idEstatus: value,
    }));
  };

  const handleKeyPressSearch = (event) => {
    const isEnterKey = event.key === "Enter";
    const isTabKey = event.key === "Tab";
    if (event.type === "keydown" && (isEnterKey || isTabKey)) {
      const value = event.target.value;
      if (!isEmptyOrInvalidString(value)) {
        validateSeccion(value);
      }
    }
  };

  const handleClear = () => {
    setCatalogFiltered(catalogs);
    formik.setValues((values) => ({
      ...values,
      Seccion: "",
      idCasilla: "",
      Municipio: "",
      RangoInicial: "",
      RangoFinal: "",
      Poligono: "",
      idUsuarioReporteMov: "",
      Nombre: "",
      idEstatus: 1,
    }));
    onClear();
  };

  useEffect(() => {
    if (load === false) {
      setCatalogFiltered(catalogs);
      formik.setValues((values) => ({
        ...values,
        idEstatus: 1,
      }));
    }
    // eslint-disable-next-line
  }, [load]);

  useEffect(() => {
    if (Object.keys(localStorage).length > 0) {
      formik.setValues((prev) => ({
        ...prev,
        Seccion: localStorage.Seccion,
        idCasilla: localStorage.idCasilla,
        labelSeccion: localStorage.labelSeccion,
        idEstatus: localStorage.idEstatus,
        RangoInicial: localStorage.RangoInicial,
        RangoFinal: localStorage.RangoFinal,
      }));
      validateSeccion(localStorage.Seccion);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Card className="card-primary">
      <CardContent>
        <Box component={"div"} marginBottom={2}>
          <Typography variant="body2" fontWeight={600}>
            FILTRO
          </Typography>
          <Divider />
        </Box>
        <Box component={"div"} marginBottom={2}>
          {showAll && (
            <Box>
              <BasicSelect
                name="Municipio"
                label="Municipio"
                options={catalogsFiltered.municipios_reportes}
                value={formik.values.Municipio}
                onChange={handleChangeMunicipality}
                isLoading={load}
                sx={{ width: "100%", mb: 3, mt: 3 }}
                error={Boolean(formik.errors.Municipio)}
                errorMessage={formik.errors.Municipio}
              />

              <BasicSelect
                name="Poligono"
                label="Polígono"
                options={catalogsFiltered.Poligono}
                value={formik.values.Poligono}
                onChange={handleChangePolygon}
                isLoading={load}
                sx={{ width: "100%", mb: 3 }}
                error={Boolean(formik.errors.Poligono)}
                errorMessage={formik.errors.Poligono}
              />
            </Box>
          )}

          <AdvancedSelect
            name="Seccion"
            label="Sección"
            value={formik.values.Seccion}
            options={catalogsFiltered?.secciones}
            onChange={handleChangeSeccion}
            onKeyDown={handleKeyPressSearch}
            onKeyPress={handleKeyPress}
            isSearchable
            isLoading={load}
            error={Boolean(formik.errors.Seccion)}
            helperText={formik.errors.Seccion}
            sx={{ width: "100%", mb: 3, mt: !showAll ? 3 : null }}
          />

          <BasicSelect
            disabled={isEmptyOrInvalidString(formik.values.Seccion) || loadingCasillas}
            name="idCasilla"
            label="Seleccione una casilla"
            options={casillasOptions}
            value={formik.values.idCasilla}
            onChange={handleChangeBox}
            size="small"
            sx={{ width: "100%", mb: 3 }}
            isLoading={loadingCasillas}
            error={Boolean(formik.errors.idCasilla)}
            errorMessage={formik.errors.idCasilla}
          />

          <BasicSelect
            name="idEstatus"
            label="Estatus"
            options={StatesOptions}
            value={formik.values.idEstatus}
            onChange={handleChangeState}
            size="small"
            sx={{ width: "100%", mb: 3 }}
            error={Boolean(formik.errors.idEstauts)}
            errorMessage={formik.errors.idEstatus}
          />

          <TextField
            name="RangoInicial"
            label="Rango alfabeto inicial"
            size="small"
            value={formik.values.RangoInicial?.toUpperCase()}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.RangoInicial)}
            helperText={formik.errors.RangoInicial}
            sx={{ width: "100%", mb: 3 }}
          />

          <TextField
            name="RangoFinal"
            label="Rango alfabeto final"
            size="small"
            value={formik.values.RangoFinal?.toUpperCase()}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.RangoFinal)}
            helperText={formik.errors.RangoFinal}
            sx={{ width: "100%", mb: 3 }}
          />

          {!showAll && (
            <Box>
              <TextField
                label="Nombre"
                type="text"
                name="Nombre"
                placeholder="Paterno Materno Nombre"
                onChange={handleChangeNombre}
                onKeyPress={handleKeyPress2}
                value={formik.values.Nombre.toUpperCase()}
                size="small"
                variant="outlined"
                fullWidth
                required
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(formik.errors.Nombre)}
                helperText={formik.errors.Nombre}
              />
            </Box>
          )}
        </Box>

        <Box marginBottom={2}>
          <Button
            variant="contained"
            color="primaryDark"
            size="small"
            startIcon={<Icon>search</Icon>}
            onClick={formik.handleSubmit}
            sx={{ width: "100%", mb: 1 }}
          >
            buscar
          </Button>
          <Button
            variant="outlined"
            color="primaryDark"
            size="small"
            startIcon={<Icon>search_off</Icon>}
            onClick={handleClear}
            sx={{ width: "100%" }}
          >
            Limpiar
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default BingoFolioFilter;

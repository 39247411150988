export const EventoInterface = {
  imgURL: "",
  imgMovilURL: "",
  files: [],

  idPartido: 1, // No se usa en front
  idAmbito: 5,
  AmbitoValor: 1,

  idTipoActividad: 7,
  Actividad: "",
  Descripcion: "",
  FechaInicio: "",
  FechaFin: "",

  idModalidad: 1,
  NombreLugar: "",
  Calle: "",
  NumExt: "",
  NumInt: "",
  Colonia: "",
  idMunicipio: 1,
  LigaVirtual: "",

  esConcurrente: false,
  diasConcurrentes: [],

  tienePreguntas: false,
  preguntas: [],

  TienePreregistro: 1,
  FechaFinPreregistro: "",

  coordenadas: { latitud: null, longitud: null },

  /* // No se usan en front
  
  LinkPreregistro: null,
  textoPreregistro: null,

  isPrivado: null,
  idModoEvento: null,
  TieneAsistenciaQR: null,
  shortUrlAsistencia: null,
  */
};

export const EventoEditInterface = {
  idEvento: 96,
  idEstatus: 1,

  imgURL: "",
  imgMovilURL: "",
  files: [],

  idPartido: 1, // No se usa en front
  idAmbito: 5,
  AmbitoValor: 1,

  idTipoActividad: 7,
  Actividad: "",
  Descripcion: "",
  FechaInicio: "",
  FechaFin: "",

  idModalidad: 1,
  NombreLugar: "",
  Calle: "",
  NumExt: "",
  NumInt: "",
  Colonia: "",
  idMunicipio: 1,
  LigaVirtual: "",

  tienePreguntas: false,
  preguntas: [],

  TienePreregistro: 1,
  FechaFinPreregistro: "",

  coordenadas: { latitud: null, longitud: null },
};

export const EventoPreguntasInterface = {
  idEvento: 0,
  preguntas: [],
};

export const EventosFilter = {
  actividad: "",
};

export const EventosInvitadosFilter = {
  celular: "",
};

export const CompromisoEventInterface = {
  isMenor: 0,
  isUsuario: 0,
  Password: "",
  Confirm: "",
  PIN: "",
  idUsuarioPropietario: "",
  idPartido: 1,
  INE: "",
  CURP: "",
  VigenciaINE: "",
  Nombre: "",
  Paterno: "",
  Materno: "",
  FechaNacimiento: "",
  idSexo: "",
  idEstadoNacimiento: "",
  CalleVive: "",
  TipoVialidad: "C",
  NumExtVive: "",
  NumIntVive: "",
  idMunicipioVive: "",
  idLocalidadVive: "",
  ColoniaVive: "",
  CPVive: "",
  SeccionVota: "",
  Celular: "",
  Telefono: "",
  TelMensajes: "",
  Correo: "",
  Facebook: "",
  Instagram: "",
  Twitter: "",
  TikTok: "",
  EsVoluntario: 1,
  idVoluntario: 1,
  TieneAuto: "",
  TieneExperiencia: "",
  AceptaCuidarOtra: "",
  Segmentos: [],
  idMedioContacto: 2,
  idEvento: "",
  token: "",
  answers: [],
};

import ApiExec, { ApiExecDecrypted } from "@utils/ApiExec";

class PpmServices {
  personInfo = (params) => {
    return ApiExec(params, "ppm/get-AboutUs", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  setPersonInfo = (params) => {
    return ApiExecDecrypted(params, "ppm/set-person-AboutUs", "POST", false)
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  updatePersonInfo = (params) => {
    return ApiExecDecrypted(params, "ppm/update-person-AboutUs", "PUT", false)
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
}

const compromiso = new PpmServices();
export default compromiso;

import { useEffect, useState } from "react";
import Swal from "sweetalert2";
//Mui
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Button,
  ButtonGroup,
} from "@mui/material";

//Components
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import Filter from "@components/Results/Filter";
import TableResultados from "../../components/Results/TableResultados";
import DrawerRelative from "@components/Containers/DrawerRelative";
import FloatingButton from "@components/Button/FloatingButton";

import result from "@services/ResultServices";
import {
  AMBITO_CASILLA,
  AMBITO_DF,
  AMBITO_DL,
  AMBITO_MPAL,
  AMBITO_ESTATAL,
} from "@data/constants";
import {
  ELECCION_DIPUTADO_FEDERAL,
  ELECCION_DIPUTADO_LOCAL,
  ELECCION_PRESIDENTE_MUNICIPAL,
} from "@data/constants/Prep";
import TableResumen from "../../components/Results/TableResumen";
import RankingCard from "../../components/Results/RankingCard";
import TableInfo from "../../components/Results/TableInfo";

const ResultPartido = () => {
  const [loadingCards, setLoadingCards] = useState(true);
  const [tableTitle, setTableTitle] = useState("");
  const [params, setParams] = useState({
    idTipoEleccion:0,
    idAmbitoAgrupador:0
  });
  const [data,setData] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(true);
  const [dataTable, setDataTable] = useState({
    Presidente: [],
    Sindico:[],
    Regidor:[]
  });
  const [paramsResumen/* , setParamsResumen */] = useState({
    page: 0,
    pageSize: 9999999,
  });
  const [expanded, setExpanded] = useState(1);

  let columns = [
    { id: "Partido", label: "Presidente Municipal", styleFlag: true },
    { id: "resultado_2021", label: "2021" },
    { id: "resultado_2024", label: "2024" },
    { id: "diferencia", label: "Diferencia" },
    {
      id: "estado",
      label: "",
      Type: "estatus",
    },
  ];
  let columnsSindicos = [
    { id: "Partido", label: "Síndicos", styleFlag: true  },
    { id: "resultado_2021", label: "2021" },
    { id: "resultado_2024", label: "2024" },
    { id: "diferencia", label: "Diferencia" },
    {
      id: "estado",
      label: "",
      Type: "estatus",
    },
  ];
  let columnsRegidor = [
    { id: "Partido", label: "Regidores", styleFlag: true  },
    { id: "resultado_2021", label: "2021" },
    { id: "resultado_2024", label: "2024" },
    { id: "diferencia", label: "Diferencia" },
    {
      id: "estado",
      label: "",
      Type: "estatus",
    },
  ];

  const buttons = [
    {
      id: 1,
      button: (
        <Button
          key="Calendario"
          variant={expanded === 1 ? "contained" : "outlined"}
          onClick={() => handleChange(1)}
        >
          PRESIDENTE MUNICIPAL
        </Button>
      ),
      container: <TableResumen columns={columns} rows={dataTable.Presidente} Height={582}/>,
    },
    {
      id: 2,
      button: (
        <Button
          key="ListaEventos"
          variant={expanded === 2 ? "contained" : "outlined"}
          onClick={() => handleChange(2)}
        >
          SINDICATO
        </Button>
      ),
      container: <TableResumen columns={columnsSindicos} rows={dataTable.Sindico} Height={582}/>
    
    },
    {
      id: 3,
      button: (
        <Button
          key="ListaEventos"
          variant={expanded === 3 ? "contained" : "outlined"}
          onClick={() => handleChange(3)}
        >
          REGIDOR
        </Button>
      ),
      container: (
        <TableResumen columns={columnsRegidor} rows={dataTable.Regidor} Height={582}/>
      ),
    },
  ];

  useEffect(()=>{},[data]);

  const handleChange = (e) => setExpanded(e);

  const handleChangeFilter = (filtered) => {
    if (!filtered.idTipoEleccion || !filtered.idAmbitoAgrupador) {
      return Swal.fire({
        icon: "info",
        title: "Parámetros insuficiente",
      });
    }

    let params = {
      ...filtered,
      idTipoVista: filtered.idTipoVista ? filtered.idTipoVista : 1,
      ValorAmbito: filtered.ValorAmbito ? filtered.ValorAmbito : 0,
    };

    setParams(params);
    handleTableTitle(filtered.idAmbitoAgrupador);

    if (filtered.idAmbitoAgrupador === AMBITO_CASILLA) {
      if (filtered.ValorAmbito === 0) {
        switch (filtered.idTipoEleccion) {
          case ELECCION_DIPUTADO_FEDERAL:
            getChart({
              ...params,
              idAmbitoAgrupador: AMBITO_DF,
            });
            break;
          case ELECCION_DIPUTADO_LOCAL:
            getChart({
              ...params,
              idAmbitoAgrupador: AMBITO_DL,
            });
            break;
          case ELECCION_PRESIDENTE_MUNICIPAL:
            getChart({
              ...params,
              idAmbitoAgrupador: AMBITO_MPAL,
            });
            break;
          default:
            getChart({
              ...params,
              idAmbitoAgrupador: AMBITO_ESTATAL,
            });
            break;
        }
      } else {
        getChart(params);
      }
    } else {
      getChart(params);
    }
  };

  const handleTableTitle = (idAmbitoAgrupador) => {
    switch (idAmbitoAgrupador) {
      case AMBITO_DF:
        setTableTitle("Mostrando detalle por distrito federal");
        break;
      case AMBITO_DL:
        setTableTitle("Mostrando detalle por distrito local");
        break;
      case AMBITO_MPAL:
        setTableTitle("Mostrando detalle por municipio");
        break;
      case AMBITO_CASILLA:
        setTableTitle("Mostrando detalle por casilla");
        break;
      default:
        setTableTitle("");
        break;
    }
  };

  const getChart = () => {
    setLoadingCards(true);
    result
      .getResult(paramsResumen)
      .then((res) => {
        const { results, response, message } = res;
        if (results) {
          setDataTable(response);
        } else {
          setDataTable([]);
          Swal.fire({
            title: message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        setDataTable([]);
        Swal.fire({
          title: error.message,
          icon: "warning",
        });
      })
      .finally(() => {
        setLoadingCards(false);
      });
  };


  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title="Resultados Electorales" />
        <Grid
          container
          spacing={2}
          sx={{ flexDirection: { xs: "column-reverse", md: "row" }, mt: 2 }}
        >
          <Grid item xs={12} md={openDrawer ? 9 : 12}>
            <Grid container spacing={2}>
              {
                ((params.idTipoEleccion === 4 && params.idTipoVista === 1) || (params.idTipoVista === 1 && params.ValorAmbito > 0))  &&
                  <Grid item xs={12} md={4}>
                  <RankingCard rankings={data} loading={loadingCards}/>
                </Grid>
              }
              {
                [4].includes(params.idTipoEleccion) &&
                <Grid item xs={12} md={8}>
                <Grid
                  container
                  spacing={2}
                  sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
                >
                  <Grid item xs={12} md={10}>
                    {buttons.map((item, index) => {
                      return expanded === item.id ? (
                        <Card className="card-primary">
                          <CardContent>
                            <Grid container spacing={2} key={index}>
                              <Grid item xs={12}>
                                {expanded === item.id && item.container}
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      ) : (
                        <></>
                      );
                    })}
                  </Grid>
                  <Grid item xs={2} textAlign={"center"}>
                    <Box>
                      <ButtonGroup
                        color="secondary"
                        aria-label="Medium-sized button group"
                        orientation="vertical"
                      >
                        {buttons.map((item) => item.button)}
                      </ButtonGroup>
                    </Box>
                  </Grid>
                </Grid>
                </Grid>
              }
              {
                (params.ValorAmbito > 0 && params.idTipoEleccion === 6) &&
                <Grid item xs={12} md={8}>
                <TableInfo idMunicipio={params.ValorAmbito} sx={{borderRadius:"16px",maxHeight:"660px",overflow:"auto"}}/>
               </Grid>
              }
            </Grid>
            <TableResultados
              title={tableTitle}
              params={params}
              setLoadingFilter={setLoadingCards}
              setData={(e)=>setData(e)}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <DrawerRelative
              anchor="right"
              open={openDrawer}
              setOpen={setOpenDrawer}
              title={"Filtrar"}
              icon="person_search"
              titleComponentPlace="under"
              className="card-primary"
              sxPaper={{ borderRadius: 3 }}
              isSticky
            >
              <Filter
                onChangeFilter={handleChangeFilter}
                disabledButtons={loadingCards}
              />
            </DrawerRelative>
          </Grid>
        </Grid>

        {!openDrawer && (
          <FloatingButton
            onClick={() => setOpenDrawer(true)}
            label="Mostrar Filtros"
          />
        )}
      </Container>
    </AdminLayout>
  );
};

export default ResultPartido;

import { useState, useEffect, cloneElement } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { format, parseISO } from "date-fns";
import { es } from "date-fns/locale";
import Swal from "sweetalert2";

import {
  Container,
  Grid,
  Box,
  Card,
  IconButton,
  CardContent,
  CardActions,
  AppBar,
  Toolbar,
  Skeleton,
  Typography,
  Button,
  CardMedia,
  Stack,
  Icon,
  Divider,
  Pagination,
  PaginationItem,
  Dialog,
} from "@mui/material";
import { ArrowBack, ArrowForward, SearchOff } from "@mui/icons-material";

import useScrollTrigger from "@mui/material/useScrollTrigger";
import CssBaseline from "@mui/material/CssBaseline";

import defaultImage from "@assets/img/eventosImg.jpg";
import BasicSelect from "@components/Selects/BasicSelect";

import EventsServices from "@services/EventsServices";
import { RECAPTCHA_SITE_KEY } from "@utils/global";
import { getArray } from "@utils/Utilities";

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return cloneElement(children, { elevation: trigger ? 4 : 0 });
}

const Publicos = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState(getArray(10));
  const [total, setTotal] = useState(0);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [open, setOpen] = useState(false);
  const [post, setPost] = useState(null);

  useEffect(() => {
    getEventos();
    // eslint-disable-next-line
  }, [page, pageSize]);

  const getEventos = async () => {
    const params = {
      page: page - 1,
      pageSize,
      filtered: [{ id: "eventos.idTipoActividad", filter: "IN", value: [7, 8, 9, 10, 11] }],
    };

    try {
      setIsLoading(true);
      setEvents(getArray(pageSize));

      const result = await EventsServices.getAllEvents(params);
      const { results, response, message } = result;

      if (results) {
        setEvents(response.data);
        setTotal(response.total);
      } else {
        setTotal(response.total);
        throw new Error(message);
      }
    } catch (e) {
      setEvents([]);
      Swal.fire({ title: e.message, icon: "warning", customClass: { container: "modal-alert" } });
    } finally {
      setIsLoading(false);
    }
  };

  const handleShare = (URL) => () => window.open(URL, "_blank", "noopener,noreferrer");

  const handleChange = (value) => setPage(value);

  const formatDate = (dateString) => {
    const date = parseISO(dateString);
    return format(date, "EEEE dd MMMM - hh:mm a", { locale: es });
  };

  const trim = (value, comma = "") => (value?.trim() ? `${comma}${("" + value).trim()}` : "");

  const showRandom = () => Math.random() < 0.5;

  const hasDatePassed = (dateString) => {
    const inputDate = new Date(dateString);
    const currentDate = new Date();

    return inputDate < currentDate;
  };

  const handleViewImage = (data) => {
    setPost(data);
    setOpen(true);
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar position="fixed" sx={{ alignItems: "center" }}>
          <Toolbar>
            <Typography variant="h6" noWrap>
              Eventos Publicos
            </Typography>
          </Toolbar>
        </AppBar>
      </ElevationScroll>

      <Container component="main" maxWidth="md" sx={{ mt: 8, mb: 2 }}>
        {isLoading ? (
          <Grid container spacing={2}>
            {events.map((item, index) => (
              <Grid item xs={12} sm={6} key={item.id || index}>
                <Card
                  className="card-primary"
                  sx={{
                    padding: 0,
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    marginBottom: "0 !important",
                  }}
                >
                  <Skeleton animation="wave" variant="rounded" width="100%" height={200} />

                  <CardContent sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                      >
                        <Skeleton animation="wave" variant="rectangular" width="80%" height={32} />
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width="50%"
                          height={24}
                          sx={{ my: 1 }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Stack
                          direction="row"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={2}
                        >
                          <Skeleton animation="wave" variant="circular" width={24} height={24} />
                          <Skeleton animation="wave" variant="text" width="80%" height={24} />
                        </Stack>
                      </Grid>

                      <Grid item xs={12}>
                        <Stack
                          direction="row"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={2}
                        >
                          <Skeleton animation="wave" variant="circular" width={24} height={24} />
                          <Skeleton animation="wave" variant="text" width="80%" height={24} />
                        </Stack>
                      </Grid>

                      {showRandom() && (
                        <Grid item xs={12}>
                          <Stack
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem />}
                            spacing={2}
                          >
                            <Skeleton animation="wave" variant="circular" width={24} height={24} />
                            <Skeleton animation="wave" variant="text" width="80%" height={24} />
                          </Stack>
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                  <CardActions
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2,
                      px: 2,
                    }}
                  >
                    <Skeleton animation="wave" variant="rectangular" width="80%" height={24} />

                    <Skeleton animation="wave" variant="rounded" width={133} height={31} />
                  </CardActions>
                </Card>
              </Grid>
            ))}
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
              <Skeleton
                animation="wave"
                variant="rounded"
                height={34}
                sx={{ pt: 2, width: { xs: "90%", sm: "80%", md: "50%" } }}
              />
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
              <Skeleton animation="wave" variant="rounded" height={45} sx={{ minWidth: 160 }} />
            </Grid>
          </Grid>
        ) : events.length ? (
          <Grid container spacing={2}>
            {events.map((item, index) => {
              const { imagen, Actividad, Descripcion, FechaInicio, URL, idModalidad } = item;
              const { NombreLugar, Calle, NumExt, Colonia, FechaFinPreregistro /*, LigaVirtual */ } = item;

              return (
                <Grid item xs={12} sm={6} key={item.id || index}>
                  <Card
                    className="card-primary"
                    sx={{
                      padding: 0,
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      marginBottom: "0 !important",
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="200"
                      image={imagen ?? defaultImage}
                      alt={Actividad}
                      onClick={() => handleViewImage(item)}
                      sx={{ cursor: "pointer" }}
                    />

                    <CardContent sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2} textAlign="center">
                        <Grid item xs={12}>
                          <Typography variant="h5">{Actividad}</Typography>
                          <Typography gutterBottom variant="body1" color="gray">
                            {idModalidad === 1 ? "Presencial" : idModalidad === 2 ? "Virtual" : "Mixto"}
                          </Typography>
                        </Grid>

                        {Descripcion && (
                          <Grid item xs={12}>
                            <Stack
                              direction="row"
                              divider={<Divider orientation="vertical" flexItem />}
                              spacing={2}
                            >
                              <Icon>notes</Icon>
                              <Typography variant="body1" align="left">
                                {Descripcion}
                              </Typography>
                            </Stack>
                          </Grid>
                        )}

                        {NombreLugar && (
                          <Grid item xs={12}>
                            <Stack
                              direction="row"
                              divider={<Divider orientation="vertical" flexItem />}
                              spacing={2}
                            >
                              <Icon>location_on</Icon>
                              <Typography variant="body1" align="left">
                                {`${trim(NombreLugar)}${trim(Calle, ", ")} ${trim(NumExt, "#")}${trim(
                                  Colonia,
                                  ", "
                                )}`}
                              </Typography>
                            </Stack>
                          </Grid>
                        )}

                        {/* {LigaVirtual && (
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Stack
                              direction="row"
                              divider={<Divider orientation="vertical" flexItem />}
                              spacing={2}
                            >
                              <Icon>link</Icon>
                              <Link
                                href={LigaVirtual}
                                target="_blank"
                                rel="noopener noreferrer"
                                underline="none"
                              >
                                <Typography variant="body1" align="left">
                                  {LigaVirtual}
                                </Typography>
                              </Link>
                            </Stack>
                          </Grid>
                        )} */}
                      </Grid>
                    </CardContent>
                    <CardActions
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 2,
                        px: 2,
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="body2" color="text.secondary">
                          <strong>Fecha:</strong> {formatDate(FechaInicio)}
                        </Typography>
                      </Box>

                      <Button
                        variant="outlined"
                        size="small"
                        aria-label="shares"
                        onClick={handleShare(URL)}
                        endIcon={<Icon>send</Icon>}
                        disabled={item.TienePreregistro !== 1 || hasDatePassed(FechaFinPreregistro)}
                      >
                        Registrarse
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}

            <PaginationItems
              total={total}
              page={page}
              handleChange={handleChange}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box textAlign="center" p={4}>
                <SearchOff style={{ fontSize: 80, color: "#999" }} />
                <Typography variant="h5" mt={2} mb={1}>
                  {`No se encontraron resultados la página ${page}`}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Intenta utilizar el paginado ubicado debajo.
                </Typography>
              </Box>
            </Grid>

            <PaginationItems
              total={total}
              page={page}
              handleChange={handleChange}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          </Grid>
        )}
      </Container>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="lg"
        PaperProps={{ style: { backgroundColor: "transparent", borderRadius: "8px" } }}
        disableEscapeKeyDown
      >
        <Card
          elevation={3}
          sx={{
            borderRadius: "8px",
            padding: 0,
            minHeight: "100px",
            overflowY: post?.imagen ? "auto" : "none",
            "&::-webkit-scrollbar": { width: { xs: "5px", sm: "10px" }, left: 0 },
            "&::-webkit-scrollbar-thumb": { backgroundColor: "rgba(0, 0, 0, 0.2)", borderRadius: "6px" },
            "&::-webkit-scrollbar-track": { backgroundColor: "rgb(255, 255, 255)" },
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "inherit",
              backgroundColor: "white",
              "&:hover": { backgroundColor: "#bdbdbd" },
            }}
          >
            <Icon>close</Icon>
          </IconButton>

          {post && <CardMedia component="img" image={post?.imagen ?? defaultImage} alt={post?.Actividad} />}
        </Card>
      </Dialog>
    </GoogleReCaptchaProvider>
  );
};

const PaginationItems = ({ total, page, handleChange, pageSize, setPageSize }) => {
  const options = [
    { value: 10, label: "10" },
    { value: 25, label: "25" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  return (
    <>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          count={parseInt(total / pageSize) + 1}
          shape="rounded"
          page={page}
          onChange={(e, value) => handleChange(value)}
          renderItem={(item) => (
            <PaginationItem slots={{ previous: ArrowBack, next: ArrowForward }} {...item} />
          )}
        />
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <BasicSelect
          label="Eventos por página"
          size="small"
          name="pageSize"
          onChange={(e) => setPageSize(e.target.value)}
          value={pageSize}
          options={options}
          sx={{ minWidth: 160 }}
        />
      </Grid>
    </>
  );
};

export default Publicos;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import MenuServices from "@services/MenuServices";
import Swal from "sweetalert2";
import {
  Box,
  Button,
  CircularProgress,
  Icon,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Tab,
} from "@mui/material";
import MenuRowHeader from "@components/Menus/MenuRowHeader";
import MenuRow4Add from "@components/Menus/MenuRow4Add";
import ActionContainer from "@components/Containers/ActionContainer";
import { TabContext, TabList, TabPanel } from "@mui/lab";

const AddNewMenuList = (props) => {
  const {
    currentMenus,
    type,
    maxHeight = 800,
    submitMenus = () => {},
    isLoadingAdd = false,
    handleCloseModal,
    disableButtons = false,
    onMenusChange = () => {},
  } = props;

  const [webMenus, setWebMenus] = useState([]);
  const [appMenus, setAppMenus] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [newMenus, setNewMenus] = useState([]);
  const [tabValue, setTabValue] = useState(() => (type === "app" ? 2 : 1));

  useEffect(() => {
    setIsLoading(true);
    MenuServices.getAllMenus({})
      .then((response) => {
        if (response.success && response.results) {
          setWebMenus(response.response.webMenus);
          setAppMenus(response.response.appMenus);
        } else {
          Swal.fire({
            title: response.message,
            icon: "warning",
            customClass: {
              container: "modal-alert",
            },
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          title: error.message,
          icon: "warning",
          customClass: {
            container: "modal-alert",
          },
        });
      });
  }, []);

  const handleChangeTab = (e, newVal) => {
    setTabValue(newVal);
  };

  const handleAddMenu = (menus) => {
    let newMenusAux = [...newMenus];
    menus.forEach((menu) => {
      const index = newMenus.findIndex((x) => x.idMenu === menu.idMenu);
      if (index > -1 && !menu.checked) {
        newMenusAux.splice(index, 1);
      } else if (index < 0 && menu.checked) {
        newMenusAux.push(menu);
      }
    });
    setNewMenus(newMenusAux);
    onMenusChange(newMenusAux);
  };

  const handleSubmitMenus = () => {
    if (newMenus.length === 0) {
      Swal.fire({
        title: "Atencion",
        html: "Agregue al menos un menú",
        icon: "warning",
        customClass: {
          container: "modal-alert",
        },
      });
      return;
    }

    Swal.fire({
      title: "Atencion",
      html: `¿Esta seguro de agregar ${newMenus.length} menú(s)?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: `Aplicar`,
      reverseButtons: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        container: "modal-alert",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        const formatedNewMenus = newMenus.map((menu) => {
          return {
            idMenu: menu.idMenu,
            Ver: menu.Ver,
            Agregar: menu.Agregar,
            Editar: menu.Editar,
            Eliminar: menu.Eliminar,
            Exportar: menu.Exportar,
            Importar: menu.Importar,
            Autorizar: menu.Autorizar,
            Visible: menu.Visible,
          };
        });
        submitMenus(formatedNewMenus);
      }
    });
  };

  return (
    <Box sx={{ width: "100%", overflow: "auto", ml: 0, mr: 0 }}>
      <TableContainer sx={{ maxHeight: maxHeight, minHeight: 400 }}>
        {isLoading && <CircularProgress />}
        {!isLoading && (
          <TabContext value={tabValue}>
            <TabList
              centered
              variant="fullWidth"
              aria-label="lab API tabs example"
              onChange={handleChangeTab}
              textColor="secondary"
              indicatorColor="secondary"
              sx={{ borderBottom: 1, borderColor: "divider" }}
              scrollButtons="auto"
            >
              {type === "web" && <Tab icon={<Icon>admin_panel_settings</Icon>} label="Menús Web" value={1} />}
              {type === "app" && <Tab icon={<Icon>app_settings_alt</Icon>} label="Menús App" value={2} />}
            </TabList>
            {type === "web" && (
              <TabPanel value={1}>
                <Table>
                  <MenuRowHeader colSpan={3} />
                  <TableBody>
                    {webMenus.map((menu, index) => (
                      <MenuRow4Add
                        menu={menu}
                        index={index}
                        key={index}
                        disabled={currentMenus.find((x) => x.idMenu === menu.idMenu)}
                        currentMenu={currentMenus.find((x) => x.idMenu === menu.idMenu)}
                        handleReturnMenu={handleAddMenu}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TabPanel>
            )}
            {type === "app" && (
              <TabPanel value={2}>
                <Table>
                  <MenuRowHeader colSpan={3} />
                  <TableBody>
                    {appMenus.map((menu, index) => (
                      <MenuRow4Add
                        menu={menu}
                        index={index}
                        key={index}
                        disabled={currentMenus.find((x) => x.idMenu === menu.idMenu)}
                        currentMenu={currentMenus.find((x) => x.idMenu === menu.idMenu)}
                        handleReturnMenu={handleAddMenu}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TabPanel>
            )}
          </TabContext>
        )}
      </TableContainer>

      {!disableButtons && (
        <ActionContainer>
          <Stack direction="row" spacing={2}>
            <Button
              color="error"
              variant="outlined"
              size="small"
              startIcon={<Icon>close</Icon>}
              disabled={isLoadingAdd}
              onClick={handleCloseModal}
            >
              Cerrar
            </Button>
            <Button
              color="primary"
              variant="outlined"
              size="small"
              startIcon={<Icon>save</Icon>}
              onClick={() => handleSubmitMenus()}
              disabled={isLoadingAdd}
            >
              Guardar
            </Button>
            {isLoadingAdd && <CircularProgress />}
          </Stack>
        </ActionContainer>
      )}
    </Box>
  );
};

AddNewMenuList.propTypes = {
  currentMenus: PropTypes.array.isRequired,
  submitMenus: PropTypes.func.isRequired,
  isLoadingAdd: PropTypes.bool.isRequired,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disableButtons: PropTypes.bool,
  onMenusChange: PropTypes.func,
};

export default AddNewMenuList;

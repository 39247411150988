import PropTypes from 'prop-types';
// material-ui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';




export default function Dot({ color, size, variant, sx }) {
  const theme = useTheme();

  const getColors = (theme, color) => {
    switch (color) {
      case 'secondary':
        return theme.palette.secondary;
      case 'error':
        return theme.palette.error;
      case 'warning':
        return theme.palette.warning;
      case 'info':
        return theme.palette.info;
      case 'success':
        return theme.palette.success;
      default:
        return theme.palette.primary;
    }
  };
  
  const colors = getColors(theme, color || 'primary');
  const { main } = colors;


  return (
    <Box
      sx={{
        width: size || 8,
        height: size || 8,
        borderRadius: '50%',
        bgcolor: variant === 'outlined' ? '' : main,
        ...(variant === 'outlined' && { border: `1px solid ${main}` }),
        ...sx
      }}
    />
  );
}

Dot.propTypes = { color: PropTypes.any, size: PropTypes.number, variant: PropTypes.string, sx: PropTypes.any };

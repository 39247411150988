import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// material-ui

import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// project import
import Dot from "./Dot";
import { TableFooter } from "@mui/material";


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// ==============================|| ORDER TABLE - HEADER ||============================== //

function TablaHead({ order, orderBy, headCells }) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align ? headCell.align : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function Status({ status }) {
  let color;
  let title;

  switch (status) {
    case "se mantuvo":
      color = "warning";
      title = "Mantuvo";
      break;
    case "aumento":
      color = "success";
      title = "Aumento";
      break;
    case "disminuyo":
      color = "error";
      title = "Disminuyo";
      break;
    default:
      color = "primary";
      title = "Ninguna";
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Dot color={color} />
      <Typography>{title}</Typography>
    </Stack>
  );
}

const TableResumen = (props) => {
  const { columns, rows, stickyHeader=true,Height= 340} = props;
  const order = "asc";
  const orderBy = "tracking_no";
  const [showScrollHint, setShowScrollHint] = useState(true);

  useEffect(()=>{},[columns,rows]);

  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop > 0) {
      setShowScrollHint(false);
    } else {
      setShowScrollHint(true);
    }
  };

  useEffect(() => {
    const container = document.getElementById("table-container");
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <Box>
      <TableContainer
        id="table-container"
        sx={{
          width: "100%",
          maxHeight: Height, // Ajusta la altura máxima aquí
          overflowY: "auto",
          position: "relative",
          display: "block",
          maxWidth: "100%",
        }}
      >
        <Table stickyHeader={stickyHeader} aria-labelledby="tableTitle">
          <TablaHead order={order} orderBy={orderBy} headCells={columns} />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy)).map(
              (row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    tabIndex={-1}
                    key={row.tracking_no}
                  >
                    {columns.map((item) => {
                      return item.Type ? (
                        <TableCell>
                          <Status status={row[item.id]} />
                        </TableCell>
                      ) : item.styleFlag ? (
                        <TableCell align="center" sx={row.style}>
                          {row[item.id]}
                        </TableCell>
                      ) : (
                        <TableCell align="center">{row[item.id]}</TableCell>
                      );
                    })}
                  </TableRow>
                );
              }
            )}
          </TableBody>
          <TableFooter>
            {showScrollHint && (
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  position: "absolute",
                  bottom: 10,
                  left: "20%",
                  transform: "translateX(-50%)",
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  padding: "4px 8px",
                  borderRadius: "4px",
                }}
              >
                Desliza para ver más
              </Typography>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TableResumen;

TablaHead.propTypes = {
  order: PropTypes.any,
  orderBy: PropTypes.string,
  headCells: PropTypes.array.isRequired,
};

Status.propTypes = {
  status: PropTypes.string.isRequired,
};

TableResumen.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
};

import { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

// Material UI
import { LinearProgress } from "@mui/material";
import "react-big-calendar/lib/css/react-big-calendar.css";

// Componentes
import EventForm from "@components/Events/EventForm";
import CardEvent from "@components/Events/CardEvent";
import CustomModal from "@components/Modal/CustomModal";

// Utilidades
import { isNullOrUndefined } from "@utils/validations";
import { Swal, SwalImage } from "@utils/alerts";

// Servicios
import EventsServices from "@services/EventsServices";

// Middleware
import middleware from "@middlewares/middleware";

const localizer = momentLocalizer(moment);

const CustomAgendaEvent = ({ event }) => {
  return (
    <div>
      <div>
        <strong>Evento:</strong> {event.title}
      </div>
      <div>
        <strong>Descripción:</strong> {event.description}
      </div>
    </div>
  );
};

const Calendario = ({ catalogs, isLoadingCat, idsTipoActividad = [], URL = "" }) => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [, /* total */ setTotal] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [type, setType] = useState(1);
  const [newEvent, setNewEvent] = useState({});
  const [event, setEvent] = useState({});
  const [currentMonth, setCurrentMonth] = useState(moment(Date()).startOf("month").format("YYYY-MM"));

  const params = {
    page: 0,
    pageSize: 10,
    filtered: [{ id: "FechaInicio", filter: "LIKE", value: currentMonth }],
    sorted: [],
  };

  if (idsTipoActividad.length) {
    params.filtered.push({ id: "eventos.idTipoActividad", filter: "IN", value: idsTipoActividad });
  }

  useEffect(() => {
    getList(params);
    // eslint-disable-next-line
  }, [currentMonth]);

  const getList = async (params) => {
    if (isLoading) return true;
    setIsLoading(true);
    setEvents([]);
    try {
      const result = await EventsServices.getEventosCalendar(params);
      const { results, response, message } = result;

      if (results) {
        setEvents(
          response.data.map((item) => ({
            ...item,
            title: item.Actividad,
            start: new Date(item.FechaInicio),
            end: new Date(item.FechaFin),
            color: "#1976d2",
            description: item.Descripcion,
            location: item.NombreLugar,
          }))
        );
        setTotal(response.total);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: "warning" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEventClick = (event) => {
    setType(2);
    setOpenDialog(true);
    setEvent(event);
  };

  const handleSelectSlot = ({ start, end }) => {
    if (!middleware.checkMenuAction("Agregar")) return;

    setNewEvent({ start, end });
    setType(1);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setNewEvent({});
  };

  const handleDeleteEvent = async (data) => {
    try {
      setIsLoading(true);

      const result = await EventsServices.deleteEvent({ idEvento: data.id });
      const { success, results, message } = result;

      if (success && results) {
        getList(params);
        setOpenDialog(false);
        Swal.fire({ title: message, icon: "success", customClass: { container: "modal-alert" } });
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: "warning", customClass: { container: "modal-alert" } });
    } finally {
      setIsLoading(false);
    }
  };

  const questionDelete = async (data) => {
    Swal.fire({
      title: "Eliminar Evento",
      text: `¿Estas seguro de querer eliminar el evento: ${data.Actividad}?`,
      icon: "warning",
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      focusDeny: true,
      denyButtonText: "Eliminar evento",
      cancelButtonText: "Cancelar",
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: { container: "modal-alert" },
    }).then((res) => {
      if (res.isDenied) handleDeleteEvent(data);
    });
  };

  const handleNavigate = (newDate, view, action) => {
    if (action === "DATE") return;
    if (isLoading) return;

    const localCM = moment(newDate).startOf("month").format("YYYY-MM");
    if ((action === "PREV" || action === "NEXT" || action === "TODAY") && localCM !== currentMonth) {
      setCurrentMonth(localCM);
    }
  };

  return (
    <div>
      {isLoading && <LinearProgress color="secondary" />}
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, marginTop: 20 }}
        selectable
        onSelectEvent={handleEventClick}
        onSelectSlot={handleSelectSlot}
        messages={{
          today: "Hoy",
          previous: "Anterior",
          next: "Siguiente",
          month: "Mes",
          week: "Semana",
          day: "Día",
          agenda: "Agenda",
          date: "Fecha",
          time: "Hora",
          event: "Evento",
          allDay: "Todo el día",
          work_week: "Semana de trabajo",
          yesterday: "Ayer",
          tomorrow: "Mañana",
          noEventsInRange: "No hay eventos en este rango",
          showMore: (total) => `+${total} más`,
        }}
        onNavigate={handleNavigate}
        eventPropGetter={(event, start, end, isSelected) => ({
          style: { backgroundColor: event.color },
        })}
        components={{
          agenda: { event: CustomAgendaEvent, description: "Hola" },
        }}
        toolbar={!isLoading}
      />

      <CustomModal
        open={openDialog}
        onClose={handleClose}
        fullWidth
        disableClose
        image={[1, 3].includes(type) ? {} : { src: event.imagen, title: event.Actividad }}
        displayImage={!isNullOrUndefined(event.imagen)}
        buttons={
          [1, 3].includes(type)
            ? []
            : [
                {
                  icon: "image",
                  title: "Abrir imagen",
                  action: () => SwalImage({ image: event.imagen, title: event.Actividad }),
                },
              ]
        }
      >
        {type === 1 && (
          <EventForm
            fechaCalendario={newEvent}
            handleAction={setOpenDialog}
            getEvents={() => getList(params)}
            catalogsOut={catalogs}
            isLoadingCat={isLoadingCat}
          />
        )}

        {type === 2 && (
          <CardEvent
            post={event}
            setType={setType}
            getEvents={() => getList(params)}
            handleDelete={questionDelete}
            URL={URL}
            canModify
          />
        )}

        {type === 3 && (
          <EventForm
            type="edit"
            data={event}
            handleAction={setOpenDialog}
            setType={setType}
            getEvents={() => getList(params)}
            handleDelete={questionDelete}
            catalogsOut={catalogs}
            isLoadingCat={isLoadingCat}
          />
        )}
      </CustomModal>
    </div>
  );
};
export default Calendario;

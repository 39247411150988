import { useState, useEffect } from "react";
import esLocale from "date-fns/locale/es";
import { format } from "date-fns";

// Material UI
import { Box, Button, Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// Componentes
import BasicSelect from "@components/Selects/BasicSelect";
import FilterCollapse from "@components/Filters/FilterCollapse";

// Utils
import { isEmptyOrNullObject, isNullOrUndefined } from "@utils/validations";

const FilterEvento = ({ onFilter, catalogs, isLoadingCat }) => {
  const clear = {
    Tipo: 0,
    Region: 0,
    Municipio: 0,
    Actividad: 0,
    NombreEvento: "",
    FechaEvento: null,
  };

  const [params, setParams] = useState(clear);

  const [catTipo, setCatTipo] = useState([]);
  const [catMunicipio, setCatMunicipio] = useState([]);
  const [catCategoria, setCatCategoria] = useState([]);

  useEffect(() => {
    if (!isLoadingCat && !isEmptyOrNullObject(catalogs)) {
      setCatTipo([{ value: 0, label: "TODOS" }].concat(catalogs.ambito_evento));

      setCatMunicipio(catalogs.municipios);

      setCatCategoria(catalogs.tipo_actividad_evento);
    } //eslint-disable-next-line
  }, [isLoadingCat, catalogs]);

  useEffect(() => {
    if (catTipo.length) setParams({ ...params, Tipo: catTipo[0]?.value });
    //eslint-disable-next-line
  }, [catTipo]);

  const handleChangeRegion = (event) => {
    const idRegion = event.target.value;
    setParams({ ...params, Region: idRegion, Municipio: 0 });

    if (idRegion > 0) {
      setCatMunicipio(catalogs.municipios.filter((item) => item.idRegion === idRegion || item.value === 0));
    } else setCatMunicipio(catalogs.municipios);
  };

  const formatDate = (date) => {
    if (date instanceof Date) return format(date, "yyyy-MM-dd", { locale: esLocale });
    return date;
  };

  const searchFilter = (values) => {
    const filters = [
      { id: "eventos.idAmbito", key: "Tipo", filter: "=" },
      { id: "eventos.idMunicipio", key: "Municipio", filter: "=" },
      { id: "eventos.idTipoActividad", key: "Actividad", filter: "=" },
      { id: "eventos.Actividad", key: "NombreEvento", filter: "LIKE" },
      { id: "eventos.FechaInicio", key: "FechaEvento", filter: "LIKE" },
    ];

    const filtered = filters
      .filter(({ key }) => values[key])
      .map(({ id, filter, key }) => ({ id, filter, value: formatDate(values[key]) }));

    onFilter({ filtered });
  };

  const clearFilter = () => {
    setParams(clear);
    searchFilter(clear);
  };

  return (
    <>
      <FilterCollapse>
        <Grid container spacing={2} marginBottom="1rem" marginTop="0.5rem" sx={{ position: "relative" }}>
          <Grid item xs={12} sm={6} lg={4}>
            <BasicSelect
              label="Tipo Evento: "
              options={catTipo}
              name="Tipo"
              value={params.Tipo}
              onChange={(e) => setParams({ ...params, Tipo: e.target.value })}
              disabled={isLoadingCat}
              sx={{ width: "100%" }}
              isLoading={isLoadingCat}
            />
          </Grid>

          {[0, 1, 2].includes(params.Tipo) && (
            <Grid item xs={12} sm={6} lg={4}>
              <BasicSelect
                label="Región"
                options={catalogs.regiones}
                name="region"
                value={catalogs?.regiones?.length === 2 ? catalogs?.regiones[1]?.value : params.Region}
                onChange={handleChangeRegion}
                disabled={catalogs?.regiones?.length === 2 || isLoadingCat}
                sx={{ width: "100%" }}
                isLoading={isLoadingCat}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={6} lg={4}>
            <BasicSelect
              label="Municipio"
              options={catMunicipio}
              name="municipio"
              value={catalogs?.municipios?.length === 2 ? catalogs?.municipios[1]?.value : params.Municipio}
              onChange={(e) => setParams({ ...params, Municipio: e.target.value })}
              disabled={catalogs?.municipios?.length === 2 || isLoadingCat}
              sx={{ width: "100%" }}
              isLoading={isLoadingCat}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              label="Nombre Evento"
              size="small"
              name="NombreEvento"
              value={params.NombreEvento.toUpperCase()}
              onChange={(e) => setParams({ ...params, [e.target.name]: e.target.value })}
              sx={{ width: "100%" }}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
              <DatePicker
                label="Fecha Evento"
                onChange={(e) => setParams({ ...params, FechaEvento: isNullOrUndefined(e) ? "" : e })}
                value={params.FechaEvento}
                renderInput={(params) => (
                  <TextField size="small" variant="outlined" sx={{ width: "100%" }} {...params} />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <BasicSelect
              label="Categoria del evento: "
              options={catCategoria}
              name="Actividad"
              value={params.Actividad}
              onChange={(e) => setParams({ ...params, Actividad: e.target.value })}
              disabled={isLoadingCat}
              sx={{ width: "100%" }}
              isLoading={isLoadingCat}
            />
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="flex-end" gap={1}>
          <Button
            variant="contained"
            color="primaryDark"
            onClick={() => searchFilter(params)}
            disabled={params.Region !== 0 && params.Municipio === 0}
          >
            Filtrar
          </Button>

          <Button color="primaryDark" onClick={clearFilter}>
            Limpiar
          </Button>
        </Box>
      </FilterCollapse>
    </>
  );
};

export default FilterEvento;

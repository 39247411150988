import { Fragment, useEffect, useState } from "react";
import {
  Typography,
  ListItemAvatar,
  ListItemText,
  Divider,
  ListItem,
  List,
  Collapse,
  IconButton,
  LinearProgress,
  Paper,
  InputBase,
  Box,
  Stack,
} from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import InfiniteScroll from "react-infinite-scroll-component";
import Swal from "sweetalert2";
import SocialService from "@services/SocialServices";

const ListComponente = (props) => {
  const {
    avatar,
    more,
    Buscar,
    titleSearch,
    pageSize,
    loading,
    config = {
      height: "770px",
      endMessage: "Fin de la lista",
    },
    data,
    fetchHasMore,
    /* totalItems,
    itemComponent, */
    hasMore,
    handleClickItem,
    handleSearchItems,
    searchText,
    setSearch,
  } = props;
  const [showedItems, setShowedItems] = useState(0);
  const [listingData, setListingData] = useState([]);
  const [open, setOpen] = useState(false);
  const [textSearch, setTextSearch] = useState(searchText);
  const [moreContent,setMoreContent,] = useState({
    TotalCompromisos: 0,
    TotalCompromisosSeg: 0,
  });
  

  useEffect(() => {
    setListingData(data);
    setShowedItems(data ? data.length : 0);
  }, [data]);

  const search = () => {
    let paramsFilter = {
      page: 0,
      pageSize: pageSize,
      filtered: [],
    };

    if (textSearch !== "") {
      let filter = textSearch.replace(/\s+/g, "%");
      paramsFilter = {
        page: 0,
        pageSize: pageSize,
        filtered: [
          {
            id: "CONCAT_WS('',usuarios.Nombre,usuarios.Paterno,usuarios.Materno,usuarios.Celular)",
            filter: "LIKE",
            value: `%${filter}%`,
          },
        ],
      };
    }

    setSearch(textSearch);
    handleSearchItems(paramsFilter);
    //setParams(paramsFilter);
  };

  const hadleOpen = (index, data) => {
    MoreContentCoordinador(data);
    setOpen(index === open ? true : index);
    handleClickItem(data);
  };

  const MoreContentCoordinador = (dato) => {
    let parms = {
      page: 0,
      pageSize: 10,
      filtered: [],
    };
    SocialService.totalCompromisos(parms)
      .then((res) => {
        if (res.results) {
          setMoreContent(res.response)
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      });
  };

  return (
    <>
      {Buscar && (
        <>
          <Paper
            //component="form"
            sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
           /*  onSubmit={(e) => search()} */
           onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              search(e);
            }
          }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder={titleSearch}
              value={textSearch}
              onChange={(e) => setTextSearch(e.target.value)}
              inputProps={{ "aria-label": titleSearch }}
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={(e) => search(e)}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
          <br />
        </>
      )}
      <InfiniteScroll
        dataLength={showedItems}
        next={fetchHasMore}
        hasMore={hasMore}
        loader={<Typography variant="subtitle2" color="secondary" gutterBottom>Cargando...</Typography>}
        height={config.height}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>
              {config.endMessage !== undefined
                ? config.endMessage
                : "¡Final de la lista de registros!"}
            </b>
          </p>
        }
      >
      {
        loading && <LinearProgress color="secondary" />
      }
        <List dense={true} sx={{bgcolor: "background.paper" }}>
          {listingData.map((item, index) => {
            return (
              <>
                <ListItem alignItems="flex-start">
                  {avatar && <ListItemAvatar>{avatar}</ListItemAvatar>}
                  <ListItemText
                    primary={item.title}
                    secondary={
                      <Fragment>
                        {
                          typeof item.subTitle === "string" || typeof item.subTitle === "number" ? <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {item.subTitle}
                        </Typography>:
                        <>{item.subTitle}</>
                        }
                        
                      </Fragment>
                    }
                  />
                {/*   {more && (
                    <IconButton
                      aria-label="more"
                      onClick={() => hadleOpen(index, item)}
                    >
                      {open === index ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  )} */}
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            );
          })}
        </List>
      </InfiniteScroll>
    </>
  );
};
export default ListComponente;


/* 
     <Collapse in={open === index} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem sx={{ pl: 4 }}>
                      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
                        <Stack
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          spacing={2}
                        >
                          <Typography
                            variant="h6"
                            color="text.primary"
                            sx={{ alignItems: "center" }}
                          >
                            Compromisos
                          </Typography>
                        </Stack>
                          <Divider variant="middle" /> 
                        <Box sx={{ m: 2 }}>
                          <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={4}
                          >
                            <Typography
                              variant="body1"
                              color="text.info"
                              sx={{ alignItems: "center" }}
                            >
                              {`  ${moreContent.TotalCompromisos}  `}
                            </Typography>
                            <Typography
                              variant="body1"
                              color="text.info"
                              sx={{ alignItems: "center" }}
                            >
                              {`  ${moreContent.TotalCompromisosSeg}  `}
                            </Typography>
                          </Stack>
                          <Divider variant="small" />
                          <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={4}
                          >
                            <Typography
                              variant="caption"
                              color="text.info"
                              sx={{ alignItems: "center" }}
                            >
                              GENERAL
                            </Typography>
                            <Typography
                              variant="caption"
                              color="text.info"
                              sx={{ alignItems: "center" }}
                            >
                              SEGMENTO
                            </Typography>
                          </Stack>
                        </Box>
                      </Box>
                    </ListItem>
                  </List>
                </Collapse>
*/

import { useEffect, useState } from "react";

// Material UI
import { Box, Button, Grid } from "@mui/material";
import Swal from "sweetalert2";

// Componentes
import FilterCollapse from "@components/Filters/FilterCollapse";
import InputSelect from "@components/Selects/BasicSelect";
import makeAnimated from "react-select/animated";
import Select from "react-select";

// Servicios
import CatalogServices from "@services/CatalogServices";

const FilterData = (props) => {
  const {
    params,
    setParams,
    handleFilter,
    isLoading,
    handleExport,
    handleImport,
  } = props;
  const animatedComponents = makeAnimated();
  const [isLoadingLocal, setIsLoadingLocal] = useState(false);

  const [region, setRegion] = useState([]);

  const [municipio, setMunicipio] = useState([]);
  const [municipioFilter, setMunicipioFilter] = useState([]);

  const [poligono, setPoligonos] = useState([]);
  const [poligonoFilter, setPoligonoFilter] = useState([]);
  const [selectedPolygons, setSelectedPolygons] = useState([]);

  const [section, setSection] = useState([]);
  const [sectionFilter, setSectionFilter] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);

  // Llamada a la api de catalogos de los selects
  const fetchData = async () => {
    setIsLoadingLocal(true);
    try {
      // parametros para traer el catalog de la API
      const params = [
        { id: "regiones", getAll: false },
        { id: "municipios", getAll: false },
        { id: "poligonos", getAll: false },
        { id: "secciones", getAll: false },
      ];

      const result = await CatalogServices.getCatalogs(params);
      const { results, message, response } = result;

      if (results) {
        params.forEach((item) => {
          switch (item.id) {
            case "regiones":
              const regItems = response.catalogs[item.id];
              const regLen = regItems.length === 1;

              const dataRegiones = [
                { value: 0, label: "TODOS" },
                ...response.catalogs[item.id],
              ];
              setRegion(regLen ? regItems : dataRegiones);

              params.Region = regLen ? regItems[0].value : 0;
              break;
            case "municipios":
              const munItems = response.catalogs[item.id];
              const munLen = munItems.length === 1;

              const dataMunicipios = [
                { value: 0, label: "TODOS" },
                ...munItems,
              ];

              setMunicipio(munLen ? munItems : dataMunicipios);
              setMunicipioFilter(munLen ? munItems : dataMunicipios);

              params.Municipio = munLen ? munItems[0].value : 0;
              break;
            case "poligonos":
              setPoligonos(response.catalogs[item.id]);
              setPoligonoFilter(response.catalogs[item.id]);
              break;
            case "secciones":
              setSection(response.catalogs[item.id]);
              setSectionFilter(response.catalogs[item.id]);
              break;
            default:
              break;
          }
        });
      } else {
        Swal.fire({
          title: message,
          icon: "warning",
        });
      }
    } catch (errors) {
      Swal.fire({
        title: errors.message,
        icon: "warning",
      });
    } finally {
      setIsLoadingLocal(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Verificar si los campos solo tienen un dato para agregarlo a params en automatico.
  // Region
  useEffect(() => {
    if (region.length === 1) {
      let municipioF = [];
      let poligonoF = [];
      let seccionF = [];

      municipioF = municipio.filter((item) => item.idRegion === params.Region);
      poligonoF = poligono.filter((item) => item.idRegion === params.Region);
      seccionF = section.filter((item) => item.idRegion === params.Region);

      setMunicipioFilter(
        municipioF.length === 1
          ? municipioF
          : [{ value: 0, label: "TODOS" }, ...municipioF]
      );
      setPoligonoFilter(poligonoF);
      setSectionFilter(seccionF);
    }
    //eslint-disable-next-line
  }, [region]);

  // Municipios
  useEffect(() => {
    if (municipioFilter.length === 1) {
      setParams({
        ...params,
        Municipio: municipioFilter[0].value,
        Poligono: [],
        Seccion: [],
      });

      setPoligonoFilter(
        poligono.filter((item) => {
          return (
            (params.Region ? item.idRegion === params.Region : true) &&
            item.idMunicipioReportes === municipioFilter[0].value
          );
        })
      );
      setSectionFilter(
        section.filter((item) => {
          return (
            (params.Region ? item.idRegion === params.Region : true) &&
            item.idMunicipioReportes === municipioFilter[0].value
          );
        })
      );
    }
    //eslint-disable-next-line
  }, [municipioFilter]);

  // Poligonos
  useEffect(() => {
    if (poligonoFilter.length === 1) {
      setParams({
        ...params,
        Poligono: [poligonoFilter[0].value],
        Seccion: [],
      });
      setSelectedPolygons([poligonoFilter[0]]);

      setSectionFilter(
        section.filter((item) => {
          return (
            (params.Region ? item.idRegion === params.Region : true) &&
            (params.Municipio
              ? item.idMunicipioReportes === params.Municipio
              : true) &&
            item.idPoligono === poligonoFilter[0].value
          );
        })
      );
    }
    //eslint-disable-next-line
  }, [poligonoFilter]);

  // Secciones
  useEffect(() => {
    if (sectionFilter.length === 1) {
      setParams({
        ...params,
        Seccion: [sectionFilter[0].value],
      });
      setSelectedSections([sectionFilter[0]]);
    }
    //eslint-disable-next-line
  }, [sectionFilter]);

  const handleClearClick = () => {
    setParams({
      Region: region.length === 1 ? region[0].value : 0,
      Municipio: municipioFilter.length === 1 ? municipioFilter[0].value : 0,
      Poligono: [],
      Seccion: [],
    });
    setMunicipioFilter(municipio);
    setSelectedPolygons([]);
    setSelectedSections([]);
    setPoligonoFilter(poligono);
    setSectionFilter(section);

    handleFilter("clear");
  };

  const handleChangeRegion = (event) => {
    const idRegion = event.target.value;
    // Reinicia los parametros
    setParams({
      Region: idRegion,
      Municipio: 0,
      Poligono: [],
      Seccion: [],
    });
    setSelectedPolygons([]);
    setSelectedSections([]);

    let municipioF = [];
    // Hace un filtrado de los selects
    if (idRegion > 0) {
      municipioF = municipio.filter((item) => item.idRegion === idRegion);

      setMunicipioFilter(
        municipioF.length === 1
          ? municipioF
          : [{ value: 0, label: "TODOS" }, ...municipioF]
      );
      setPoligonoFilter(poligono.filter((item) => item.idRegion === idRegion));
      setSectionFilter(section.filter((item) => item.idRegion === idRegion));
    } else {
      // Pone los datos por defecto
      setMunicipioFilter(municipio);
      setPoligonoFilter(poligono);
      setSectionFilter(section);
    }
  };

  const handleChangeMunicipio = (event) => {
    const idMunicipio = event.target.value;
    // Reinicia algunos parametros
    setParams({
      ...params,
      Municipio: idMunicipio,
      Poligono: [],
      Seccion: [],
    });
    setSelectedPolygons([]);
    setSelectedSections([]);

    // Hace un filtrado de los selects
    if (idMunicipio > 0) {
      setPoligonoFilter(
        poligono.filter((item) => {
          return (
            (params.Region ? item.idRegion === params.Region : true) &&
            item.idMunicipioReportes === idMunicipio
          );
        })
      );
      setSectionFilter(
        section.filter((item) => {
          return (
            (params.Region ? item.idRegion === params.Region : true) &&
            item.idMunicipioReportes === idMunicipio
          );
        })
      );
    } else {
      setPoligonoFilter(
        poligono.filter((item) =>
          params.Region ? item.idRegion === params.Region : true
        )
      );
      setSectionFilter(
        section.filter((item) =>
          params.Region ? item.idRegion === params.Region : true
        )
      );
    }
  };

  const handleChangePoligono = (newValue) => {
    setSelectedPolygons(newValue);
    const array_poligon = newValue.map((option) => option.value || option);
    // Actualiza los poligonos
    setParams({
      ...params,
      Poligono: array_poligon,
      Seccion: [],
    });
    setSelectedSections([]);

    // Hace un filtrado en Sección
    if (array_poligon.length > 0) {
      setSectionFilter(
        section.filter((item) => {
          return (
            (params.Region ? item.idRegion === params.Region : true) &&
            (params.Municipio
              ? item.idMunicipioReportes === params.Municipio
              : true) &&
            array_poligon.some((id) => id === item.idPoligono)
          );
        })
      );
    } else {
      // Que conserve los filtros anteriores en caso de borrar el poligono

      setSectionFilter(
        section.filter((item) => {
          return (
            (params.Region ? item.idRegion === params.Region : true) &&
            (params.Municipio
              ? item.idMunicipioReportes === params.Municipio
              : true)
          );
        })
      );
    }
  };

  const handleChangeSection = (newValue) => {
    setSelectedSections(newValue);
    const array_section = newValue.map((option) => option.value || option);
    // Actualiza las secciones
    setParams({
      ...params,
      Seccion: array_section,
    });
  };

  return (
    <FilterCollapse
      expand={true}
      handleExport={handleExport}
      handleImport={handleImport}
      isLoading={isLoading}
    >
      <Box display="flex" flexDirection="column">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} lg={4}>
            <InputSelect
              label={"Región"}
              options={region}
              name="región"
              value={params.Region}
              onChange={handleChangeRegion}
              isLoading={isLoadingLocal}
              sx={{ width: "100%" }}
              disabled={region.length === 1 ? true : false}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <InputSelect
              label="Municipio"
              options={municipioFilter}
              name="municipio"
              value={params.Municipio}
              onChange={handleChangeMunicipio}
              isLoading={isLoadingLocal}
              sx={{ width: "100%" }}
              disabled={municipioFilter.length <= 1 ? true : false}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <Select
              placeholder="Polígono"
              options={poligonoFilter}
              value={selectedPolygons}
              onChange={handleChangePoligono}
              isLoading={isLoadingLocal}
              isDisabled={poligonoFilter.length <= 1 ? true : false}
              components={animatedComponents}
              closeMenuOnSelect={false}
              isMulti
              styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <Select
              placeholder="Sección"
              options={sectionFilter}
              value={selectedSections}
              onChange={handleChangeSection}
              isLoading={isLoadingLocal}
              isDisabled={sectionFilter.length <= 1 ? true : false}
              components={animatedComponents}
              closeMenuOnSelect={false}
              isMulti
              styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
            />
          </Grid>

          {/* Botones */}
          <Grid item xs={12} lg={4}>
            <Box sx={{ display: "flex", gap: { xs: 1, sm: 2 } }}>
              <Button
                variant="outlined"
                sx={{ paddingX: { xs: 5, sm: "auto" } }}
                onClick={handleClearClick}
                disabled={isLoadingLocal}
              >
                Limpiar
              </Button>
              <Button
                variant="contained"
                sx={{ paddingX: { xs: 5, sm: "auto" } }}
                color="primary"
                onClick={() => handleFilter("filter")}
                disabled={isLoadingLocal}
              >
                Filtrar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </FilterCollapse>
  );
};

export default FilterData;

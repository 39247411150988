import { useState, useEffect } from "react";

// Material UI
import { Container, Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import Swal from "sweetalert2";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import FilterData from "@components/Numeralia/FilterData";
import BarChartTop from "@components/Numeralia/BarChartTop";
import PieChart from "@components/Numeralia/PieChart";
import TextCard from "@components/Numeralia/TextCard";
import BarGraph from "@components/Numeralia/BarGraph";
import AgeGraphBar from "@components/Numeralia/AgeGraphBar";
import BasicTable from "@components/Tables/BasicTable";
import { numberWithCommas } from "@utils/Utilities/";

// Servicios
import NumeraliaServices from "@services/NumeraliaServices";

const Numeralia = () => {
  const [isLoadingForm /* setIsLoadingForm */] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [isSuccessFormSave] = useState(false);
  const [loading, setLoading] = useState(false);
  const [actualDate, setActualDate] = useState(new Date().toLocaleString());
  const [age, setAge] = useState({
    label: [],
    hombre: [],
    mujer: [],
  });

  // Datos para el filtro
  const optionsF = [
    { value: 2, label: "Región", id: "idRegion", idLabel: "Region" },
    { value: 3, label: "DF", id: "idDF", idLabel: "DistritoFederal" },
    { value: 4, label: "DL", id: "idDL", idLabel: "DistritoLocal" },
  ];
  const [params, setParams] = useState({
    Selected: 2,
    Variable: 0,
    Municipio: 0,
    Poligono: [],
    Seccion: [],
  });

  // Charts
  const [change, setChange] = useState(false);
  const [chartName, setChartName] = useState(optionsF[0].label);
  const [filtered] = useState({
    idAmbitoAgrupador: params.Selected,
    filtered: [],
  });

  const handleFilter = (action) => {
    setChange(!change);
    setChartName(optionsF[params.Selected - 2].label);

    filtered.idAmbitoAgrupador = params.Selected;
    filtered.filtered = [];

    if (action !== "clear") {
      if (params.Variable) {
        filtered.filtered.push({
          id: optionsF[params.Selected - 2].id,
          filter: "=",
          value: params.Variable,
        });
      }

      if (params.Municipio) {
        filtered.filtered.push({
          id: "idMunicipio",
          filter: "=",
          value: params.Municipio,
        });
      }

      if (params.Poligono.length > 0) {
        filtered.filtered.push({
          id: "idPoligono",
          filter: "IN",
          value: params.Poligono,
        });
      }

      if (params.Seccion.length > 0) {
        filtered.filtered.push({
          id: "cat_secciones.Seccion",
          filter: "IN",
          value: params.Seccion,
        });
      }
    }
  };

  useEffect(() => {
    const ApiResponseAge = async () => {
      setLoading(true);
      try {
        const result = await NumeraliaServices.getNumeraliaEdadSexo(filtered);
        const { results, message, response } = result;
        if (results) {
          const newData = response.data;
          setAge({
            label: newData.categorias,
            hombre: newData.series.hombres.map((item) => parseInt(item)),
            mujer: newData.series.mujeres.map((item) => parseInt(item)),
          });
        } else {
          Swal.fire({
            title: message,
            icon: "warning",
          });
          setAge({
            label: [],
            hombre: [],
            mujer: [],
          });
        }
      } catch (error) {
        Swal.fire({
          title: error.message,
          icon: "warning",
        });
        setAge({
          label: [],
          hombre: [],
          mujer: [],
        });
      } finally {
        setActualDate(new Date().toLocaleString());
        setLoading(false);
      }
    };

    ApiResponseAge();
  }, [filtered, change]);
  return (
    <>
      <AdminLayout>
        <Container maxWidth={false} sx={{ paddingX: { xs: 0, sm: 2, md: 3 } }}>
          <ActionHeader title="Numeralia" />
          <LoadingForm
            loadinMessage={"Validando..."}
            successMessage="¡Consultado con éxito!"
            isLoadingForm={isLoadingForm}
            success={isSuccessFormSave}
            isOpen={openLinearProgress}
            setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
          />

          <Box sx={{ mt: 1, mb: 2 }}>
            <Grid2 container spacing={2}>
              {/* Filtro */}
              <Grid2 xs={12}>
                <FilterData
                  params={params}
                  setParams={setParams}
                  optionsF={optionsF}
                  handleFilter={handleFilter}
                />
              </Grid2>
              {/* Card de Texto y PieChart */}
              <Grid2 xs={12} lg={4}>
                <Box>
                  <Grid2 container spacing={2}>
                    <Grid2 xs={12} sm={6} lg={12}>
                      <TextCard
                        title={`LNOM / ${chartName}`}
                        dataAPI={NumeraliaServices.getNumeraliaTerritorial}
                        filtered={filtered}
                        change={change}
                      />
                    </Grid2>
                    <Grid2 xs={12} sm={6} lg={12}>
                      <PieChart
                        title={`Sexo`}
                        dataAPI={NumeraliaServices.getNumeraliaSexo}
                        filtered={filtered}
                        change={change}
                      />
                    </Grid2>
                  </Grid2>
                </Box>
              </Grid2>
              {/* Graficas de barras */}
              <Grid2 xs={12} lg={8}>
                <Box>
                  <Grid2 container spacing={2}>
                    <Grid2 xs={12} lg={12}>
                      <BarChartTop
                        title={`Peso electoral por ${chartName}`}
                        dataAPI={NumeraliaServices.getNumeraliaLnom}
                        filtered={filtered}
                        idCategories={optionsF[params.Selected - 2].idLabel}
                        idSeries={"Lista_Nominal"}
                        change={change}
                      />
                    </Grid2>

                    <Grid2 xs={12} md={6}>
                      <BarChartTop
                        title={`Tipo de sección/${chartName}`}
                        dataAPI={NumeraliaServices.getNumeraliaTipoSeccion}
                        filtered={filtered}
                        idCategories={"Tipo"}
                        idSeries={"Total"}
                        change={change}
                      />
                    </Grid2>
                    <Grid2 xs={12} md={6}>
                      <BarChartTop
                        title={`Sección alcance/${chartName}`}
                        dataAPI={NumeraliaServices.getNumeraliaTipoSeccion}
                        filtered={filtered}
                        idCategories={"Tipo"}
                        idSeries={"LNOM"}
                        change={change}
                      />
                    </Grid2>
                  </Grid2>
                </Box>
              </Grid2>
              <Grid2 xs={12} lg={12}>
                <Box>
                  <Grid2 container spacing={2}>
                    <Grid2 xs={12} lg={6}>
                      <BarGraph
                        title={`P. ML/${chartName}`}
                        filtered={filtered}
                        type="total"
                        API={NumeraliaServices.getPrioridadTotal}
                        change={change}
                      />
                    </Grid2>
                    <Grid2 xs={12} lg={6}>
                      <BarGraph
                        title={`ML LNOM/${chartName}`}
                        filtered={filtered}
                        API={NumeraliaServices.getPrioridadLNOM}
                        change={change}
                      />
                    </Grid2>
                  </Grid2>
                </Box>
              </Grid2>
              <Grid2 xs={12} lg={12}>
                <Box>
                  <Grid2 container spacing={2}>
                    <Grid2 xs={12} lg={9}>
                      <AgeGraphBar
                        title={"RANGO POR EDAD"}
                        data={age}
                        actualDate={actualDate}
                        loading={loading}
                        change={change}
                      />
                    </Grid2>
                    <Grid2 xs={12} lg={3}>
                      <BasicTable
                        rows={age.label.map((item, index) => ({
                          label: item,
                          hombre: numberWithCommas(age.hombre[index]),
                          mujer: numberWithCommas(age.mujer[index]),
                        }))}
                        hcolumns={[
                          { id: "label", label: "Edad" },
                          { id: "hombre", label: "Masculino", align: "right" },
                          { id: "mujer", label: "Femenino", align: "right" },
                        ]}
                        isLoading={loading}
                        stickyHeader={true}
                        showPagination={false}
                      />
                    </Grid2>
                  </Grid2>
                </Box>
              </Grid2>
            </Grid2>
          </Box>
        </Container>
      </AdminLayout>
    </>
  );
};

export default Numeralia;

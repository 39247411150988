import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import QrReader from "react-qr-reader";

// Material UI
import { Grid, Box, Button, Icon } from "@mui/material";
import { ThreeSixty } from "@mui/icons-material";

// Componentes
import { showSnackbar } from "@components/Alerts/CustomSnack";

const QrReaderComponent = (props) => {
  const {
    handleScan = null,
    setResult = () => {},
    result = null,
    maxWidth = "400px",
    delay = 2000,
    startMode = "environment",
    dontCloseOnScan = false,
    externalVerify = null,
    verifyUUID = false,
    verifyURL = false,
    open = false,
  } = props;

  const [resultLocal, setResultLocal] = useState("");
  const [openLocal, setOpenLocal] = useState(open);
  const [facingMode, setFacingMode] = useState(startMode);

  useEffect(() => {
    setOpenLocal(open);
  }, [open]);

  useEffect(() => {
    setResultLocal(result);
  }, [result]);

  const handleOpen = () => setOpenLocal(!openLocal);

  const handleFacingMode = () => {
    if (facingMode === "user") setFacingMode("environment");
    else setFacingMode("user");
  };

  const verficarDatos = (cadena) => {
    const verify = verifyUUID || verifyURL || externalVerify ? true : false;

    if (verify && !cadena) return null;

    // Verifica si la cadena es un UUID
    if (verifyUUID) {
      const regexUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
      if (regexUUID.test(cadena) && cadena.length === 36) return cadena;
    }

    // Verifica si la cadena es una URL y extrae la última parte
    if (verifyURL) {
      const regexURL = /^(https?:\/\/)?(www\.)?siangto\.com\/invitado\/(.+)$/i;
      const matchURL = cadena.match(regexURL);
      if (matchURL) return matchURL[3];

      const regexURLDebug = /^(http:\/\/)?localhost:3000\/invitado\/(.+)$/i;
      const matchURLDebug = cadena.match(regexURLDebug);

      if (matchURLDebug) return matchURLDebug[2];
    }

    // Verifica una funcion externa personable (debe retornar null o el valor a mandar)
    if (externalVerify) {
      const valuesExt = externalVerify(cadena);
      if (valuesExt !== null) return valuesExt;
    }

    if (verify) return null;

    return cadena;
  };

  const handleScanLocal = (itemQR) => {
    const verify = verficarDatos(itemQR);

    if (itemQR !== null) {
      if (verify) {
        if (verify !== resultLocal) {
          setResult(verify);
          if (result === null) setResultLocal(verify);
          if (!dontCloseOnScan) setOpenLocal(false);
        } else showSnackbar({ message: "¡Ya escaneaste este QR!", color: "warning" });
      } else showSnackbar({ message: "El QR no es permitido", color: "error" });
    }
  };

  const handleError = (e) => {};

  return (
    <Grid container spacing={2}>
      {openLocal && (
        <Grid item xs={12} sx={{}}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <QrReader
              facingMode={facingMode}
              delay={delay}
              onError={handleError}
              onScan={handleScan ?? handleScanLocal}
              style={{ width: "100%", maxWidth }}
            />
          </Box>
        </Grid>
      )}

      <Grid item xs={12} sm={openLocal ? 6 : 12}>
        <Button
          variant="outlined"
          onClick={handleOpen}
          fullWidth
          startIcon={<Icon>{openLocal ? "close" : "qr_code_scanner"}</Icon>}
        >
          {openLocal ? "Cerrar cámara" : "Escanear QR"}
        </Button>
      </Grid>

      {openLocal && (
        <Grid item xs={12} sm={6}>
          <Button variant="contained" onClick={handleFacingMode} endIcon={<ThreeSixty />} fullWidth>
            Girar cámara
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

QrReaderComponent.propTypes = {
  handleScan: PropTypes.func,
  setResult: PropTypes.func,
  result: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  startMode: PropTypes.oneOf(["environment", "user"]),
  delay: PropTypes.number,
  dontCloseOnScan: PropTypes.bool,
  externalVerify: PropTypes.func,
  verifyUUID: PropTypes.bool,
  verifyURL: PropTypes.bool,
  open: PropTypes.bool,
};

export default QrReaderComponent;

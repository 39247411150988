import React, { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, Grid, Typography,LinearProgress,Box } from "@mui/material";
import PositionCard from "./PositionCard";
import Swal from "sweetalert2";
import result from "@services/ResultServices";

const TableInfo = (props) => {
  const { idMunicipio = null, sx = {} } = props;
  const [data, setData] = useState({
    PRESIDENTE: [{ NombreCandidato: "", Municipio: "", substitute: "" }],
    Síndico: [{ NombreCandidato: "", Municipio: "", substitute: "" }],
    Regidor: [{ NombreCandidato: "", Municipio: "", substitute: "" }],
  });
  const [params/* , setParams */] = useState({
    page: 0,
    pageSize: 99999999,
  });
  const [showScrollHint, setShowScrollHint] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (idMunicipio !== null) {
      getData();
    }
    // eslint-disable-next-line
  }, [idMunicipio]);

  const handleScroll = (event) => {
    const container = event.currentTarget;
    if (
      container.scrollTop > 0 ||
      container.scrollHeight <= container.clientHeight
    ) {
      setShowScrollHint(false);
    } else {
      setShowScrollHint(true);
    }
  };

  useEffect(() => {
    const container = document.getElementById("table-container");
    if (container) {
      container.addEventListener("scroll", handleScroll);
      // Check if scroll is needed initially
      if (container.scrollHeight <= container.clientHeight) {
        setShowScrollHint(false);
      }
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [data]);

  const getData = () => {
    setIsLoading(true);
    result
      .getResponsable({
        ...params,
        filtered: [{ id: "idMunicipio", value: idMunicipio, filter: "=" }],
      })
      .then((res) => {
        const { results, response, message } = res;
        if (results) {
          setData(response);
        } else {
          setData([]);
          Swal.fire({
            title: message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        setData([]);
        Swal.fire({
          title: error.message,
          icon: "warning",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Card
      sx={{ ...sx, overflow: "auto", position: "relative" }}
      id="table-container"
    >
      {isLoading ? (
        <Box sx={{ width: '100%' }}>
            <LinearProgress />
        </Box>
      ) : (
        <CardContent>
          <CardHeader
            sx={{
              bgcolor: data.PRESIDENTE[0].Color,
              borderRadius: "16px",
              m: -2,
              mb: 1,
              color: "white",
              maxHeight: "55px",
            }}
            title={
              <Typography variant="h4" align="center" gutterBottom>
                {data.PRESIDENTE[0].Municipio}
              </Typography>
            }
          />
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              {data.PRESIDENTE.map((item) => (
                <PositionCard key={item.NombreCandidato} data={item} />
              ))}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" align="center">
                Síndico
              </Typography>
              <Grid container spacing={2} justifyContent="center">
                {data.Síndico.map((position, index) => (
                  <Grid item xs={12} sm={6} md={6} key={index}>
                    <PositionCard data={position} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" align="center">
                Regidurías
              </Typography>
              <Grid container spacing={2} justifyContent="center">
                {data.Regidor.map((position, index) => (
                  <Grid item xs={12} sm={6} md={6} key={index}>
                    <PositionCard data={position} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          {showScrollHint && (
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                position: "absolute",
                bottom: 10,
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                padding: "4px 8px",
                borderRadius: "4px",
              }}
            >
              Desliza para ver más
            </Typography>
          )}
        </CardContent>
      )}
    </Card>
  );
};

export default TableInfo;

import * as react from "react";

// MUI
import { Box, IconButton, } from "@mui/material";

import { WhatsApp as WhatsAppIcon, Message as MessageIcon, Phone as PhoneIcon, } from "@mui/icons-material";
import { Tooltip } from "@mui/material";


import Swal from "sweetalert2";

// UTILS
import { isTypePhone } from "@utils/validations";

const IconsMessage = (props) => {
  const { showIcons, params, fontSize, iconColor, hoverColor } = props;

  const redirectToWhatsApp = (phoneNumber) => {
    const whatsappUrl = `https://wa.me/52${phoneNumber}`;
    if (isTypePhone(phoneNumber)) window.open(whatsappUrl, "_blank");
    else
      Swal.fire({
        title: "Número invalido",
        text: `"${phoneNumber}" no es un número válido`,
        icon: "warning",
      });
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center" width="100%" m={1}>
      {showIcons.Phone && (
        <Tooltip title={`Teléfono: ${params.Phone}`} arrow>
          <IconButton
            edge="end"
            aria-label="teléfono"
            sx={{
              "&:hover": {
                color: hoverColor ? hoverColor : "rgb(0, 65, 160, .9)",
              },
            }}
          >
            <PhoneIcon sx={{ fontSize: fontSize ? fontSize : 24, color: iconColor ? iconColor: null}} />
          </IconButton>
        </Tooltip>
      )}
      {showIcons.WhatsApp && (
        <Tooltip title={`WhatsApp: ${params.WhatsApp}`} arrow>
          <IconButton
            edge="end"
            aria-label="whatsapp"
            onClick={() => redirectToWhatsApp(params.WhatsApp)}
            sx={{
              "&:hover": {
                color: hoverColor ? hoverColor : "rgb(0, 65, 160, .9)",
              },
              marginLeft: 1,
            }}
          >
            <WhatsAppIcon sx={{ fontSize: fontSize ? fontSize : 24, color: iconColor ? iconColor: null }} />
          </IconButton>
        </Tooltip>
      )}
      {showIcons.Message && (
        <Tooltip title={`Mensajes: ${params.Message}`} arrow>
          <IconButton
            edge="end"
            aria-label="mensajes"
            onClick={() => redirectToWhatsApp(params.Message)}
            sx={{
              "&:hover": {
                color: hoverColor ? hoverColor : "rgb(0, 65, 160, .9)",
              },
              marginLeft: 1,
            }}
          >
            <MessageIcon sx={{ fontSize: fontSize ? fontSize : 24, color: iconColor ? iconColor: null }} />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default IconsMessage;

import { useState, useEffect, Fragment, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useCatalogs } from "@hooks/useCatalogs";

import {
  Button,
  Card,
  CardContent,
  Container,
  Icon,
  Typography,
  Stack,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  ListItemText,
  Box,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import { blue, red, yellow } from "@mui/material/colors";
import Swal from "sweetalert2";

import moment from "moment";
import "moment/locale/es";

import AdminLayout from "@components/MainPage/AdminLayout";
import BasicTable from "@components/Tables/BasicTable";
import ActionHeader from "@components/Containers/ActionHeader";
import VolunteerForm from "@pages/Volunteers/VolunteerForm";
import Filter from "@components/Compromisos/HistoryFilter";
import CardsHistory from "@components/Compromisos/CardsHistory";
import CompromisoServices from "@services/CompromisoServices";
import { isEmptyOrNullObject } from "@utils/validations";
import middleware from "@middlewares/middleware";
import { setVars, getVars } from "@utils/global";

const SeccionCell = (props) => {
  const { item } = props;
  if (!item.SeccionValida) {
    return (
      <Fragment>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Icon sx={{ color: red[900] }}>warning</Icon>
          <Typography fontWeight="bold" fontSize={14} sx={{ color: red[900] }}>
            {item.SeccionVota}
          </Typography>
        </Stack>
      </Fragment>
    );
  }

  if (item.SeccionDiffVive) {
    return (
      <Fragment>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Icon sx={{ color: yellow[900] }}>warning</Icon>
          <Typography
            fontWeight="bold"
            fontSize={14}
            sx={{ color: yellow[900] }}
          >
            {item.SeccionVota}
          </Typography>
        </Stack>
      </Fragment>
    );
  }

  return <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.SeccionVota}</>;
};

const CaptureHistory = () => {
  //Constantes
  const colums = [
    { label: "Fecha", id: "FechaCreo", columnAction: false, width: 150 },
    {
      label: "Nombre Completo",
      id: "NombreCompleto",
      columnAction: false,
      width: 900,
      orderBy: "NombreCompleto",
    },
    {
      label: "Sección Vota",
      id: "Seccion",
      align: "center",
      columnAction: false,
      width: 300,
    },
    { label: "Municipio", id: "Municipio", columnAction: false, width: 350 },
    { label: "Colonia", id: "ColoniaVive", columnAction: false, width: 350 },
    {
      label: "Voluntario",
      id: "EsVoluntario",
      align: "center",
      columnAction: false,
    },
    { id: "TieneUsuario", label: "Tiene Usuario" },
    { id: "Responsabilidad", label: "Responsabilidad" },
  ];
  const catalogsOptions = [
    { id: "regiones", getAll: false },
    { id: "dl", getAll: false },
    { id: "df", getAll: false },
    { id: "municipios", getAll: false },
    { id: "municipios_dl", getAll: false },
    { id: "municipios_df", getAll: false },
    { id: "poligonos", getAll: false },
    { id: "secciones", getAll: false },
    { id: "perfiles_hijos", getAll: false },
    { id: "calidad_representante", getAll: false },
  ];

  let navigate = useNavigate();
  const [data, setData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [{ id: "compromisos.FechaCreo", value: "desc" }],
  });
  const [dataVars, setDataVars] = useState({
    page: !isEmptyOrNullObject(getVars("Historial"))
      ? getVars("Historial").page
      : 0,
    pageSize: !isEmptyOrNullObject(getVars("Historial"))
      ? getVars("Historial").pageSize
      : 10,
    filtered: [],
    sorted: [{ id: "compromisos.FechaCreo", value: "desc" }],
  });

  const { catalogs, load } = useCatalogs({
    catalogsOptions,
    putDefaultOption: true,
  });

  //State
  const [view, setView] = useState("table");
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [dataLisCommitments, setDataLisCommitments] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [selectedVolunteer, setSelectedVolunteer] = useState({});

  const getCaptureHistory = useCallback((data) => {
    setIsLoading(true);
    CompromisoServices.getCaptureHistory(data)
      .then((res) => {
        if (res.results) {
          const Commitments = res.response.data.map((item) => {
            let style = {};
            if (!item.SeccionValida) {
              style = {
                background: red[50],
                color: red[900],
              };
            } else {
              if (item.SeccionDiffVive) {
                style = {
                  background: yellow[50],
                  color: yellow[900],
                };
              }
            }

            return {
              ...item,
              Seccion: <SeccionCell item={item} />,
              FechaCreo: moment(item.FechaCreo).format("DD/MM/YYYY H:m"),
              NombreCompleto: (
                <Button
                  disableElevation
                  variant="text"
                  color="primaryDark"
                  size="small"
                  sx={{
                    ":hover": {
                      bgcolor: blue[900],
                      color: "white",
                    },
                    p: "0 auto",
                    textAlign: "left",
                  }}
                  startIcon={<Icon>edit</Icon>}
                  onClick={(e) => handleEdit(item)}
                >
                  <ListItemText
                    primary={
                      <Typography variant="div" fontWeight={700} fontSize={15}>
                        {item.NombreCompleto}
                      </Typography>
                    }
                    secondary={
                      <Typography fontWeight={500} fontSize={13}>
                        {item.Edad} Años
                      </Typography>
                    }
                  />
                </Button>
              ),
              EsVoluntario: `${item.EsVoluntario === 1 ? "Si" : "No"}`,
              Responsabilidad: middleware.checkMenuActionId("Agregar", 7) ? (
                <Button
                  disableElevation
                  variant="text"
                  color="primaryDark"
                  size="small"
                  sx={{
                    ":hover": {
                      bgcolor: blue[900],
                      color: "white",
                    },
                    p: "0 auto",
                    textAlign: "left",
                  }}
                  startIcon={<Icon>edit</Icon>}
                  onClick={(e) =>
                    handleEditVolunteer({ ...item, id: item.idCompromisoUnico })
                  }
                >
                  <ListItemText
                    primary={
                      <Typography variant="div" fontWeight={700} fontSize={15}>
                        {CellResponsibility(item.Responsabilidad)}
                      </Typography>
                    }
                  />
                </Button>
              ) : (
                CellResponsibility(item.Responsabilidad)
              ),
              TieneUsuario: item.TieneUsuario === 1 ? "Si" : "No",
              style,
            };
          });
          setDataLisCommitments(Commitments);
          setTotal(res.response.total);
          //setTotalvars(res.response.total);
          //setVars("Total",res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((err) => Swal.fire({ title: err, icon: "warning" }))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, []);

  const CellResponsibility = (value) => {
    if (value === null) return "Sin Responsabilidad";

    return (
      <>
        {value.split("|").map((item) => (
          <Typography>{item}</Typography>
        ))}
      </>
    );
  };

  useEffect(() => {
    const _dataVars = getVars("Historial");
    setDataVars((prevState) => ({ ...prevState, ..._dataVars }));
    getCaptureHistory(!isEmptyOrNullObject(_dataVars) ? _dataVars : data);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (sortedData.length > 0) {
      const params = {
        ...data,
        sorted: sortedData,
      };

      getCaptureHistory(params);
    }
    // eslint-disable-next-line
  }, [sortedData]);

  const handleEdit = (commitment) => {
    const { id } = commitment;

    CompromisoServices.getFindCommitments(id)
      .then((res) => {
        if (res.results) {
          navigate("/compromisos-registro", { state: res.response });
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((error) => Swal.fire({ title: error, icon: "warning" }));
  };

  const handleChangeFilter = (filtered) => {
    setData((prevState) => ({ ...prevState, filtered }));
    setVars("Historial", { ...data, page: 0, pageSize: 10, filtered });
    //setDataVars((prevState) => ({ ...prevState, filtered }));
  };

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
    setVars("Historial", { ...data, ...pagination });
  };

  const handleSorted = (dataSorted) => {
    const fields = {
      NombreCompleto:
        "CONCAT_WS(' ',compromisos.Nombre, compromisos.Paterno, compromisos.Materno)",
      FechaCreo: "compromisos.FechaCreo",
      Seccion: "compromisos.SeccionVota",
      Municipio: "cat_municipios.Municipio",
      ColoniaVive: "compromisos.ColoniaVive",
      EsVoluntario: "compromisos.EsVoluntario",
      TieneUsuario: "TieneUsuario",
      Responsabilidad: "Responsabilidad",
    };

    let sorted = [];
    if (!isEmptyOrNullObject(dataSorted)) {
      sorted.push({
        id: fields[dataSorted.orderBy],
        value: dataSorted.order,
      });
    }

    return sorted;
  };

  const handleRequestSort = (sortData) => {
    if (total > 0) setSortedData(handleSorted(sortData));
  };

  const handleEditVolunteer = (volunteer) => {
    setView("form");
    setSelectedVolunteer(volunteer);
  };

  const handleSubmit = (values) => {
    getCaptureHistory(data);
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title="Historial de captura" />
        {
          {
            table: (
              <Box>
                <CardsHistory load={load} />
                <Filter onChangeFilter={(e) => handleChangeFilter(e)} />
                <Card className="card-primary">
                  <CardContent>
                    <Grid2 container sx={{ p: 2 }} spacing={2}>
                      <Grid2 xs={12}>
                        <Table size="small">
                          <TableHead
                            sx={{ backgroundColor: "rgba(189, 189, 189, 0.3)" }}
                          >
                            <TableRow>
                              <TableCell>
                                <Typography variant="h6" fontWeight={700}>
                                  <Icon sx={{ mr: 2 }}>format_color_fill</Icon>
                                  Tabla de colores
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell sx={{ backgroundColor: yellow[50] }}>
                                La Sección donde Vota no corresponde al
                                municipio donde reside
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ backgroundColor: red[50] }}>
                                La Sección donde Vota no corresponde al estado o
                                posiblemente fue actualizada
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid2>
                    </Grid2>
                    <BasicTable
                      rows={dataLisCommitments}
                      hcolumns={colums}
                      handleChangePagination={handleChangePagination}
                      isLoading={load || isLoading}
                      total={total}
                      pageProp={
                        !isEmptyOrNullObject(dataVars) ? dataVars.page : data
                      }
                      pageSize={
                        !isEmptyOrNullObject(dataVars)
                          ? dataVars.pageSize
                          : data
                      }
                      stickyHeader={true}
                      handleManualSort={handleRequestSort}
                    />
                  </CardContent>
                </Card>
              </Box>
            ),
            form: (
              <VolunteerForm
                setView={setView}
                volunteer={selectedVolunteer}
                catalogs={catalogs}
                onSubmit={handleSubmit}
              />
            ),
          }[view]
        }
      </Container>
    </AdminLayout>
  );
};

export default CaptureHistory;

import {
  Box,
  CircularProgress,
  Icon,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { styledGridx4, styledGridx3 } from "../../theme";
import { blue } from "@mui/material/colors";

const GridDocuments = ({
  dataInfo,
  openDrawer,
  children,
  setOpenDrawer,
  handleDownloadAll,
}) => {
  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="subtitle2" fontWeight={600} fontSize={16}>
          Archivos
        </Typography>
        {dataInfo && dataInfo.sections?.length > 0 && (
          <Box display={"flex"} gap={1}>
            <Tooltip title={"Descargar todo"}>
              <IconButton onClick={handleDownloadAll}>
                <Icon>cloud_download</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip
              title={openDrawer ? "Ocultar detalles" : "Ver detalles"}
              onClick={() => setOpenDrawer(!openDrawer)}
            >
              <IconButton
                color={openDrawer ? "primaryDark" : "default"}
                sx={{ backgroundColor: openDrawer ? blue[100] : "transparent" }}
              >
                <Icon>info</Icon>
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Stack>
      <Box
        maxHeight={"calc(100vh - 260px)"}
        padding={1}
        overflow={"auto"}
        marginTop={3}
        sx={openDrawer ? styledGridx3 : styledGridx4}
      >
        {children}
      </Box>
    </>
  );
};

export default GridDocuments;

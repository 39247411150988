import React, { useState } from "react";
import {
  Box,
  Container,
  Divider,
  Icon,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import Navbar from "../Navbar";
import Toast from "../Toast";

import { getVars } from "@utils/global";
import { useMobileServices } from "@hooks/MobileDiaD/MobileServices";
import FormBingos from "./FormBingos";

const ScreenBingo = ({ setTypeScreen }) => {
  const { snackbar, setSnackbar } = useMobileServices();

  const {
    ruta_rg: { Ruta, Descripcion, Casillas },
  } = getVars("Token");

  const [showForm, setShowForm] = useState(false);
  const [casilla, setCasilla] = useState(null);

  const handleClickItem = (casilla) => {
    setShowForm(true);
    setCasilla(casilla);
  };

  return (
    <>
      {!showForm ? (
        <>
          <Toast
            open={snackbar.show}
            message={snackbar.message}
            setOpen={setSnackbar}
          />

          <Navbar
            isScreen
            screenBack={"rgs"}
            title={"Folio Bingo RG"}
            setTypeScreen={setTypeScreen}
            color="black"
          />

          <Container sx={{ mt: 4 }}>
            <Typography textAlign={"center"} fontWeight={600} marginBottom={2}>
              Folios Bingos
            </Typography>

            <Box textAlign={"center"} marginBottom={2}>
              <Typography variant="subtitle1" fontWeight={600}>
                {Ruta}
              </Typography>
              <Typography variant="subtitle1" fontWeight={600}>
                {Descripcion}
              </Typography>
            </Box>

            <Box>
              <Typography variant="body2" marginBottom={1} textAlign={"center"}>
                Seleccione la casilla para capturar los folios
              </Typography>
              <List>
                {Casillas &&
                  Casillas.map((casilla, index) => (
                    <div key={index}>
                      <ListItem
                        secondaryAction={<Icon>keyboard_arrow_right</Icon>}
                        onClick={() => handleClickItem(casilla)}
                      >
                        <ListItemText
                          primary={`${casilla.Seccion} - ${casilla.NombreCasilla}`}
                        />
                      </ListItem>
                      {index !== Casillas.length - 1 && <Divider />}
                    </div>
                  ))}
              </List>
            </Box>
          </Container>
        </>
      ) : (
        <FormBingos
          idCasilla={casilla.idCasilla}
          Seccion={casilla.Seccion}
          NombreCasilla={casilla.NombreCasilla}
          setShowForm={setShowForm}
        />
      )}
    </>
  );
};

export default ScreenBingo;

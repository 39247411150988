// Material UI
import {
  Typography,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Divider,
  Icon,
  Stack,
} from "@mui/material";
import { blue, yellow } from "@mui/material/colors";

// Utils
import { isNullOrUndefined } from "@utils/validations";

const RouteListItem = ({ item, selectedIndex, handleSelectedIndex, handleDeleteItem, idTipo }) => {
  const handleListItemClick = (event, index) => {
    let data = item;
    handleSelectedIndex(index, data);
  };

  return (
    <>
      <ListItem>
        <ListItemButton
          selected={selectedIndex === item.id}
          onClick={(event) => handleListItemClick(item, item.id)}
          disabled={idTipo === 0 ? (isNullOrUndefined(item.idRuta) ? false : true) : false}
          style={{
            backgroundColor: selectedIndex === item.id ? blue[100] : "transparent",
            "&:hover": { backgroundColor: blue[50] },
          }}
        >
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: yellow[900] }}>
              <Icon>assignment_ind</Icon>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={1}>
                <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.primary">
                  {item.NombreCompleto}
                </Typography>
                {item.Responsabilidad ? item.Responsabilidad : ""}
              </Stack>
            }
            secondary={
              idTipo === 0 ? (item.idRuta ? `Ruta: ${item.Ruta} (${item.Descripcion})` : "") : item.ruta
            }
          />
        </ListItemButton>
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};

export default RouteListItem;

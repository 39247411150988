import { Public } from "@middlewares";

import LoginSian from "@pages/auth/LoginSian";
import Confirmation from "@pages/auth/Confirmation";
import Register from "../../pages/auth/Register";
import RecoveryPasswd from "@pages/auth/RecoveryPasswd";
import UnsubscribedRequest from "@pages/auth/UnsubscribedRequest";
import PrivacyPolicy from "@pages/auth/PrivacyPolicy";
import ChangePassword from "@pages/auth/ChangePassword";
import DownloadApp from "@pages/auth/DownloadApp";
import HelpCenter from "@pages/auth/HelpCenter";
import Coaliciones from "@pages/auth/Coaliciones";

const AuthRoutes = [
  {
    path: "/login",
    element: (
      <Public>
        <LoginSian />
      </Public>
    ),
  },
  {
    path: "/confirmar-celular/:id",
    element: (
      <Public>
        <Confirmation />
      </Public>
    ),
  },
  {
    path: "/invitado/:id",
    element: (
      <Public>
        <Register />
      </Public>
    ),
  },
  {
    path: "recovery-passwd/:id",
    element: (
      <Public>
        <RecoveryPasswd />
      </Public>
    ),
  },
  {
    path: "solicitud-baja",
    element: (
      <Public>
        <UnsubscribedRequest />
      </Public>
    ),
  },
  {
    path: "privacidad",
    element: (
      <Public>
        <PrivacyPolicy />
      </Public>
    ),
  },
  {
    path: "/cambio-validation/:id",
    element: (
      <Public>
        <ChangePassword />
      </Public>
    ),
  },
  {
    path: "/descarga-app",
    element: (
      <Public>
        <DownloadApp />
      </Public>
    ),
  },
  {
    path: "/soporte",
    element: (
      <Public>
        <HelpCenter/>
      </Public>
    )
  },
  {
    path: "/coaliciones",
    element: (
      <Public>
        <Coaliciones />
      </Public>
    ),
  },
];

export default AuthRoutes;

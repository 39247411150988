import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  IconButton,
  Button,
  Icon,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Stack,
  LinearProgress,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Tooltip
} from "@mui/material";
import middleware from "@middlewares/middleware";
import CloseIcon from "@mui/icons-material/Close";
import electoral from "../../services/ElectoralServices";
import VolunteerServices from "@services/VolunteerServices";
import Swal from "sweetalert2";
import { red } from "@mui/material/colors";

const ProfileRepresentante = ({
  open,
  setOpen,
  title = { tipo: "Propietatario 1", casilla: "POR DEFINIR", seccion: 1 },
}) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState([]);

  useEffect(() => {
    if (open) {
      setData({});
      getData(title.id, title.idPropietario);
    }
  }, [title,open]);

  const getData = (casilla, propietario) => {
    if (loading) {
      return true;
    }
    setLoading(true);
    electoral
      .getDetalleRC({
        idCasilla: casilla,
        idPropietario: propietario,
      })
      .then((res) => {
        if (res.results) {
          setData(res.response.data);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (item) => {
    setOpen(false);
    Swal.fire({
      title: "¿Desea continuar?",
      html: `Esta acción eliminará la responsabilidad asignada a ${item?.NombreCompleto}. ¿Qué acción desea realizar?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, Cancelar",
      confirmButtonText: "Si, Eliminar",
      cancelButtonColor: red[800],
      reverseButtons: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      target: document.getElementById(this) 
    }).then((res) => {
      if (res.isConfirmed) {
        const params = {
          id: item.id,
          idCompromisoUnico: item.idCompromisoUnico,
        };
        setLoadingDelete([...loadingDelete, item.id]);
        VolunteerServices.deleteResponsibility(params)
          .then((res) => {
            if (res.results) {
             // getData(title.id, title.idPropietario);
            } else {
              Swal.fire({
                title: res.message,
                icon: "warning",
              });
            }
          })
          .catch((error) =>
            Swal.fire({
              title: error,
              icon: "warning",
          })
          )
          .finally(() => {
            let loding = loadingDelete.filter(i => i !== item.id);
            setOpen(true);
            setLoadingDelete(loding);
          });
      }
    });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            //overflowY: "auto",
            bgcolor: "white",
            borderRadius: "8px",
            boxShadow: 24,
            p: 2,
          }}
        >
          <IconButton
            aria-label="cancel"
            onClick={() => setOpen(false)}
            sx={{ position: "fixed", left: "3%", top: "2%", color: "#FFFFFF" }}
          >
            <Icon>cancel</Icon>
          </IconButton>
          <Card elevation={0}>
            <CardHeader
              title={`Información ${title.tipo}`}
              subheader={`Casilla ${title.casilla} Sección ${title.seccion}`}
              titleTypographyProps={{
                align: "center",
                variant: "h5",
                color: "white",
              }}
              subheaderTypographyProps={{ align: "center", color: "white" }}
              sx={{ backgroundColor: "#152A9E", pt: "25px" }}
            />
            {loading && data.length !== 0 ? <LinearProgress color="secondary" /> :
            (data.length > 1 ? (
              <CardContent>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 460,
                    maxHeight:360,
                    bgcolor: "background.paper",
                    overflow:"auto"
                  }}
                >
                   {
                      data.map(item => {
                        return<ListItem
                        secondaryAction={middleware.checkMenuAction("Eliminar") &&
                        <Tooltip title="Eliminar Responsabilidad" placement="right-start">
                          <IconButton edge="end" aria-label="delete" color="error" onClick={()=>handleDelete(item)}>
                            <Icon>delete</Icon>
                          </IconButton>
                          </Tooltip>
                          }
                        >
                        <ListItemAvatar>
                          <Avatar>
                            P1
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={item.NombreCompleto} secondary={item.Username} />
                      </ListItem>
                      })
                    }
                </List>
              </CardContent>
            ) : (
              <CardContent>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={0.5}
                >
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    Nombre:
                  </Typography>
                  <Typography component="div" variant="h6">
                    {loading ? <CircularProgress /> : data[0]?.NombreCompleto}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    Celular:
                  </Typography>
                  <Typography component="div" variant="h6">
                    {loading ? <CircularProgress /> : data[0]?.Username}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    Domicilio:
                  </Typography>
                  <Typography component="div" variant="h6">
                    {loading ? <CircularProgress /> : data[0]?.Domicilio}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    Sección Vota:
                  </Typography>
                  <Typography component="div" variant="h6">
                    {loading ? <CircularProgress /> : data[0]?.SeccionVota}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    Asignado por:
                  </Typography>
                  <Typography component="div" variant="h6">
                    {loading ? <CircularProgress /> : data[0]?.responsable}
                  </Typography>
                  <Typography component="div" variant="h6">
                    {loading ? <CircularProgress /> : data[0]?.FechaCreo}
                  </Typography>
                </Stack>
              </CardContent>
            ))}
          </Card>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="flex-end"
            spacing={0.5}
          >
            {data.length === 1 && middleware.checkMenuAction("Eliminar") && 
              <Button
              size="small"
              variant="contained"
              color="error"
              onClick={()=>handleDelete(data[0])}
              sx={{ borderRadius: "10px" }}
              endIcon={<Icon>delete</Icon>}
            >
              Eliminar
            </Button>
            }
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={() => setOpen(false)}
              sx={{ borderRadius: "10px" }}
              endIcon={<CloseIcon />}
            >
              Cerrar
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default ProfileRepresentante;

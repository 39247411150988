import { ApiExec, ApiExecDecrypted } from "@utils/ApiExec";

class NoticiasServices {
  getNoticias = (data) => ApiExec(data, "noticias/get-noticias", "POST");

  setNoticia = (data) => ApiExecDecrypted(data, "noticias/set-noticia", "POST", false);

  updateNoticia = (data) => ApiExecDecrypted(data, "noticias/update-noticia", "POST", false);

  deleteNoticia = (data) => ApiExec(data, "noticias/delete-noticia", "POST");
}

const service = new NoticiasServices();
export default service;

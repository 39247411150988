import { useState } from "react";
import Swal from "sweetalert2";

// Material UI
import {
  Grid,
  Box,
  CircularProgress,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
  TextField,
  FormHelperText,
  Card,
  CardContent,
  Button,
  Icon,
} from "@mui/material";
import { BackHandOutlined, BackHand, SmartToyOutlined, SmartToy } from "@mui/icons-material";

// Componentes
import LoadingForm from "@components/LinearProgress/LoadingForm";
import Filter from "@components/Activation/Asign/Filter";
import AdaptiveCard from "@components/Card/AdaptiveCard";
import CustomTable from "@components/Tables/CustomTable";
import CustomUploadFile from "@components/Files/CustomUploadFile";

// Utilidades
import { setVars } from "@utils/global";
import { isEmptyOrNullObject, isValidSection } from "@utils/validations";

const Config = (props) => {
  const {
    section,
    catView,
    handleFilter,
    handleClear,
    isLoading,
    mobilizers,
    distributionType,
    setDistributionType,
    limit,
    limitVariable,
    setLimit,
  } = props;

  const totalResponsables = mobilizers?.totalsResponsables?.reduce((result, item) => result + item.total, 0);
  const totalCompromisos = mobilizers?.totalsCompromisos?.reduce((result, item) => result + item.total, 0);

  const division =
    totalResponsables <= 0 || totalCompromisos <= 0 ? 0 : Math.ceil(totalCompromisos / totalResponsables);

  const [isLoadingImport, setIsLoadingImport] = useState({
    message: "Cargando",
    successMessage: "Cargado con exito!",
    isLoading: false,
    open: false,
  });

  const handleChangeCheck = (value) => {
    setVars("MobAsignDistributionType", value);
    setDistributionType(value);
  };

  const handleChangeLimit = (value) => {
    if (isValidSection(value) /* && value <= totalCompromisos */) {
      setVars("MobAsignMobilizationLimitVariable", value);
      setLimit(value);

      setVars("MobAsignDistributionType", "");
      setDistributionType("");
    }
  };

  const rows = [{ name: "Juan Antonio Rodriguez", cellphone: "61822334334" }];

  const columns = [
    { id: "name", label: "Movilizador", type: "text" },
    { id: "cellphone", label: "Celular", type: "text" },
  ];

  const handleFileUpload = () => {
    return { results: true, message: "API local de pruebas" };
  };

  const handleFileDownload = async () => {
    setIsLoadingImport({
      message: "Descargando plantilla",
      successMessage: "Plantilla descargada con éxito!",
      isLoading: true,
      open: true,
    });
    try {
    } catch (error) {
      Swal.fire({ title: error, icon: "warning" });
    } finally {
      setTimeout(() => {
        setIsLoadingImport((prev) => ({ ...prev, isLoading: false, open: false }));
      }, 2000);
    }
  };

  return (
    <Grid container spacing={3}>
      <LoadingForm
        loadinMessage={isLoadingImport.message}
        successMessage={isLoadingImport.successMessage}
        isLoading={isLoadingImport.isLoading}
        isOpen={isLoadingImport.open}
        setIsOpen={() => {}}
      />
      <Grid item xs={12}>
        <Filter onFilter={handleFilter} onClear={handleClear} catView={catView} />
      </Grid>

      {isLoading ? (
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
            <CircularProgress />
          </Box>
        </Grid>
      ) : (
        section > 0 &&
        !isEmptyOrNullObject(mobilizers) && (
          <>
            <Grid item xs={12}>
              <Grid container spacing={2} sx={{ px: { xs: 2, sm: 0 } }}>
                <Grid item xs={12} sm={6}>
                  <AdaptiveCard
                    title="Usuarios movilizadores"
                    config={{
                      title: totalResponsables === 0 ? "Añade movilizadores" : "",
                      data: totalResponsables,
                    }}
                    adjust
                    avatar
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <AdaptiveCard
                    title="Compromisos"
                    config={{
                      title: totalCompromisos === 0 ? "Añade compromisos" : "",
                      data: totalCompromisos,
                    }}
                    adjust
                    avatar
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ mb: 2 }} />
              <Box sx={{ px: { xs: 2, sm: 0 }, display: "flex", flexWrap: "wrap", gap: 2 }}>
                <Typography variant="h6" color="initial" sx={{ pt: 1 }}>
                  No. de compromisos sugeridos por movilizador:
                </Typography>
                <TextField
                  id="compromisos"
                  size="small"
                  label="Compromisos por movilizador"
                  helperText={`Cantidad sugerida: ${limit}`}
                  error={limitVariable === 0 || limitVariable === ""}
                  variant="outlined"
                  onChange={(e) => handleChangeLimit(e.target.value)}
                  value={limitVariable}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ px: { xs: 2, sm: 0 } }}>
                <Box
                  sx={{
                    display: { xs: "none", sm: "flex" },
                    gap: 2,
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Box sx={{ textAlign: "center" }}>
                    Compromisos <br /> <b>{totalCompromisos}</b>
                  </Box>
                  <Box sx={{ textAlign: "center", my: "auto", fontWeight: 700, fontSize: 20 }}>/</Box>
                  <Box sx={{ textAlign: "center" }}>
                    Responsables <br /> <b>{totalResponsables}</b>
                  </Box>
                  <Box sx={{ textAlign: "center", my: "auto", fontWeight: 700, fontSize: 20 }}>=</Box>
                  <Box sx={{ textAlign: "center", whiteSpace: "nowrap" }}>
                    Compromisos por movilizador <br /> <b>{division}</b>
                  </Box>
                </Box>
                <Box sx={{ display: { xs: "block", sm: "none" }, textAlign: "center" }}>
                  Compromisos <b>/</b> Responsables <b>=</b> Compromisos por movilizador
                  <br />
                  <b>{` ${totalCompromisos} / ${totalResponsables} = ${division}`}</b>
                </Box>

                {limitVariable < division && (
                  <FormHelperText error variant="filled" sx={{ fontSize: 16 }}>
                    Se recomienda agregar <b>{` ${division - limitVariable} `}</b>
                    {`compromiso${
                      division - limitVariable > 1 ? "s" : ""
                    } más por movilizador para esta sección`}
                  </FormHelperText>
                )}

                {limit < division && (
                  <FormHelperText error variant="filled" sx={{ fontSize: 16 }}>
                    Se recomienda agregar <b>{` ${Math.ceil(division / limit) - 1} `}</b>
                    {`movilizador${Math.ceil(division / limit) - 1 > 1 ? "es" : ""} más para esta sección`}
                  </FormHelperText>
                )}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ mb: 2 }} />
              <Card className="card-primary" sx={{ backgroundColor: { xs: "transparent", sm: "white" } }}>
                <CardContent sx={{ paddingY: 0, paddingX: 2 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} paddingX={1}>
                      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Button
                          onClick={handleFileDownload}
                          variant="contained"
                          size="small"
                          style={{ marginTop: "12px", backgroundColor: "#0E69BE" }}
                          startIcon={<Icon>download</Icon>}
                        >
                          <Typography align="left" variant="body3" fontWeight="400">
                            {"Descargar plantilla "}
                          </Typography>
                        </Button>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <CustomUploadFile
                        fileName="Excel"
                        acceptFiles=".xlsx"
                        API={handleFileUpload}
                        setLoading={(value) =>
                          setIsLoadingImport({
                            message: "Subiendo archivo",
                            successMessage: "Archivo subido con éxito!",
                            isLoading: value,
                            open: value,
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <CustomTable rows={rows} columns={columns} disableCardType />
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ mb: 2 }} />
              <Box sx={{ px: { xs: 2, sm: 0 } }}>
                <Typography>Elige el tipo de distribución que deseas utilizar:</Typography>
                <FormGroup row sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 1 }}>
                  <FormControlLabel
                    name="manual"
                    checked={distributionType === "manual"}
                    disabled={limitVariable === 0 || limitVariable === ""}
                    onChange={() => handleChangeCheck("manual")}
                    control={<Checkbox icon={<BackHandOutlined />} checkedIcon={<BackHand />} />}
                    label="Distribución manual"
                  />
                  <FormControlLabel
                    checked={distributionType === "auto"}
                    disabled={limitVariable === 0 || limitVariable === ""}
                    onChange={() => handleChangeCheck("auto")}
                    control={<Checkbox icon={<SmartToyOutlined />} checkedIcon={<SmartToy />} />}
                    label="Distribución automática"
                  />
                </FormGroup>
              </Box>
            </Grid>
          </>
        )
      )}
    </Grid>
  );
};

export default Config;

import { useEffect, useState } from "react";

import { Alert, Button, CardContent, TextField } from "@mui/material";

import Modal from "../../Modal/Dataview";

import movilizacion from "@services/MovilizationServices";
import Swal from "sweetalert2";

const NameEditing = ({
  row,
  open,
  editedData,
  data,
  setOpen,
  setReloadNameEdit,
}) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState({
    Paterno: "",
    Materno: "",
    Nombre: "",
  });
  const [dataEdit, setDataEdit] = useState([]);

  const handleChangeValue = (e) => {
    const { name, value: newValue } = e.target;

    setValue((prevValues) => ({
      ...prevValues,
      [name]: newValue.toUpperCase(),
    }));
  };

  const updateName = async () => {
    try {
      setLoading(true);

      const { results, success, message } = await movilizacion.updateName({
        idCompromisoUnico: row.id,
        Nombre: value.Nombre.trim(),
        Paterno: value.Paterno.trim(),
        Materno: value.Materno.trim(),
      });
      if (!results && !success) throw new Error(message);

      if (results && success) {
        setOpen(false);
        setReloadNameEdit(true);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setValue((prev) => ({
      ...prev,
      Paterno: row.Paterno,
      Materno: row.Materno,
      Nombre: row.Nombre,
    }));
  }, [row, open]);

  useEffect(() => {
    const modifiedData = editedData.filter((editedRow) => {
      const originalRow = data.find((row) => row.id === editedRow.id);
      return (
        originalRow &&
        originalRow.FolioBingo !== editedRow.FolioBingo &&
        editedRow.FolioBingo !== ""
      );
    });

    setDataEdit(modifiedData);
    // eslint-disable-next-line
  }, [editedData]);

  return (
    <Modal
      open={open}
      headerTitle="Editar Nombre"
      setOpen={setOpen}
      maxWidth="xs"
    >
      <CardContent>
        <TextField
          value={value.Paterno}
          name="Paterno"
          label="Paterno"
          variant="outlined"
          size="small"
          fullWidth
          autoFocus
          onChange={handleChangeValue}
          error={value.Paterno?.length < 2}
          helperText={
            value.Paterno?.length < 2 &&
            "El apellido paterno debe tener al menos dos caracteres"
          }
          sx={{ mb: 2 }}
        />
        <TextField
          value={value.Materno}
          name="Materno"
          label="Materno"
          variant="outlined"
          size="small"
          fullWidth
          onChange={handleChangeValue}
          error={value.Materno?.length < 2}
          helperText={
            value.Materno?.length < 2 &&
            "El apellido materno debe tener al menos dos caracteres"
          }
          sx={{ mb: 2 }}
        />
        <TextField
          value={value.Nombre}
          name="Nombre"
          label="Nombre"
          variant="outlined"
          size="small"
          fullWidth
          onChange={handleChangeValue}
          error={value.Nombre?.length < 2}
          helperText={
            value.Nombre?.length < 2 &&
            "El apellido Nombre debe tener al menos dos caracteres"
          }
          sx={{ mb: 2 }}
        />

        <Button
          variant="contained"
          color="primaryDark"
          size="small"
          fullWidth
          disabled={loading || dataEdit.length > 0}
          onClick={updateName}
        >
          {loading ? "Guardando..." : "Editar"}
        </Button>

        {dataEdit.length > 0 && (
          <Alert severity="warning" sx={{ mt: 2 }}>
            Antes de cambiar el nombre, asegúrate de guardar el progreso de los{" "}
            {dataEdit.length} folios asignados.
          </Alert>
        )}
      </CardContent>
    </Modal>
  );
};

export default NameEditing;

import { useEffect, useState } from "react";
import useWindowDimensions from "@hooks/useWindowDimensions";
import PropTypes from "prop-types";

import { Card, FormControlLabel, Paper, Slider, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { SwatchesPicker, MaterialPicker } from "react-color";
import { red, green, blue } from "@mui/material/colors";

const MyMaterialPicker = styled(MaterialPicker)(({ theme }) => ({
  width: "auto !important",
  height: "auto !important",
}));

const ColorPicker = (props) => {
  const { getColor, title = "Seleccione un color", height = "auto", initColor = "b71c1c" } = props;

  const { width } = useWindowDimensions();
  const [color, setColor] = useState(initColor && initColor.length > 0 ? initColor : "#b71c1c");
  const [redColor, setRedColor] = useState(183);
  const [greenColor, setGreenColor] = useState(28);
  const [blueColor, setBlueColor] = useState(28);

  useEffect(() => {
    if (initColor) {
      setColor(initColor);
    }
  }, [initColor]);

  const handleSelectColor = (color) => {
    setColor(color.hex);
    getColor(color.hex);
    setRedColor(color.rgb.r);
    setGreenColor(color.rgb.g);
    setBlueColor(color.rgb.b);
  };

  const handleSetColor = (color) => {
    setColor(color.hex);
    getColor(color.hex);
    setRedColor(color.rgb.r);
    setGreenColor(color.rgb.g);
    setBlueColor(color.rgb.b);
  };

  const decToHex = (dec) => {
    return dec.toString(16);
  };

  const padToTwo = (str) => {
    return str.padStart(2, "0");
  };

  const rgbToHex = (r, g, b) => {
    const hexR = padToTwo(decToHex(r));
    const hexG = padToTwo(decToHex(g));
    const hexB = padToTwo(decToHex(b));

    return `#${hexR}${hexG}${hexB}`;
  };

  const handleSliderColors = (r, g, b) => {
    setColor(rgbToHex(r, g, b));
    getColor(rgbToHex(r, g, b));
  };

  const handleChangesRedScale = (value) => {
    setRedColor(value);
    handleSliderColors(value, greenColor, blueColor);
  };

  const handleChangesGreenScale = (value) => {
    setGreenColor(value);
    handleSliderColors(redColor, value, blueColor);
  };

  const handleChangesBlueScale = (value) => {
    setBlueColor(value);
    handleSliderColors(redColor, greenColor, value);
  };

  return (
    <Stack
      spacing={2}
      direction={"column"}
      component={Card}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ overflow: "scroll", p: 2, height: height }}
    >
      <Typography gutterBottom variant="h6">
        {title}
      </Typography>
      <SwatchesPicker
        color={color}
        onChangeComplete={(e) => handleSelectColor(e)}
        width={"auto"}
        height={"auto"}
      />
      <Paper sx={{ width: 130, height: 130, padding: 2, backgroundColor: color }}></Paper>
      <MyMaterialPicker color={color} onChangeComplete={(e) => handleSetColor(e)} />
      <FormControlLabel
        label={
          <Typography color={red[500]} fontWeight={600} variant="h6">
            R
          </Typography>
        }
        control={
          <Slider
            value={redColor}
            valueLabelDisplay="auto"
            sx={{
              mr: 2,
              color: red[500],
              width: width / 4,
            }}
            min={0}
            max={255}
            onChange={(e) => handleChangesRedScale(e.target.value)}
          />
        }
      />

      <FormControlLabel
        label={
          <Typography color={green[500]} fontWeight={600} variant="h6">
            G
          </Typography>
        }
        control={
          <Slider
            value={greenColor}
            valueLabelDisplay="auto"
            sx={{
              mr: 2,
              width: width / 4,
              color: green[500],
            }}
            min={0}
            max={255}
            onChange={(e) => handleChangesGreenScale(e.target.value)}
          />
        }
      />

      <FormControlLabel
        label={
          <Typography color={blue[500]} fontWeight={600} variant="h6">
            B
          </Typography>
        }
        control={
          <Slider
            value={blueColor}
            valueLabelDisplay="auto"
            sx={{
              mr: 2,
              width: width / 4,
              color: blue[500],
            }}
            min={0}
            max={255}
            onChange={(e) => handleChangesBlueScale(e.target.value)}
          />
        }
      />
    </Stack>
  );
};

ColorPicker.propTypes = {
  initColor: PropTypes.string.isRequired,
  getColor: PropTypes.func.isRequired,
  title: PropTypes.string,
  height: PropTypes.any,
};

export default ColorPicker;

import Yup from "@utils/Yupi18n";

export const NoticiasSchema = Yup.object().shape({
  idTipo: Yup.number().required(),
  Titulo: Yup.string().min(5).max(50).required(),
  Descripcion: Yup.string().min(5).max(200).required().label("Descripción"),
  Contenido: Yup.string().min(100, "Escribe más contenido").required(),
  files: Yup.array().min(1).required().label("Imagen"),
  FileName: Yup.string()
    .min(1, "Imagen debe tener al menos 1 elementos")
    .max(100, "El nombre del la imagen debe tener máximo 100 caracteres")
    .required()
    .label("Imagen"),
  FechaPublicacion: Yup.date().required().label("Fecha de Publicación"),
});

export const EditNoticiasSchema = Yup.object().shape({
  id: Yup.number().required(),
  idTipo: Yup.number().required(),
  Titulo: Yup.string().min(5).max(50).required(),
  Descripcion: Yup.string().min(5).max(200).required().label("Descripción"),
  Contenido: Yup.string().min(100, "Escribe más contenido").required(),
  files: Yup.array().optional().label("Imagen"),
  FileName: Yup.string()
    .min(1, "Imagen debe tener al menos 1 elementos")
    .max(100, "El nombre de la imagen debe tener máximo 100 caracteres")
    .required()
    .label("Imagen"),
  FileURL: Yup.string().required(),
  FechaPublicacion: Yup.date().required().label("Fecha de Publicación"),
});

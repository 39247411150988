import { useEffect } from "react";

import { useMap } from "react-leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import "@components/Maps/Styles/GeoSearch.css";

const SearchField = ({ handleCoordsEvent }) => {
  const provider = new OpenStreetMapProvider();

  const handleCoords = (e) => handleCoordsEvent(e);

  // @ts-ignore
  const searchControl = new GeoSearchControl({
    provider: provider,
    style: "button",
    showMarker: false,
    showPopup: false,
    searchLabel: "!Busca tu direccion aquí!",
    popupFormat: ({ query, result }) => {
      if (result.x !== undefined && result.y !== undefined) {
        handleCoords({
          latitud: parseFloat(result.y),
          longitud: parseFloat(result.x),
        });
      }
      return result.label;
    },
    resultFormat: ({ result }) => result.label, // optional: function    - default returns result label
  });

  const map = useMap();
  useEffect(() => {
    map.addControl(searchControl);
    return () => map.removeControl(searchControl);
    // eslint-disable-next-line
  }, []);

  return null;
};

export default SearchField;

import {forwardRef} from 'react';
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography,Icon, CircularProgress } from '@mui/material';
import { numberWithCommas } from '@utils/Utilities';

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
}));


CardIconColor.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  totalTextL: PropTypes.string,
  totalTextR: PropTypes.string,
  sx: PropTypes.object,
};


const Iconify = forwardRef(({ icon, width = 20,fontSize=25, sx, ...other }, ref) => (
    <Icon ref={ref} sx={{ width, height: width,fontSize:fontSize, ...sx }} {...other} >
        {icon}
    </Icon>
));

export default function CardIconColor({ title, total, totalTextL, totalTextR, loading, icon, color = 'primary', sx,...other }) {  
  return (
    <Card
      elevation={6}
      sx={{
        py: 5,
        boxShadow: 1,
        borderRadius : 2,
        textAlign: 'center',
        color: (theme) => theme.palette[color].dark,
        ...sx,
      }}
      {...other}
    >
      <StyledIcon
        sx={{
          color: (theme) => theme.palette[color].light,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(theme.palette[color].light, 0)} 0%, ${alpha(
              theme.palette[color].dark,
              0.30
            )} 100%)`,
        }}
      >
        <Iconify icon={icon} width={24} height={24} />
      </StyledIcon>

      {loading ? <CircularProgress color={color}/> :
      <Typography variant="h3">{totalTextL} {numberWithCommas(total)} {totalTextR}</Typography>
      }
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title}
      </Typography>
    </Card>
  );
}

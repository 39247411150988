import { useState, useEffect } from "react";

import { Button, Card, CardContent, Divider, Stack, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import BasicSelect from "@components/Selects/BasicSelect";
import AdvancedSelect from "@components/Selects/AdvancedSelect";

import CatalogServices from "@services/CatalogServices";
import Swal from "sweetalert2";
import { CATALOG_OPTIONS } from "@data/constants/Rco";
import rco from "@services/RcosServices";

const FilterEstatal = ({
  //idAmbitoAgrupador,
  setIdAmbitoAgrupador,
  setData,
  onChangeFilter,
  onClearFilter,
}) => {
  const CATALOG_AVANCE_PORCENTAGE = [
    { value: 1, label: "0 < 25 %" },
    { value: 2, label: "25 % < 50 %" },
    { value: 3, label: "50 % < 75 %" },
    { value: 4, label: "75 % < 100 %" },
  ];

  const defaultOption = { value: 0, label: "TODOS" };
  const [typeView, setTypeView] = useState([]);
  const [catalogs, setCatalogs] = useState({});
  const [loadingCatalogs, setLoadingCatalogs] = useState({
    catalogs: false,
    ambitos: false,
  });
  const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    Object.keys(CATALOG_OPTIONS).forEach((key) => {
      CATALOG_OPTIONS[key.id] = [];
    });

    return newObject;
  });
  const [filterValue, setFilterValue] = useState({
    tipoVista: 0,
    region: 0,
    municipio: 0,
    poligono: 0,
    seccion: defaultOption,
    avanceEstructura: 0,
    avanceCompromisos: 0,
  });
  const [catalogAmbitos, setCatalogAmbitos] = useState([]);
  // const [idAmbito, setIdAmbito] = useState(0);

  const isIncluded = (value) => typeView.includes(value);

  const getCatalogs = async () => {
    setLoadingCatalogs((prevState) => ({
      ...prevState,
      catalogs: true,
    }));

    try {
      const result = await CatalogServices.getCatalogs(CATALOG_OPTIONS);
      // const result = await sije.getCatalogs({ catalogs: CATALOG_OPTIONS });
      const { results, response, message } = await result;
      if (results) {
        setCatalogFiltered(
          concatDefaultOption({
            ...catalogsFiltered,
            ...response.catalogs,
          })
        );
        setCatalogs(response.catalogs);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadingCatalogs((prevState) => ({
        ...prevState,
        catalogs: false,
      }));
    }
  };

  const getCatalogAmbitos = async () => {
    try {
      setLoadingCatalogs((prevState) => ({
        ...prevState,
        ambitos: true,
      }));
      const {
        results,
        response: { data },
        message,
      } = await rco.getCatalogAmbitos({});
      if (!results) {
        throw new Error(message);
      }

      const [valueAmbito] = data;
      setCatalogAmbitos(data);
      setIdAmbitoAgrupador(valueAmbito?.value);
      setTypeView([valueAmbito?.value]);
      setFilterValue((prevState) => ({
        ...prevState,
        tipoVista: valueAmbito?.value,
      }));
      setData((prevState) => ({
        ...prevState,
        idAmbitoAgrupador: valueAmbito?.value,
      }));
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoadingCatalogs((prevState) => ({
        ...prevState,
        ambitos: false,
      }));
    }
  };

  const concatDefaultOption = (data) => {
    let newObject = {};
    Object.keys(data).forEach((key) => {
      newObject[key] = [defaultOption, ...data[key]];
    });
    return newObject;
  };

  const handleChangeTypeView = ({ event }) => {
    const view = event.target.value;
    setIdAmbitoAgrupador(view);
    setFilterValue((prevState) => ({
      ...prevState,
      tipoVista: view,
      region: 0,
      municipio: 0,
      poligono: 0,
      seccion: defaultOption,
      avanceEstructura: 0,
      avanceCompromisos: 0,
    }));
    setTypeView([view]);
    setCatalogFiltered(
      concatDefaultOption({
        ...catalogsFiltered,
        ...catalogs,
      })
    );
    setData((prevState) => ({
      ...prevState,
      page: 0,
      pageSize: 25,
      filtered: [],
      idAmbitoAgrupador: view,
    }));
  };

  const handleChangeFilter = ({ name, value }) => {
    if (name === "seccion") {
      setFilterValue((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setFilterValue((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleCatalogFilterChange = ({ filterKey, filterValue, filterType }) => {
    setCatalogFiltered((prevState) => ({
      ...prevState,
      [filterKey]: [defaultOption, ...catalogs[filterKey].filter((item) => item[filterType] === filterValue)],
    }));
  };

  const handleChangeRegion = ({ event }) => {
    const _idRegion = event.target.value;
    if (_idRegion > 0) {
      handleCatalogFilterChange({
        filterKey: "municipios_reportes",
        filterType: "idRegion",
        filterValue: _idRegion,
      });
      handleCatalogFilterChange({
        filterKey: "poligonos",
        filterType: "idRegion",
        filterValue: _idRegion,
      });
      handleCatalogFilterChange({
        filterKey: "secciones",
        filterType: "idRegion",
        filterValue: _idRegion,
      });

      setFilterValue((prevState) => ({
        ...prevState,
        municipio: 0,
        poligono: 0,
        seccion: defaultOption,
      }));
    } else {
      setCatalogFiltered(
        concatDefaultOption({
          ...catalogsFiltered,
          ...catalogs,
        })
      );
      setFilterValue((prevState) => ({
        ...prevState,
        municipio: 0,
        poligono: 0,
        seccion: defaultOption,
      }));
    }
  };

  const handleChangeMunicipality = ({ event }) => {
    const _idMunicipality = event.target.value;
    if (_idMunicipality > 0) {
      handleCatalogFilterChange({
        filterKey: "poligonos",
        filterType: "idMunicipioReportes",
        filterValue: _idMunicipality,
      });
      handleCatalogFilterChange({
        filterKey: "secciones",
        filterType: "idMunicipioReportes",
        filterValue: _idMunicipality,
      });

      setFilterValue((prevState) => ({
        ...prevState,
        poligono: 0,
        seccion: defaultOption,
      }));
    } else {
      setCatalogFiltered(
        concatDefaultOption({
          ...catalogsFiltered,
          ...catalogs,
        })
      );
      setFilterValue((prevState) => ({
        ...prevState,
        poligono: 0,
        seccion: defaultOption,
      }));
    }
  };

  const handleChangePolygon = ({ event }) => {
    const _idPolygon = event.target.value;
    if (_idPolygon > 0) {
      handleCatalogFilterChange({
        filterKey: "secciones",
        filterType: "idPoligono",
        filterValue: _idPolygon,
      });

      setFilterValue((prevState) => ({
        ...prevState,
        seccion: defaultOption,
      }));
    } else {
      setCatalogFiltered(
        concatDefaultOption({
          ...catalogsFiltered,
          ...catalogs,
        })
      );
      setFilterValue((prevState) => ({
        ...prevState,
        seccion: defaultOption,
      }));
    }
  };

  const handleChangeStructureAdvancement = ({ event }) => {
    const PAvance = event.target.value;
    console.log(PAvance);
  };
  const handleSearch = () => {
    const filters = [
      {
        id: "idRegion",
        key: "region",
      },
      {
        id: "idMunicipioReportes",
        key: "municipio",
      },
      {
        id: "idPoligono",
        key: "poligono",
      },
      {
        id: "Seccion",
        key: "seccion",
      },
      {
        id: "PorcentajeAvanceEstructura",
        key: "avanceEstructura",
      },
      {
        id: "PorcentajeAvanceCompromisos",
        key: "avanceCompromisos",
      },
    ];

    /* const filtered = filters
      .filter((filter) => filterValue[filter.key] > 0)
      .map((filter) => ({
        id: filter.id,
        filter: "=",
        value: filterValue[filter.key],
      })); */

    const filtered = filters
      .filter((filter) => {
        const value = filterValue[filter.key];
        return value && (value.value || value > 0);
      })
      .map((filter) => ({
        id: filter.id,
        filter: "=",
        value: filterValue[filter.key].value || filterValue[filter.key],
      }));

    onChangeFilter({ filtereds: filtered });
  };

  const handleClear = () => {
    setFilterValue((prevState) => ({
      ...prevState,
      // tipoVista: idAmbito,
      region: 0,
      municipio: 0,
      poligono: 0,
      seccion: defaultOption,
      avanceEstructura: 0,
      avanceCompromisos: 0,
    }));
    //setTypeView([idAmbito]);
    setCatalogFiltered(
      concatDefaultOption({
        ...catalogsFiltered,
        ...catalogs,
      })
    );
    onClearFilter();
  };

  useEffect(() => {
    getCatalogs();
    getCatalogAmbitos();
    // eslint-disable-next-line
  }, []);

  return (
    <Card className="card-primary" sx={{ mt: 2 }}>
      <CardContent>
        <Typography variant="subtitle2" fontWeight={600} marginBottom={2}>
          FILTROS
        </Typography>

        <Grid2 container>
          <Grid2 xs={12} md={3}>
            <BasicSelect
              name="agrupador"
              label="Agrupar por"
              options={catalogAmbitos}
              value={filterValue.tipoVista}
              onChange={(e) => handleChangeTypeView({ event: e })}
              sx={{ width: "100%", mb: 2 }}
              isLoading={loadingCatalogs.ambitos}
            />
          </Grid2>
        </Grid2>
        <Divider sx={{ mt: 1 }} />

        <Grid2 container marginTop={1} spacing={2}>
          {(typeView.includes(2) || typeView.includes(5) || typeView.includes(7) || typeView.includes(8)) && (
            <Grid2 xs={12} sm={6} md={3} lg={2}>
              <BasicSelect
                name="region"
                label="Región"
                options={catalogsFiltered.regiones}
                value={
                  catalogsFiltered.regiones && catalogsFiltered.regiones?.length === 2
                    ? catalogsFiltered.regiones[1]?.value
                    : filterValue.region
                }
                onChange={(e) => {
                  handleChangeFilter({
                    name: e.target.name,
                    value: e.target.value,
                  });
                  handleChangeRegion({ event: e });
                }}
                isLoading={loadingCatalogs.catalogs}
                disabled={catalogsFiltered.regiones?.length === 2}
                sx={{ width: "100%" }}
              />
            </Grid2>
          )}

          {(isIncluded(3) || isIncluded(5) || isIncluded(7) || isIncluded(8)) && (
            <Grid2 xs={12} sm={6} md={3} lg={2}>
              <BasicSelect
                name="municipio"
                label="Municipio"
                options={catalogsFiltered.municipios_reportes}
                value={
                  catalogsFiltered.municipios_reportes && catalogsFiltered.municipios_reportes?.length === 2
                    ? catalogsFiltered.municipios_reportes[1]?.value
                    : filterValue.municipio
                }
                onChange={(e) => {
                  handleChangeFilter({
                    name: e.target.name,
                    value: e.target.value,
                  });
                  handleChangeMunicipality({ event: e });
                }}
                isLoading={loadingCatalogs.catalogs}
                disabled={catalogsFiltered.municipios_reportes?.length === 2}
                sx={{ width: "100%" }}
              />
            </Grid2>
          )}

          {(isIncluded(7) || isIncluded(8)) && (
            <Grid2 xs={12} sm={6} md={3} lg={2}>
              <BasicSelect
                name="poligono"
                label="Polígono"
                options={catalogsFiltered.poligonos}
                value={filterValue.poligono}
                onChange={(e) => {
                  handleChangeFilter({
                    name: e.target.name,
                    value: e.target.value,
                  });
                  handleChangePolygon({ event: e });
                }}
                isLoading={loadingCatalogs.catalogs}
                sx={{ width: "100%" }}
              />
            </Grid2>
          )}

          {isIncluded(8) && (
            <Grid2 xs={12} sm={6} md={3} lg={2}>
              <AdvancedSelect
                name="seccion"
                label="Sección"
                options={
                  catalogsFiltered.secciones !== undefined
                    ? catalogsFiltered.secciones.map((item) => ({
                        value: item.value,
                        label: String(item.label),
                      }))
                    : []
                }
                value={filterValue.seccion}
                onChange={(value) => handleChangeFilter({ name: value.name, value: value.value })}
                isSearchable
              />
            </Grid2>
          )}

          <Grid2 xs={12} sm={6} md={3} lg={2}>
            <BasicSelect
              name="avanceEstructura"
              label="% Avance Estructura"
              options={[defaultOption, ...CATALOG_AVANCE_PORCENTAGE]}
              value={filterValue.avanceEstructura}
              onChange={(e) => {
                handleChangeFilter({
                  name: e.target.name,
                  value: e.target.value,
                });
                handleChangeStructureAdvancement({ event: e });
              }}
              sx={{ width: "100%" }}
              isLoading={loadingCatalogs.catalogs}
            />
          </Grid2>

          <Grid2 xs={12} sm={6} md={3} lg={2}>
            <BasicSelect
              name="avanceCompromisos"
              label="% Avance Compromisos"
              options={[defaultOption, ...CATALOG_AVANCE_PORCENTAGE]}
              value={filterValue.avanceCompromisos}
              onChange={(e) => {
                handleChangeFilter({
                  name: e.target.name,
                  value: e.target.value,
                });
              }}
              sx={{ width: "100%" }}
              isLoading={loadingCatalogs.catalogs}
            />
          </Grid2>
        </Grid2>

        <Stack direction={"row"} spacing={1} marginTop={2} justifyContent={"flex-end"}>
          <Button variant="outlined" color="primaryDark" size="small" onClick={handleClear}>
            Limpiar
          </Button>
          <Button variant="contained" color="primaryDark" size="small" onClick={handleSearch}>
            Filtrar
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default FilterEstatal;

import {
  Checkbox,
  Icon,
  ListItemText,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
//import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
//import { TreeItem } from "@mui/x-tree-view/TreeItem";

const TreeResponsibles = ({ data, handleToggle, isChecked }) => {
  return (
    <>
      {data.map((item) => (
        <Accordion
          key={item.id}
          className="card-secondary"
          sx={{ ":before": { content: "none" }, mt: 1 }}
        >
          <AccordionSummary expandIcon={<Icon>expand_more</Icon>} sx={{ p: 0 }}>
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Checkbox
                edge="start"
                checked={
                  isChecked(item) ||
                  item.Propietarios?.every(
                    (propietario) => propietario.esPaseLista === 1
                  )
                }
                disabled={item.Propietarios?.every(
                  (propietario) => propietario.esPaseLista === 1
                )}
                onChange={(e) => handleToggle(e, e.target.checked, item)}
                onClick={(e) => e.stopPropagation()}
              />
              <ListItemText
                primary={
                  <Typography variant="body2" fontWeight={600}>
                    SECCIÓN {item.Seccion} - {item.NombreCasilla}
                  </Typography>
                }
              />
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            {item.Propietarios?.map((propietario) => (
              <Stack
                key={propietario.idUsuario}
                direction={"row"}
                alignItems={"center"}
              >
                <Checkbox
                  edge="start"
                  checked={
                    isChecked(item, propietario) || propietario.esPaseLista
                  }
                  disabled={propietario.esPaseLista === 1}
                  inputProps={{
                    "aria-labelledby": propietario.idUsuario,
                  }}
                  onChange={(e) => {
                    handleToggle(e, e.target.checked, item, propietario);
                  }}
                />
                <ListItemText
                  primary={
                    <Stack direction={"column"}>
                      <Typography variant="body2">{propietario.RC}</Typography>
                      {propietario.HoraPaseLista && (
                        <Typography variant="caption">
                          Hora de pase lista: {propietario.HoraPaseLista}
                        </Typography>
                      )}
                    </Stack>
                  }
                />
              </Stack>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </>

    /*   <SimpleTreeView
      sx={{ width: "100%" }}
      defaultCollapseIcon={<Icon>expand_more</Icon>}
      defaultExpandIcon={<Icon>chevron_right</Icon>}
    >
      {data.map((item) => (
        <TreeItem
          //key={item.id}
          //nodeId={item.id.toString()}
          key={Math.random().toString() + item.id}
          itemId={Math.random().toString() + item.id}
          label={
            // <ListItemButton sx={{ p: 0 }}>
            //   <ListItemIcon>
            //     <Checkbox
            //       edge="start"
            //       checked={isChecked(item)}
            //       onChange={(e) =>
            //         handleToggle(e, e.target.checked, item)
            //       }
            //     />
            //   </ListItemIcon>
            //   <ListItemText
            //     primary={`SECCIÓN ${item.Seccion} - ${item.NombreCasilla}`}
            //   />
            // </ListItemButton>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Checkbox
                edge="start"
                checked={
                  isChecked(item) ||
                  item.Propietarios?.every(
                    (propietario) => propietario.esPaseLista === 1
                  )
                }
                onChange={(e) => handleToggle(e, e.target.checked, item)}
                inputProps={{ "aria-labelledby": item.id }}
              />
              <ListItemText
                primary={`SECCIÓN ${item.Seccion} - ${item.NombreCasilla}`}
              />
            </Stack>
          }
          aria-expanded={isChecked(item) ? "true" : "false"}
          aria-haspopup="true"
          onClick={(e) => e.stopPropagation()}
        >
          {item.Propietarios.map((propietario) => (
            <TreeItem
              //key={propietario.idUsuario}
              key={Math.random().toString() + propietario.idUsuario}
              itemId={Math.random().toString() + propietario.idUsuario}
              label={
                // <ListItemButton sx={{ p: 0 }}>
                //   <ListItemIcon>
                //     <Checkbox
                //       edge="start"
                //       checked={isChecked(item, propietario)}
                //       onChange={(e) =>
                //         handleToggle(
                //           e,
                //           e.target.checked,
                //           item,
                //           propietario
                //         )
                //       }
                //     />
                //   </ListItemIcon>
                //   <ListItemText primary={propietario.RC} />
                // </ListItemButton>
                <Stack direction={"row"} alignItems={"center"}>
                  <Checkbox
                    edge="start"
                    checked={
                      isChecked(item, propietario) || propietario.esPaseLista
                    }
                    inputProps={{
                      "aria-labelledby": propietario.idUsuario,
                    }}
                    onChange={(e) => {
                      handleToggle(e, e.target.checked, item, propietario);
                    }}
                  />
                  <ListItemText primary={propietario.RC} />
                </Stack>
              }
            />
          ))}
        </TreeItem>
      ))}
    </SimpleTreeView> */
  );
};

export default TreeResponsibles;

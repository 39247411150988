import { useRef } from "react";
import { QRCode } from "react-qrcode-logo";
import Pdf from "react-to-pdf";

// Material UI
import { Container, Grid, Box, Typography, Paper, Button } from "@mui/material";
import { DownloadForOffline } from "@mui/icons-material";

function QRPreregistro(props) {
  const { dataQR, mensaje } = props;
  const pdfRef = useRef();

  return (
    <Box component="section" pt={6} my={6} sx={{ alignItems: "center" }}>
      <Container component="main" maxWidth="md">
        <Paper
          elevation={4}
          alignItems="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 2,
          }}
        >
          <Grid container item justifyContent="center" alignItems="center">
            <Typography color={"#00a152"} variant="h5">{`${mensaje}`}</Typography>
          </Grid>

          {dataQR && (
            <div
              style={{
                width: "100%",
                height: "100%",
                marginTop: 50,
                textAlign: "center",
              }}
              ref={pdfRef}
            >
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <Typography variant="h4" sx={{ color: "#1769aa" }} gutterBottom>
                    Pase Personal
                  </Typography>
                  <br />
                </Grid>
                <Grid item xs={12}>
                  <QRCode value={dataQR.UUID} />
                </Grid>
                <Grid item xs={12}>
                  <br />
                  <br />
                  <Typography variant="h6" sx={{ color: "#1769aa" }} gutterBottom>
                    {dataQR.NombreCompleto}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Folio: <strong>{dataQR.Folio}</strong>
                  </Typography>
                </Grid>
              </Grid>
            </div>
          )}
        </Paper>
      </Container>

      <Box alignItems="center">
        {dataQR && (
          <Grid container justifyContent="center" alignItems="center">
            <Pdf targetRef={pdfRef} filename={`Pase_${dataQR.NombreCompleto}_QR.pdf`} y={50}>
              {({ toPdf }) => (
                <Button variant="contained" size="small" onClick={toPdf} endIcon={<DownloadForOffline />}>
                  descargar QR
                </Button>
              )}
            </Pdf>{" "}
          </Grid>
        )}
      </Box>
    </Box>
  );
}

export default QRPreregistro;

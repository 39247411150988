import { useState } from "react";
import moment from "moment";
import Swal from "sweetalert2";
//Mui
import {
    Box,
    Card,
    CardContent,
    FormControlLabel,
    Radio,
    RadioGroup,
    Skeleton,
    Grid
} from "@mui/material";

//Components
import PrepSamplingHeaders from "@components/Prep/PrepSamplingHeaderss";

import Chart from "@components/Prep/ChartColumComparisonn";

import PrepFilter from "@components/Prep/Filter";
import DrawerRelative from "@components/Containers/DrawerRelative";
import FloatingButton from "@components/Button/FloatingButton";
import PrepTable from "@components/Prep/PrepTable";

import prep from "@services/PrepServices";
import { AMBITO_CASILLA, AMBITO_DF, AMBITO_DL, AMBITO_ESTATAL } from "@data/constants";
import { ELECCION_DIPUTADO_FEDERAL, ELECCION_DIPUTADO_LOCAL } from "@data/constants/Prep";

const PrepConteoRapidoMuestreo = () => {
    const [sampling, setSampling] = useState(true);
    const [series, setSeries] = useState([]);
    const [vista, setVista] = useState(1);
    const [loadingChart, setLoadingChart] = useState(true);
    const [loadingFilter, setLoadingFilter] = useState(true);
    const [summaryData, setSummaryData] = useState(
        {
            TipoEleccion: "",
            TotalActas: 0,
            ActasRecibidas: 0,
            TotalVotos: 0,
            LNom: 0,
            FechaHora: moment().format('DD/MM/YYYY HH:mm'),
            PActas: 0,
            Participacion: 0,
            Ambito: ""
        });

    const [params, setParams] = useState();
    const [tableParams, setTableParams] = useState({
        idTipoVista: 2,
        idAmbitoAgrupador: AMBITO_CASILLA,
        filtered: [
            {id: 'casillas.MuestraGto', filter: '=', value: 1}
        ]
    });

    const [openDrawer, setOpenDrawer] = useState(true);

    const handleChangeRadio = (event) => {
        setVista(event.target.value);
    };

    const handleChangeFilter = (filtered) => {

        if (!filtered.idTipoEleccion || !filtered.idAmbitoAgrupador) {
            return Swal.fire({
                icon: 'info',
                title: 'Parámetros insuficiente'
            });
        }

        let params = {
            ...filtered,
            ValorAmbito: filtered.ValorAmbito ? filtered.ValorAmbito : 0,
            idTipoVista: parseInt(vista),
        };

        setParams(params);

        const { idTipoVista, ...paramsSummary } = params;

        setTableParams((prev) => ({
            ...prev, 
            ...paramsSummary,
            idAmbitoAgrupador: AMBITO_CASILLA,
            filtered: [
                {id: 'MuestraC27', filter: '=', value: 1}
            ]
        }))

        if (filtered.idAmbitoAgrupador === AMBITO_CASILLA) {
            if (filtered.ValorAmbito === 0) {
                switch (filtered.idTipoEleccion) {
                    case ELECCION_DIPUTADO_FEDERAL:
                        getChartAndSummary({
                            ...params,
                            idAmbitoAgrupador: AMBITO_DF
                        });
                        break;
                    case ELECCION_DIPUTADO_LOCAL:
                        getChartAndSummary({
                            ...params,
                            idAmbitoAgrupador: AMBITO_DL
                        });
                        break;
                    default:

                        getChartAndSummary({
                            ...params,
                            idAmbitoAgrupador: AMBITO_ESTATAL
                        });
                        break;
                }
            } else {
                getChartAndSummary(params);
            }
        } else {
            getChartAndSummary(params);
        }
    }

    const getChartAndSummary = (params) => {      
        const updatedParams = { ...params, Muestra: "C27" };

        setLoadingChart(true);
        setLoadingFilter(true);
        prep.getQuickCount(updatedParams).then(res => {
            const { results, response, message } = res;
            if (results) {
                setSeries(response.data.series);
                setSummaryData(response.data.summary);
            } else {
                setSeries([]);
                Swal.fire({
                    title: message,
                    icon: "warning",
                });
            }
        }).catch(error => {
            setSeries([]);
            Swal.fire({
                title: error.message,
                icon: "warning",
            });
        }).finally(() => {
            setLoadingChart(false);
            setLoadingFilter(false);
        });
    }

    const handleRefresh = () => {
        // setSummaryData((prev) => ({ ...prev }))
        // setParams((prev) => ({ ...prev }))
        // getChartAndSummary(params)
        handleChangeFilter(params)
    };

    return (
        <>
            <Grid
                container
                spacing={2}
                sx={{ flexDirection: { xs: "column-reverse", md: "row" }, mt: 2 }}
            >
                <Grid item xs={12} md={openDrawer ? 9 : 12}>
                    <PrepSamplingHeaders
                        sampling={sampling}
                        setSampling={setSampling}
                        summaryData={summaryData}
                        loadingCards={loadingChart}
                        handleRefresh={handleRefresh}
                        openDrawer={openDrawer}
                    />

                    <Box component={Card} className="card-primary">
                        <Box component={CardContent}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={vista}
                                onChange={handleChangeRadio}
                                sx={{ mb: 2, display: "flex", justifyContent: "center" }}
                            >
                                <FormControlLabel
                                    value={1}
                                    control={<Radio />}
                                    label="Vista por candidatos"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value={2}
                                    control={<Radio />}
                                    label="Vista por partidos"
                                    labelPlacement="end"
                                />
                            </RadioGroup>

                            {
                                loadingChart ? (
                                    <Skeleton animation="wave" variant="rounded" width={"100%"} height={400} />
                                ) : (
                                    <Chart
                                        Series={series}
                                        tipoVista={vista}
                                        loading={loadingChart}
                                        percent={true}
                                        openDrawer={openDrawer}
                                    />
                                )
                            }
                        </Box>
                    </Box>

                    <PrepTable 
                        title="Mostrando detalle por casilla" 
                        params={tableParams}
                        setLoadingFilter={setLoadingFilter}
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <DrawerRelative
                        anchor="right"
                        open={openDrawer}
                        setOpen={setOpenDrawer}
                        title={"Filtrar"}
                        icon="person_search"
                        titleComponentPlace="under"
                        className="card-primary"
                        sxPaper={{ borderRadius: 3 }}
                        isSticky
                    >
                        <PrepFilter onChangeFilter={handleChangeFilter} disabledButtons={loadingFilter} />
                    </DrawerRelative>
                </Grid>
            </Grid>

            {!openDrawer && (
                <FloatingButton
                    onClick={() => setOpenDrawer(true)}
                    label="Mostrar Filtros"
                />
            )}

        </>
    );
};

export default PrepConteoRapidoMuestreo;

import { useState } from "react";

// Material UI
import { Card, CardContent } from "@mui/material";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import CustomTabs from "@components/Tabs/CustomTabs";
import Form from "@components/Noticias/Form";
import View from "@components/Noticias/View";

// Middleware
import middleware from "@middlewares/middleware";

// Hooks
import { useCatalogs } from "@hooks/useCatalogs";

const Noticias = ({ page }) => {
  const add = middleware.checkMenuAction("Agregar");
  const editable = middleware.checkMenuAction("Editar") || middleware.checkMenuAction("Eliminar");

  const [tab, setTab] = useState(add ? 0 : 1);

  const catalogsOptions = [{ id: "cat_tipo_noticia" }];

  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsOptions,
    putDefaultOption: false,
  });

  return (
    <AdminLayout>
      <ActionHeader title="Noticias" />

      <Card className="card-primary">
        <CardContent key={page}>
          <CustomTabs
            value={tab}
            setValue={setTab}
            tabs={[
              {
                value: 0,
                label: "Agregar",
                icon: "add",
                sxContent: { pt: 0 },
                component: <Form idPagina={page} catalogs={catalogs} load={load} />,
                display: add,
              },
              {
                value: 1,
                label: editable ? "Editar" : "Ver",
                icon: editable ? "edit" : "visibility",
                sxContent: { pt: 0 },
                component: <View idPagina={page} catalogs={catalogs} load={load} />,
              },
            ]}
          />
        </CardContent>
      </Card>
    </AdminLayout>
  );
};

export default Noticias;

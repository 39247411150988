import { useState } from "react";
import { useFormik } from "formik";

// Material UI
import {
  Box,
  TextField,
  Button,
  FormHelperText,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

import Swal from "sweetalert2";

// Componentes
import InputSelect from "@components/Selects/BasicSelect";

//Validaciones
import { isEmptyOrInvalidString, validateNumber } from "@utils/validations";
import { AddGoalInterface } from "@interfaces/GoalsInterfaces"
import { AddGoalSchema } from "@schemas/GoalsSchemas"

import metas from "@services/MetasServices";

const FormTable = (props) => {
  const { catalogs, load, setLoading, refreshTable } = props;

  const defaultOption = { value: 0, label: 'NINGUNO' }
  // FORMIK PARA FORMULARIO DE REGISTRO
  const formik = useFormik({
    initialValues: AddGoalInterface,
    validationSchema: AddGoalSchema,
    onSubmit: async (values) => {
      await saveItem(values);
    },
  });
  const [catDF, setCatDF] = useState([]);
  //MOSTRAR O NO INPUT DF
  const [requiereDF, setRequiereDF] = useState(false);


  const checkRequiereDF = (perfilSeleccionado) => {
    // Busca el perfil seleccionado en el catálogo de perfiles
    const perfil = catalogs.perfil.find(
      (perfil) => perfil.value === perfilSeleccionado
    );

    // Si se encuentra el perfil, actualiza el estado requiereDF
    if (perfil) {
      setRequiereDF(perfil.RequiereDF === 1);
    }
  };

  const handlePerfilChange = (e) => {
    // Llamada a la función original onBlurItem porque no pueden haber 2 onChange juntos
    formik.setFieldValue('idPerfil', e.target.value);
    // Comprueba si el perfil seleccionado requiere DF
    checkRequiereDF(e.target.value);
  };


  const handleMunicipio = (e) => {
    const idMunicipio = e.target.value;

    const filteredcatDF = catalogs.municipios_df.filter((item) => item.idMR === idMunicipio);
    setCatDF([defaultOption].concat(filteredcatDF));

    //Cuando cambia el filter, checa si hay uno y si si, pone por defecto el 1.
    if (requiereDF && filteredcatDF.length === 1) {
      formik.setValues({
        ...formik.values,
        idMunicipioReportes: idMunicipio,
        idDF: filteredcatDF[0].value
      });
    } else {
      formik.setValues({
        ...formik.values,
        idMunicipioReportes: idMunicipio,
        idDF: ''
      });
    }
  };

  const saveItem = async (values) => {
    if (requiereDF && isEmptyOrInvalidString(values.idDF)) {
      return
    }

    setLoading({
      isLoading: true,
      isOpen: true,
      isSuccess: false,
      loadingMessage: "Guardando...",
      successMessage: "Guardado con éxito!",
    });

    try {
      // Realizando la llamada a la API
      const result = await metas.setElectoralGoal(values);
      // Manejo de la respuesta
      const { success, message } = result;

      Swal.fire({
        title: message,
        icon: success ? "success" : "warning",
      });

      if (success) {
        refreshTable();
      }
    } catch (error) {

      // Manejo de errores
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoading({
        isLoading: false,
        isOpen: false,
        isSuccess: true,
        loadingMessage: "",
        successMessage: "",
      });
    }
  }

  //Limpiar campos de formik
  const clearForm = () => {
    formik.resetForm();
    //Llena el setCatalogFilter con la informacion que guarda
    setCatDF([]);
    setRequiereDF(false);
  };

  return (
    <Box>
      <Grid2 container spacing={3} mb={2}>

        {/* Select de Perfil */}
        <Grid2 xs={12} sm={12} md={8} lg={4}>
          <InputSelect
            label="Selecciona un perfil"
            name="idPerfil"
            sx={{ width: "100%" }}
            value={formik.values.idPerfil}
            options={catalogs.perfil}
            //Esta funcion muestra o no el Input DF
            onChange={handlePerfilChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.idPerfil &&
              !isEmptyOrInvalidString(formik.errors.idPerfil)
            }
            errorMessage={formik.errors.idPerfil}
            isLoading={load}
          />
          {formik.values.idPerfil === "" && (
            <FormHelperText>Seleccione el perfil</FormHelperText>
          )}
        </Grid2>

        {/* Select de Municipios */}
        <Grid2 xs={12} sm={12} md={8} lg={4}>
          <InputSelect
            label="Selecciona un municipio"
            name="idMunicipioReportes"
            sx={{ width: "100%" }}
            value={formik.values.idMunicipioReportes}
            options={catalogs.municipios_dl}
            onChange={handleMunicipio}
            onBlur={formik.handleBlur}
            error={
              formik.touched.idMunicipioReportes &&
              !isEmptyOrInvalidString(formik.errors.idMunicipioReportes)
            }
            errorMessage={formik.errors.idMunicipioReportes}
            isLoading={load}
          />
          {formik.values.idMunicipioReportes === "" && (
            <FormHelperText>Seleccione el municipio</FormHelperText>
          )}
        </Grid2>

        {/* Validacion de si se muestra o no el input */}
        <Grid2 xs={12} sm={12} md={9} lg={4}>
          <InputSelect
            required
            fullWidth
            multiline
            label="Ingresa un DF"
            name="idDF"
            value={formik.values.idDF}
            options={catDF}
            sx={{ width: "100%" }}
            type="text"
            variant="outlined"
            size="small"
            // className="fixed-input"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.idDF &&
              ((!isEmptyOrInvalidString(formik.errors.idDF) ||
                formik.values.idDF === "") && requiereDF)
            }
            errorMessage={formik.errors.idDF}
            helperText={
              formik.touched.idDF ||
              formik.errors.idDF ||
              isEmptyOrInvalidString(formik.values.idDF)
            }
            isLoading={load}
          />
          <FormHelperText error>{(isEmptyOrInvalidString(formik.values.idDF) && requiereDF) && "Seleccione el DF"}</FormHelperText>
        </Grid2>

        <Grid2 xs={12} sm={12} md={9} lg={4}>
          <TextField
            required
            fullWidth
            multiline
            label="Ingresa una meta"
            name="Meta"
            value={formik.values.Meta}
            sx={{ width: "100%" }}
            type="text"
            variant="outlined"
            size="small"
            // className="fixed-input"
            onChange={(e) =>
              formik.handleChange({
                target: {
                  name: "Meta",
                  value: validateNumber(e.target.value)
                    ? e.target.value
                    : formik.values.Meta,
                },
              })
            }
            onBlur={formik.handleBlur}
            error={
              formik.touched.Meta &&
              (!isEmptyOrInvalidString(formik.errors.Meta) ||
                formik.values.Meta === "")
            }
            errorMessage={formik.errors.Meta}
            helperText={
              formik.touched.Meta ||
              formik.errors.Meta ||
              isEmptyOrInvalidString(formik.values.Meta)
            }
          />
          <FormHelperText error>{isEmptyOrInvalidString(formik.values.Meta) && "Ingrese el valor de la meta"}</FormHelperText>
        </Grid2>

        <Grid2 xs={12} sm={12} md={6} lg={4}>
          <Box>
            <Grid2 container spacing={2}>
              <Grid2 xs={12} sm={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primaryDark"
                  onClick={formik.handleSubmit}
                  sx={{ paddingX: 4 }}
                >
                  Agregar
                </Button>
              </Grid2>
              <Grid2 xs={12} sm={6}>
                <Button
                  onClick={clearForm}
                  fullWidth
                  variant="outlined"
                  sx={{ paddingX: 4 }}
                >
                  Limpiar
                </Button>
              </Grid2>
            </Grid2>
          </Box>
        </Grid2>
      </Grid2>

    </Box>
  )
}

export default FormTable;
import React, { useEffect, useState, useRef, memo } from "react";
import { useNavigate } from "react-router-dom";
import Yup from "@utils/Yupi18n";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Icon,
  Typography,
  Checkbox,
  FormControlLabel,
  TextField,
  LinearProgress,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import BasicList from "./ListElectoral";
import ArchivoListItem from "./ArchivoListItem";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import electoral from "@services/ElectoralServices";
import { useCatalogs } from "@hooks/useCatalogs";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { convertBase64 } from "@utils/Utilities";
import middleware from "@middlewares/middleware";
import TableGenerarRG from "./TableGenerarRG";


const ExportRGSList = () => {
  
  const fileInputRef = useRef(null);
  const fileInputRefExcel = useRef(null);
  const animatedComponents = makeAnimated();
  const catalogsOptions = [{ id: "df", getAll: false }];
  const config = {
    title: "Historial de Archivos",
    icon: "people",
    height: 639,
    placeholder: "Buscar Archivo",
    endMessage: "No hay más registros para mostrar",
  };
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingValidate, setIsLoadingValidate] = useState(false);
  const [idTxt, setIdTxt] = useState();
  const [params, setParams] = useState({
    page: 0,
    pageSize: 10,
    idPerfil: 3,
  });
  const [paramstxt, setParamstxt] = useState({
    page: 0,
    pageSize: 10,
    idPerfil: 2,
  });

  const [hasMore, setHasMore] = useState(false);

  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: "9999 !important" }),
  };

  const formik = useFormik({
    initialValues: {
      df: 0,
      remuneracion: 0,
    },
    validationSchema: Yup.object()
      .shape({
        df: Yup.number().min(1).required().label("Distrito Federal"),
        remuneracion: Yup.number().required(),
      }),
    onSubmit: (values) => {
      generar(values);
    },
  });
  const [flagbutton, setFlagButton] = useState(false);
  //const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Subiendo datos...");

  const [idFile, setIdFile] = useState(null);

  useEffect(() => {
    getTxt();
  }, [params]);

  useEffect(() => {
    if (Object.values(formik.errors).length > 0 && flagbutton) {
      Swal.fire({
        icon: "error",
        backdrop: false,
        title: Object.values(formik.errors).toString(),
      }).then((result) => {
        if (result.isConfirmed) {
          setFlagButton(false);
        }
      });
    }
  }, [formik.errors, flagbutton]);

  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsOptions,
    putDefaultOption: false,
  });

  const handleClickItem = (event) => {
    // setParams({...params,page:0,filtered:[]})
    setIdTxt(event.id);
  };

  const handleSearch = (event) => {
    const { Nombre, DF, Estatus } = event;
    console.log(event);
    let filtro = [];
    if (Nombre && Nombre.length > 0) {
      filtro.push({
        id: "registros_export_txt_electoral.NombreArchivo",
        value: `%${Nombre}%`,
        filter: "LIKE",
      });
    }
    if (DF > 0) {
      filtro.push({
        id: "registros_export_txt_electoral.AmbitoValor",
        value: DF,
        filter: "=",
      });
    }
    if (Estatus > 0) {
      filtro.push({
        id: "registros_export_txt_electoral.idEstatus",
        value: Estatus,
        filter: "=",
      });
    }

    setParams({
      ...params,
      page: 0,
      filtered: filtro,
    });
  };

  const generar = (value) => {
    if (isLoading) {
      return true;
    }
    setIsLoading(true);

    let data = {
      filtered: [
        { id: "cat_df.id", value: formik.values.df, filter: "=" },
      ],
      remuneracion: formik.values.remuneracion,
    };
    electoral
      .exportTXTRGs(data)
      .then((response) => {
        if (response.success && response.results) {
          setIdTxt(response.data);
          setParams({
            ...params,
            page: 0,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Ha ocurrido un error",
          text: `No se pudo descargar el archivo, intente de nuevo, ${error.message}`,
          icon: "warning",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getTxt = () => {
    if (loading) {
      return true;
    }
    setLoading(true);
    electoral
      .getListTXTRG(params)
      .then((res) => {
        if (res.results) {
          if (params.page === 0) {
            setData(
              res.response.data.map((item) => {
                return { ...item };
              })
            );
            setTotal(res.response.total);
          } else {
            setData(data.concat(res.response.data));
            setTotal(res.response.total);
          }

          let end =
            (params ? params.page + 1 : params.page + 1) * params.pageSize;

          if (end >= res.response.total) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchHasMore = () => {
    setParams({
      ...params,
      page: params.page + 1,
    });
  };

  const handleDeleteItem = (e) => {
    Swal.fire({
      title: "Eliminar Listado",
      text: "¿Estás seguro de eliminar el listado?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const res = await electoral.deleteFileRG({ idTxt: e, idPerfil: 3 });
          if (res.results) {
            return res;
          } else {
            throw new Error(res.message);
          }
        } catch (error) {
          Swal.showValidationMessage(`${error.message}`);
          return false;
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.value && result.value.results) {
        setIdTxt(0);
        setParams({
          ...params,
          page: 0,
        });
        Swal.fire({
          title: "Eliminado Correctamente",
          icon: "success",
        });
      }
    });
  };

  const handleValidate = (e) => {
    setIsLoadingValidate(true);
    Swal.fire({
      title: "Validar el reporte",
      text: "Esta Seguro de validar el listado completo de rcs este colocara a todos los rcs en el listado como certificados",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        try {
          const res = await electoral.validateFile({ idTxt: e, idPerfil: 3 });
          if (res.results) {
            setIdTxt(e);
            setParams({
              ...params,
              page: 0,
            });
            Swal.fire({
              title: res.message,
              icon: "success",
            });
          } else {
            setIsLoadingValidate(false);
            throw new Error(res.message);
          }
        } catch (error) {
          setIsLoadingValidate(false);
          Swal.showValidationMessage(`${error.message}`);
          return false;
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const handleSelect = (id,type) => {
    setIdFile(id);
    if(type === 1){
      fileInputRef.current.click();
    }else{
      fileInputRefExcel.current.click();
    }
    
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const type = e.target.name;

    const allowedTypes = [
      "text/plain",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    try {
      if (!allowedTypes.includes(file.type)) throw new Error("Formato no es compatible.");

      setIsLoading(true);
      setLoadingMessage(`Subiendo ${type}...`);
      const base64 = await convertBase64(file);

      let API = electoral.uploadExcelRG;
      if (allowedTypes[0] === file.type) /* API = electoral.uploadFile */;

      const result = await API({ idFile: idFile, file: base64 });
      const { results, response, message } = result;

      if (results) {
     /*    setRows(response.data);
        setColumns(setDiynamicColumns({ data: response.data[0], columns: columns_base }));
 */
        Swal.fire({ title: message, icon: "success" });
        getTxt();
      } else throw new Error(message);
    } catch (error) {
      e.target.value = null;
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setIsLoading(false);
      e.target.value = null;
    }
  };
  const CheckboxItem = memo(({ name, checked, handleChange }) => {
    return (
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleChange} />}
        label={name}
      />
    );
  });

  const handleChange = (name) => {
    formik.setFieldValue(name, !formik.values[name]);
  };

  return (
    <>
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
          <Grid item xs={12} md={7} lg={8}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item xs={12} md={12} lg={12}>
                <Card>
                  <CardHeader
                    title={<Typography variant="h5">Generar TXT</Typography>}
                  />
                  <CardContent>
                    <Grid container spacing={3}>
                      {/* middleware.checkMenuAction("Exportar") */ true && (
                        <>
                          <Grid item xs={12} md={4} lg={4}>
                            <Select
                              styles={selectStyles}
                              menuPortalTarget={document.body}
                              components={animatedComponents}
                              placeholder="Distrito Federal"
                              closeMenuOnSelect={true}
                              value={
                                catalogs?.df
                                  ? catalogs.df.find(
                                      (item) => item.value === formik.values.df
                                    )
                                  : null
                              }
                              onChange={(e) => {
                                formik.handleChange({
                                  target: {
                                    name: "df",
                                    value: e.value,
                                  },
                                });
                              }}
                              options={catalogs.df}
                            />
                          </Grid>
                          <Grid item xs={12} md={3} lg={3}>
                            <TextField
                              id="outlined-number"
                              label="Remuneración"
                              type="number"
                              fullWidth
                              variant="outlined"
                              value={formik.values.remuneracion}
                              onChange={(e) =>
                                formik.handleChange({
                                  target: {
                                    name: "remuneracion",
                                    value: e.target.value,
                                  },
                                })
                              }
                              InputProps={{
                                inputProps: { min: 0 },
                                shrink: true,
                              }}
                              sx={{
                                "& .MuiInputBase-root": {
                                  height: "40px",
                                },
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={2}
                            lg={2}
                            sx={{ textAlign: "end" }}
                          >
                            <LoadingButton
                              size="small"
                              onClick={(e) => {
                                setFlagButton(true);
                                formik.handleSubmit(e);
                              }}
                              loading={isLoading}
                              loadingPosition="start"
                              startIcon={<Icon>file_download</Icon>}
                              variant="contained"
                            >
                              <span>Generar</span>
                            </LoadingButton>
                          </Grid>
                        </>
                      )}
                      {isLoading && (
                        <Grid item xs={12} md={12} lg={12}>
                          <LinearProgress color="secondary" />
                        </Grid>
                      )}
                      <Grid item xs={12} md={12} lg={12}>
                        <TableGenerarRG id={idTxt} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            <Grid container alignItems="center" justifyContent="space-between">
              {/*  <Grid item>
                <Typography variant="h5">Historial de Archivos</Typography>
              </Grid> */}
              <Grid item />
            </Grid>
            <input
              ref={fileInputRef}
              id="upload-txt"
              name="txt"
              type="file"
              accept=".txt"
              style={{ display: "none" }}
              onChange={(e) => handleFileUpload(e)}
            />
            <input
              ref={fileInputRefExcel}
              style={{ display: "none" }}
              id="upload-excel"
              name="excel"
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
              disabled={!idFile || idFile === "0"}
            />
            {loading && <LinearProgress color="secondary" />}
            <BasicList
              configParams={config}
              data={data}
              fetchHasMore={fetchHasMore}
              totalItems={total}
              itemComponent={ArchivoListItem}
              hasMore={hasMore}
              handleClickItem={handleClickItem}
              finder={true}
              search={handleSearch}
              handleDeleteItem={handleDeleteItem}
              handleUploadItem={handleSelect}
              handleValidatedItem={handleValidate}
              df={true}
              estatus={true}
            />
          </Grid>
        </Grid>
        </>
  );
};
export default ExportRGSList;

let nullable = false;

export const validate = (value, validators, params) => {
  value = value === null ? "" : value;
  value = typeof value === "string" ? value.replace(/\s+/, "") : value;
  let validated = {
    valid: true,
    message: "",
  };
  validators.forEach((validator) => {
    const validationsResults = validator(value, params);
    validated.valid = validated.valid * validationsResults.valid === 1;
    validated.message = validationsResults.message;
  });
  return validated;
};

export const required = (value, params) => {
  if (value === undefined) {
    return {
      valid: false,
      message: "Valor requerido",
    };
  }

  return {
    valid: value !== null ? value.length > 0 : false,
    message: "Valor requerido",
  };
};

export const requiredSelect = (value, params) => {
  if (value === undefined || value === null) {
    return {
      valid: false,
      message: "Seleccione una opción",
    };
  }

  return {
    valid: value.label && value.label.length > 0 && value.value !== 0,
    message: "Seleccione una opción",
  };
};

export const size = (value, params) => {
  nullable = params.nullable !== undefined ? params.nullable : nullable;
  if (!nullable) {
    if (value !== undefined) {
      return {
        valid: value.length === params.size,
        message: `debe contener ${params.size} caracteres`,
      };
    } else {
      return {
        valid: false,
        message: `debe contener ${params.size} caracteres`,
      };
    }
  } else {
    return {
      valid: true,
      message: `debe contener ${params.size} caracteres`,
    };
  }
};

export const min = (value, params) => {
  nullable = params.nullable !== undefined ? params.nullable : nullable;
  if (!nullable) {
    if (value !== undefined) {
      return {
        valid: value.length >= params.minValue,
        message: `debe contener al menos ${params.minValue} caracteres`,
      };
    } else {
      return {
        valid: false,
        message: `debe contener al menos ${params.minValue} caracteres`,
      };
    }
  } else {
    return {
      valid: true,
      message: `debe contener al menos ${params.minValue} caracteres`,
    };
  }
};

export const max = (value, params) => {
  nullable = params.nullable !== undefined ? params.nullable : nullable;
  let minValue = params.minValue !== undefined ? params.minValue : 0;
  if (!nullable) {
    if (value !== undefined) {
      return {
        valid: value.length <= params.maxValue && value.length >= minValue,
        message: `debe contener máximo ${params.maxValue} caracteres`,
      };
    } else {
      return {
        valid: value.length >= minValue,
        message: `debe contener máximo ${params.maxValue} caracteres`,
      };
    }
  } else {
    return {
      valid: true,
      message: `debe contener máximo ${params.maxValue} caracteres`,
    };
  }
};

export const sizeLength = (value, params) => {
  nullable = params.nullable !== undefined ? params.nullable : nullable;
  if (!nullable) {
    if (value !== undefined) {
      return {
        valid: value.length === params.size,
        message: `debe contener ${params.size} caracteres`,
      };
    } else {
      return {
        valid: false,
        message: `debe contener ${params.size} caracteres`,
      };
    }
  } else {
    return {
      valid: true,
      message: `debe contener ${params.size} caracteres`,
    };
  }
};

export const sizeValue = (value, params) => {
  nullable = params.nullable !== undefined ? params.nullable : nullable;
  if (!nullable) {
    if (value !== undefined) {
      return {
        valid: value === params.size,
        message: `debe ser igual a ${params.size}`,
      };
    } else {
      return {
        valid: false,
        message: `debe ser igual a ${params.size}`,
      };
    }
  } else {
    return {
      valid: true,
      message: `debe ser igual a ${params.size}`,
    };
  }
};

export const minLength = (value, params) => {
  nullable = params.nullable !== undefined ? params.nullable : nullable;
  if (!nullable) {
    if (value !== undefined) {
      return {
        valid: value.length >= params.minValue,
        message: `debe contener al menos ${params.minValue} caracteres`,
      };
    } else {
      return {
        valid: false,
        message: `debe contener al menos ${params.minValue} caracteres`,
      };
    }
  } else {
    let isValid = true;
    if (value !== "" && value !== undefined && value.length < params.minValue)
      isValid = false;
    return {
      valid: isValid,
      message: `debe contener al menos ${params.minValue} caracteres`,
    };
  }
};

export const maxLength = (value, params) => {
  nullable = params.nullable !== undefined ? params.nullable : nullable;
  let minValue = params.minValue !== undefined ? params.minValue : 0;
  if (!nullable) {
    if (value !== undefined) {
      return {
        valid: value.length <= params.maxValue && value.length >= minValue,
        message: `debe contener máximo ${params.maxValue} caracteres`,
      };
    } else {
      return {
        valid: value.length >= minValue,
        message: `debe contener máximo ${params.maxValue} caracteres`,
      };
    }
  } else {
    return {
      valid: true,
      message: `debe contener máximo ${params.maxValue} caracteres`,
    };
  }
};

export const minValue = (value, params) => {
  nullable = params.nullable !== undefined ? params.nullable : nullable;
  if (!nullable) {
    if (value !== undefined) {
      return {
        valid: value >= params.minValue,
        message: `debe ser mayor o igual a ${params.minValue}`,
      };
    } else {
      return {
        valid: false,
        message: `debe ser mayor o igual a ${params.minValue}`,
      };
    }
  } else {
    return {
      valid: true,
      message: `debe ser mayor o igual a ${params.minValue}`,
    };
  }
};

export const maxValue = (value, params) => {
  nullable = params.nullable !== undefined ? params.nullable : nullable;
  let minValue = params.minValue !== undefined ? params.minValue : 0;
  if (!nullable) {
    if (value !== undefined) {
      return {
        valid: value <= params.maxValue && value >= minValue,
        message: `debe ser menor o igual a ${params.maxValue}`,
      };
    } else {
      return {
        valid: value >= minValue,
        message: `debe ser menor o igual a ${params.maxValue}`,
      };
    }
  } else {
    return {
      valid: true,
      message: `debe ser menor o igual a ${params.maxValue}`,
    };
  }
};

export const email = (value, params) => {
  nullable = params.nullable !== undefined ? params.nullable : nullable;
  let pattern = "/^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/";
  return {
    valid: value.length > 0 ? pattern.test(value) : nullable,
    message: `debe ser una dirección de correo válida`,
  };
};

export const date = (value, params) => {
  nullable = params.nullable !== undefined ? params.nullable : nullable;
  // let pattern = "/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/";
  return {
    // valid: (value.length > 0)?pattern.test(value):nullable,
    valid: true,
    message: `no corresponde con una fecha válida`,
  };
};

export const vpassword = (value, params) => {
  nullable = params.nullable !== undefined ? params.nullable : nullable;
  let passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
  let isValidPassword = passwordRegex.test(value);
  return {
    valid: value.length > 0 ? isValidPassword : nullable,
    message: `La contraseña debe contener un mínimo de ocho caracteres, letra mayúscula y minúcscula, y un número.`,
  };
};

export const requieredLevels = (value, params) => {
  let isEmpty = 0;
  let validated = {
    valid: true,
    message: "",
  };
  value.forEach((respuesta, index) => {
    isEmpty += respuesta === undefined || respuesta === null;
  });
  if (isEmpty > 0) {
    validated.valid = false;
    validated.message = "Todas las preguntas deben ser respondidas";
  }
  return validated;
};

export const requiredMultipleSelect = (value) => {
  let validated = {
    valid: true,
    message: "",
  };
  if (value.length === 0) {
    validated.valid = false;
    validated.message = "Seleccione al menos una opción";
  }
  return validated;
};

export const isNullOrUndefined = (value) => {
  return value === null || value === undefined;
};

export const isEmptyOrInvalidString = (value) => {
  if (value) {
    return (typeof value === 'string') ? value.split(/\s+/).join("") === "" : false
  }
  return true
};

export const isEmptyOrInvalidArray = (value) => {
  return value === null || value === undefined || value.length === 0;
};

export const isEmptyOrNullObject = (data) => {
  for (var key in data) {
    return false;
  }
  return true;
};

export const isTypePhone = (data) => {
  const regex = /^[0-9]*$/;
  if (data.match(regex) && data.length <= 10) return true;
  else {
    return false;
  }
};

export const isValidSection = (data) => {
  const regex = /^[0-9]*$/;
  if (data.match(regex) && data.length <= 4) return true;
  else {
    return false;
  }
}

export const isValidINE = (data) => {
  const regex = /^[A-Za-z]{1,6}\d{0,8}[A-Za-z]?\d{0,3}$/g;
  if (data.length <= 18) {
    return data.match(regex)
  }
}

/* export const isValidINE = (data) => {
  const value = data.toUpperCase(); // Convertir el valor a mayúsculas
  console.log(/^[A-Z]{6}\d{8}[A-Z]\d{3}$/.test(value));
  const isValid = /^[A-Z]{6}\d{8}[A-Z]\d{3}$/.test(value); // Validar con expresión regular
  console.log(isValid);
  if (isValid) {
   return value;
  }
}; */

// Formato del numero
export const validateNumber = (input) => {
  const pattern = /^\d*$/;
  return pattern.test(input.toString());
};

// Formato de letras con acentos
export const validateLetters = (input) => {
  const pattern = /^[A-Za-zÁÉÍÓÚáéíóúÜüÑñ]+$/;
  return pattern.test(input.toString());
};

// Formato de letras con acentos y espacios
export const validateLettersWithSpaces = (input) => {
  const pattern = /^[A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]+$/;
  return pattern.test(input.toString());
};

// Formato de letras con acentos, espacios y .
export const validateLettersWithSpacesDot = (input) => {
  const pattern = /^[A-Za-zÁÉÍÓÚáéíóúÜüÑñ. ]+$/;
  return pattern.test(input.toString());
};

// Formato de alfanumérico sin acentos
export const validateAlphanumeric = (input) => {
  const pattern = /^[A-Za-z0-9]+$/;
  return pattern.test(input.toString());
};

// Formato de alfanumérico con acentos
export const validateAlphanumericExtra = (input) => {
  const pattern = /^[A-Za-zÁÉÍÓÚáéíóúÜüÑñ0-9]+$/;
  return pattern.test(input.toString());
};

// Formato de alfanumérico con acentos y espacios
export const validateAlphanumericExtraWS = (input) => {
  const pattern = /^[A-Za-zÁÉÍÓÚáéíóúÜüÑñ0-9 ]+$/;
  return pattern.test(input.toString());
};

// Validar formato de fecha dd/mm/aaaa
export const validateDateFormat = (input) => {
  const pattern = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
  return pattern.test(input.toString());
};

// Convertir numero a letra de excel
export function convertToExcelColumn(index) {
  let result = "";
  while (index > 0) {
    const remainder = (index - 1) % 26;
    result = String.fromCharCode(65 + remainder) + result;
    index = Math.floor((index - 1) / 26);
  }
  return result;
}

import { TextField, Autocomplete, CircularProgress } from "@mui/material";

function AutoComplete(props) {
  const {
    data,
    loading,
    setSelect,
    onKeyDown = () => {},
    onKeyPress = () => {},
    size,
    margin,
    required,
    fullWidth,
    id,
    name,
    label,
    helperText,
    error,
    placeholder,
    disabled,
    variant,
    sx,
    value,
  } = props;

  const handleSelect = (value) => {
    let newData = data.find((item) => item.label.toUpperCase() === value.toUpperCase());
    if (newData) setSelect(newData);
    else setSelect({ value: "string", label: value });
  };

  return (
    <Autocomplete
      disabled={disabled}
      required={required}
      id={id}
      name={name}
      value={value}
      freeSolo
      size={size}
      margin={margin}
      sx={sx}
      fullWidth={fullWidth}
      options={data}
      loading={loading}
      //options={data.map((option) => option.label)}
      onInputChange={(e, label) => handleSelect(label)}
      onKeyDown={onKeyDown}
      onKeyPress={onKeyPress}
      //onBlur={(e) => handleSelect(e.target.value)}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.value ? option.value : Math.random()}>
            {option.label}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={loading ? <CircularProgress size={15} /> : label}
          placeholder={placeholder}
          error={error}
          variant={variant}
          helperText={helperText}
        />
      )}
    />
  );
}
export default AutoComplete;

import React, { useState, useEffect } from "react";

import { Grid, TextField, Button, FormControl, InputLabel, MenuItem, Select, Box } from "@mui/material";
import FilterCollapse from "@components/Filters/FilterCollapse";

const FilterData = ({ onChangeFilter, TabValue }) => {
  const [estatus, setEstatus] = useState(0);
  const [celular, setCelular] = useState("");
  const [nombre, setNombre] = useState("");
  const [estatusOptions, setEstatusOptions] = useState([]);

  const pendientesEstatus = [
    { value: 0, label: "Todos" },
    { value: 1, label: "Pendientes" },
    { value: 2, label: "No Localizados" },
  ]

  const contactadosEstatus = [
    { value: 0, label: "Todos" },
    { value: 1, label: "Ya votó" },
    { value: 2, label: "No va a votar" },
  ]

  const handleEstatusChange = (event) => {
    setEstatus(event.target.value);
  };

  const handleCelularChange = (event) => {
    setCelular(event.target.value);
  };
  const handleNombreChange = (event) => {
    setNombre(event.target.value);
  };

  const handleLimpiar = () => {
    setEstatus(0);
    setCelular("");
    setNombre("");
  };

  const handleFiltrar = () => {
    let filtered = [];
    if (estatus) {
      filtered.push({
        id: "Estatus",
        filter: "=",
        value: estatus,
      });
    }
    if (nombre) {
      filtered.push({
        id: ["Nombre", "Paterno", "Materno"],
        filter: "Like",
        value: nombre,
      });
    }
    if (celular) {
      filtered.push({
        id: "Username",
        filter: "=",
        value: celular,
      });
    }
    onChangeFilter(filtered);
  };

  const handleKeyPress = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };
  
  const handleKeyPress2 = (event) => {
    const inputValue = event.key;
    const regex = /^[a-zA-ZáéíóúüÁÉÍÓÚÜñÑ\s]*$/; 
  
    if (!regex.test(inputValue)) {
      event.preventDefault();
    }
  };

  useEffect(()=>{
    setEstatusOptions(
      TabValue !==2 ? pendientesEstatus : contactadosEstatus
    )
    //eslint-disable-next-line
  },[TabValue])

  return (
    <FilterCollapse expand={true}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth size="small">
            <InputLabel id="estatus-label">Estatus</InputLabel>
            <Select labelId="estatus-label" id="estatus-select" value={estatus} label="Estatus" onChange={handleEstatusChange}>
              {estatusOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField label="Nombre" fullWidth value={nombre} onChange={handleNombreChange} size="small" onKeyPress={handleKeyPress2}/>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField label="Celular" fullWidth value={celular} onChange={handleCelularChange} size="small"  onKeyPress={handleKeyPress}/>
        </Grid>
        <Grid item xs={12} md={2}>
          <Box display="flex">
            <Button variant="outlined" onClick={handleLimpiar}>
              Limpiar
            </Button>
            <Button variant="contained" color="primaryDark" onClick={handleFiltrar} sx={{ ml: 1 }}>
              Filtrar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </FilterCollapse>
  );
};

export default FilterData;

import { useEffect } from "react";
import Swal from "sweetalert2";

// Material UI
import {
  Card,
  CardContent,
  Typography,
  Stack,
  Box,
  Divider,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { AccountCircle, LocalPhone } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

// Utils
import { isEmptyOrNullObject, isEmptyOrInvalidArray } from "@utils/validations";
import DiaDServices from "@services/DiaDServices";

const UserInfo = (props) => {
  const { params, setParams, userData, setUserData, setLoading, setLoadingMessage } = props;

  useEffect(() => {
    if (!isEmptyOrNullObject(params)) getResponsabilityRutaDiad();
    //eslint-disable-next-line
  }, [params]);

  const handleConfirm = (item) => {
    Swal.fire({
      title: "¿Estás seguro de que quieres eliminar?",
      text: "No podrás revertir esto.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminarlo",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) handleDelete(item);
    });
  };

  const handleDelete = async (item) => {
    const sendParams = { id: item.id, idCompromisoUnico: item.idCompromisoUnico };

    try {
      setLoading(true);
      setLoadingMessage("Eliminando dato...");

      const result = await DiaDServices.deleteResponsibility(sendParams);
      const { results, message } = result;

      if (results) {
        Swal.fire({ title: message, icon: "success" });
        setParams({ ...params, delete: item.id });
      } else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoading(false);
    }
  };

  const getResponsabilityRutaDiad = async (item) => {
    const sendParams = { idUsuario: params.id };

    try {
      setLoading(true);
      setLoadingMessage("Cargando datos...");

      const result = await DiaDServices.getResponsabilityRutaDiad(sendParams);
      const { results, response, message } = result;

      if (results) setUserData(response.data);
      else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="card-primary" sx={{ height: "100%", marginBottom: "0 !important" }}>
      <CardContent>
        <Box textAlign="center" mt={2}>
          <Typography component={"h6"} variant="h6" fontWeight={800} fontSize={18}>
            DATOS DEL USUARIO
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {!isEmptyOrNullObject(params) ? (
          <>
            <Box mb={2}>
              <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                <AccountCircle fontSize="large" style={{ color: grey[500] }} />
                <Typography fontWeight={600} variant="h6">
                  {params.Nombre}
                </Typography>
              </Stack>
            </Box>
            <Box mb={2}>
              <Stack direction="row" spacing={2} alignItems="center" ml="2vh">
                <LocalPhone />
                <Typography fontWeight={400} fontSize={15}>
                  {params.Celular}
                </Typography>
              </Stack>
            </Box>
            <Divider sx={{ mt: 1 }} />
            {!isEmptyOrInvalidArray(userData) ? (
              <Box style={{ maxHeight: "400px", overflowY: "auto" }}>
                <List dense>
                  {userData.map((item, index) => (
                    <ListItem disablePadding key={index}>
                      <ListItemIcon sx={{ width: "100%" }}>
                        <IconButton color="error" onClick={(e) => handleConfirm(item)}>
                          <Icon>delete</Icon>
                        </IconButton>
                        <ListItemText
                          primary={
                            <Tooltip title={item.label} disableInteractive arrow>
                              <Box
                                sx={{
                                  width: "100%",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "noWrap",
                                  fontWeight: 700,
                                }}
                              >
                                Ruta: {item.label}
                              </Box>
                            </Tooltip>
                          }
                          secondary={
                            <span>
                              <Tooltip title={item.responsable} disableInteractive arrow>
                                <Box
                                  sx={{
                                    width: "100%",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "noWrap",
                                  }}
                                >
                                  {item.responsable}
                                </Box>
                              </Tooltip>
                              {item.fecha}
                            </span>
                          }
                        />
                      </ListItemIcon>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ) : (
              <Typography fontWeight={600} fontSize={15} align="center" mt={2}>
                SIN CERTIFICACIONES ASIGNADAS
              </Typography>
            )}
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: 2,
              p: 2,
            }}
          >
            <Icon fontSize="large">info</Icon>
            <Typography variant="body1" align="center">
              Sin Información. Seleccione un usuario.
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default UserInfo;

import PropTypes from "prop-types";
import { isTypePhone } from "@utils/validations";
//Mui
import {
  List,
  ListItem,
  Divider,
  ListItemText,
  Typography,
  TextField,
  Box,
  FormHelperText,
  Stack,
  CardContent,
} from "@mui/material";

import { URL_IMAGES } from "@utils/global";

const ListParties = ({ politicalParties = [], totalVotos = 0, handleVotes }) => {
  return (
    <List sx={{ width: "100%" }}>
      {politicalParties.map((item, idx) => (
        <div key={idx}>
          <Box display={"flex"}>
            <ListItem sx={{ width: "100%", backgroundColor: `${idx % 2 ? "rgba(189, 189, 189, 0.3)" : ""}` }}>
              <Box
                component={"div"}
                sx={{ width: "40%" }}
                display={"flex"}
                alignItems="center"
                justifyContent="center"
              >
                <img
                  variant="rounded"
                  alt={item.Partido}
                  src={`${URL_IMAGES}/${item.RutaLogo}`}
                  height={40}
                  sx={{ pr: 2 }}
                />
              </Box>
              <Box component={"div"} sx={{ width: "40%" }}>
                <ListItemText
                  primary={
                    <Typography variant="body2" fontWeight={700}>
                      {item.siglas}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      sx={{ display: "inline" }}
                      component="p"
                      variant="body2"
                      fontSize={13}
                      color="text.primary"
                    >
                      {item.Partido}
                    </Typography>
                  }
                />
              </Box>
              <Box component={"div"} sx={{ width: "20%" }}>
                <Stack direction={"column"} spacing={0}>
                  <TextField
                    label="votos"
                    name="Votos"
                    size="small"
                    value={item.Votos}
                    inputProps={{ style: { textAlign: "center" } }}
                    onChange={(e) =>
                      handleVotes(
                        {
                          target: {
                            name: "otra",
                            value: isTypePhone(e.target.value) ? e.target.value : item.Votos,
                          },
                        },
                        idx
                      )
                    }
                  />
                  <FormHelperText error>
                    {item.Votos && item.Votos > 0 && parseInt(item.Votos) > 2000
                      ? "El voto no puede ser mayor a 2000"
                      : ""}
                  </FormHelperText>
                </Stack>
              </Box>
            </ListItem>
          </Box>
          <Divider variant="inset" component="li" />
        </div>
      ))}
      {/* <Typography textAlign={"end"} marginTop={2} variant="h6">
        {totalVotos > 0 && <>Total: {totalVotos}</>}
      </Typography> */}
      <Box component={CardContent}>
        <Typography marginTop={2} variant="h6" fontWeight={400} textAlign={"end"}>
          TOTAL DE VOTOS
        </Typography>
        <Typography marginTop={1} variant="h2" textAlign={"end"} sx={{ color: "#0041a0" }}>
          {totalVotos}
        </Typography>
      </Box>
    </List>
  );
};

ListParties.propTypes = {
  politicalParties: PropTypes.array.isRequired,
  totalVotos: PropTypes.number.isRequired,
  handleVotes: PropTypes.func.isRequired,
};

export default ListParties;

import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Divider,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import Toast from "../Toast";

import { useMobileServices } from "@hooks/MobileDiaD/MobileServices";
import { ORIGEN_CAPTURA, TIPO_BINGO } from "@data/constants/MobileDiaD";

const FormBingos = ({ idCasilla, Seccion, NombreCasilla, setShowForm }) => {
  const {
    loading,
    loadingGet,
    snackbar,
    setSnackbar,
    getBingosList,
    setBingo,
  } = useMobileServices();

  const [folioBingo, setFolioBingo] = useState("");
  const [bingoLists, setBingoLists] = useState([]);
  const [total, setTotal] = useState(0);

  const handleGetBingoList = async () => {
    const response = await getBingosList({
      idCasilla,
    });

    if (response) {
      setBingoLists(response.data);
      setTotal(response.total);
    }
  };

  const handleSetBingo = async () => {
    if (parseInt(folioBingo) !== 0) {
      const params = {
        idTipoBingo: TIPO_BINGO,
        Seccion,
        idCasilla,
        FolioBingo: folioBingo,
        OrigenCaptura: ORIGEN_CAPTURA,
      };

      const results = await setBingo(params);

      if (results) handleGetBingoList();
    } else {
      setSnackbar({
        show: true,
        severity: "error",
        message: "Folio Bingo no puede ser 0",
      });
    }
  };

  useEffect(() => {
    if (idCasilla) handleGetBingoList();
  }, []);

  useEffect(() => {
    if (!loading) setFolioBingo("");
  }, [loading]);

  return (
    <>
      <Toast
        open={snackbar.show}
        message={snackbar.message}
        severity={snackbar.severity}
        setOpen={setSnackbar}
      />
      <Container>
        <Stack direction={"column"}>
          <Stack
            direction="row"
            alignItems={"center"}
            // justifyContent={"center"}
          >
            <IconButton onClick={() => setShowForm(false)}>
              <Icon sx={{ color: "black" }}>keyboard_arrow_left</Icon>
            </IconButton>
            <Typography variant="body1">Folio Bingos</Typography>
          </Stack>

          <Box marginTop={4}>
            <Typography variant="body2">Sección</Typography>
            <Typography variant="subtitle1" fontWeight={600}>
              {Seccion}
            </Typography>
            <Typography variant="body2">Casilla</Typography>
            <Typography variant="subtitle1" fontWeight={600}>
              {NombreCasilla}
            </Typography>
          </Box>

          <TextField
            label="Folio Bingo"
            variant="standard"
            value={folioBingo}
            onChange={(e) => setFolioBingo(e.target.value)}
            disabled={loading || loadingGet}
            error={!folioBingo}
            helperText={!folioBingo ? "Folio Bingo es requerido" : ""}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            InputProps={{
              endAdornment: (
                <IconButton
                  size="large"
                  disabled={!folioBingo}
                  onClick={handleSetBingo}
                >
                  {loading ? (
                    <Icon sx={{ animation: "spin 1s linear infinite" }}>
                      autorenew
                    </Icon>
                  ) : (
                    <Icon>add_circle_outline</Icon>
                  )}
                </IconButton>
              ),
            }}
            sx={{ mt: 2, mb: 2 }}
          />
        </Stack>

        <Box>
          {bingoLists && bingoLists.length > 0 ? (
            <>
              <Typography
                variant="body2"
                textAlign={"center"}
                fontWeight={600}
                marginBottom={1}
              >
                Mostando {total} resultados
              </Typography>
              <Box sx={{ height: "50vh", overflow: "auto" }}>
                <List>
                  {bingoLists.map(
                    (item, index) =>
                      item.FolioBingo && (
                        <div key={index}>
                          <ListItem>
                            <ListItemText
                              primary={item.FolioBingo}
                              secondary={item.Hora}
                            />
                          </ListItem>
                          {index !== bingoLists.length - 1 && <Divider />}
                        </div>
                      )
                  )}
                </List>
              </Box>
            </>
          ) : (
            <Box textAlign={"center"} marginTop={4}>
              {loadingGet ? "Cargando..." : "No tienes bingos registrados."}
            </Box>
          )}
        </Box>
      </Container>
    </>
  );
};

export default FormBingos;

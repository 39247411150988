export const columns_required = [
    { id: 0, name: "Linea 1" },
    { id: 1, name: "Linea 2" },
    { id: 2, name: "Linea 3" },
    { id: 3, name: "Nombres" },
    { id: 4, name: "Paterno" },
    { id: 5, name: "Materno" },
    { id: 6, name: "Duración" },
    { id: 7, name: "Lugar" },
    { id: 8, name: "FechaTexto" },
    { id: 9, name: "Fecha" },
  ];

  export const columns = [
    { id: "Linea 1", label: "Linea 1", type: "text" },
    { id: "Linea 2", label: "Linea 2", type: "text" },
    { id: "Linea 3", label: "Linea 3", type: "text" },
    { id: "Nombres", label: "Nombre", type: "text" },
    { id: "Paterno", label: "Paterno", type: "text" },
    { id: "Materno", label: "Materno", type: "text" },
    { id: "Duracion", label: "Duración", type: "text" },
    { id: "Lugar", label: "Lugar", type: "text" },
    { id: "FechaTexto", label: "Fecha Texto", type: "text" },
    { id: "Fecha", label: "Fecha", type: "text" },
  ];
  
import React from "react";
import {
  Typography,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Divider,
  Icon,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import middleware from "@middlewares/middleware";

const ArchivoListItem = ({
  item,
  selectedIndex,
  handleSelectedIndex,
  handleDeleteItem,
  handleUpload,
  handelValidete
}) => {


  const handleListItemClick = (event, index) => {
    let data = item;
    handleSelectedIndex(index, data);
  };



  return (
    <>
      <ListItemButton onClick={() => handleListItemClick(item)}>
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: item.Color /* blue[500] */ }}>
            <Icon>subject</Icon>
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              spacing={1}
            >
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {item.NombreArchivo}
              </Typography>
              {item.Perfil ? item.Perfil : ""}
            </Stack>
          }
          secondary={
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              spacing={1}
            >
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="caption"
                color="text.primary"
              >
                {item.Estatus}
              </Typography>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="caption"
                color="text.primary"
              >
                {`Registros: ${item.TotalRegistros}`}
              </Typography>
          {/*     <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="caption"
                color="text.primary"
              >
                  {`En Proceso: ${item.EnProceso}`}
              </Typography> */}
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="caption"
                color="text.primary"
              >
                 {`Con Observacion: ${item.ConObservacion}`}
              </Typography>
              
            </Stack>
          }
        />
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
        >
    {
       middleware.checkMenuAction("Importar") &&<>
         {[1,2].includes(item.idEstatus) &&  <Tooltip title="Subir Excel" placement="top-start">
            <IconButton color="success" onClick={() => handleUpload(item.id,2)}>
              <Icon>description</Icon>
            </IconButton>
          </Tooltip>  }
        {/*   {[1,2].includes(item.idEstatus) &&  <Tooltip title="Subir Observaciónes" placement="top-start">
          <IconButton color="info" onClick={() => handleUpload(item.id,1)}>
            <Icon>upload_file</Icon>
          </IconButton>
          </Tooltip>  } */}
       </>
    }
    {
      middleware.checkMenuAction("Autorizar") &&<>
      {[1,2].includes(item.idEstatus) && <Tooltip title="Validar RCS" placement="top-start">
            <IconButton color="success" onClick={() => handelValidete(item.id)}>
              <Icon>task_alt</Icon>
            </IconButton>
          </Tooltip>} 
      </>
    }
     {
      middleware.checkMenuAction("Eliminar") &&
      <>
        {[1,2].includes(item.idEstatus) && <Tooltip title="Eliminar Listado" placement="top-start">
            <IconButton color="error" onClick={() => handleDeleteItem(item.id)}>
              <Icon>delete</Icon>
            </IconButton>
          </Tooltip>}
      </>
     }   
        </Stack>
      </ListItemButton>
      <Divider variant="inset" component="li" />
    </>
  );
};

export default ArchivoListItem;

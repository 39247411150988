import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Icon,
  Card,
  InputAdornment,
  IconButton,
  Button,
  CircularProgress,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { theme } from "@src/theme/index";
import Yup from "@utils/Yupi18n";

import UserServices from "@services/UserServices";
import { RECAPTCHA_SITE_KEY } from "@utils/global";
import { isEmptyOrInvalidString } from "@utils/validations";
import avatar from "@assets/img/SIAN.png";
import "@pages/auth/LoginStyle.css";
// import ReCAPTCHA from "react-google-recaptcha";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const CardHeader = ({ flagAreYou }) => {
  return (
    <>
      <div className="card-header-multimedia">
        <img alt="Logo" src={avatar} width={120} height={120} />
        <Typography
          sx={{ textAlign: "center", mt: 2 }}
          variant="h5"
          fontWeight={400}
        >
          Cambio de Contraseña
        </Typography>
        {!flagAreYou && (
          <Typography
            sx={{ textAlign: "center", mt: 2 }}
            variant="subtitle1"
            fontWeight={200}
          >
           coloca tu usuario.
          </Typography>
        )}
        {flagAreYou && (
          <Typography
            sx={{ textAlign: "center", mt: 2 }}
            variant="subtitle1"
            fontWeight={200}
          >
            Eres tú?
          </Typography>
        )}
      </div>
    </>
  );
};

const CardBody = ({
  formik,
  handleOnKeyPress,
  // captchaRef,
  // onChangeCaptcha,
}) => {
  return (
    <>
      <div className="card-content">
        <TextField
          //label="Usuario"
          placeholder="Usuario"
          className="input"
          size="small"
          sx={{ mb: 1 }}
          error={
            formik.touched.username &&
            !isEmptyOrInvalidString(formik.errors.username)
          }
          helperText={
            formik.touched.username &&
            formik.errors.username &&
            formik.errors.username
          }
          type="text"
          name="username"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.username}
          autoFocus
          onKeyPress={handleOnKeyPress}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton sx={{ cursor: "inherit" }}>
                  <Icon>account_circle</Icon>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </>
  );
};

const CardAction = ({ search, loading,navigate }) => {
  return (
    <>
      <div className="card-action">
        {loading ? (
          <CircularProgress
            sx={{
              display: "flex",
              margin: "0 auto",
              width: "30px !important",
              height: "30px !important",
            }}
          />
        ) : (
          <Button className="button-login" onClick={search}>
            Confirmar
          </Button>
        )}
        <Button
          variant="outlined"
          startIcon={<Icon>login</Icon>}
          onClick={() => navigate("/login")}
          size="small"
          color="success"
          sx={{width:"100%", borderRadius:"30px"}}
        >
          Iniciar Sesión
        </Button>
     {/*    <Typography
          variant="body2"
          sx={{
            textAlign: "center",
            color: "#9a9a9a",
            marginBottom: "5px",
          }}
        >
          {Version ? Version : "1.0.0"}
        </Typography> */}
        <Typography
          variant="body2"
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/*    <a
            style={{ color: "#0041a0" }}
            target="_blank"
            rel="noreferrer"
            href="https://espormexico.com/privacidad"
          >
            Ver aviso de privacidad
          </a> */}
        </Typography>
       {/*  <br />
        <Divider />
        <Typography
          component={Link}
          onClick={()=>navigate("/registro")}
          variant="subtitle1"
          sx={{ textDecoration: "none" }}
        >
          Si aún no tienes cuenta, Regístrate Aquí.
        </Typography> */}
      </div>
    </>
  );
};


const Change = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [flagAreYou, setFlagAreYou] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [infoLogin,setInfoLogin] = useState(id ? JSON.parse(atob(id)):{value:0,dato:""})
  // const captchaRef = useRef(null);
  
  useEffect(()=>{
    setInfoLogin(id ? JSON.parse(atob(id)):{value:0,dato:""})
  },[id])

  const initialValues = {
    username: infoLogin.dato ? infoLogin.dato : "",
  };

  const validationSchema = Yup.object({
    username: Yup.string().required().label("Usuario")
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      //signIn(values);
      onChangeCaptcha(values);
    },
  });

  const handleOnKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      formik.submitForm();
    }
  };

  const onChangeCaptcha = useCallback(
    (values) => {
      if (!executeRecaptcha) {
        return;
      }

      executeRecaptcha("change")
        .then((token) => {
          const data = {
            ...values,
            token: token,
          };
          search(data);
        })
        .catch((error) => Swal.fire({ title: error, icon: "warning" }));
    },
    // eslint-disable-next-line
    [executeRecaptcha]
  );

  const search = (values) => {
    // const token = captchaRef.current.getValue()
    setLoading(true);
    let data = values;
    UserServices.messageChange({ ...data })
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          Swal.fire({
            title: res.message,
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            allowOutsideClick: true 
          }).then((result) => {
            if (result.isConfirmed || result.dismiss === Swal.DismissReason.backdrop) {
              navigate("/login");
            }
          })
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({
          title: e.message,
          icon: "warning",
        });
      })
      .finally((end) => {
        setLoading(false);
        // captchaRef.current.reset();
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        className="section-image"
        sx={{
          backgroundImage: `url(${require("@assets/img/back02.png")})`,
        }}
      >
        <Container component="main" maxWidth="sm">
          <Box className="login-container">
            <Card className="card-login">
              <CardHeader />
                <>
                  <CardBody
                    formik={formik}
                    handleOnKeyPress={handleOnKeyPress}
                    navigate={navigate}
                  />
                  <CardAction search={formik.submitForm} loading={loading} navigate={navigate}/>
                </>
            </Card>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

const ChangePassword = () => {
  return (
    <>
      <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
        <Change />
      </GoogleReCaptchaProvider>
    </>
  );
};

export default ChangePassword;

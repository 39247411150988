import { useState, useEffect } from "react";

// Componentes
import CustomTable from "@components/Tables/CustomTable";
import FilterEvento from "@components/Events/FilterEvento";
import CardListEvent from "@components/Events/CardListEvent";
import CustomModal from "@components/Modal/CustomModal";

// Utilidades
import { isEmptyOrNullObject, isNullOrUndefined } from "@utils/validations";
import { Swal, SwalImage } from "@utils/alerts";

// Servicios
import EventsServices from "@services/EventsServices";

const ListEventos = ({ catalogs, isLoadingCat, idsTipoActividad = [], URL = "" }) => {
  const [total, setTotal] = useState(0);
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState({ page: 0, pageSize: 10, filtered: [], sorted: [] });
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(0);
  const [event, setEvent] = useState({});

  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, [params]);

  const handleVerDetalle = (a, b, c) => {
    setOpen(true);
    setEvent(c);
  };

  const columns = [
    { id: "FechaInicio", label: "Fecha Inicial" },
    { id: "Actividad", label: "Evento" },
    { id: "TipoActividad", label: "Tipo Evento" },
    { id: "TotalInvitados", label: "Total Invitados", type: "number" },
    { id: "TotalAsistencia", label: "Total Asistencia", type: "number" },
    { id: "Detalle", label: "Detalles", type: "button", onClick: handleVerDetalle, sort: false },
  ];

  const getList = async () => {
    try {
      setIsLoading(true);

      if (idsTipoActividad.length) {
        params.filtered.push({ id: "eventos.idTipoActividad", filter: "IN", value: idsTipoActividad });
      }

      const result = await EventsServices.getEventosList(params);
      const { results, response, message } = result;

      if (results) {
        setEvents(
          response.data.map((item) => ({
            ...item,
            Detalle: "Ver detalle",
          }))
        );

        setTotal(response.total);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: "warning", customClass: { container: "modal-alert" } });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSort = (sortData) => {
    if (total > 0) setParams({ ...params, sorted: handleSorted(sortData) });
  };

  const handleSorted = (dataSorted) => {
    const fields = {
      FechaInicio: "eventos.FechaInicio",
      Actividad: "eventos.Actividad",
      TipoActividad: "cat_actividades.TipoActividad",
      TotalInvitados: "Invitado.total",
      TotalAsistencia: "Asistencia.total",
    };

    let sorted = [];

    if (!isEmptyOrNullObject(dataSorted)) {
      sorted.push({ id: fields[dataSorted.orderBy], value: dataSorted.order });
    }

    return sorted;
  };

  const handlePagination = (pagination) => setParams({ ...params, ...pagination });

  const handleClose = () => {
    setOpen(false);
    setShow(0);
  };

  const handleFilter = (values) => setParams({ ...params, ...values });

  return (
    <>
      <FilterEvento onFilter={handleFilter} catalogs={catalogs} isLoadingCat={isLoadingCat} />
      <br />
      <CustomTable
        rows={events}
        columns={columns}
        page={params.page}
        pageSize={params.pageSize}
        total={total}
        isLoading={isLoading}
        stickyHeader
        maxHeight={700}
        handleSort={handleSort}
        handlePagination={handlePagination}
      />

      {!isEmptyOrNullObject(event) && (
        <CustomModal
          open={open}
          onClose={handleClose}
          fullWidth
          image={{ src: event.imagen, title: event.Actividad }}
          displayImage={!isNullOrUndefined(event.imagen)}
          buttons={[
            {
              icon: "image",
              title: "Abrir imagen",
              action: () => SwalImage({ image: event.imagen, title: event.Actividad }),
            },
          ]}
        >
          <CardListEvent
            post={event}
            show={show}
            setShow={setShow}
            cat={catalogs}
            isLoadingCat={isLoadingCat}
            URL={URL}
          />
        </CustomModal>
      )}
    </>
  );
};
export default ListEventos;

import { useEffect, useState } from "react";
import { Box, Icon, TextField, InputAdornment, Divider, Button } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

import ModalResponsibleList from "@components/Compromisos/ModalResponsibleList";
import { getVars } from "@utils/global";
//import middleware from "@middlewares/middleware";
import { isEmptyOrNullObject } from "@utils/validations";

const ResponsibleList = (props) => {
  const { values, setValues } = props;

  const { user } = getVars("Token");
  const [openModal1, setOpenModal1] = useState(false);
  const [responsible, setResponsible] = useState({

      value: user.id,
      label: `${user.Nombre} ${user.Paterno} ${user.Materno}`,
      TieneUsuario: 1,

  });

  useEffect(() => {
    if (!isEmptyOrNullObject(responsible)) {
        setValues({
          ...values,
          idUsuarioReporteMov: responsible.value,
        });
    }
    // eslint-disable-next-line
  }, [responsible]);

  return (
    <Box>

      <Grid2 container>
        <Grid2 xs={12}>
          <TextField
            required={true}
            disabled={true}
            label="Nombre del Responsable"
            value={responsible?.label}
            name="Nombre"
            sx={{ width: "100%" }}
            type="text"
            variant="outlined"
            size="small"
            className="fixed-input"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <Button color="info" onClick={(e) => setOpenModal1(true)} sx={{ mr: 0, pr: 0 }}>
                    <Icon>search</Icon>
                    <small>Buscar</small>
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Grid2>
      </Grid2>

      <ModalResponsibleList openModal={openModal1} setOpenModal={setOpenModal1} setResponsible={setResponsible} />
    </Box>
  );
};

export default ResponsibleList;

import { useEffect, useState } from "react";

import {
  Container,
  Box,
  Card,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  CardContent,
  Typography,
  Stack,
  Chip,
  Avatar,
  IconButton,
  Icon,
  Popover,
  MenuItem,
  TableHead,
  TableSortLabel,
  LinearProgress,
  Divider,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import Swal from "sweetalert2";

import OnClick from "./../../assets/img/onClic.svg";

import electoral from "@services/ElectoralServices";
import middleware from "@middlewares/middleware";
import ModalEditRCS from "./ModalEditRCS";
import FilterINE from "./FilterCertificacionINE";
import { object } from "prop-types";
import { filter } from "lodash";

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};

const TableEmptyRows = ({ emptyRows, height }) => {
  if (!emptyRows) {
    return null;
  }

  return (
    <TableRow
      sx={{
        ...(height && {
          height: height * emptyRows,
        }),
      }}
    >
      <TableCell colSpan={9} />
    </TableRow>
  );
};

const TableNoId = ({ query }) => {
  return (
    <TableRow>
      <TableCell align="center" colSpan={20} sx={{ py: 3 }} rowSpan={20}>
        <Paper
          sx={{
            textAlign: "center",
          }}
        >
          <Typography variant="h6" paragraph>
            Da clic en un archivo del listado
          </Typography>
          <img
            src={OnClick}
            className="d-inline-block align-top wow fadeInLeft img-fluid"
            data-wow-delay="0.6s"
            width="30%" // Modifica el ancho aquí
            height="auto"
            alt="click"
          />
        </Paper>
      </TableCell>
    </TableRow>
  );
};

const TableNoData = ({ query }) => {
  return (
    <TableRow>
      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
        <Paper
          sx={{
            textAlign: "center",
          }}
        >
          <Typography variant="h6" paragraph>
            Not found
          </Typography>

          <Typography variant="body2">
            No results found for &nbsp;
            <strong>&quot;{query}&quot;</strong>.
            <br /> Try checking for typos or using complete words.
          </Typography>
        </Paper>
      </TableCell>
    </TableRow>
  );
};

const TableRowConponent = ({ data, selected, update }) => {
  const [open, setOpen] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [info, setInfo] = useState({});

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleEdit = (e) => {
    setInfo(e);
    setOpenEdit(true);
  };

  const handleDeleteItem = (e) => {
    Swal.fire({
      title: "Eliminar RC",
      text: "¿Estás seguro de eliminar el rcs de este txt?, Eliminando el RCS del txt , se podrá incluir si, se genera un nuevo txt",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const res = await electoral.deleteRCs({
            id: e.id,
            idUsuario: e.idUsuario,
          });
          if (res.results) {
            return res;
          } else {
            throw new Error(res.message);
          }
        } catch (error) {
          update(false);
          Swal.showValidationMessage(`${error.message}`);
          return false;
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.value && result.value.results) {
        update(true);
        Swal.fire({
          title: "Eliminado Correctamente",
          icon: "success",
        });
      }
    });
  };

  return (
    <>
      {openEdit && (
        <ModalEditRCS
          modalOpen={openEdit}
          setModalOpen={(e) => setOpenEdit(e)}
          params={info}
        />
      )}
      <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>
        {/*     <TableCell padding="checkbox">
            <Checkbox disableRipple checked={selected} onChange={handleClick} />
          </TableCell> */}
        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle2" noWrap>
              {data.INE}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell>{data.Paterno}</TableCell>
        <TableCell>{data.Materno}</TableCell>
        <TableCell>{data.Nombre}</TableCell>
        <TableCell>{data.remuneracion}</TableCell>
        <TableCell>
          <Chip sx={{ bgcolor: data.Color }} label={data.Estatus} />
        </TableCell>
        {(middleware.checkMenuActionId("Editar") ||
          middleware.checkMenuActionId("Eliminar")) &&
          data.idEstatus === 5 && (
            <TableCell align="right">
              <IconButton onClick={handleOpenMenu} color="warning">
                <Icon>manage_accounts</Icon>
              </IconButton>
            </TableCell>
          )}
      </TableRow>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: { width: 140 },
        }}
      >
        {middleware.checkMenuActionId("Editar") && (
          <MenuItem onClick={(e) => handleEdit(data)} sx={{ color: "purple" }}>
            <Icon sx={{ mr: 2 }} color="secondary">
              edit-fill
            </Icon>
            Edit
          </MenuItem>
        )}
        {middleware.checkMenuActionId("Eliminar") && (
          <MenuItem
            onClick={() => handleDeleteItem(data)}
            sx={{ color: "error.main" }}
          >
            <Icon sx={{ mr: 2 }}>delete</Icon>
            Delete
          </MenuItem>
        )}
      </Popover>
    </>
  );
};

const TableHeadComponent = ({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
}) => {
  const onSort = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/*      <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell> */}

        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ width: headCell.width, minWidth: headCell.minWidth }}
          >
            <TableSortLabel
              hideSortIcon
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={onSort(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box sx={{ ...visuallyHidden }}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const TableGenerarRG = (props) => {
  const {
    id,
    config = {
      height: 588,
    },
  } = props;
  const [data, setData] = useState([]);
  const [params, setParams] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
  });
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showedItems, setShowedItems] = useState(0);
  const [colums, setColums] = useState([
    { id: "INE", label: "Clave Elector" },
    { id: "Paterno", label: "Paterno" },
    { id: "Materno", label: "Materno" },
    { id: "Nombre", label: "Nombre" },
    {
      id: "remuneracion",
      label: "Remuneración",
      align: "center",
    },
    { id: "Estatus", label: "Estatus" },
    { id: "" },
  ]);

  useEffect(() => {
    if (id) {
      setData([]);
      setParams({
        page: 0,
        pageSize: 10,
        filtered: [],
      });
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      getListRCStxt(id);
      setHasMore(true);
    }
  }, [params]);

  const getListRCStxt = (id) => {
    if (loading) {
      return true;
    }

    let filtros = {
      ...params,
      filtered: [
        ...params.filtered,
        { id: "rgs_estatus.idTxt", value: id, filter: "=" },
      ],
    };
    setLoading(true);
    electoral
      .getListTXTRGs(filtros)
      .then((res) => {
        if (res.results) {
          if (filtros.page > 0) {
            setData(data.concat(res.response.data));
          } else {
            setData(
              res.response.data.map((item) => {
                return { ...item };
              })
            );
          }

          setTotal(res.response.total);

          let end =
            (params ? params.page + 1 : params.page + 1) * params.pageSize;

          if (end >= res.response.total) {
            setHasMore(false);
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchHasMore = () => {
    setParams({
      ...params,
      page: params.page + 1,
    });
  };

  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === "asc";
    if (id !== "") {
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(id);
    }
  };

  const hadleUpdate = (bool) => {
    console.log(bool);
    if (bool) {
      setParams({
        ...params,
        page: 0,
        pageSize: 11,
      });
    }
  };

  const handleFilter = (e) =>{
    const {Celular,NombreCompleto,ClaveElector, Estatus
    } = e;

    let filtro = [];
    if(Celular.length > 0){
      filtro.push({
        id:"usuarios.Username",
        value:`${Celular}`,
        filter:"LIKE"
      })
    }
    if(NombreCompleto.length > 0){
      filtro.push({id:"CONCAT(usuarios.Nombre,usuarios.Paterno,usuarios.Materno)",filter:"like",value:`%${NombreCompleto.toUpperCase().replace(/ /g, "%")}%`})
    }
    if(ClaveElector.length > 0){
      filtro.push({
        id:"usuarios.INE",
        value:`${ClaveElector}`,
        filter:"LIKE"
      })
    }
    if(Estatus > 0){
      filtro.push({
        id:"rgs_estatus.idEstatus",
        value:Estatus,
        filter:"="
      })
    }

    setParams({
      ...params,
      page:0,
      filtered:filtro
    });

  }

  const notFound = !data.length && !!filterName;

  return (
    <>
      {(id !== undefined && id !== 0) && (
        <CardContent>
          <FilterINE
            onChangeFilter={(e) => handleFilter(e)}
          />
        </CardContent>
      )}
      <Divider />
      <InfiniteScroll
        dataLength={data.length}
        next={fetchHasMore}
        hasMore={hasMore}
        loader={<LinearProgress color="secondary" />}
        height={config.height}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>
              {config.endMessage !== undefined
                ? config.endMessage
                : !id
                ? ""
                : "¡Final de la lista de registros!"}
            </b>
          </p>
        }
      >
        <TableContainer sx={{ overflow: "unset" }}>
          <Table sx={{ minWidth: 800 }}>
            <TableHeadComponent
              order={order}
              orderBy={orderBy}
              rowCount={data.length}
              numSelected={selected.length}
              onRequestSort={handleSort}
              // onSelectAllClick={handleSelectAllClick}
              headLabel={colums}
            />
            <TableBody>
              {data.length > 0 ? (
                data.map((row) => (
                  <TableRowConponent
                    data={row}
                    selected={selected.indexOf(row.name) !== -1}
                    update={(e) => hadleUpdate(e)}
                    //handleClick={(event) => handleClick(event, row.name)}
                  />
                ))
              ) : (
                <></>
              )}

              {!id && data.length === 0 && <TableNoId query={filterName} />}

              {/*        <TableEmptyRows
              height={77}
              emptyRows={
                params.page
                  ? Math.max(
                      0,
                      (1 + params.page) * params.pageSize - data.length
                    )
                  : 0
              }
            /> */}

              {notFound && <TableNoData query={filterName} />}
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </>
  );
};
export default TableGenerarRG;

import React, { useState, useEffect } from "react";

//MUI
import { Box, Button, Grid, TextField } from "@mui/material";

//Components
import InputSelect from "@components/Selects/BasicSelect";
import FilterCollapse from "@components/Filters/FilterCollapse";
import { isTypePhone } from "@utils/validations";

//catalogos
import { useCatalogs } from "@hooks/useCatalogs";

const FilterInsaculado = ({ onChangeFilter = () => {},refreash }) => {
  // catalogo
  const catalogsParams = [
    { id: "cargos_insaculacion", getAll: false},
  ];

  //variables
  const defaultOption = [{ value: 0, label: "TODOS" }];
  //States
  const [catEstatus] = useState([
    ...defaultOption,
    {value:1, label:"SI"},
    {value:2, label:"POSIBLEMENTE"}
  ]);
  const [clean, setClean] = useState(false);
  /*   const [dataCatalog, setDataCatalog] = useState({
    Region: 0,
    MunicipioDL:0,
    Responsabilidad:0,
  }); */
  const [filter, setFilter] = useState({
    Celular: "",
    Cargo: 0,
    Estatus: 0,
  });

    const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsParams,
    putDefaultOption: false,
  });


  const hadleChangeTextField = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const searchFilter = () => {
    const valueFilter = {
      Celular: filter.Celular,
      Cargo: filter.Cargo,
      Estatus: filter.Estatus,
    };

    onChangeFilter(valueFilter);
  };

  const clearFilter = () => {
    setClean(!clean);
    const newFilter = {
      Cargo: 0,
      Estatus: 0,
      Celular: "",
    };


    setFilter(newFilter);
    onChangeFilter(newFilter);
  };

  return (
    <>
      <FilterCollapse expand={false} effect={refreash}>
        <Grid container spacing={2} marginBottom={"1rem"} marginTop={"0.5rem"}>
          <Grid item xs={12} md={4}>
            <TextField
              label="Celular"
              size="small"
              name="Celular"
              value={filter.Celular}
              //onChange={hadleChangeTextField}
              onChange={(e) =>
                hadleChangeTextField({
                  target: {
                    name: "Celular",
                    value: isTypePhone(e.target.value)
                      ? e.target.value
                      : filter.Celular,
                  },
                })
              }
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={4} sm={2}>
            <InputSelect
              label="Cargo"
              options={defaultOption.concat(catalogs.cargos_insaculacion)}
              name="Cargo"
              value={filter.Cargo}
              onChange={(e) =>
                setFilter({ ...filter, Cargo: e.target.value })
              }
              loading={load}
             // disabled={catalogs.cargos_insaculacion.length === 2 ? true : false}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={4} sm={2}>
            <InputSelect
              label="Estatus de Insaculación"
              options={catEstatus}
              name="Estatus"
              value={filter.Estatus}
              onChange={(e) =>
                setFilter({ ...filter, Estatus: e.target.value })
              }
              //disabled={catResponsable.length === 2 ? true : false}
              sx={{ width: "100%" }}
            />
          </Grid>
        </Grid>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Button
            variant="contained"
            color="primaryDark"
            onClick={searchFilter}
          >
            Filtrar
          </Button>
          <Button color="primaryDark" sx={{ ml: 1 }} onClick={clearFilter}>
            Limpiar
          </Button>
        </Box>
      </FilterCollapse>
    </>
  );
};

export default FilterInsaculado;

import { useEffect, useState } from "react";

// Material UI
import { Card, CardContent, Box, Button, Grid } from "@mui/material";
import Swal from "sweetalert2";

// Componentes
import FilterCollapse from "@components/Filters/FilterCollapse";
import InputSelect from "@components/Selects/BasicSelect";
import makeAnimated from "react-select/animated";
import Select from "react-select";

// Servicios
import CatalogServices from "@services/CatalogServices";
import CatalogSije from "@services/NumeraliaServices";

const FilterData = (props) => {
  const { params, setParams, optionsF, handleFilter } = props;
  const animatedComponents = makeAnimated();
  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: "9999 !important" }),
  };
  const [isLoadingLocal, setIsLoadingLocal] = useState(false);

  const [variable, setVariable] = useState([]);

  const [region, setRegion] = useState([]);
  const [DL, setDL] = useState([]);
  const [DF, setDF] = useState([]);

  const [municipio, setMunicipio] = useState([]);
  const [municipioDL, setMunicipioDL] = useState([]);
  const [municipioDF, setMunicipioDF] = useState([]);
  const [municipioFilter, setMunicipioFilter] = useState([]);

  const [poligono, setPoligonos] = useState([]);
  const [poligonoFilter, setPoligonoFilter] = useState([]);
  const [selectedPolygons, setSelectedPolygons] = useState([]);

  const [section, setSection] = useState([]);
  const [sectionFilter, setSectionFilter] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);

  // Llamada a la api de catalogos de los selects
  const fetchData = async () => {
    setIsLoadingLocal(true);
    try {
      // parametros para traer el catalog de la API
      const params1 = [
        { id: "regiones", getAll: false },
        { id: "df", getAll: false },
        { id: "dl", getAll: false },
        // { id: "municipios", getAll: false },
        // { id: "municipios_dl", getAll: false },
        // { id: "municipios_df", getAll: false },
        { id: "poligonos", getAll: false },
        { id: "secciones", getAll: false },
      ];

      const params2 = {
        catalogs: [
          { id: "municipios" },
          { id: "municipios_df" },
          { id: "municipios_dl" },
        ],
      };

      const paramsAll = [...params1, ...params2.catalogs];

      const result = await CatalogServices.getCatalogs(params1);
      const result2 = await CatalogSije.getCatalogsSije(params2);

      const { results, message, response } = result;

      if (results && result2.results) {
        paramsAll.forEach((item) => {
          switch (item.id) {
            case "regiones":
              const regItems = response.catalogs[item.id];
              const regLen = regItems.length === 1;

              const dataRegiones = [
                { value: 0, label: "TODOS" },
                ...response.catalogs[item.id],
              ];

              setVariable(regLen ? regItems : dataRegiones);
              setRegion(regLen ? regItems : dataRegiones);

              params.Variable = regLen ? regItems[0].value : 0;
              break;
            case "dl":
              const DLItems = response.catalogs[item.id];
              const DLLen = DLItems.length === 1;
              const dataDL = [
                { value: 0, label: "TODOS" },
                ...response.catalogs[item.id],
              ];
              setDL(DLLen ? DLItems : dataDL);
              break;
            case "df":
              const DFItems = response.catalogs[item.id];
              const DFLen = DFItems.length === 1;
              const dataDF = [
                { value: 0, label: "TODOS" },
                ...response.catalogs[item.id],
              ];
              setDF(DFLen ? DFItems : dataDF);
              break;
            case "municipios":
              const munItems = result2.response.catalogs[item.id];
              const munLen = munItems.length === 1;

              const dataMunicipios = [
                { value: 0, label: "TODOS" },
                ...munItems,
              ];

              setMunicipio(munLen ? munItems : dataMunicipios);
              setMunicipioFilter(munLen ? munItems : dataMunicipios);

              params.Municipio = munLen ? munItems[0].value : 0;
            case "municipios_dl":
              // Lo ponemos en un formato que los selects entiendan
              const dlFormat = result2.response.catalogs["municipios_dl"].map(
                (item) => ({
                  value: item.idMunicipioReportes,
                  label: item.Municipio,
                  idDL: item.idDL,
                })
              );
              const dataMunicipiosDL = [
                { value: 0, label: "TODOS" },
                ...dlFormat,
              ];
              setMunicipioDL(dataMunicipiosDL);
            case "municipios_df":
              // Lo ponemos en un formato que los selects entiendan
              const dfFormat = result2.response.catalogs["municipios_df"].map(
                (item) => ({
                  value: item.idMunicipioReportes,
                  label: item.Municipio,
                  idDF: item.idDF,
                })
              );
              const dataMunicipiosDF = [
                { value: 0, label: "TODOS" },
                ...dfFormat,
              ];
              setMunicipioDF(dataMunicipiosDF);
              break;
            case "poligonos":
              setPoligonos(response.catalogs[item.id]);
              setPoligonoFilter(response.catalogs[item.id]);
              break;
            case "secciones":
              setSection(response.catalogs[item.id]);
              setSectionFilter(response.catalogs[item.id]);
              break;
          }
        });
      } else {
        Swal.fire({
          title: message,
          icon: "warning",
        });
      }
    } catch (errors) {
      Swal.fire({
        title: errors.message,
        icon: "warning",
      });
    } finally {
      setIsLoadingLocal(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Verificar si los campos solo tienen un dato para agregarlo a params en automatico.
  // Variable (Region - DF - DL)
  useEffect(() => {
    if (variable.length === 1) {
      let municipioF = [];
      let poligonoF = [];
      let seccionF = [];

      switch (params.Selected) {
        case 2: // Región
          municipioF = municipio.filter(
            (item) => item.idRegion === params.Variable
          );
          poligonoF = poligono.filter(
            (item) => item.idRegion === params.Variable
          );
          seccionF = section.filter(
            (item) => item.idRegion === params.Variable
          );
          break;
        case 3: // DF
          municipioF = municipioDF.filter(
            (item) => item.idDF === params.Variable
          );
          poligonoF = poligono.filter((item) => item.idDF === params.Variable);
          seccionF = section.filter((item) => item.idDF === params.Variable);
          break;
        case 4: // DL
          municipioF = municipioDL.filter(
            (item) => item.idDL === params.Variable
          );
          poligonoF = poligono.filter((item) => item.idDL === params.Variable);
          seccionF = section.filter((item) => item.idDL === params.Variable);
          break;
      }
      setMunicipioFilter(
        municipioF.length === 1
          ? municipioF
          : [{ value: 0, label: "TODOS" }, ...municipioF]
      );
      setPoligonoFilter(poligonoF);
      setSectionFilter(seccionF);
    }
  }, [variable]);

  // Municipios
  useEffect(() => {
    if (municipioFilter.length === 1) {
      setParams({
        ...params,
        Municipio: municipioFilter[0].value,
        Poligono: [],
        Seccion: [],
      });

      switch (params.Selected) {
        case 2: // Región
          setPoligonoFilter(
            poligono.filter((item) => {
              return (
                (params.Variable ? item.idRegion === params.Variable : true) &&
                item.idMunicipioReportes === municipioFilter[0].value
              );
            })
          );
          setSectionFilter(
            section.filter((item) => {
              return (
                (params.Variable ? item.idRegion === params.Variable : true) &&
                item.idMunicipioReportes === municipioFilter[0].value
              );
            })
          );
          break;
        case 3: // DF
          setPoligonoFilter(
            poligono.filter((item) => {
              return (
                (params.Variable ? item.idDF === params.Variable : true) &&
                item.idMunicipioReportes === municipioFilter[0].value
              );
            })
          );
          setSectionFilter(
            section.filter((item) => {
              return (
                (params.Variable ? item.idDF === params.Variable : true) &&
                item.idMunicipioReportes === municipioFilter[0].value
              );
            })
          );
          break;
        case 4: // DL
          setPoligonoFilter(
            poligono.filter((item) => {
              return (
                (params.Variable ? item.idDL === params.Variable : true) &&
                item.idMunicipioReportes === municipioFilter[0].value
              );
            })
          );
          setSectionFilter(
            section.filter((item) => {
              return (
                (params.Variable ? item.idDL === params.Variable : true) &&
                item.idMunicipioReportes === municipioFilter[0].value
              );
            })
          );
          break;
      }
    }
  }, [municipioFilter]);

  // Poligonos
  useEffect(() => {
    if (poligonoFilter.length === 1) {
      setParams({
        ...params,
        Poligono: [poligonoFilter[0].value],
        Seccion: [],
      });
      setSelectedPolygons([poligonoFilter[0]]);

      switch (params.Selected) {
        case 2: // Región
          setSectionFilter(
            section.filter((item) => {
              return (
                (params.Variable ? item.idRegion === params.Variable : true) &&
                (params.Municipio
                  ? item.idMunicipioReportes === params.Municipio
                  : true) &&
                item.idPoligono === poligonoFilter[0].value
              );
            })
          );
          break;
        case 3: // DF
          setSectionFilter(
            section.filter((item) => {
              return (
                (params.Variable ? item.idDF === params.Variable : true) &&
                (params.Municipio
                  ? item.idMunicipioReportes === params.Municipio
                  : true) &&
                item.idPoligono === poligonoFilter[0].value
              );
            })
          );
          break;
        case 4: // DL
          setSectionFilter(
            section.filter((item) => {
              return (
                (params.Variable ? item.idDL === params.Variable : true) &&
                (params.Municipio
                  ? item.idMunicipioReportes === params.Municipio
                  : true) &&
                item.idPoligono === poligonoFilter[0].value
              );
            })
          );
          break;
      }
    }
  }, [poligonoFilter]);

  // Secciones
  useEffect(() => {
    if (sectionFilter.length === 1) {
      setParams({
        ...params,
        Seccion: [sectionFilter[0].value],
      });
      setSelectedSections([sectionFilter[0]]);
    }
  }, [sectionFilter]);

  const handleClearClick = () => {
    setParams({
      ...params,
      Variable: variable.length === 1 ? variable[0].value : 0,
      Municipio: municipioFilter.length === 1 ? municipioFilter[0].value : 0,
      Poligono: [],
      Seccion: [],
    });
    setSelectedPolygons([]);
    setSelectedSections([]);
    setPoligonoFilter(poligono);
    setSectionFilter(section);

    let municipioF = [];

    if (params.Selected === 2) municipioF = municipio; // Región
    if (params.Selected === 3) municipioF = municipioDF; // DF
    if (params.Selected === 4) municipioF = municipioDL; // DL

    setMunicipioFilter(municipioF);

    handleFilter("clear");
  };

  const handleChangeFiltrar = (event) => {
    const filtrar = event.target.value;
    // Reinicia los parametros
    params.Selected = filtrar;
    params.Poligono = [];
    params.Seccion = [];

    // Vacia datos de los selects
    setSelectedPolygons([]);
    setSelectedSections([]);

    // Poner datos iniciales
    setPoligonoFilter(poligono);
    setSectionFilter(section);

    switch (filtrar) {
      case 2: // Región
        setVariable(region);
        setMunicipioFilter(municipio);
        params.Variable = region.length === 1 ? region[0].value : 0;
        params.Municipio = municipio.length === 1 ? municipio[0].value : 0;
        break;
      case 3: // DF
        setVariable(DF);
        setMunicipioFilter(municipioDF);
        params.Variable = DF.length === 1 ? DF[0].value : 0;
        params.Municipio = municipioDF.length === 1 ? municipioDF[0].value : 0;
        break;
      case 4: // DL
        setVariable(DL);
        setMunicipioFilter(municipioDL);
        params.Variable = DL.length === 1 ? DL[0].value : 0;
        params.Municipio = municipioDL.length === 1 ? municipioDL[0].value : 0;
        break;
    }
  };

  const handleChangeVariable = (event) => {
    const idVariable = event.target.value;
    // Reinicia los parametros
    setParams({
      ...params,
      Variable: idVariable,
      Municipio: 0,
      Poligono: [],
      Seccion: [],
    });
    setSelectedPolygons([]);
    setSelectedSections([]);

    let municipioF = [];
    let poligonoF = [];
    let seccionF = [];
    // Hace un filtrado de los selects
    if (idVariable > 0) {
      switch (params.Selected) {
        case 2: // Región
          municipioF = municipio.filter((item) => item.idRegion === idVariable);
          poligonoF = poligono.filter((item) => item.idRegion === idVariable);
          seccionF = section.filter((item) => item.idRegion === idVariable);
          break;
        case 3: // DF
          municipioF = municipioDF.filter((item) => item.idDF === idVariable);
          poligonoF = poligono.filter((item) => item.idDF === idVariable);
          seccionF = section.filter((item) => item.idDF === idVariable);
          break;
        case 4: // DL
          municipioF = municipioDL.filter((item) => item.idDL === idVariable);
          poligonoF = poligono.filter((item) => item.idDL === idVariable);
          seccionF = section.filter((item) => item.idDL === idVariable);
          break;
      }
      setMunicipioFilter(
        municipioF.length === 1
          ? municipioF
          : [{ value: 0, label: "TODOS" }, ...municipioF]
      );
      setPoligonoFilter(poligonoF);
      setSectionFilter(seccionF);
    } else {
      // Pone los datos por defecto
      if (params.Selected === 2) municipioF = municipio; // Región
      if (params.Selected === 3) municipioF = municipioDF; // DF
      if (params.Selected === 4) municipioF = municipioDL; // DL
      setMunicipioFilter(municipioF);
      setPoligonoFilter(poligono);
      setSectionFilter(section);
    }
  };

  const handleChangeMunicipio = (event) => {
    const idMunicipio = event.target.value;
    // Reinicia algunos parametros
    setParams({
      ...params,
      Municipio: idMunicipio,
      Poligono: [],
      Seccion: [],
    });
    setSelectedPolygons([]);
    setSelectedSections([]);

    let poligonoF = [];
    let seccionF = [];
    // Hace un filtrado de los selects
    if (idMunicipio > 0) {
      switch (params.Selected) {
        case 2: // Región
          poligonoF = poligono.filter((item) => {
            return (
              (params.Variable ? item.idRegion === params.Variable : true) &&
              item.idMunicipioReportes === idMunicipio
            );
          });
          seccionF = section.filter((item) => {
            return (
              (params.Variable ? item.idRegion === params.Variable : true) &&
              item.idMunicipioReportes === idMunicipio
            );
          });
          break;
        case 3: // DF
          poligonoF = poligono.filter((item) => {
            return (
              (params.Variable ? item.idDF === params.Variable : true) &&
              item.idMunicipioReportes === idMunicipio
            );
          });
          seccionF = section.filter((item) => {
            return (
              (params.Variable ? item.idDF === params.Variable : true) &&
              item.idMunicipioReportes === idMunicipio
            );
          });
          break;
        case 4: // DL
          poligonoF = poligono.filter((item) => {
            return (
              (params.Variable ? item.idDL === params.Variable : true) &&
              item.idMunicipioReportes === idMunicipio
            );
          });
          seccionF = section.filter((item) => {
            return (
              (params.Variable ? item.idDL === params.Variable : true) &&
              item.idMunicipioReportes === idMunicipio
            );
          });
          break;
      }
      setPoligonoFilter(poligonoF);
      setSectionFilter(seccionF);
    } else {
      switch (params.Selected) {
        case 2: // Región
          poligonoF = poligono.filter((item) =>
            params.Variable ? item.idRegion === params.Variable : true
          );
          seccionF = section.filter((item) =>
            params.Variable ? item.idRegion === params.Variable : true
          );
          break;
        case 3: // DF
          poligonoF = poligono.filter((item) =>
            params.Variable ? item.idDF === params.Variable : true
          );
          seccionF = section.filter((item) =>
            params.Variable ? item.idDF === params.Variable : true
          );
          break;
        case 4: // DL
          poligonoF = poligono.filter((item) =>
            params.Variable ? item.idDL === params.Variable : true
          );
          seccionF = section.filter((item) =>
            params.Variable ? item.idDL === params.Variable : true
          );
          break;
      }
      setPoligonoFilter(poligonoF);
      setSectionFilter(seccionF);
    }
  };

  const handleChangePoligono = (newValue) => {
    setSelectedPolygons(newValue);
    const array_poligon = newValue.map((option) => option.value || option);
    // Actualiza los poligonos
    setParams({
      ...params,
      Poligono: array_poligon,
      Seccion: [],
    });
    setSelectedSections([]);

    let seccionF = [];
    // Hace un filtrado en Sección
    if (array_poligon.length > 0) {
      switch (params.Selected) {
        case 2: // Región
          seccionF = section.filter((item) => {
            return (
              (params.Variable ? item.idRegion === params.Variable : true) &&
              (params.Municipio
                ? item.idMunicipioReportes === params.Municipio
                : true) &&
              array_poligon.some((id) => id === item.idPoligono)
            );
          });
          break;
        case 3: // DF
          seccionF = section.filter((item) => {
            return (
              (params.Variable ? item.idDF === params.Variable : true) &&
              (params.Municipio
                ? item.idMunicipioReportes === params.Municipio
                : true) &&
              array_poligon.some((id) => id === item.idPoligono)
            );
          });
          break;
        case 4: // DL
          seccionF = section.filter((item) => {
            return (
              (params.Variable ? item.idDL === params.Variable : true) &&
              (params.Municipio
                ? item.idMunicipioReportes === params.Municipio
                : true) &&
              array_poligon.some((id) => id === item.idPoligono)
            );
          });
          break;
      }
      setSectionFilter(seccionF);
    } else {
      // Que conserve los filtros anteriores en caso de borrar el poligono
      switch (params.Selected) {
        case 2: // Región
          seccionF = section.filter((item) => {
            return (
              (params.Variable ? item.idRegion === params.Variable : true) &&
              (params.Municipio
                ? item.idMunicipioReportes === params.Municipio
                : true)
            );
          });
          break;
        case 3: // DF
          seccionF = section.filter((item) => {
            return (
              (params.Variable ? item.idDF === params.Variable : true) &&
              (params.Municipio
                ? item.idMunicipioReportes === params.Municipio
                : true)
            );
          });
          break;
        case 4: // DL
          seccionF = section.filter((item) => {
            return (
              (params.Variable ? item.idDL === params.Variable : true) &&
              (params.Municipio
                ? item.idMunicipioReportes === params.Municipio
                : true)
            );
          });
          break;
      }
      setSectionFilter(seccionF);
    }
  };

  const handleChangeSection = (newValue) => {
    setSelectedSections(newValue);
    const array_section = newValue.map((option) => option.value || option);
    // Actualiza las secciones
    setParams({
      ...params,
      Seccion: array_section,
    });
  };

  return (
    <Card className="card-primary">
      <CardContent>
        <FilterCollapse expand>
          <Box display="flex" flexDirection="column">
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} lg={4}>
                <InputSelect
                  label="Filtrar por"
                  options={optionsF}
                  name="FiltrarPor"
                  value={params.Selected}
                  onChange={handleChangeFiltrar}
                  sx={{ width: "100%" }}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <InputSelect
                  label={optionsF[params.Selected - 2].label}
                  options={variable}
                  name="variable"
                  value={params.Variable}
                  onChange={handleChangeVariable}
                  isLoading={isLoadingLocal}
                  sx={{ width: "100%" }}
                  disabled={variable.length === 1 ? true : false}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <InputSelect
                  label="Municipio"
                  options={municipioFilter}
                  name="municipio"
                  value={params.Municipio}
                  onChange={handleChangeMunicipio}
                  isLoading={isLoadingLocal}
                  sx={{ width: "100%" }}
                  disabled={municipioFilter.length <= 1 ? true : false}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <Select
                  placeholder="Polígono"
                  styles={selectStyles}
                  menuPortalTarget={document.body}
                  options={poligonoFilter}
                  value={selectedPolygons}
                  onChange={handleChangePoligono}
                  isLoading={isLoadingLocal}
                  isDisabled={poligonoFilter.length <= 1 ? true : false}
                  components={animatedComponents}
                  closeMenuOnSelect={false}
                  isMulti
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <Select
                  placeholder="Sección"
                  styles={selectStyles}
                  menuPortalTarget={document.body}
                  options={sectionFilter}
                  value={selectedSections}
                  onChange={handleChangeSection}
                  isLoading={isLoadingLocal}
                  isDisabled={sectionFilter.length <= 1 ? true : false}
                  components={animatedComponents}
                  closeMenuOnSelect={false}
                  isMulti
                />
              </Grid>

              {/* Botones */}
              <Grid item xs={12} lg={4}>
                <Box sx={{ display: "flex", gap: { xs: 1, sm: 2 } }}>
                  <Button
                    variant="outlined"
                    color="primaryDark"
                    onClick={handleClearClick}
                    disabled={isLoadingLocal}
                  >
                    Limpiar
                  </Button>
                  <Button
                    variant="contained"
                    color="primaryDark"
                    onClick={() => handleFilter("filter")}
                    disabled={isLoadingLocal}
                  >
                    Filtrar
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </FilterCollapse>
      </CardContent>
    </Card>
  );
};

export default FilterData;

import {
    Button,
    Card,
    Container,
    Grid,
    Icon,
    Typography,
    Stack,
    TextField,
  } from "@mui/material";
  import { useFormik } from "formik";
  import PropTypes from "prop-types";
  
  import { FilterFrente } from "@data/interfaces/FrenteAmplioInterfaces";
  import { FilterFrenteSchema } from "@data/schemas/FrenteAmplioSchemas";
  import { useCatalogs } from "@hooks/useCatalogs";
  import { isTypePhone } from "@utils/validations";
  import InputSelect from "@components/Selects/BasicSelect";
  import { useEffect,useState } from "react";
  
  const Filter = ({ onChangeFilter, correo = true, municipio = false }) => {
    const defaultOption = [{ value: 0, label: "TODOS" }];
    const catalogsParams = [
      { id: "regiones", getAll: false  },
      { id: "municipios", getAll: false  },
      { id: "municipios_dl", getAll: false  },
      { id: "poligonos" , getAll: false },
      { id: "secciones" , getAll: false },
      { id: "calidad_representante", getAll: false  },
      { id: "dl" , getAll: false },
      { id: "df", getAll: false  },
    ];
    const { catalogs, load } = useCatalogs({
      catalogsOptions: catalogsParams,
      putDefaultOption: false,
    });
    const [catMunicipalityFilter, setCatMunicipalityFilter] = useState([]);
  
    useEffect(() => {
      if (load === false) {
        setCatMunicipalityFilter(defaultOption.concat(catalogs.municipios));
      }
      // eslint-disable-next-line
    }, [load]);
  
    const formik = useFormik({
      initialValues: FilterFrente,
      validationSchema: FilterFrenteSchema,
      onSubmit: (values) => {
        handleFilter(values);
      },
    });
  
    const handleFilter = (values) => {
      const { celular, nombre, Correo,municipio } = values;
      let filtered = [];
  
      if (formik.values.celular !== "") {
        filtered.push({
          id: "R.Celular",
          filter: "=",
          value: celular,
        });
      }
  
      if (formik.values.nombre !== "") {
        filtered.push({
          id: "R.Capturo",
          filter: "LIKE",
          value: nombre.toUpperCase(),
        });
      }
  
      if (formik.values.Correo !== "") {
        filtered.push({
          id: "mail_accounts.Correo",
          filter: "LIKE",
          value: `%${Correo.toUpperCase().replace(/ /g, "%")}%`,
        });
      }
      if (formik.values.municipio !== "") {
          filtered.push({
            id: "R.idMunicipio",
            filter: "=",
            value: municipio,
          });
        }
  
      onChangeFilter(filtered);
    };
  
    const handleOnKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        formik.submitForm();
      }
    };
  
    const handleClearFilter = () => {
      formik.setValues({
        ...formik,
        celular: "",
        nombre: "",
        Correo: "",
      });
  
      onChangeFilter([]);
    };
    return (
      <Card className="card-primary">
        <Container maxWidth={false} sx={{ p: 2 }}>
          <Stack direction="row" spacing={1} marginBottom={2} alignItems="center">
            <Icon>tune</Icon>
            <Typography variant="subtitle1" fontWeight={600}>
              Filtros
            </Typography>
          </Stack>
          <Grid container spacing={2}>
          {municipio && (
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <InputSelect
                  label="Municipio"
                  options={catMunicipalityFilter}
                  name="municipio"
                  value={
                      catMunicipalityFilter.length === 2
                      ? catMunicipalityFilter[1].value
                      : formik.municipio
                  }
                  onChange={formik.handleChange}
                  disabled={catMunicipalityFilter.length === 2 ? true : false}
                  sx={{ width: "100%" }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                sx={{ width: "100%" }}
                label="Celular"
                type="text"
                name="celular"
                onChange={(e) =>
                  formik.handleChange({
                    target: {
                      name: "celular",
                      value: isTypePhone(e.target.value)
                        ? e.target.value
                        : formik.values.celular,
                    },
                  })
                }
                onBlur={(e) =>
                  formik.handleChange({
                    target: {
                      name: "celular",
                      value: isTypePhone(e.target.value)
                        ? e.target.value
                        : formik.values.celular,
                    },
                  })
                }
                onKeyPress={handleOnKeyPress}
                value={formik.values.celular}
                variant="outlined"
                size="small"
                className="fixed-input"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                sx={{ width: "100%" }}
                label="Nombre"
                type="text"
                name="nombre"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onKeyPress={handleOnKeyPress}
                value={formik.values.nombre.toUpperCase()}
                variant="outlined"
                size="small"
                className="fixed-input"
              />
            </Grid>
            {correo && (
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  sx={{ width: "100%" }}
                  label="Correo"
                  type="text"
                  name="Correo"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onKeyPress={handleOnKeyPress}
                  value={formik.values.Correo.toUpperCase()}
                  variant="outlined"
                  size="small"
                  className="fixed-input"
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Button
                variant="contained"
                color="primaryDark"
                onClick={formik.submitForm}
              >
                Filtrar
              </Button>
              <Button
                variant="text"
                color="primaryDark"
                onClick={handleClearFilter}
                sx={{ ml: 1 }}
              >
                Limpiar
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Card>
    );
  };
  
  Filter.propTypes = {
    onChangeFilter: PropTypes.func.isRequired,
  };
  
  export default Filter;
  
import React, { useState, useContext } from "react";
import { useFormik } from "formik";
import {
  InputAdornment,
  IconButton,
  Box,
  Button,
  Icon,
  TextField
} from "@mui/material";
import Swal from "sweetalert2";
import UserUpdateContext from './UserUpdateContext'
import { isEmptyOrInvalidString } from "@utils/validations";
import { UserPinInterface } from "@data/interfaces/UserInterfaces";
import { UserPinSchema } from "@data/schemas/UserSchemas";
import * as Validate from '@data/schemas/UserSchemas'

const UserPin = props => {
  const { idUsuario, API } = props;
  const {
    setIsLoadingForm, setOpenLinearProgress, setIsSuccessForm
  } = useContext(UserUpdateContext);

  const [flags, setFlags] = useState({
    showPin: false,
    showConfirmPin: false
  });

  const formik = useFormik({
    initialValues: UserPinInterface,
    validationSchema: UserPinSchema,
    onSubmit: values => {
      values.id = idUsuario;
      handleSave(values);
    }
  });

  const handleSave = async (data) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);

    try {
      const result = await API(data);
      const { results, message } = result;

      if (results) {
        Swal.fire({
          title: message,
          icon: "success",
          allowEscapeKey: false,
          allowOutsideClick: false,
          allowEnterKey: false
        }).then((res) => {
          if (res) {
            formik.resetForm()
          }
        });
      } else {
        Swal.fire({
          title: message,
          icon: "warning",
        });
        setIsSuccessForm(false);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
      setIsSuccessForm(false);
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  };

  const handleClickShowPin = prop => event => {
    setFlags({ ...flags, [prop]: !flags[prop] });
  };

  const handleMouseDownPin = event => {
    event.preventDefault();
  };

  const handleCleanForm = e => {
    formik.setValues(UserPinInterface);
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{ ml: { xs: 6, sm: 7 }, mr: { xs: 2, sm: 3 } }}>
        <TextField
          error={
            formik.touched.PIN &&
            !isEmptyOrInvalidString(formik.errors.PIN)
          }
          label="Pin"
          helperText={
            formik.touched.PIN &&
            formik.errors.PIN &&
            formik.errors.PIN
          }
          type={flags.showPin ? "text" : "password"}
          name="PIN"
          onChange={(e) => {
            if (e.target.value.length <= 5) {
              formik.handleChange(e);
            }
          }}
          onBlur={formik.handleBlur}
          value={formik.values.PIN}
          variant="standard"
          fullWidth
          className="fixed-input"
          onKeyPress={Validate.handleKeyPress}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle pin visibility"
                  onClick={handleClickShowPin("showPin")}
                  onMouseDown={handleMouseDownPin}
                >
                  {flags.showPin ? (
                    <Icon>visibility_off</Icon>
                  ) : (
                    <Icon>visibility</Icon>
                  )}
                </IconButton>
              </InputAdornment>
            )
          }}
        />

        <TextField
          error={
            formik.touched.Confirm &&
            !isEmptyOrInvalidString(formik.errors.Confirm)
          }
          label="Confirmar Pin"
          helperText={
            formik.touched.Confirm &&
            formik.errors.Confirm &&
            formik.errors.Confirm
          }
          type={flags.showConfirmPin ? "text" : "password"}
          name="Confirm"
          onChange={(e) => {
            if (e.target.value.length <= 5) {
              formik.handleChange(e);
            }
          }}
          onBlur={formik.handleBlur}
          value={formik.values.Confirm}
          variant="standard"
          fullWidth
          className="fixed-input"
          onKeyPress={Validate.handleKeyPress}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle pin visibility"
                  onClick={handleClickShowPin("showConfirmPin")}
                  onMouseDown={handleMouseDownPin}
                >
                  {flags.showConfirmPin ? (
                    <Icon>visibility_off</Icon>
                  ) : (
                    <Icon>visibility</Icon>
                  )}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Box>
      <Box sx={{ mt: "5vh", textAlign: "end" }}>
        <Button
          variant="outlined"
          size="small"
          onClick={handleCleanForm}
          startIcon={<Icon>cancel</Icon>}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primaryDark"
          onClick={formik.submitForm}
          startIcon={<Icon>save</Icon>}
          disabled={!idUsuario}
          sx={{ ml: 1 }}
        >
          Guardar
        </Button>
      </Box>
    </Box>
  );
};

export default UserPin;

import { ApiExec } from "@utils/ApiExec";

class AuthServices {
  login = (data) => ApiExec(data, "auth/login", "POST");

  getRefreshLogin = (data) => ApiExec(data, "auth/refresh-login", "POST");
}

const service = new AuthServices();
export default service;

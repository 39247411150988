import { useEffect, useState } from "react";
import { useCatalogs } from "@hooks/useCatalogs";

import {
  Box,
  Card,
  CardContent,
  Container,
  Icon,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import style from "./drawer.module.css";
import { blue, grey } from "@mui/material/colors";
import { TraceSpinner } from "react-spinners-kit";

import TreeDrive from "@components/TreeDrive/TreeDrive";
import Document from "@components/TreeDrive/Document";
import GridDocuments from "../TreeDrive/GridDocuments";
import DrawerRelative from "@components/Containers/DrawerRelative";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import InfoDrive from "./InfoDrive";
import PreviewDocument from "../TreeDrive/PreviewDocument";

import movilizacion from "@services/MovilizationServices";
import Swal from "sweetalert2";

const Drive = () => {
  const catalogsOptions = [
    { id: "regiones", getAll: false },
    { id: "municipios_reportes", getAll: false },
    { id: "poligonos", getAll: false },
    { id: "secciones", getAll: false },
  ];

  const [documentTree, setDocumentTree] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loadProgress, setLoadProgress] = useState({
    isLoading: true,
    isOpen: true,
    message: "Cargando ...",
  });
  const [dataInfo, setDataInfo] = useState({});
  const [loadingSimulate, setLoadingSimulate] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [paramsPreview, setParamsPreview] = useState({});
  const [titlePreview, setTitlePreview] = useState("");
  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsOptions,
    putDefaultOption: false,
  });

  const simulateAPICall = (delay = 1000) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, delay);
    });
  };

  const generateUniqueId = () => {
    return Math.random().toString(36).substring(2, 10);
  };

  const generateNodeRegions = (regions, municipalities, poligons, sections) => {
    return regions.map((region) => ({
      id: generateUniqueId(),
      value: region.value,
      label: region.label,
      hasPermissionDownload: false,
      children: generateNodeMunicipalities(
        region.value,
        municipalities,
        poligons,
        sections
      ),
    }));
  };

  const generateNodeMunicipalities = (
    regionId,
    municipalities,
    poligons,
    sections
  ) => {
    return municipalities
      .filter((mpio) => mpio.idRegion === regionId)
      .map((mpio) => ({
        id: generateUniqueId(),
        value: mpio.value,
        label: mpio.label,
        hasPermissionDownload: false,
        children: generateNodePoligons(mpio.value, poligons, sections),
      }));
  };

  const generateNodePoligons = (municipalityId, poligons, sections) => {
    return poligons
      .filter((poligon) => poligon.idMunicipioReportes === municipalityId)
      .map((poligon) => ({
        id: generateUniqueId(),
        value: poligon.value,
        label: poligon.label,
        hasPermissionDownload: true,
        sections: generateNodeSections(poligon.value, sections),
      }));
  };

  const generateNodeSections = (poligonId, sections) => {
    return sections
      .filter((seccion) => seccion.idPoligono === poligonId)
      .map((seccion) => ({
        id: generateUniqueId(),
        value: seccion.value,
        label: seccion.label,
        hasPermissionDownload: true,
      }));
  };

  useEffect(() => {
    if (!load) {
      const treeData = generateNodeRegions(
        catalogs.regiones,
        catalogs.municipios_reportes,
        catalogs.poligonos,
        catalogs.secciones
      );

      setDocumentTree(treeData);
      setLoadProgress({ ...loadProgress, isLoading: false, isOpen: false });
    }
  }, [load]);

  const handleClick = async (e) => {
    try {
      if (e.hasPermissionDownload) {
        setLoadingSimulate(true);
        await simulateAPICall();

        setDataInfo(e);
      } else {
        openDrawer && setOpenDrawer(false);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoadingSimulate(false);
    }
  };

  const handleDownload = (e) => {
    if (e.hasPermissionDownload) {
      executeDownload({
        field: "Seccion",
        value: e.value,
      });
    }
  };

  const handlePreview = async (e) => {
    if (e.hasPermissionDownload) {
      setParamsPreview({ field: "Seccion", value: e.value });
      setTitlePreview(e.label);
      setOpenPreview(!openPreview);
    }
  };

  const handleDownloadAll = (e) => {
    e.stopPropagation();
    const { value } = dataInfo;

    executeDownload({ field: "idPoligono", value });
  };

  const handleDownloadAllPolygon = (e, obj) => {
    e.stopPropagation();
    const { value } = obj;

    executeDownload({ field: "idPoligono", value });
  };

  const executeDownload = async (params = {}) => {
    try {
      setLoadProgress({
        ...loadProgress,
        isLoading: true,
        isOpen: true,
        message: "Descargando ...",
      });

      const res = await movilizacion.exportDocuments({
        params,
        api: "generate-mobilization-list",
      });

      if (!res.results && !res.success) throw new Error(res.message);
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      //setLoadingDownload(false);
      setLoadProgress({
        ...loadProgress,
        isLoading: false,
        isOpen: false,
        message: "",
      });
    }
  };

  return (
    <>
      <LoadingForm
        loadinMessage={loadProgress.message}
        isLoading={loadProgress.isLoading}
        isOpen={loadProgress.isOpen}
        setIsOpen={() => setLoadProgress({ ...loadProgress, isOpen: false })}
      />
      <Grid2 container spacing={2}>
        <Grid2 xs={12} md={3} lg={3}>
          <TreeDrive
            data={documentTree}
            handleClick={handleClick}
            handleDownload={handleDownloadAllPolygon}
          />
        </Grid2>
        <Grid2 xs={12} md={openDrawer ? 6 : 9} lg={openDrawer ? 6 : 9}>
          <Card
            elevation={0}
            sx={{
              height: "100%",
              borderRadius: 5,
              minHeight: "calc(100vh - 200px)",
              maxHeight: "calc(100vh - 150px)",
              position: "sticky",
              top: "5rem",
            }}
          >
            <CardContent>
              {loadingSimulate ? (
                <>
                  <Typography
                    variant="subtitle2"
                    fontWeight={600}
                    fontSize={16}
                  >
                    Archivos
                  </Typography>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    minHeight={"calc(100vh - 150px)"}
                  >
                    <TraceSpinner
                      frontColor={blue[800]}
                      backColor={blue[100]}
                      loading={true}
                    />
                  </Box>
                </>
              ) : (
                <>
                  {dataInfo && dataInfo.sections ? (
                    <GridDocuments
                      dataInfo={dataInfo}
                      openDrawer={openDrawer}
                      setOpenDrawer={setOpenDrawer}
                      handleDownloadAll={handleDownloadAll}
                    >
                      {dataInfo &&
                        dataInfo.sections &&
                        dataInfo.sections.map((item) => (
                          <Document
                            key={item.id}
                            name={"Sección " + item.label}
                            handleDownload={() => handleDownload(item)}
                            handlePreview={() => handlePreview(item)}
                          />
                        ))}
                    </GridDocuments>
                  ) : (
                    <>
                      <Typography
                        variant="subtitle2"
                        fontWeight={600}
                        fontSize={16}
                      >
                        Archivos
                      </Typography>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        flexDirection={"column"}
                        minHeight={"calc(100vh - 150px)"}
                        gap={1}
                      >
                        <Icon
                          fontSize="large"
                          sx={{
                            fontSize: 70,
                            color: grey[400],
                            backgroundColor: grey[100],
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            p: 6,
                          }}
                        >
                          insert_drive_file
                        </Icon>
                        <Typography variant="body2" fontWeight={600}>
                          Por favor, selecciona un polígono para proceder con la
                          descarga de los archivos.
                        </Typography>
                      </Box>
                    </>
                  )}
                </>
              )}
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 xs={12} md={3} lg={3}>
          <DrawerRelative
            anchor="right"
            open={openDrawer}
            setOpen={setOpenDrawer}
            title="Detalles"
            icon="info"
            sxPaper={{ borderRadius: 3 }}
            className={style.drawer}
            isSticky
            screenHeight="md"
          >
            <Container>
              <InfoDrive data={dataInfo} />
            </Container>
          </DrawerRelative>
        </Grid2>
      </Grid2>
      <PreviewDocument
        title={`Sección ${titlePreview}`}
        params={paramsPreview}
        open={openPreview}
        setOpen={setOpenPreview}
      />
    </>
  );
};

export default Drive;

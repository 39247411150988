
import { useEffect, useState } from "react";
import { Card,CardContent,Container,Chip, Icon } from "@mui/material";
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import BasicTable from "@components/Tables/BasicTable";
import { useNavigate } from "react-router-dom";
import FilterInsaculado from "../../components/Electoral/FilterInsaculado";
import Swal from "sweetalert2";
import electoral from "@services/ElectoralServices";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Insaculado = (props) => {
    const navigate = useNavigate();
    const [data,setData] = useState([]);
    const [total,setTotal] = useState(0);
    const [loading,setLoading] = useState(false);
    const [tablaData,setTablaData] = useState({page:0,pageSize:10});
    let columns =[
        { id: "Celular", label: "Celular" },
        { id: "Nombre", label: "Nombre" },
        { id: "FechaNacimiento", label: "Fecha de Nacimiento" },
        { id: "Cargo", label: "Cargo" },
        { id: "TieneUsuario", label: "Tiene Usuario" },
        { id: "Insaculado", label: "Insaculado" },
    ];

    useEffect(()=>{
        getList();
    },[tablaData])

    const getList = () => {
        if (loading) {
          return true;
        }
        setLoading(true);
        electoral
          .getListInsaculado(tablaData)
          .then((res) => {
            if (res.results) {
              setData(res.response.data.map(item => {
                return {...item,
                    Celular: <a href={`https://api.whatsapp.com/send?phone=52${item.Celular}&text=Hola ${item.Nombre} estás en la lista ${item.Insaculado === "POSIBLEMENTE" ? "de posibles ": "de "} insaculados.`}
                    target="_blank">{<Chip color="success" icon={<WhatsAppIcon />}  label={item.Celular} variant="outlined" />}</a>,
                    Insaculado: item.Insaculado === "SI" ? <Chip label={`${item.Insaculado}`} color="error" /> : item.Insaculado
                }}));
              setTotal(res.response.total);
            } else {
              Swal.fire({ title: res.message, icon: "warning" });
            }
          })
          .catch((e) => {
            Swal.fire({ title: e.message, icon: "warning" });
          })
          .finally(() => {
            setLoading(false);
          });
    
      };

 
    const handleChangePagination = (pagination) => {
        setTablaData({ ...tablaData, ...pagination });
    };

    const handleChangeFilter = (type) => {
        const {
          Celular,
          Cargo,
          Estatus
        } = type;
       
        let filtered = [];
    
        if (Cargo > 0 ) {
        if(Cargo === 1){
            filtered = [
                ...filtered,
                { id: " AND (usuarios_ambito_perfil.idPerfil IS NULL OR usuarios_ambito_perfil.idPerfil = 1)", filterQuery: true, },
              ];
        }else{
            filtered = [
                ...filtered,
                { id: "usuarios_ambito_perfil.idPerfil", filter: "=", value: Cargo },
              ];
        }
         
        }
        if (Estatus > 0) {
        filtered = [
            ...filtered,
            Estatus === 2 ?  { id: "compromisos_unicos_insaculados.INE", filter: "IS", value: "NULL" } : { id: "compromisos_unicos_insaculados.INE", filter: "IS NOT", value: "NULL" } 
            ];
          
        }
        if (Celular.length > 0) {
          filtered = [
            ...filtered,
            {
              id: "compromisos_unicos.Celular",
              filter: "=",
              value: Celular,
            },
          ];
        }
    
        setTablaData({
          ...tablaData,
          page:0,
          filtered: filtered,
        });
    
        
      };
    

    return (
        <AdminLayout>
        <Container maxWidth="false">
          <ActionHeader
            title={`Insaculados`}
            handleclick={() => navigate(`/insaculado`)}
          />
          <Card>
          <CardContent>
          <FilterInsaculado onChangeFilter={(e)=>handleChangeFilter(e)}/>
          </CardContent>
          </Card>
          <br/>
         <Card>
            <CardContent>
            <BasicTable
              rows={data}
              hcolumns={columns}
              pageProp={tablaData.page}
              pageSize={tablaData.pageSize}
              total={total}
              handleChangePagination={handleChangePagination}
              isLoading={loading}
              stickyHeader={true}
            />
            </CardContent>
         </Card>
        </Container>
        </AdminLayout>
    );
}
export default Insaculado;
import React, { useEffect, useState } from "react";

import AdaptiveCard from "@components/Card/AdaptiveCard";

import movilizacion from "@services/MovilizationServices";
import Swal from "sweetalert2";
import { Stack } from "@mui/material";

const StatisticalCard = ({ params, refresh, setRefresh }) => {
  const [attendanceData, setAttendanceData] = useState({
    Movilizacion: {
      Total: "",
      Avance: "",
      PorcentajeAvance: "",
    },
  });

  const getCardsTerritorial = async (params) => {
    try {
      const { response, results, message } =
        await movilizacion.getCardsTerritorial({ filtered: params });

      if (!results) throw new Error(message);

      setAttendanceData((prev) => ({
        ...prev,
        Movilizacion: {
          Total: response.data.Movilizacion.Total,
          Avance: response.data.Movilizacion.Avance,
          PorcentajeAvance: response.data.Movilizacion.PorcentajeAvance,
        },
      }));
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setRefresh(false);
    }
  };

  useEffect(() => {
    getCardsTerritorial(params);
  }, [params, refresh]);

  return (
    <Stack direction={"row"}>
      <AdaptiveCard
        title="Avance de Movilización"
        icon="description"
        config={{
          percent:
            parseFloat(attendanceData.Movilizacion?.PorcentajeAvance) || 0,
        }}
        leftConfig={{
          title: "Meta",
          data: parseFloat(attendanceData.Movilizacion?.Total) || 0,
        }}
        rightConfig={{
          title: "Avance",
          data: parseFloat(attendanceData.Movilizacion?.Avance) || 0,
        }}
        fixed
        sx={{ width: "100%" }}
        //loading={loadingCards}
      />
    </Stack>
  );
};

export default StatisticalCard;

import React, { useState, useRef, useCallback, useEffect } from "react";

import {
  Box,
  TextField,
  Typography,
  Chip,
  Icon,
  CardMedia,
  IconButton,
  Stack,
  CircularProgress,
  InputAdornment,
  Divider,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import esLocale from "date-fns/locale/es";
import moment from "moment";
import FrenteIne from "@assets/img/frenteIne.png";
import DetrasIne from "@assets/img/detrasIne.png";
import Swal from "sweetalert2";
import { green, deepOrange } from "@mui/material/colors";
import { TaskAlt } from "@mui/icons-material";

import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";

import BasicSelect from "@components/Selects/BasicSelect";
import { isEmptyOrInvalidString, isNullOrUndefined, isValidSection } from "@utils/validations";
import Webcam from "react-webcam";
import "./WebCamera.css";
import ModalIne from "./ModalIne";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import CompromisoService from "@services/CompromisoServices";

const PersonalInformationForm = (props) => {
  const {
    catalogs,
    loadigCatalogs,
    errors,
    touched,
    values,
    handleChange,
    setValues,
    isMenor = false,
    NotINE = false,
    NotSeccion = false,
    NotSimple = false,
    ScanIne = false,
    setLoading,
    validar = true
  } = props;

  const webcamRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [openFront, setOpenFront] = useState(false);
  const [openBehind, setOpenBehind] = useState(false);
  const [loadingFoto, setLoadingFoto] = useState(false);
  const [open, setOpen] = useState({
    open: false,
    message: "",
    color: "",
    severity: "",
  });

  const [typeCamara, setTypeCamara] = useState({
    width: 1280,
    height: 720,
    facingMode: "user",
  });

  useEffect(() => {}, [openFront, openBehind, values.FrontIne, values.BehindIne, typeCamara]);

  const onblurINE = (INEValue) => {
    let genderId = "";
    let stateId = "";
    let dob = "";
    let len = INEValue.length;

    if (len >= 12) {
      let year = moment().format("YYYY");
      let dobAux = INEValue.substring(6, 12);
      let anio = dobAux.substring(0, 2);
      let mes = dobAux.substring(2, 4);
      let dia = dobAux.substring(4, 6);

      anio = moment(anio, "YY").format("YYYY");

      let diff = parseInt(year) - parseInt(anio);

      if (diff > 0) {
        dob = `${dia}/${mes}/${anio}`;
      } else {
        anio = anio - 100;
        dob = `${dia}/${mes}/${anio}`;
      }
    }
    if (len >= 14) {
      let _sId = parseInt(INEValue.substring(12, 14));
      stateId = catalogs.entidades.find((item) => item.value === _sId) ? _sId : "";
    }
    if (len >= 15) {
      const genderKey = INEValue.substring(14, 15);
      genderId = genderKey === "M" ? 3 : genderKey === "H" ? 2 : 1;
    }
    let IneData = {
      ...values,
      FechaNacimiento: moment(dob, "DD/MM/YYYY"),
      idEstadoNacimiento: stateId,
      idSexo: genderId,
    };

    setValues(IneData);

    return IneData;
  };

  const onblurCurp = (CurpValue) => {
    let genderId = "";
    let stateId = "";
    let dob = "";
    let len = CurpValue.length;

    if (len >= 12) {
      let year = moment().format("YYYY");
      let dobAux = CurpValue.substring(4, 10);
      let anio = dobAux.substring(0, 2);
      let mes = dobAux.substring(2, 4);
      let dia = dobAux.substring(4, 6);

      anio = moment(anio, "YY").format("YYYY");

      let diff = parseInt(year) - parseInt(anio);

      if (diff > 0) {
        dob = `${dia}/${mes}/${anio}`;
      } else {
        anio = anio - 100;
        dob = `${dia}/${mes}/${anio}`;
      }
    }
    if (len >= 11) {
      const genderKey = CurpValue.substring(11, 12);
      genderId = genderKey === "M" ? 3 : genderKey === "H" ? 2 : 1;
    }
    let CurpData = {
      ...values,
      FechaNacimiento: moment(dob, "DD/MM/YYYY"),
      idSexo: genderId,
    };

    setValues(CurpData);

    return CurpData;
  };

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    let validation = false;
    if (openFront) {
      validation = values.BehindIne !== null && values.BehindIne !== undefined && true;
      handleChange({
        target: {
          name: "FrontIne",
          value: imageSrc,
        },
      });

      setOpenFront(false);
    }
    if (openBehind) {
      validation = values.FrontIne !== null && values.FrontIne !== undefined && true;
      handleChange({
        target: {
          name: "BehindIne",
          value: imageSrc,
        },
      });
      setOpenBehind(false);
    }

    if (validation) {
      getDataInformation(imageSrc);
    }
  };

  const ChangeCamara = () => {
    setTypeCamara({
      ...typeCamara,
      facingMode: typeCamara.facingMode === "user" ? "environment" : "user",
    });
  };

  const getDataInformation = (base64Image) => {
    if (loadingFoto) {
      return true;
    }

    setLoadingFoto(true);
    let itemFront = "";
    let itemBehind = "";
    let base = base64Image.split(",")[0];
    if (openFront) {
      itemFront = base64Image.split(",")[1];
      itemBehind = values.BehindIne.split(",")[1];
    }
    if (openBehind) {
      itemBehind = base64Image.split(",")[1];
      itemFront = values.FrontIne.split(",")[1];
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        // Token: "dgfdd4d1-5bf8-10fa-b382-13dgf533g3gd",
        Token: "16eb7187-ae2c-4c20-83e7-1166cefca729",
        front: itemFront,
        back: itemBehind,
      }),
    };

    fetch("https://api.mapea.me/api/ines/get", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        let IneMunicipio = "";
        let localidadIne = "";
        if (data.response.status === "ok") {
          var CP = "";
          var Colonia = "";
          if (data.response.colonia) {
            CP = data.response.colonia.substring(data.response.colonia.length - 5, data.response.colonia.length);
            let OnlyColonia = data.response.colonia.substring(0, data.response.colonia.length - 6);
            let splitCol = OnlyColonia.split(" ");

            let TypeAsentamiento = [
              "Col",
              "C.H",
              "Fracc",
              "Z.I",
              "Ej",
              "Rch",
              "Grj",
              "Rcho",
              "Pbl",
              "Equip",
              "Brr",
              "Aerop",
              "Res",
              "P.I",
              "Fca",
              "Z.M",
              "Cond",
              "U.H",
              "Z.C",
              "Pje",
              "Hda",
              "P.C",
            ];
            let validateColonia = TypeAsentamiento.find((item) => item.toUpperCase() === splitCol[0]);

            if (validateColonia) {
              splitCol.shift();
              Colonia = splitCol.toString().replace(/,/g, " ");
            }
          }
          if (data.response.subTipo === "F") {
            const tmpCity = data.response.ciudad.split(",");
            const tmpCity1 = tmpCity[0].trim();

            const removeAccents = (str) => {
              return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            };

            if (tmpCity.length > 1) {
              const existeMuniX = catalogs.municipios.filter((m) => removeAccents(m.label.toUpperCase()) === removeAccents(tmpCity1.toUpperCase()));
              if (existeMuniX.length > 0) {
                IneMunicipio = existeMuniX[0];
                //  setMunicipioGrupo(IneMunicipio);
              }
            }
          } else {
            if (data.response.municipio) {
              if (parseInt(data.response.municipio) !== 20) {
                catalogs.municipio.map((item) => {
                  // console.log(item);
                  // console.log(
                  //   `${parseInt(item.Clave)} = ${parseInt(data.response.municipio)}`
                  // );
                  if (parseInt(item.Clave) === parseInt(data.response.municipio)) {
                    IneMunicipio = item;
                  }
                });
              } else {
                IneMunicipio = {
                  ClaveRegion: "R7",
                  value: "20",
                  Clave: "20",
                  label: "LEÓN",
                };
              }

              if (IneMunicipio) {
                const existeLoc = catalogs.localidades.filter(
                  (item) => parseInt(item.idMunicipio) === parseInt(IneMunicipio.Clave) && item.cve_loc === data.response.localidad
                );

                if (existeLoc.length > 0) {
                  localidadIne = existeLoc[0];
                  // setLocalidadGrupo(existeLoc[0]);
                } else {
                  console.log("=>No Existe la localidad");
                }
              }
            }
          }
          let D0 = "C";
          let D1 = "";
          let D2 = "";
          let D3 = "";
          let Num = "";
          let calleIne = "";
          if (data.response.calle) {
            const tmpCalle = data.response.calle.split(" ");

            D0 = tmpCalle[0].toUpperCase();
            calleIne = `${tmpCalle[1]} ${tmpCalle[2]}`;
            D1 = tmpCalle[tmpCalle.length - 1];
            D2 = tmpCalle[tmpCalle.length - 2];
            D3 = tmpCalle[tmpCalle.length - 3];

            if (parseInt(D1)) {
              Num = D1;
            }
          }

          let returnData = onblurINE(data.response.claveElector);
          let newVal = {
            ...values,
            INE: data.response.claveElector,
            Nombre: data.response.nombres,
            Paterno: data.response.primerApellido,
            Materno: data.response.segundoApellido,
            idSexo: returnData.idSexo,
            VigenciaINE: parseInt(data.response.vigencia),
            idEstadoNacimiento: returnData.idEstadoNacimiento,
            idMunicipioVive: IneMunicipio.value,
            FechaNacimiento: returnData.FechaNacimiento,
            SeccionVota: isNaN(parseInt(data.response.seccion)) ? "" : parseInt(data.response.seccion),
            CalleVive: calleIne !== "" ? calleIne : values.CalleVive,
            ColoniaVive: Colonia,
            NumExtVive: Num !== "" ? Num : values.NumExtVive,
            TipoVialidad: D0,
            CPVive: CP,
            FrontIne: `${base},${itemFront}`,
            BehindIne: `${base},${itemBehind}`,
            insertID: data.response.insertID,
          };

          setValues(newVal);
        } else {
          let newVal = {
            ...values,
            FrontIne: `${base},${itemFront}`,
            BehindIne: `${base},${itemBehind}`,
          };
          setValues(newVal);
          Swal.fire({ title: data.response["code_error"], icon: "error" });
        }
      })
      .catch((error) => {
        Swal.fire({ title: error.response["code_error"], icon: "error" });
      })
      .finally(() => {
        setLoadingFoto(false);
      });
  };

  const validIne = async () => {
    if(isNullOrUndefined(setLoading)) return

    try {
      setLoading(true);
      const result = await CompromisoService.validateINE(values.INE.toUpperCase());
      const { results, message } = result;
      if (results) {
        setOpen({
          open: true,
          message: message,
          color: green[600],
          severity: "success",
        });
      } else {
        throw new Error(message);
      }
    } catch (error) {
      setOpen({
        open: true,
        message: error.message,
        color: deepOrange[700],
        severity: "warning",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOnKeyPress = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault();
      validIne();
    }
  };

  const handleFront = () => {
    if (openFront && openBehind) {
      return true;
    }

    setOpenModal(true);
    setOpenFront(true);
  };

  const handleBehind = () => {
    if (openFront && openBehind) {
      return false;
    }
    setOpenModal(true);
    setOpenBehind(true);
  };

  const onCloseCamara = () => {
    setOpenFront(false);
    setOpenBehind(false);
  };

  const handleClose = async (event) => {
    setOpen({
      ...open,
      open: false,
      message: "",
    });
  };

  return (
    <Box sx={{ mt: 1, mb: 2 }}>
      <Grid2 xs={12} sm={12} md={12} lg={12} display="flex">
        <Chip icon={<Icon>person</Icon>} label="Datos personales" />
        {open ? (
          <Snackbar open={open.open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
            <Alert onClose={handleClose} severity={open.severity} variant="filled" sx={{ width: "auto", ml: 2, backgroundColor: open.color }}>
              {open.message}
            </Alert>
          </Snackbar>
        ) : null}
        {/* </Snackbar> */}
      </Grid2>
      <Grid2 container spacing={3} sx={{ mt: 2 }}>
        {ScanIne && (
          <>
            <Grid2 xs={12} sm={12} md={12} lg={12}>
              <Typography variant="body1">Escannea la parte delantera y trasera de la INE:</Typography>
            </Grid2>
            <Grid2 xs={6} sm={12} md={2} lg={2}>
              <CardMedia
                component="img"
                sx={{
                  minHeight: "5",
                  maxHeight: "10",
                  position: "relative",
                  "&:before": {
                    content: '""',
                    display: "block",
                    paddingTop: "100%", // Proporción de aspecto de la imagen
                  },
                }}
                image={values.FrontIne ? values.FrontIne : FrenteIne}
                alt="Frente Ine"
                onClick={(e) => handleFront(e)}
              />
            </Grid2>
            <Grid2 xs={6} sm={12} md={2} lg={2}>
              <CardMedia
                component="img"
                sx={{ minHeight: "5", maxHeight: "9" }}
                image={values.BehindIne ? values.BehindIne : DetrasIne}
                alt="Detras Ine"
                onClick={(e) => handleBehind(e)}
              />
            </Grid2>
            {loadingFoto && (
              <Grid2 xs={12} sm={12} md={12} lg={12}>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                  <CircularProgress color="primary" />
                </Stack>
              </Grid2>
            )}
            {(errors.FrontIne || errors.BehindIne) && (
              <Typography variant="caption" color="error">
                {errors.FrontIne ? errors.FrontIne : errors.BehindIne}
              </Typography>
            )}
            {(openFront || openBehind) && (
              <Grid2 xs={12} sm={6} md={4} lg={4}>
                <Stack direction="column" justifyContent="center" alignItems="center" spacing={0}>
                  <Typography variant="body1" color={"#114897"} sx={{ fontFamily: "monospace" }}>
                    {openFront ? "Frente" : openBehind ? "Detrás" : ""}
                  </Typography>
                  <Webcam forceScreenshotSourceSize audio={false} ref={webcamRef} videoConstraints={typeCamara} height={"50%"} width={"100%"} />
                  <Grid2 container sx={{ width: "100%", height: "10%", p: 0, mt: 1 }} spacing={0}>
                    <Grid2 item xs={4} sm={4} md={4} lg={4} sx={{ p: 0 }}>
                      <IconButton color="primary" size="large" sx={{ p: 0, fontSize: "5rem" }} onClick={ChangeCamara}>
                        <CameraswitchIcon sx={{ fontSize: "3rem" }} />
                      </IconButton>
                    </Grid2>
                    <Grid2 item xs={4} sm={4} md={4} lg={4} sx={{ p: 0 }}>
                      <IconButton color="primary" size="large" sx={{ p: 0 }} onClick={capture}>
                        <PhotoCameraIcon sx={{ fontSize: "3rem" }} />
                      </IconButton>
                    </Grid2>
                    <Grid2 item xs={4} sm={4} md={4} lg={4} sx={{ p: 0 }}>
                      <IconButton color="error" size="large" sx={{ p: 0 }} onClick={() => onCloseCamara()}>
                        <DisabledByDefaultIcon sx={{ fontSize: "3rem" }} />
                      </IconButton>
                    </Grid2>
                  </Grid2>
                </Stack>
              </Grid2>
            )}
          </>
        )}
      </Grid2>
      <Grid2 container spacing={3} sx={{ mt: 2 }}>
        {!isMenor ? (
          <Grid2 xs={12} sm={12} md={4} lg={4}>
            <TextField
              error={touched.INE && !isEmptyOrInvalidString(errors.INE)}
              helperText={touched.INE && errors.INE && errors.INE}
              sx={{ width: "100%" }}
              required
              label="Clave de Elector"
              type="text"
              name="INE"
              pattern="/[A-Z]{6}[0-9]{8}[A-Z]{1}[0-9]{3}/g"
              onChange={handleChange}
              onKeyUp={handleOnKeyPress}
              onBlur={(e) => {
                //handleBlur(e);
                onblurINE(e.target.value.trim());
              }}
              value={values.INE.toUpperCase()}
              variant="outlined"
              size="small"
              className="fixed-input"
              InputProps={{
                endAdornment: validar ? (
                  <InputAdornment position="end">
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <Button color="info" onClick={validIne} sx={{ mr: 0, pr: 0 }} disabled={!values.INE}>
                      <TaskAlt />
                      <small>Validar</small>
                    </Button>
                  </InputAdornment>
                ) : <Icon>badge</Icon>,
              }}
            />
          </Grid2>
        ) : (
          <Grid2 xs={12} sm={12} md={4} lg={4}>
            <TextField
              error={touched.CURP && !isEmptyOrInvalidString(errors.CURP)}
              helperText={touched.CURP && errors.CURP && errors.CURP}
              sx={{ width: "100%" }}
              required
              label="CURP"
              type="text"
              name="CURP"
              pattern="/[A-Z]{4}\d{6}[HM]\w{5}[A-Z\d]/g"
              onChange={handleChange}
              onBlur={(e) => {
                //handleBlur(e);
                onblurCurp(e.target.value.trim());
              }}
              value={values.CURP.toUpperCase()}
              variant="outlined"
              size="small"
              className="fixed-input"
            />
          </Grid2>
        )}
        {!NotINE && (
          <Grid2 xs={6} sm={12} md={2} lg={2}>
            <TextField
              error={touched.VigenciaINE && !isEmptyOrInvalidString(errors.VigenciaINE)}
              helperText={touched.VigenciaINE && errors.VigenciaINE && errors.VigenciaINE}
              sx={{ width: "100%" }}
              label="Vigencia de INE"
              type="number"
              name="VigenciaINE"
              inputProps={{
                maxLength: 4,
              }}
              onChange={(e) =>
                handleChange({
                  target: {
                    name: "VigenciaINE",
                    value: isValidSection(e.target.value) ? e.target.value : values.VigenciaINE,
                  },
                })
              }
              onBlur={(e) =>
                handleChange({
                  target: {
                    name: "VigenciaINE",
                    value: isValidSection(e.target.value) ? e.target.value : values.VigenciaINE,
                  },
                })
              }
              value={values.VigenciaINE}
              variant="outlined"
              size="small"
              className="fixed-input"
            />
          </Grid2>
        )}
        {!NotSeccion && !isMenor && (
          <Grid2 xs={6} sm={12} md={2} lg={2}>
            <TextField
              error={touched.SeccionVota && !isEmptyOrInvalidString(errors.SeccionVota)}
              helperText={touched.SeccionVota && errors.SeccionVota && errors.SeccionVota}
              sx={{ width: "100%" }}
              required
              label="Sección Vota"
              type="text"
              name="SeccionVota"
              onChange={(e) =>
                handleChange({
                  target: {
                    name: "SeccionVota",
                    value: isValidSection(e.target.value) ? e.target.value : values.SeccionVota,
                  },
                })
              }
              onBlur={(e) =>
                handleChange({
                  target: {
                    name: "SeccionVota",
                    value: isValidSection(e.target.value) ? e.target.value : values.SeccionVota,
                  },
                })
              }
              value={values.SeccionVota}
              variant="outlined"
              size="small"
              className="fixed-input"
              inputProps={{
                maxLength: 4,
              }}
            />
          </Grid2>
        )}
      </Grid2>

      <Grid2 container spacing={3} sx={{ mt: 2 }}>
        <Grid2 xs={12} sm={12} md={3} lg={3}>
          <TextField
            error={touched.Nombre && !isEmptyOrInvalidString(errors.Nombre)}
            helperText={touched.Nombre && errors.Nombre && errors.Nombre}
            sx={{ width: "100%" }}
            required
            label="Nombre"
            type="text"
            name="Nombre"
            onChange={handleChange}
            onBlur={(e) => {
              handleChange({
                target: {
                  name: e.target.name,
                  value: e.target.value.trim(),
                },
              });
            }}
            value={values.Nombre.toUpperCase()}
            variant="outlined"
            size="small"
            className="fixed-input"
          />
        </Grid2>
        <Grid2 xs={12} sm={12} md={3} lg={3}>
          <TextField
            error={touched.Paterno && !isEmptyOrInvalidString(errors.Paterno)}
            helperText={touched.Paterno && errors.Paterno && errors.Paterno}
            sx={{ width: "100%" }}
            required
            label="Apellido Paterno"
            type="text"
            name="Paterno"
            onChange={handleChange}
            onBlur={(e) => {
              handleChange({
                target: {
                  name: e.target.name,
                  value: e.target.value.trim(),
                },
              });
            }}
            value={values.Paterno.toUpperCase()}
            variant="outlined"
            size="small"
            className="fixed-input"
          />
        </Grid2>
        <Grid2 xs={12} sm={12} md={3} lg={3}>
          <TextField
            error={touched.Materno && !isEmptyOrInvalidString(errors.Materno)}
            helperText={touched.Materno && errors.Materno && errors.Materno}
            sx={{ width: "100%" }}
            required
            label="Apellido Materno"
            type="text"
            name="Materno"
            onChange={handleChange}
            onBlur={(e) => {
              handleChange({
                target: {
                  name: e.target.name,
                  value: e.target.value.trim(),
                },
              });
            }}
            value={values.Materno.toUpperCase()}
            variant="outlined"
            size="small"
            className="fixed-input"
          />
        </Grid2>
      </Grid2>
      {!NotSimple || isMenor ? (
        <Grid2 container spacing={3} sx={{ mt: 1 }}>
          <Grid2 xs={4} sm={12} md={3} lg={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
              <DatePicker
                // disabled={true}
                label="Fecha Nacimiento*"
                error={touched.FechaNacimiento && !isEmptyOrInvalidString(errors.FechaNacimiento)}
                onChange={(e) => {
                  handleChange({
                    target: {
                      name: "FechaNacimiento",
                      value: isNullOrUndefined(e) ? "" : e,
                    },
                  });
                }}
                //onBlur={handleBlur}
                value={values.FechaNacimiento}
                renderInput={(params) => <TextField size="small" variant="outlined" sx={{ width: "100%" }} {...params} />}
              />
            </LocalizationProvider>
            {errors.FechaNacimiento && (
              <Typography variant="caption" color="error">
                {errors.FechaNacimiento}
              </Typography>
            )}
          </Grid2>
          <Grid2 xs={4} sm={12} md={3} lg={3}>
            <BasicSelect
              error={touched.idSexo && !isEmptyOrInvalidString(errors.idSexo)}
              errorMessage={errors.idSexo}
              // disabled={true}
              name="idSexo"
              label="Género *"
              options={catalogs.generos}
              value={values.idSexo}
              onChange={handleChange}
              size="small"
              sx={{ width: "100%" }}
              isLoading={loadigCatalogs}
            />
          </Grid2>
          <Grid2 xs={4} sm={12} md={3} lg={3}>
            <BasicSelect
              error={touched.idEstadoNacimiento && !isEmptyOrInvalidString(errors.idEstadoNacimiento)}
              errorMessage={errors.idEstadoNacimiento}
              // disabled={true}
              name="idEstadoNacimiento"
              label="Estado de Nacimiento *"
              options={catalogs.entidades}
              value={values.idEstadoNacimiento}
              onChange={handleChange}
              sx={{ width: "100%" }}
              isLoading={loadigCatalogs}
            />
          </Grid2>
        </Grid2>
      ) : (
        <></>
      )}
      <ModalIne openModal={openModal} setOpenModal={(e) => setOpenModal(e)} />
    </Box>
  );
};

export default PersonalInformationForm;

//import { useEffect, useState } from "react";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Typography,
  Chip,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { orange, green } from "@mui/material/colors";

//import Swal from "sweetalert2";
//import movilizacion from "@services/MovilizationServices";

const TimeLineSchedule = ({ data, loading }) => {
  //const [loading, setLoading] = useState(false);
  //const [data, setData] = useState([]);

  /* const getMobilizedByScheduleHist = async (body) => {
    setLoading(true);
    try {
      const {
        results,
        message,
        response: { data },
        success,
      } = await movilizacion.getMobilizedByScheduleHist(body);

      if (!results && !success) throw new Error(message);

      if (results && success) {
        setData(data);
        setDisabledHistory(data.length > 1 ? true : false);
      } else {
        setData([]);
        setDisabledHistory(false);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMobilizedByScheduleHist({
      Seccion: params.Seccion,
      Hora: params.Hora,
    });
  }, [params]); */

  return (
    <Box>
      {loading ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems="center"
          marginTop={2}
        >
          <CircularProgress />
        </Box>
      ) : (
        data.length > 0 && (
          <Timeline position="alternate">
            {data.map((item) => (
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot color="primary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Box
                    component={Card}
                    boxShadow={4}
                    borderRadius={3}
                    sx={{
                      padding: "12px 16px",
                    }}
                    className="card-primary"
                  >
                    <Grid2 container spacing={4}>
                      <Grid2 xs={12} md={9}>
                        <Typography
                          variant="subtitle1"
                          fontWeight={600}
                          textAlign="start"
                        >
                          {item.Nombre.toUpperCase()}
                        </Typography>
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          flexWrap={"wrap"}
                        >
                          <Typography variant="body2" fontWeight={400}>
                            Sección: {item.Seccion}
                          </Typography>
                          <Typography
                            variant="body2"
                            fontWeight={400}
                            marginBottom={1}
                          >
                            Responsabilidad: {item.Responsabilidad}
                          </Typography>
                          <Typography variant="body2" fontWeight={600}>
                            Movilizados
                          </Typography>
                          <Divider sx={{ mb: 1 }} />
                          <Chip
                            label={`Valor Actual: ${item.ValorActual}`}
                            color="success"
                            size="small"
                            sx={{
                              mb: 1,
                              backgroundColor: green[100],
                              color: green[900],
                              width: "fit-content",
                            }}
                          />
                          <Chip
                            label={`Valor Anterior: ${item.ValorAnterior}`}
                            color="warning"
                            size="small"
                            sx={{
                              backgroundColor: orange[100],
                              color: orange[900],
                              width: "fit-content",
                            }}
                          />
                        </Box>
                      </Grid2>
                      <Divider orientation="vertical" flexItem sx={{ mr: 2 }} />
                      <Grid2
                        xs={12}
                        md={2}
                        display={"flex"}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <div>
                          <Typography
                            variant="subtitle2"
                            fontWeight={600}
                            textAlign="center"
                          >
                            {item.Hora}
                          </Typography>
                        </div>
                      </Grid2>
                    </Grid2>
                  </Box>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        )
      )}
    </Box>
  );
};

export default TimeLineSchedule;

import { useState, useEffect } from "react";

// Material UI
import { Card, CardContent, Grid, Button, IconButton, Icon, Box, Typography } from "@mui/material";

// Componentes
import CustomTable from "@components/Tables/CustomTable";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import Form from "@components/Noticias/Form";
import Filter from "@components/Noticias/Filter";
import CustomModal from "@components/Modal/CustomModal";

// Servicios
import services from "@services/NoticiasServices";
import { COLUMNS_NOTICIAS } from "@data/constants/Noticias";

// Middleware y utils
import middleware from "@middlewares/middleware";
import { Swal, SwalImage, SwalDelete } from "@utils/alerts";

const NoticiasView = ({ idPagina, catalogs = {}, load = false }) => {
  const canEdit = middleware.checkMenuAction("Editar");
  const canDelete = middleware.checkMenuAction("Eliminar");

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const [open, setOpen] = useState(false);
  const [currentData, setCurrentData] = useState({});

  const [params, setParams] = useState({ page: 0, pageSize: 10, filtered: [], sorted: [] });

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    getData(params);
    // eslint-disable-next-line
  }, [params]);

  const getData = async (params) => {
    try {
      setIsLoading(true);

      const localParams = {
        ...params,
        filtered: params.filtered.concat([{ id: "noticias.idPagina", filter: "=", value: idPagina }]),
      };

      const result = await services.getNoticias(localParams);
      const { results, response, message } = result;

      if (results) {
        const style = { overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" };

        const format = response.data.map((item) => ({
          ...item,
          Titulo: <Typography sx={{ maxWidth: 200, ...style }}>{item.Titulo}</Typography>,
          Descripcion: <Typography sx={{ maxWidth: 200, ...style }}>{item.Descripcion}</Typography>,
          Contenido: (
            <Typography
              sx={{ maxWidth: 300, ...style }}
              dangerouslySetInnerHTML={{ __html: item.Contenido }}
            />
          ),
          FechaPublicacion: ("" + (item.FechaPublicacion ?? "")).slice(0, 10),
          FileNameButton: (
            <Button
              size="small"
              fullWidth
              onClick={() => SwalImage({ image: item.FileURL, title: item.Titulo })}
              sx={{
                justifyContent: "flex-start",
                textAlign: "left",
                textTransform: "none",
              }}
            >
              {item.FileName}
            </Button>
          ),
          actions: (
            <Box sx={{ display: "flex", gap: 1 }}>
              {canEdit && (
                <IconButton aria-label="edit" color="success" onClick={() => handleEdit(item)}>
                  <Icon>edit</Icon>
                </IconButton>
              )}
              {canDelete && (
                <IconButton aria-label="delete" color="error" onClick={() => deleteConfirmation(item)}>
                  <Icon>delete</Icon>
                </IconButton>
              )}
            </Box>
          ),
        }));

        setData(format);
        setTotal(response.total);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: "warning" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => setOpen(false);

  const handleEdit = (values) => {
    setOpen(true);

    setCurrentData(values);
  };

  const handleIsEdited = () => setParams((prev) => ({ ...prev, page: 0 }));

  const deleteConfirmation = async ({ Titulo, id, FileName }) => {
    SwalDelete({
      title: "Eliminar Noticia",
      text: `¿Estas seguro de querer eliminar la noticia: ${Titulo}?`,
      buttonText: "Eliminar noticia",
      onDelete: () => handleDelete({ id, FileName }),
    });
  };

  const handleDelete = async ({ id, FileName }) => {
    try {
      setIsDeleting(true);

      const result = await services.deleteNoticia({ id, FileName });
      const { results, message } = result;

      if (results) {
        Swal.fire({ title: message, icon: "success" });
        handleClose();
        getData(params);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: "warning" });
    } finally {
      setIsDeleting(false);
    }
  };

  const handlePagination = ({ page, pageSize }) => setParams((prev) => ({ ...prev, page, pageSize }));

  const handleSort = ({ orderBy, order }) => {
    const filters = {
      Titulo: "Titulo",
      Descripcion: "Descripcion",
      Contenido: "Contenido",
      FileNameButton: "FileName",
      Tipo: "idTipo",
    };

    setParams((prev) => ({ ...prev, sorted: [{ id: filters[orderBy], value: order }] }));
  };

  const handleFilter = (values) => setParams((prev) => ({ ...prev, filtered: values }));

  return (
    <Card>
      <LoadingForm
        loadinMessage={"Elimindo Noticia"}
        successMessage={"Noticia eliminada con exito!"}
        isLoading={isDeleting}
        isOpen={isDeleting}
        setIsOpen={() => {}}
      />

      <CustomModal open={open} onClose={handleClose} title="Editar Noticia" fullWidth disableClose>
        <Form
          type="edit"
          idPagina={idPagina}
          catalogs={catalogs}
          load={load}
          data={currentData}
          handleDelete={deleteConfirmation}
          handleClose={handleClose}
          handleIsEdited={handleIsEdited}
        />
      </CustomModal>

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Filter handleFilter={handleFilter} catalogs={catalogs} load={load} />
          </Grid>
          <Grid item xs={12}>
            <CustomTable
              rows={data}
              columns={COLUMNS_NOTICIAS(canEdit || canDelete)}
              total={total}
              page={params.page}
              pageSize={params.pageSize}
              isLoading={isLoading}
              handlePagination={handlePagination}
              handleSort={handleSort}
              disableCardType
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default NoticiasView;

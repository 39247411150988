import PieChart from "@components/Charts/PieChart";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Icon,
  Chip
} from "@mui/material";
import SocialService from "@services/SocialServices";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";

const StatsUser = (props) => {
  const { idUsuario, AvanceSegmentos,AvanceEtiquetados } = props;
  const [loadingStats, setLoadingStats] = useState(false);
  const [data, setData] = useState([]);
  const [color, setColor] = useState([]);
  const [table, setTable] = useState([]);

  useEffect(() => {
    getList();
  }, [idUsuario]);

  const getList = () => {
    if (loadingStats) {
      return false;
    }
    setLoadingStats(true);
    SocialService.getCompromisosUsuariosStats({
      idUsuario: idUsuario,
    })
      .then((res) => {
        if (res.results) {
          let datos = [];
          let dataTB = [];
          let colors = [];
          if (res.response.data?.length > 0) {
            let suma = 0;
            res.response.data.forEach((item) => {
              let color = generarColor();
              colors.push(color);
              suma = suma + parseInt(item.Total);
              dataTB.push({...item,color:color})
              datos.push({
                name: item.Alias,
                color: color,
                y: parseInt(item.Total),
                total: AvanceSegmentos,
                sliced: true,
                selected: true,
              });
            });
            colors.push("#90A4AE");
            datos.push({
              name: "General",
              color: "#90A4AE",
              y: AvanceSegmentos - AvanceEtiquetados,
              total: AvanceSegmentos,
              sliced: true,
              selected: true,
            });
          }
          setData(datos);
          setColor(colors);
          setTable(dataTB);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((err) => Swal.fire({ title: err, icon: "warning" }))
      .finally(() => setLoadingStats(false));
  };

  function generarColor() {
    // Definir rangos para azul, rosa y naranja
    var azul = Math.floor(Math.random() * 256); // Valor aleatorio entre 0 y 255
    var rosa = Math.floor(Math.random() * 156) + 100; // Valor aleatorio entre 100 y 255 (para obtener tonos de rosa)
    var naranja = Math.floor(Math.random() * 156) + 100; // Valor aleatorio entre 100 y 255 (para obtener tonos de naranja)

    var color =
      "#" +
      componentToHex(azul) +
      componentToHex(rosa) +
      componentToHex(naranja);
    return color;
  }

  function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }

  return (
    <>
      {loadingStats ? (
        <CircularProgress color="secondary" />
      ) : (
        <Grid2 container>
          <Grid2 item  xs={12} sm={12} md={6} lg={6}>
            <PieChart
              series={{ data: data }}
              options={{
                title: "",
                subtitle: "",
              }}
              showInLegend={false}
              pieColors={color}
              loading={loadingStats}
            />
          </Grid2>
          <Grid2 item  xs={12} sm={12} md={6} lg={6}>
            <TableContainer
              component={Paper}
              sx={{ maxHeight: "500px", overflow: "auto" }}
            >
              <Table  aria-label="listado de segmentos">
                <TableBody>
                  {table.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell  component="th" scope="row">
                        <Chip label={row.Alias} sx={{bgcolor:row.color}} variant="outlined"  /> 
                      </TableCell>
                      <TableCell align="right">
                        {row.Total}
                      </TableCell>
                    </TableRow>
                  ))}
                  {table.length < 1 && (
                    <TableRow style={{ height: 53 * 1 }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid2>
        </Grid2>
      )}
    </>
  );
};
export default StatsUser;

import { useEffect, useState } from "react";
import { useCatalogs } from "@hooks/useCatalogs";
import { useLocation } from "react-router-dom";

import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Icon,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

import AdvancedSelect from "@components/Selects/AdvancedSelect";

import mov from "@services/MovilizationServices";
import Swal from "sweetalert2";

const BingoFolioFilter = ({
  seccionLocation,
  onChange,
  onClear = () => {},
  setSeccionLocation,
}) => {
  //CONSTANTS
  const catalogsOptions = [{ id: "dl" }];
  

  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsOptions,
    putDefaultOption: false,
  });

  const [catSecciones, setCatSecciones] = useState([]);
  const [catalogFiltered, setCatalogFiltered] = useState({});
  const [filterValue, setFilterValue] = useState({
    distritoLocal: "",
    seccion: "",
  });

  const handleChangeFilter = ({ name, value }) => {
    setFilterValue({ ...filterValue, [name]: value });
  };

  const handleChangeDl = (e) => {
    const value = e.value;
    const seccionesDl = catSecciones.filter(({ idDL }) => idDL === value);
    setCatalogFiltered((prevState) => ({
      ...prevState,
      secciones: seccionesDl,
    }));
  };

  const handleSearch = () => {
    onChange({ Seccion: parseInt(filterValue.seccion) });
  };

  /*  const handleKeyPressSearch = (event) => {
    const isEnterKey = event.key === "Enter";
    const isTabKey = event.key === "Tab";
    if (event.type === "keydown" && (isEnterKey || isTabKey)) {
      const value = event.target.value;
      if (!isEmptyOrInvalidString(value)) {
        validateSeccion(value);
      }
    }
  }; */

  const handleClear = () => {
    setFilterValue((prevState) => ({
      ...prevState,
      distritoLocal: "",
      seccion: "",
    }));
    setCatalogFiltered((prevState) => ({
      ...prevState,
      secciones: catSecciones,
    }));
    setSeccionLocation(null);
    onClear();
  };

  const getCat = async () => {
    try {
      const { results, success, response, message } =
        await mov.getCatalogsForStats([{ id: "secciones" }]);

      if (!results && !success) throw new Error(message);

      if (results && success) {
        setCatSecciones(response.catalogs.secciones);
        setCatalogFiltered((prevState) => ({
          ...prevState,
          secciones: response.catalogs.secciones,
        }));
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: error.message,
      });
    }
  };

  useEffect(() => {
    getCat();
  }, []);

  useEffect(() => {
    if (seccionLocation) setFilterValue({ ...filterValue, seccion: seccionLocation });
  }, [seccionLocation]);

  return (
    <Card className="card-primary">
      <CardContent>
        <Box marginBottom={2}>
          <Typography variant="body2" fontWeight={600}>
            FILTRO
          </Typography>
          <Divider />
        </Box>
        <Grid2 container spacing={3}>
          <Grid2 xs={12} md={3}>
            <AdvancedSelect
              name="distritoLocal"
              label="Distrito Local"
              value={filterValue.distritoLocal}
              options={catalogs?.dl}
              onChange={(e) => {
                handleChangeDl(e);
                handleChangeFilter({ name: "distritoLocal", value: e.value });
              }}
              isSearchable
              isLoading={load}
              sx={{ width: "100%" }}
            />
          </Grid2>
          <Grid2 xs={12} md={3}>
            <AdvancedSelect
              name="Seccion"
              label="Sección"
              value={filterValue.seccion}
              options={catalogFiltered?.secciones}
              onChange={(e) => {
                handleChangeFilter({ name: "seccion", value: e.value });
              }}
              //onKeyDown={handleKeyPressSearch}
              //onKeyPress={handleKeyPress}
              isSearchable
              isLoading={load}
              //error={Boolean(formik.errors.Seccion)}
              //helperText={formik.errors.Seccion}
              sx={{ width: "100%" }}
            />
          </Grid2>

          <Grid2 xs={12} md={3}>
            <Button
              variant="contained"
              color="primaryDark"
              size="small"
              startIcon={<Icon>search</Icon>}
              disabled={!filterValue.seccion}
              onClick={handleSearch}
              sx={{ width: "30%" }}
            >
              buscar
            </Button>
            <Button
              variant="outlined"
              color="primaryDark"
              size="small"
              startIcon={<Icon>search_off</Icon>}
              disabled={!filterValue.seccion}
              onClick={handleClear}
              sx={{ ml: 2, width: "30%" }}
            >
              Limpiar
            </Button>
          </Grid2>
        </Grid2>
      </CardContent>
    </Card>
  );
};

export default BingoFolioFilter;

import { useState, useRef, useEffect } from "react";
import esLocale from "date-fns/locale/es";
import { useFormik } from "formik";
import { Swal } from "@utils/alerts";

// Material UI
import { Icon, TextField, IconButton, Button, Card, CardContent } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// Componentes
import BasicSelect from "@components/Selects/BasicSelect";
import LoadingForm from "@components/LinearProgress/LoadingForm";

// Utils
import { handleAttrs, formatDateAPI } from "@utils/Utilities";

// Servicios
import services from "@services/Foca/PublicacionesServices";

// Data
import { EditPublicacionesInterface, PublicacionesInterface } from "@interfaces/Foca/PublicacionesInterfaces";
import { EditPublicacionesSchema, PublicacionesSchema } from "@schemas/Foca/PublicacionesSchemas";
import { CATALOGS_PUBLICACIONES } from "@data/constants/Foca";

// Middleware
import middleware from "@middlewares/middleware";

const PublicacionesForm = (props) => {
  const {
    type = "create",
    data = null,
    handleDelete = () => {},
    handleClose = () => {},
    handleIsEdited = () => {},
  } = props;

  const isEdit = type === "edit";

  const formik = useFormik({
    initialValues: isEdit ? EditPublicacionesInterface : PublicacionesInterface,
    validationSchema: isEdit ? EditPublicacionesSchema : PublicacionesSchema,
    onSubmit: (values) => {
      if (isEdit) handleEdit(handleAttrs(values));
      else handleCreate(handleAttrs(values));
    },
  });

  const fileInputRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMessage, setIsLoadingMessage] = useState("Creando publicación...");

  useEffect(() => {
    if (data) formik.setValues({ ...data, files: [] });
    else formik.setValues({ ...formik.values, FechaPublicacion: new Date() });
    // eslint-disable-next-line
  }, [data]);

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];

    e.target.value = null;

    if (uploadedFile) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;

        let fileName = uploadedFile.name;

        fileName = fileName.replace(/\s+/g, "_");
        fileName = fileName.replace(/[^a-zA-Z0-9-_.]/g, "");

        formik.setValues({
          ...formik.values,
          fileName: fileName,
          files: [{ file: base64String }],
        });
      };

      reader.readAsDataURL(uploadedFile);
    }
  };

  const handleClick = () => fileInputRef.current.click();

  const handleEdit = async (params) => {
    const localParams = {
      ...params,
      FechaPublicacion: formatDateAPI(params.FechaPublicacion),
    };

    try {
      setIsLoading(true);
      setIsLoadingMessage("Editando publicación...");

      const result = await services.updatePublicacion(localParams);
      const { success, results, message } = result;

      if (success && results) {
        handleIsEdited();
        formik.setValues({ ...params, files: [] });
        Swal.fire({ title: message, icon: "success", customClass: { container: "modal-alert" } });
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: "warning", customClass: { container: "modal-alert" } });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreate = async (params) => {
    const localParams = {
      ...params,
      FechaPublicacion: formatDateAPI(params.FechaPublicacion),
    };

    try {
      setIsLoading(true);
      setIsLoadingMessage("Creando publicación...");

      const result = await services.setPublicacion(localParams);
      const { success, results, message } = result;

      if (success && results) {
        Swal.fire({ title: message, icon: "success", customClass: { container: "modal-alert" } });
        formik.resetForm();
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: "warning", customClass: { container: "modal-alert" } });
    } finally {
      setIsLoading(false);
    }
  };

  const isError = (value) => formik.touched[value] && Boolean(formik.errors[[value]]);

  const loadAll = false;

  return (
    <>
      <LoadingForm
        loadinMessage={isLoadingMessage}
        successMessage={"Publicación creada con exito!"}
        isLoading={isLoading}
        isOpen={isLoading}
        setIsOpen={() => {}}
      />

      {isEdit ? (
        <Form
          formik={formik}
          loadAll={loadAll}
          isError={isError}
          handleClick={handleClick}
          fileInputRef={fileInputRef}
          handleFileChange={handleFileChange}
          isEdit={isEdit}
          handleClose={handleClose}
          handleDelete={handleDelete}
          data={data}
        />
      ) : (
        <Card>
          <CardContent>
            <Form
              formik={formik}
              loadAll={loadAll}
              isError={isError}
              handleClick={handleClick}
              fileInputRef={fileInputRef}
              handleFileChange={handleFileChange}
              isEdit={isEdit}
              handleClose={handleClose}
              handleDelete={handleDelete}
              data={data}
            />
          </CardContent>
        </Card>
      )}
    </>
  );
};

const Form = ({
  formik,
  loadAll,
  isError,
  handleClick,
  fileInputRef,
  handleFileChange,
  isEdit,
  handleClose,
  handleDelete,
  data,
}) => (
  <Grid2 container spacing={2}>
    <Grid2 xs={12} sm={6} lg={4}>
      <BasicSelect
        name="idTipo"
        label="Tipo *"
        options={CATALOGS_PUBLICACIONES}
        value={formik.values.idTipo}
        onChange={formik.handleChange}
        size="small"
        sx={{ width: "100%" }}
        disabled={loadAll}
        isLoading={loadAll}
        error={isError("idTipo")}
        errorMessage={formik.errors.idTipo}
      />
    </Grid2>

    <Grid2 xs={12} sm={6} lg={4}>
      <TextField
        required
        label="Nombre"
        size="small"
        name="Nombre"
        fullWidth
        value={formik.values.Nombre}
        onChange={formik.handleChange}
        disabled={loadAll}
        error={isError("Nombre")}
        helperText={formik.errors.Nombre}
      />
    </Grid2>

    <Grid2 xs={12} sm={12} lg={4}>
      <TextField
        required
        label="Descripción"
        size="small"
        name="Descripcion"
        fullWidth
        value={formik.values.Descripcion}
        onChange={formik.handleChange}
        disabled={loadAll}
        error={isError("Descripcion")}
        helperText={formik.errors.Descripcion}
      />
    </Grid2>

    <Grid2 xs={12} sm={6} lg={6}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
        <MobileDateTimePicker
          label="Fecha de Publicación *"
          value={formik.values.FechaPublicacion}
          onChange={(e) => formik.setValues({ ...formik.values, FechaPublicacion: e ?? "" })}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              variant="outlined"
              disabled={loadAll}
              sx={{ width: "100%" }}
              error={isError("FechaPublicacion")}
              helperText={formik.errors.FechaPublicacion}
            />
          )}
        />
      </LocalizationProvider>
    </Grid2>

    {/* Archivo */}
    <Grid2 xs={12} sm={6}>
      <TextField
        required={Boolean(!formik.values.fileName)}
        label="PDF"
        size="small"
        name="PDF"
        fullWidth
        onClick={handleClick}
        onChange={handleClick}
        value={formik.values.fileName}
        disabled={loadAll}
        error={isError("fileName")}
        helperText={formik.errors.fileName}
        InputProps={{
          endAdornment: (
            <IconButton component="span" aria-label="upload file" color="primary">
              <Icon>upload_file</Icon>
            </IconButton>
          ),
        }}
      />
      <input
        name="PDF"
        type="file"
        ref={fileInputRef}
        disabled={loadAll}
        accept=".pdf"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </Grid2>

    <Grid2 xs={12} lg={12}>
      <TextField
        required
        label="Sinopsis"
        size="small"
        name="Sinopsis"
        fullWidth
        multiline
        minRows={2}
        value={formik.values.Sinopsis}
        onChange={formik.handleChange}
        disabled={loadAll}
        error={isError("Sinopsis")}
        helperText={formik.errors.Sinopsis}
      />
    </Grid2>

    <Grid2 xs={12}>
      <>
        <Grid2 container spacing={2} justifyContent={"end"}>
          {isEdit && (
            <Grid2 xs={12} sm={4} md={2} lg={2}>
              <Button variant="contained" color="warning" onClick={handleClose} fullWidth>
                Cancelar
              </Button>
            </Grid2>
          )}

          {isEdit && middleware.checkMenuAction("Eliminar") && (
            <Grid2 xs={12} sm={4} md={2} lg={2}>
              <Button variant="contained" color="error" onClick={() => handleDelete(data)} fullWidth>
                Eliminar
              </Button>
            </Grid2>
          )}

          <Grid2 xs={12} sm={4} md={2} lg={2}>
            <Button
              variant="contained"
              color="success"
              onClick={formik.handleSubmit}
              disabled={loadAll}
              fullWidth
            >
              {isEdit ? "Editar" : "Agregar"}
            </Button>
          </Grid2>
        </Grid2>
      </>
    </Grid2>
  </Grid2>
);

export default PublicacionesForm;

import React, { useEffect } from 'react';
import { Card, CardContent, Typography, Avatar, List, ListItem, ListItemAvatar, ListItemText, Box,LinearProgress } from '@mui/material';
import { styled } from '@mui/system';
import { URL_IMAGES } from "@utils/global";



const StyledListItem = styled(ListItem)(({ theme, isFirst,Color }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: isFirst ? Color : 'transparent', // Fondo dorado para el primer lugar
  border: isFirst ? '2px solid #f57c00' : 'none', // Borde para el primer lugar
  borderRadius: isFirst ? '8px' : 'none', // Bordes redondeados para el primer lugar
  padding: isFirst ? '1rem' : '0.5rem', // Padding adicional para el primer lugar
  marginBottom: '0.5rem', // Espaciado entre elementos
}));


const RankingCard = ({ rankings,loading }) => {

  useEffect(()=>{},[rankings])

  return (
    <Card className='primary-card'>
      {
        loading ? 
        <Box sx={{ width: '100%' }}>
        <LinearProgress />
        </Box>:
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Fuerzas
        </Typography>
        <List>
          {rankings.map((ranking) => (
            <StyledListItem key={ranking.Fuerza} Color={ranking.Color} isFirst={ranking.Fuerza === 1 || ranking.Fuerza === "1"}>
              <ListItemAvatar>
                <Avatar color={ranking.Color} src={`${URL_IMAGES}/${ranking.RutaLogo}`} alt={ranking.NombreCandidato} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Box display="flex" alignItems="center">
                    <Typography variant="h6" component="span" style={{ marginRight: '0.5rem' }}>
                      {ranking.Fuerza}
                    </Typography>
                    {ranking.NombreCandidato}
                  </Box>
                }
                secondary={
                  <>
                   <Typography variant="body2" color="textSecondary">
                      <strong>Listado Nominal:</strong> {ranking.LISTA_NOMINAL}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Votos:</strong> {ranking.VotosCandidato}
                    </Typography>
                   {(ranking.Fuerza === 1 || ranking.Fuerza === "1") ? "Primera Fuerza" : <Typography variant="body2" color="textSecondary">
                      <strong>Diferencia:</strong> {ranking.Diff1Fuerza}
                    </Typography>}
                    {(ranking.Fuerza === 1 || ranking.Fuerza === "1") ? "" : <Typography variant="body2" color="textSecondary">
                      <strong>% Diferencia:</strong> {ranking.PorcDiff1Fuerza}
                    </Typography>}
                    <Typography variant="body2" color="textSecondary">
                      <strong>Municipio:</strong> {ranking.Municipio}
                    </Typography>
                  </>
                }
                primaryTypographyProps={{ style: { fontWeight: ranking.rank === 1 ? 'bold' : 'normal' } }}
              />
            </StyledListItem>
          ))}
        </List>
      </CardContent>
    } 
    </Card>
  );
};

export default RankingCard;

import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { URL_PUBLICA } from "@utils/global";
import { QRCode } from 'react-qrcode-logo';
import { Container, Paper, Grid, Typography, Link,Box } from "@mui/material";
import events from "../../services/EventsServices";
import Swal from "sweetalert2";
import { isNullOrUndefined } from "@utils/validations";
import eventosImg from "./../../assets/img/eventosImg.jpg";

const QRAsistencia = () => {
  const { id } = useParams();
  const [dataEvent, setDataEvent] = useState({});

  useEffect(() => {
    events
      .getPublicEvent({ uuid: id })
      .then((res) => {
        if (res.results) {
          setDataEvent(res.response.data);
        } else {
          Swal.fire({
            title: res.message,
            icon: "warning",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }
      })
      .catch((error) =>
        Swal.fire({
          title: error.message,
          icon: "warning",
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        })
      )
  }, [id]);

  return (
    <Box
    sx={{
      minHeight: "100vh",
      width: "100%",
      backgroundImage:
        Object.entries(dataEvent).length !== 0 &&
        isNullOrUndefined(dataEvent.imgURL)
          ? `url(${eventosImg})`
          : `url(${dataEvent.imgURL})`,
      backgroundSize: "100%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      display: "grid",
      placeItems: "center",
    }}
  >
      <Container component="main" maxWidth="md">
        {dataEvent && 
         <Paper
         elevation={4}
         sx={{
           display: "flex",
           flexDirection: "column",
           alignItems: "center",
           p: 2,
         }}
       >
         <Grid
           container
           justifyContent="center"
           alignItems="center"
           style={{display: "flex",
           flexDirection: "column",
           minHeight: "calc(80vh - 50px)"}}>
           <Grid item xs={12}>
             <Typography variant="h4" sx={{ color: "#1769aa" }} align="center" gutterBottom>
               {`Escanea el QR Para registrar tu asistencia, en el Evento`}<br/><strong>{dataEvent.Actividad}</strong>
             </Typography>
             <br />
           </Grid>
           <Grid item xs={12}>
             <QRCode value={`${URL_PUBLICA}/eventos/asistencia/${id}`} />
           </Grid>
           <Grid item xs={12} align="center">
             <br />
             <br />
             <Typography variant="h6" sx={{ color: "#1769aa" }} gutterBottom>
                Si no ingrese al siguiente link.
             </Typography>
             <br/>
             <Link href="https://t.ly/0Rpd" variant="h5" align="center">
               {'https://t.ly/0Rpd'}
              </Link>
              <br/>
              <br/>
              <Typography variant="body1" sx={{ color: "#f73378" }} gutterBottom>
               Nota: Es importante respetar el uso de mayúsculas y minúsculas en el link proporcionado
             </Typography>
           </Grid>
         </Grid>
       </Paper>
        }
       
      </Container>
    </Box>
  );
};
export default QRAsistencia;

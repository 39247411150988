import { PrivilegedRoute, ProtectedRoute } from "@middlewares";

import Users from "@pages/Users/Users";
import Menus from "@pages/Menus/Menus";
import Roles from "@pages/Roles/Roles";
import MyUsers from "@pages/Users/MyUsers";
import UserProfile from "@pages/Users/UserProfile";
import AgregarPerfil from "@pages/Roles/AgregarPerfil";
import MyUsersEdit from "@pages/Users/MyUsersEdit";
import Schedules from "@pages/Schedules";

const AdministrationRoutes = [
  {
    path: "/admin/usuarios",
    element: (
      <PrivilegedRoute>
        <Users />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/admin/perfiles",
    element: (
      <PrivilegedRoute>
        <Roles />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/admin/perfiles/agregar",
    element: (
      <PrivilegedRoute>
        <AgregarPerfil />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/admin/horarios",
    element: (
      <PrivilegedRoute>
        <Schedules />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/admin/menus",
    element: (
      <PrivilegedRoute>
        <Menus />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/mis-usuarios",
    element: (
      <PrivilegedRoute>
        <MyUsers />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/mis-usuarios-editar",
    element: (
      <ProtectedRoute>
        <MyUsersEdit />
      </ProtectedRoute>
    ),
  },
  {
    path: "/mi-perfil",
    element: (
      <ProtectedRoute>
        <UserProfile />
      </ProtectedRoute>
    ),
  },
];

export default AdministrationRoutes;

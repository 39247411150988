import { useState, useEffect, useCallback } from "react";
import Swal from "sweetalert2";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import Map from "@components/Maps/CustomComponents/GenericMap";
import Filter from "@components/MapsModules/National/FilterTabs";
import Colors from "@components/Maps/InternalComponents/Cards/Colors";
import TableInfo from "@components/Maps/InternalComponents/Cards/TableInfo";
import CardInfoMini from "@components/Maps/InternalComponents/Cards/CardInfoMini";

// Servicios y Utils
import CatalogService from "@services/CatalogServices";
import NationalService from "@services/MapServices/NationalServices";
import CompromisosService from "@services/MapServices/CompromisoServices";
import { setVars, getVars, deleteVars } from "@utils/global";

// Estilos de mapas
import { style_original_data, style_original_sub_data } from "@components/Maps/Auxiliars/ColorFeature";

const commonElements = [
  { title: "Meta: ", name: "meta", type: "int" },
  { title: "Avance: ", name: "avance", type: "int" },
  { title: "% Avance: ", name: "porcentaje", type: "percent" },
];

const elements = {
  entidad: [{ title: "Entidad: ", name: "entidad", type: "text" }, ...commonElements],
  region: [{ title: "Region: ", name: "region", type: "text" }, ...commonElements],
  municipio: [{ title: "Municipio: ", name: "municipio", type: "text" }, ...commonElements],
  dfed: [{ title: "DFED: ", name: "DistritoFederal", type: "text" }, ...commonElements],
  dloc: [{ title: "DLOC: ", name: "DistritoLocal", type: "text" }, ...commonElements],
};

const Maps = () => {
  const [polygons, setPolygons] = useState({ type: "FeatureCollection", features: [] });

  const [table, setTable] = useState(null);
  const [currentFeature, setCurrentFeature] = useState(null);

  const [isLoadingCatalogs, setIsLoadingCatalogs] = useState(false);
  const [catalogs, setCatalogs] = useState({ municipios: [] });
  const [shpInfoGeneral, setShpInfoGeneral] = useState("entidad");

  const [colorPercent, setColorPercent] = useState([]);

  const getEmptyMap = () => {
    setTable(null);
    setCurrentFeature(null);
    setPolygons({ type: "FeatureCollection", features: [] });
  };

  const handleFilterExect = async (filter = [], value = 0, shp = "entidad") => {
    if (filter.length === 0) return true;

    const idEntidad = filter.find((item) => item.id === "tb.idEntidad").value;
    setShpInfoGeneral(shp);

    try {
      setPolygons(null);
      const params = { color: 1, page: 0, pageSize: 10, filtered: filter, shp, idEntidad };

      const result = await NationalService.getShapesElectoral(params);
      const { results, response, message } = result;

      if (results) {
        const features = response.data.features.map((item) => item.properties);
        setTable(features);
        setPolygons(response.data);
      } else throw new Error(message);
    } catch (e) {
      getEmptyMap();
      Swal.fire({ title: e.message, icon: "warning" });
    }
  };

  const loadCatalogs = useCallback(async () => {
    const params = [
      { id: "estructura_electoral", getAll: false },
      { id: "municipios_reportes", getAll: false },
      { id: "df", getAll: false },
      { id: "dl", getAll: false },
      { id: "regiones", getAll: false },
    ];

    setIsLoadingCatalogs(true);

    try {
      const result = await CatalogService.getCatalogs(params);
      const { results, response, message } = result;

      if (results) {
        if (response.errors.length > 0) {
          Swal.fire({
            title: "Algunos catálogos NO pudieron ser cargados. Contacte al administrador",
            icon: "warning",
          });
        }

        const { estructura_electoral, municipios_reportes, df, dl, regiones } = response.catalogs;
        const options = (value, label) => (value ? [{ value: 0, label: `TOD${label}S` }].concat(value) : []);

        const estructura_electoral_edit = estructura_electoral.filter((item) => [2, 3].includes(item.value));

        const avance = [
          { value: 1, label: "0% - 25%" },
          { value: 2, label: "25.01% - 50%" },
          { value: 3, label: "50.01% - 75%" },
          { value: 4, label: "75.01% - 100%" },
        ];

        const all = {
          estructura_electoral: options(estructura_electoral, "A"),
          estructura_electoral_edit: options(estructura_electoral_edit, "A"),
          municipios_reportes: options(municipios_reportes, "O"),
          municipios_df: options(df, "O"),
          municipios_dl: options(dl, "O"),
          regiones: options(regiones, "A"),
          avance: options(avance, "O"),
        };

        setCatalogs(all);
        setVars("allCatalogsMap", all);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: "warning" });
    } finally {
      setIsLoadingCatalogs(false);
    }
  }, []);

  const loadColors = async () => {
    try {
      const result = await CompromisosService.getRangosColores({});
      const { results, response, message } = result;

      if (results) {
        setColorPercent(response.data.local);
        setVars("allColorsMap", response.data.local);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: "warning" });
    }
  };

  useEffect(() => {
    const nationalMapFlag = getVars("nationalMapFlag", "");

    if (nationalMapFlag === "") {
      deleteVars();

      setVars("filterSavedMap", {
        idTipo: "",
        idResponsabilidad: "",
        idMunicipio: "",
        idDF: "",
        idDL: "",
        Seccion: "",
        porAvance: "",
      });
      setVars("mapCatalogMunicipio", "");
      getEmptyMap();
      loadCatalogs();
      loadColors();
      setVars("nationalMapFlag", true);
      handleFilterExect([{ id: "tb.idEntidad", filter: "IN", value: 11 }]);
    } else {
      const shpMap = getVars("filteredShapeMap", {
        filtered: [{ id: "tb.idEntidad", filter: "IN", value: 11 }],
        tab: getVars("valueTab", 0),
        shp: "entidad",
      });
      const catalogs = getVars("allCatalogsMap", "");
      const allColorsMap = getVars("allColorsMap", "");
      setCatalogs(catalogs);
      setColorPercent(allColorsMap);
      handleFilterExect(shpMap.filtered, shpMap.tab, shpMap.shp);
    }

    // eslint-disable-next-line
  }, []);

  const FilterComponent = (
    <>
      <Filter
        catalogs={catalogs}
        loadingCatalogs={isLoadingCatalogs}
        handleFilter={handleFilterExect}
        type
        responsability
      />
      {currentFeature && (
        <CardInfoMini
          properties={currentFeature}
          elements={elements[shpInfoGeneral]}
          title={"INFORMACIÓN"}
          toolTip
        />
      )}
      {table && <TableInfo properties={table} elements={elements[shpInfoGeneral]} />}
    </>
  );

  const handleClickViewDetail = async (e) => {
    // Mandar a la capa inferior con el parametro solicitado
    const tab = getVars("valueTab", 0);
    const idEntidad = e.idEntidad;

    let filterValues = getVars("filterSavedMap", {
      idTipo: "",
      idResponsabilidad: "",
      idRegion: [],
      idMunicipio: [],
      idDF: [],
      idDL: [],
      Seccion: 0,
      porAvance: 0,
    });
    const shapeMap = getVars("filteredShapeMap", {
      filtered: [],
      tab: 0,
      shp: "entidad",
    });

    let shp = "entidad";
    let id = "idMunicipio";
    let filter = [{ id: "tb.idEntidad", filter: "IN", value: idEntidad }];

    switch (e.shp) {
      case "entidad":
        if (tab === 0) {
          shp = "region";
          id = "idRegion";
        }
        if (tab === 1) {
          shp = "dfed";
          id = "idDF";
        }
        if (tab === 2) {
          shp = "dloc";
          id = "idDL";
        }
        break;
      case "region":
      default:
        shp = "municipio";
        id = "idMunicipio";
        filterValues.idRegion = [e.idRegion];
        const catMunicipios = catalogs.municipios_reportes.filter(
          (item) => item.idRegion === e.idRegion || item.value === 0
        );
        const values = catMunicipios.map((item) => item.value).filter((item) => item !== 0);

        setVars("mapCatalogMunicipio", catMunicipios);
        filter.push({ id: "tb.id", filter: "IN", value: values });
        break;
    }

    setVars("filterSavedMap", {
      ...filterValues,
      [id]: [0],
    });
    setVars("filteredShapeMap", {
      filtered: filter,
      tab: shapeMap.tab,
      shp: shp,
    });

    setShpInfoGeneral(shp);

    try {
      setPolygons(null);
      const params = { color: 1, page: 0, pageSize: 10, filtered: filter, shp, idEntidad };

      const result = await NationalService.getShapesElectoral(params);
      const { results, response, message } = result;

      if (results) {
        const features = response.data.features.map((item) => item.properties);
        setTable(features);
        setPolygons(response.data);
      } else throw new Error(message);
    } catch (e) {
      getEmptyMap();
      Swal.fire({ title: e.message, icon: "warning" });
    }
  };

  const highlightFeature = (e, params) => {
    const { resetHighlight, setFeature, L, selectedFeature, setSelectedFeature } = params;
    const layer = e.target;
    const properties = layer.feature.properties;

    if (selectedFeature) resetHighlight(selectedFeature);
    if (!properties[properties.shp]) resetHighlight(e);

    setCurrentFeature(properties);
    setFeature(layer.feature);
    setSelectedFeature(e);

    if (properties[properties.shp]) {
      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) layer.bringToFront();
      layer.setStyle(style_original_data(layer.feature, true));
    }
  };

  const cardComponent = {
    component: <Colors elements={colorPercent} height={20} spacing={0.5} />,
    title: "Colores",
  };

  return (
    <AdminLayout delPadding>
      <Map
        data={polygons}
        drawerLeft={{ open: true, component: FilterComponent, width: { sm: 300, md: 350, lg: 400 } }}
        cardInfo={{
          initialValues: { title: "INFORMACIÓN", elements: elements[shpInfoGeneral] },
          button: { handleClick: handleClickViewDetail, invalidShp: ["municipio", "dfed", "dloc"] },
        }}
        cardComponent={colorPercent?.length ? cardComponent : null}
        highlightFeature={highlightFeature}
        centerByFeatures
        styleProp={{
          data: style_original_data,
          subData: style_original_sub_data,
          onEachFeature: style_original_data,
          highlightFeature: style_original_data,
        }}
      />
    </AdminLayout>
  );
};

export default Maps;

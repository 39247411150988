export const AddBingoInterface = {
  Seccion: "",
  idCasilla: "",
  FolioBingo: "",
  OrigenCaptura: "web",
};

export const MovilizationByHourInterface = {
  Seccion: "",
  Hora: "",
};

export const Movilizacion = {
    Seccion: '',
    idCasilla: '',
    Nombre: '',
    idUsuarioResponsable: "",
    idCompromisoPropietario: "",
    RangoInicial: "",
    RangoFinal: "",
    OrigenCaptura: 'web'
}

export const BingoFolioInterface = {
  Seccion: "",
  idCasilla: "",
  Municipio: "",
  RangoFinal: "",
  RangoInicial: "",
  Poligono: "",
  idUsuarioReporteMov: "",
  Nombre: "",
  idEstatus: 0,
};

import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Button,
  InputAdornment,
  Icon,
  Link,
  Checkbox,
  FormControlLabel,
  Box,
} from "@mui/material";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import { useFormik } from "formik";

import { isEmptyOrInvalidString } from "@utils/validations";
import BasicSelect from "@components/Selects/BasicSelect";
import { MenuAddInterface } from "@interfaces/MenuInterfaces";
import { MenuAddSchema, MenuEditSchema } from "@schemas/MenuSchemas";
import MenuService from "@services/MenuServices";
import { MENUS_PRIVILEGES } from "@data/constants";

const MenuForm = (props) => {
  const {
    action = "add",
    data = MenuAddInterface,
    handleNewRecord,
    setIsOpen,
    setIsLoading,
    setIsSuccess,
  } = props;

  const [webMenus, setWebMenus] = useState([]);
  const [appMenus, setAppMenus] = useState([]);
  const [parentMenus, setParentMenus] = useState([]);
  const [orderedMenus, setOrderedMenus] = useState([]);
  const [isMainMenu, setIsMainMenu] = useState(1);
  const columns = MENUS_PRIVILEGES;

  const formik = useFormik({
    initialValues: action === "add" ? MenuAddInterface : data,
    validationSchema: action === "add" ? MenuAddSchema : MenuEditSchema,
    onSubmit: (values) => {
      handleSave(values);
    },
  });

  const catMenuType = [
    { value: 1, label: "Menú principal" },
    { value: 0, label: "Submenú" },
  ];

  useEffect(() => {
    MenuService.getAllMenus({})
      .then((res) => {
        if (res.results) {
          setWebMenus(res.response.webMenus);
          setAppMenus(res.response.appMenus);

          if (res.response.webMenus.length > 0) {
            setOrderedMenus(
              res.response.webMenus.map((item) => {
                return {
                  value: item.Orden,
                  label: item.Menu,
                };
              })
            );
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      });
  }, []);

  useEffect(() => {
    let menus = [];
    if (formik.values.idPadre === "" || formik.values.idPadre === null) {
      if (formik.values.Movil === 1) {
        menus = appMenus.filter((i) => i.idPadre === null);
      } else {
        menus = webMenus.filter((i) => i.idPadre === null);
      }
    } else {
      let menu = {};
      if (formik.values.Movil === 1) {
        menu = appMenus.filter((i) => i.idMenu === formik.values.idPadre);
      } else {
        menu = webMenus.filter((i) => i.idMenu === formik.values.idPadre);
      }

      if (menu.length > 0) {
        if (menu[0].submenus.length > 0) {
          menus = menu[0].submenus;
        }
      }
    }

    if (menus.length > 0) {
      setOrderedMenus(
        menus.map((item) => {
          return {
            value: item.Orden,
            label: item.Menu,
          };
        })
      );
    } else {
      setOrderedMenus([]);
    }
  }, [formik.values.Movil, formik.values.idPadre, appMenus, webMenus]);

  useEffect(() => {
    if (isMainMenu === 0) {
      let menus = {};
      if (formik.values.Movil === 1) {
        menus = appMenus.filter((i) => i.idPadre === null);
      } else {
        menus = webMenus.filter((i) => i.idPadre === null);
      }
      setParentMenus(
        menus.map((item) => {
          return {
            value: item.idMenu,
            label: item.Menu,
          };
        })
      );
    }
  }, [formik.values.Movil, isMainMenu, appMenus, webMenus]);

  const handleSave = (params) => {
    if (params.Orden < 1) {
      if (orderedMenus.length > 0) {
        params.Orden = Math.max(...orderedMenus.map((item) => item.value));
      } else {
        if (params.idPadre !== "" || params.idPadre !== null) {
          if (formik.values.Movil === 1) {
            params.Orden = appMenus.find((i) => i.idMenu === params.idPadre).Orden;
          } else {
            params.Orden = webMenus.find((i) => i.idMenu === params.idPadre).Orden;
          }
        }
      }
    }
    params.Orden += isMainMenu ? 100 : 1; // incremento en orden
    params.idPadre = ["", 0, null, undefined].includes(params.idPadre) ? null : params.idPadre;

    setIsLoading(true);
    setIsOpen(true);

    if (action === "add") {
      MenuService.setMenu(params)
        .then((res) => {
          if (res.results) {
            Swal.fire({
              title: res.message,
              icon: "success",
            }).then((res) => {
              if (res) {
                handleCleanForm();
                updateListedMenus();
                setIsSuccess(true);
              }
            });
          } else {
            Swal.fire({
              title: res.message,
              icon: "warning",
            });
          }
        })
        .catch((e) => {
          setIsSuccess(false);
          Swal.fire({
            title: "Ha ocurrido un error",
            text: e.message,
            icon: "warning",
          });
        })
        .finally(() => {
          setIsLoading(false);
          setIsOpen(false);
        });
    } else {
      MenuService.updateMenu({ params: [{ id: data.idMenu, Orden: params.Orden }] })
        .then((res) => {
          if (res.results) {
            Swal.fire({
              title: res.message,
              icon: "success",
            }).then((res) => {
              if (res) {
                handleCleanForm();
                updateListedMenus();
                setIsSuccess(true);
              }
            });
          } else {
            Swal.fire({
              title: res.message,
              icon: "warning",
            });
          }
        })
        .catch((e) => {
          setIsSuccess(false);
          Swal.fire({
            title: "Ha ocurrido un error",
            text: e.message,
            icon: "warning",
          });
        })
        .finally(() => {
          setIsLoading(false);
          setIsOpen(false);
        });
    }
  };

  const handleCleanForm = () => {
    formik.setValues({
      Menu: "",
      Descripcion: "",
      Ver: 1,
      Agregar: 0,
      Editar: 0,
      Eliminar: 0,
      Exportar: 0,
      Importar: 0,
      Autorizar: 0,
      Visible: 1,
      Desplegable: 0,
      Ruta: "",
      Icono: "",
      TipoIcono: "mui",
      Siglas: "",
      Plantilla: "admin",
      Orden: 0,
      Movil: 0,
      idPadre: "",
    });
  };

  const handleChecked = (e) => {
    let value = e.target.value === "1" ? 0 : 1;
    formik.setFieldValue(e.target.name, value, false);
  };

  const handleFlagMainMenu = (e) => {
    setIsMainMenu(e.target.value);
    if (e.target.value === 1) {
      setParentMenus([]);
      formik.setFieldValue("idPadre", 0, false);
    }
  };

  const updateListedMenus = () => {
    MenuService.getAllMenus({})
      .then((res) => {
        if (res.results) {
          setWebMenus(res.response.webMenus);
          setAppMenus(res.response.appMenus);
          handleNewRecord(res.response.webMenus, res.response.appMenus);
          if (res.response.webMenus.length > 0) {
            setOrderedMenus(
              res.response.webMenus.map((item) => {
                return {
                  value: item.Orden,
                  label: item.Menu,
                };
              })
            );
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={10}>
          <Grid container spacing={3} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <BasicSelect
                disabled={action !== "add" ? true : false}
                label="Tipo de Menú"
                name="TipoMenu"
                onChange={handleFlagMainMenu}
                value={isMainMenu}
                options={catMenuType}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              {isMainMenu === 0 && (
                <BasicSelect
                  disabled={action !== "add" ? true : false}
                  errorMessage={formik.touched.idPadre && !isEmptyOrInvalidString(formik.errors.idPadre)}
                  label="Menú principal"
                  name="idPadre"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.idPadre}
                  options={parentMenus}
                  sx={{ width: "100%" }}
                />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                disabled={action !== "add" ? true : false}
                error={formik.touched.Menu && !isEmptyOrInvalidString(formik.errors.Menu)}
                label="Menú"
                helperText={formik.touched.Menu && formik.errors.Menu && formik.errors.Menu}
                type="text"
                name="Menu"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Menu}
                size="small"
                variant="outlined"
                className="fixed-input"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                disabled={action !== "add" ? true : false}
                error={formik.touched.Siglas && !isEmptyOrInvalidString(formik.errors.Siglas)}
                label="Siglas"
                helperText={formik.touched.Siglas && formik.errors.Siglas && formik.errors.Siglas}
                type="text"
                name="Siglas"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Siglas}
                size="small"
                variant="outlined"
                className="fixed-input"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                disabled={action !== "add" ? true : false}
                error={formik.touched.Ruta && !isEmptyOrInvalidString(formik.errors.Ruta)}
                label="Ruta"
                helperText={formik.touched.Ruta && formik.errors.Ruta && formik.errors.Ruta}
                type="text"
                name="Ruta"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Ruta}
                size="small"
                variant="outlined"
                className="fixed-input"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                disabled={action !== "add" ? true : false}
                error={formik.touched.Icono && !isEmptyOrInvalidString(formik.errors.Icono)}
                label="Ícono"
                helperText={
                  <Link
                    href="https://fonts.google.com/icons?icon.platform=android"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Busca algún ícono aquí
                  </Link>
                }
                type="text"
                name="Icono"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Icono}
                size="small"
                variant="outlined"
                className="fixed-input"
                placeholder="category"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>{formik.values.Icono !== "" ? formik.values.Icono : "category"}</Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <BasicSelect
                errorMessage={formik.touched.Orden && !isEmptyOrInvalidString(formik.errors.Orden)}
                label="Colocar después de"
                name="Orden"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Orden}
                options={orderedMenus}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                disabled={action !== "add" ? true : false}
                error={formik.touched.Descripcion && !isEmptyOrInvalidString(formik.errors.Descripcion)}
                label="Descripción"
                helperText={
                  formik.touched.Descripcion && formik.errors.Descripcion && formik.errors.Descripcion
                }
                type="text"
                multiline
                rows={2}
                name="Descripcion"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Descripcion}
                size="small"
                variant="outlined"
                className="fixed-input"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          {columns &&
            columns.length > 0 &&
            columns.map((col) => (
              <Grid container key={Math.random()}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={action !== "add" ? true : false}
                      name={col}
                      onChange={handleChecked}
                      onBlur={formik.handleBlur}
                      value={formik.values[col]}
                      checked={formik.values[col] === 1 ? true : false}
                      color="primary"
                    />
                  }
                  label={col}
                />
              </Grid>
            ))}
        </Grid>
        <Grid item xs={12} sx={{ mt: "5vh", mr: 4, textAlign: "end" }}>
          <Button variant="outlined" size="small" onClick={handleCleanForm} startIcon={<Icon>cancel</Icon>}>
            Cancelar
          </Button>
          <Button variant="outlined" size="small" onClick={formik.submitForm} startIcon={<Icon>save</Icon>}>
            Guardar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

MenuForm.propTypes = {
  action: PropTypes.string.isRequired,
  data: PropTypes.object,
  handleNewRecord: PropTypes.func,
  setIsOpen: PropTypes.func,
  setIsLoading: PropTypes.func,
  setIsSuccess: PropTypes.func,
};

export default MenuForm;

import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useCatalogs } from "@hooks/useCatalogs";

import { Card, CardContent, Typography, Stack, Box, Button, Icon, ButtonGroup } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

import BasicSelect from "@components/Selects/BasicSelect";
import AdvancedSelect from "@components/Selects/AdvancedSelect";
import middleware from "@middlewares/middleware";
import DiaDServices from "@services/DiaDServices";
import { AMBITO_ESTATAL } from "@data/constants";
import { addUserInterface } from "@interfaces/DiaDInterfaces";
import { addUserSchema } from "@schemas/DiaDSchemas";
import { AMBITO_DF, AMBITO_DL, AMBITO_MPAL, AMBITO_MPIO_DL, AMBITO_REGIONAL } from "@data/constants";
import { isEmptyOrNullObject } from "@utils/validations";
import Swal from "sweetalert2";

const UserForm = (props) => {
  const { setLoading, params, getPermisos } = props;
  const catalogsOptions = [
    { id: "regiones", getAll: false },
    { id: "municipios", getAll: false },
    { id: "municipios_reportes", getAll: false },
    { id: "df", getAll: false },
    { id: "dl", getAll: false },
  ];

  const [formType, setFormType] = useState(0);
  const [catalogsFiltered, setCatalogsFiltered] = useState([]);
  const [scope, setScope] = useState([]);
  const [catScope, setCatScope] = useState([]);
  const [labelScope, setLabelScope] = useState("");

  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsOptions,
    putDefaultOption: false,
  });

  const formik = useFormik({
    initialValues: addUserInterface,
    validationSchema: addUserSchema,
    onSubmit: async (values) => {
      await handleSave(values);
    },
  });

  useEffect(() => {
    if (!isEmptyOrNullObject(params)) {
      formik.setValues((prev) => ({
        ...prev,
        idUsuario: params.id,
      }));
    }
  }, [params]);

  useEffect(() => {
    loadResponsibilitiesTypes();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!load) {
      setCatalogsFiltered((prevState) => ({ ...prevState, ...catalogs }));
    }
    //eslint-disable-next-line
  }, load);

  useEffect(() => {
    if (formType && !load) {
      const scopes = catalogsFiltered.perfiles_ambitos ? catalogsFiltered.perfiles_ambitos.filter((item) => item.idPerfil === formType) : [];

      setScope(
        scopes.map((item) => {
          return {
            value: item.idAmbitoCargo,
            label: item.Ambito,
          };
        })
      );
    }
    //eslint-disable-next-line
  }, [formType, load]);

  const loadResponsibilitiesTypes = async () => {
    try {
      const { results, response } = await DiaDServices.getCatalogs();
      if (results) {
        setCatalogsFiltered({ ...catalogsFiltered, ...response.catalogs });
        if (response.catalogs.perfiles) {
          handleClickProfile(response.catalogs.perfiles[0].value);
        }
      }
    } catch (error) {
    } finally {
    }
  };

  const handleClickProfile = (profileId) => {
    setFormType(profileId);
    formik.setValues({
      ...formik.values,
      idPerfil: profileId,
      idAmbitoCargo: "",
      AmbitoValorCargo: "",
    });
  };

  const handleChangeScopeId = async (id) => {
    formik.setValues({
      ...formik.values,
      idAmbitoCargo: id,
      AmbitoValorCargo: "",
    });

    switch (id) {
      case AMBITO_REGIONAL:
        setLabelScope("Región");
        setCatScope(catalogs?.regiones || []);
        break;
      case AMBITO_MPAL:
        setLabelScope("Municipio");
        setCatScope(catalogs?.municipios || []);
        break;
      case AMBITO_MPIO_DL:
        setLabelScope("Municipio Extendido");
        setCatScope(catalogs?.municipios_reportes || []);
        break;
      case AMBITO_DF:
        setLabelScope("Distrito Federal");
        setCatScope(catalogs?.df || []);
        break;
      case AMBITO_DL:
        setLabelScope("Distrito Local");
        setCatScope(catalogs?.dl || []);
        break;
      case AMBITO_ESTATAL:
      default:
        setLabelScope("NA");
        setCatScope([]);
        break;
    }
  };

  const handleChangeScopeValue = async (item) => {
    formik.setFieldValue("AmbitoValorCargo", item.value);
  };

  const handleSave = async (values) => {
    const {AmbitoValorCargo, idAmbitoCargo, idPerfil, idUsuario } = values
    let params = {
      AmbitoValorCargo: AmbitoValorCargo || null,
      idAmbitoCargo: idAmbitoCargo,
      idPerfil: idPerfil,
      idUsuario: idUsuario,
    };
    try {
      setLoading(true);
      const result = await DiaDServices.setResponsability(params);
      const { results, message } = result;
      if (results) {
        Swal.fire({ title: message, icon: "success" });
        getPermisos(prev => !prev)
    }
      else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoading(false);
    }
  };

  const handleClean = async () => {
    formik.resetForm();
  };

  return (
    <Card className="card-primary" sx={{ height: "100%" }}>
      <CardContent>
        <Box textAlign="center" mt={2}>
          <Typography component={"h6"} variant="h6" fontWeight={800} fontSize={18}>
            AGREGAR PRIVILEGIOS DE ACCESO
          </Typography>

          {middleware.checkMenuAction("Agregar") ? (
            <Stack spacing={2} justifyContent="center" alignItems="center">
              <Typography variant="div">Tipo de responsabilidad</Typography>
              <ButtonGroup size="large" variant="outlined">
                {!load &&
                  catalogsFiltered.perfiles.map((item) => (
                    <Button
                      color="info"
                      key={item.value}
                      onClick={(e) => handleClickProfile(item.value)}
                      variant={item.value === formType ? "contained" : "outlined"}
                      sx={{
                        borderWidth: "1px",
                        borderRadius: "30px",
                        pr: "23 px",
                        pl: "23 px",
                      }}
                    >
                      {item.label}
                    </Button>
                  ))}
              </ButtonGroup>
            </Stack>
          ) : (
            <Grid2 container mt={4} justifyContent={"center"} alignContent={"center"}>
              <Typography component={"h6"} variant="h6" fontWeight={300} fontSize={20} color={"error"}>
                NO cuenta con los permisos suficientes
              </Typography>
            </Grid2>
          )}
        </Box>

        <Grid2 container spacing={2} mt={2}>
          <Grid2 item xs={12} sm={12} md={12}>
            <BasicSelect
              label="Ámbito de Visualización"
              options={scope}
              name="idAmbitoCargo"
              value={formik.values.idAmbitoCargo}
              onChange={(e) => handleChangeScopeId(e.target.value)}
              sx={{ width: "100%" }}
              isLoading={load}
              error={Boolean(formik.errors.idAmbitoCargo)}
              errorMessage={formik.errors.idAmbitoCargo}
            />
          </Grid2>

          {formik.values.idAmbitoCargo && formik.values.idAmbitoCargo > AMBITO_ESTATAL && (
            <Grid2 item xs={12} sm={12} md={12}>
              <AdvancedSelect
                name={"AmbitoValorCargo"}
                label={labelScope}
                options={catScope}
                value={formik.values.AmbitoValorCargo}
                onChange={handleChangeScopeValue}
                isLoading={load}
                disabled={formik.values.idAmbitoCargo === AMBITO_ESTATAL}
                error={Boolean(formik.errors.AmbitoValorCargo)}
                helperText={formik.errors.AmbitoValorCargo}
                isSearchable
              />
            </Grid2>
          )}

          <Grid2 item xs={12} container spacing={1} sx={{ justifyContent: "end" }}>
            <Grid2 item xs={12} sm={4} md={5}>
              <Button variant="outlined" color="primaryDark" size="small" onClick={handleClean} fullWidth>
                Limpiar
              </Button>
            </Grid2>
            <Grid2 item xs={12} sm={4} md={5}>
              <Button
                fullWidth
                variant="contained"
                color="primaryDark"
                size="small"
                startIcon={<Icon>save</Icon>}
                onClick={formik.handleSubmit}
                disabled={!params.id}
                s
              >
                Guardar
              </Button>
            </Grid2>
          </Grid2>
        </Grid2>
      </CardContent>
    </Card>
  );
};

export default UserForm;

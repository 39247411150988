//Librerías
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

// Material UI
import { Container, Card, CardContent, Box, Grid, Typography, Button, Chip, Icon, Alert } from "@mui/material";
import { KeyboardDoubleArrowRight, KeyboardDoubleArrowLeft } from "@mui/icons-material";
import { green, amber } from '@mui/material/colors';

//Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import ActionHeader from "@components/Containers/ActionHeader";
import CustomTabs from "@components/Tabs/CustomTabs";
import DrawerRelative from "@components/Containers/DrawerRelative";
import Filter from "@components/Activation/BingoCapturistList/Filter";
import CustomTable from "@components/Tables/CustomTable";
import Download from "@components/Downloads/Download";

//Servicios
import MobilizationService from "@services/MovilizationServices";

import { TOTALES_POR_USUARIO_FECHA, CONSULTA_POR_CASILLA_O_USUARIO } from "@data/constants/Bingo";
import moment from "moment/moment";

const BingoCapturistList = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(true);
    const [catalogs, setCatalogs] = useState({});

    const [loadingForm, setLoadingForm] = useState({
        isLoading: false,
        isOpen: false,
        isSuccess: false,
        loadingMessage: "Validando...",
        successMessage: "¡Consultado con éxito!",
    });

    //Sirve para ver en cual pestaña del tab empezar
    const [tabValue, setTabValue] = useState("1");

    //Datos del filtro
    const [filtered, setFiltered] = useState([]);

    const [isLoadingCatalogs, setIsLoadingCatalogs] = useState(false);

    //Datos de tabla
    const [dataTable, setDataTable] = useState([]);

    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const [orderBy, setOrderBy] = useState("");
    const [order, setOrder] = useState("");

    const [summary, setSummary] = useState([])

    const [isDownload, setIsDownloading] = useState(false);

    const [noData, setNoData] = useState(true)
    const [showMessage, setShowMessage] = useState(true);

    // const handleFilter = (action, params) => {
    //     const filter = [];
    //     if (action === "activate") {
    //         const params = {
    //             page: page,
    //             pageSize: pageSize,
    //             filtered: filtered,
    //             sorted: orderBy ? [{ id: orderBy, value: order }] : [],
    //         };
    //         fetchData(params);
    //     } else if (action !== "clear") {
    //         if (params.Nombre) {
    //             filter.push({
    //                 id: ["usuarios.Nombre", "usuarios.Paterno", "usuarios.Materno"],
    //                 filter: "LIKE",
    //                 value: params.Nombre,
    //             });
    //         }

    //         if (params.Celular) {
    //             filter.push({
    //                 id: "usuarios.Username",
    //                 filter: "=",
    //                 value: params.Celular,
    //             });
    //         }

    //         setFiltered(filter);

    //         const sendParams = {
    //             page: 0,
    //             pageSize: pageSize,
    //             filtered: filter,
    //             sorted: orderBy ? [{ id: orderBy, value: order }] : [],
    //         };
    //         fetchData(sendParams);

    //         setPage(0);
    //     } else {
    //         setFiltered([]);

    //         const clearFiltered = {
    //             page: 0,
    //             pageSize: pageSize,
    //             filtered: [],
    //             sorted: [],
    //         };

    //         setOrderBy("");
    //         setOrder("");
    //         fetchData(clearFiltered);
    //     }
    // };

    // Paginación remota de la tabla
    const handlePagination = ({ page, pageSize }) => {
        setPage(page);
        setPageSize(pageSize);

        const params = {
            page: page,
            pageSize: pageSize,
            filtered: filtered,
            sorted: orderBy ? [{ id: orderBy, value: order }] : [],
        };

        if (tabValue === "1") {
            fetchData(params);
        } else if (tabValue === "2") {
            fetchData2(params);
        }
    };

    // Ordenamiento remoto de la tabla
    const handleSort = (e) => {
        setOrderBy(e.orderBy);
        setOrder(e.order);

        const params = {
            page: page,
            pageSize: pageSize,
            filtered: filtered,
            sorted: [{ id: e.orderBy, value: e.order }],
        };

        if (tabValue === "1") {
            fetchData(params);
        } else if (tabValue === "2") {
            fetchData2(params);
        }
    };

    const fetchData = async (params) => {
        setDataTable([])
        setTotal(0)
        setOrder("")
        setOrderBy("")
        setSummary([])
        setNoData(true)

        setIsLoading(true);
        setLoadingForm({
            isLoading: true,
            isOpen: true,
            isSuccess: false,
            loadingMessage: "Datos...",
            successMessage: "Datos obtenidos con éxito!",
        });

        try {
            const result = await MobilizationService.getStatsByUser(params);
            const { results, message, response } = result;

            if (results) {
                let summaryValues = {};
                TOTALES_POR_USUARIO_FECHA.forEach(col => {
                    summaryValues[col.id] = response.totales[col.id];
                });

                const array_values = Object.values(summaryValues);
                setSummary(array_values);

                setDataTable(response.data.map(item => ({
                    ...item,
                    Fecha: moment(item.Fecha, 'YYYY-MM-DD').format('DD/MM/YYYY')
                })));

                setTotal(response.total);
            } else {
                Swal.fire({ title: message, icon: "warning" });
                setDataTable([]);
                setTotal(0);
                setNoData(false)
            }
        } catch (e) {
            Swal.fire({ title: e.message, icon: "warning" });
        } finally {
            setIsLoading(false);
            setLoadingForm({ ...loadingForm, isLoading: false, isOpen: false });
        }
    };

    const fetchData2 = async (params) => {
        setDataTable([])
        setTotal(0)
        setOrder("")
        setOrderBy("")
        setSummary([]);
        setNoData(true)

        setIsLoading(true);
        setLoadingForm({
            isLoading: true,
            isOpen: true,
            isSuccess: false,
            loadingMessage: "Datos...",
            successMessage: "Datos obtenidos con éxito!",
        });

        try {
            const result = await MobilizationService.getStatsByUserDetails(params);
            const { results, message, response } = result;

            if (results) {
                const modifiedData = response.data.map((item) => {
                    let statusChip;

                    statusChip = <Chip label={item.EstatusCuadernillo} style={{
                        backgroundColor: item.EstatusCuadernillo === "CAPTURA" ? amber[200] : green[200],
                        color: item.EstatusCuadernillo === "CAPTURA" ? amber[800] : green[800],
                        fontWeight: 600, width: 120, height: 25
                    }} />

                    return {
                        ...item,
                        Fecha: moment(item.Fecha, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                        TotalTareasNumber: item.TotalTareas,
                        EstatusCuadernillo: statusChip
                    };
                });
                let summaryValues = {};
                CONSULTA_POR_CASILLA_O_USUARIO.forEach(col => {
                    summaryValues[col.id] = response.totales[col.id];
                });

                const array_totals = ['TOTALES', ...(Object.values(summaryValues).splice(1))];
                setSummary(array_totals);

                setDataTable(modifiedData);
                setTotal(response.total);
            } else {
                Swal.fire({ title: message, icon: "warning" });
                setDataTable([]);
                setTotal(0);
                setNoData(false)
            }
        } catch (e) {
            Swal.fire({ title: e.message, icon: "warning" });
        } finally {
            setIsLoading(false);
            setLoadingForm({ ...loadingForm, isLoading: false, isOpen: false });
        }
    };

    const getCatalogs = async () => {
        const CATALOG_OPTIONS = [
            { id: "secciones" },
            // { id: "casillas", getAll: false },
            { id: "usuarios" }
        ];

        setIsLoadingCatalogs(true);
        try {
            const result = await MobilizationService.getCatalogsForStats(CATALOG_OPTIONS);
            const { results, response, message } = await result;

            if (results) setCatalogs(response.catalogs);
            else throw new Error(message);
        } catch (error) {
            Swal.fire({ title: error, icon: "warning" });
        } finally {
            setIsLoadingCatalogs(false);
        }
    };

    useEffect(() => {
        getCatalogs();
        // eslint-disable-next-line
    }, []);

    const onSearch = async (values) => {
        setFiltered(values);
        setOrderBy("")
        setOrder("")
    };

    const onClear = async () => {
        setFiltered([]);
        setPage(0)
        setPageSize(10)
        setOrderBy("")
        setOrder("")
    };

    useEffect(() => {
        setFiltered([])
        setPage(0)
        setPageSize(10)
    }, [tabValue])

    useEffect(() => {
        if (tabValue === "1") {
            const params = {
                page: page,
                pageSize: pageSize,
                filtered: filtered,
                sorted: orderBy ? [{ id: orderBy, value: order }] : [],
            };
            fetchData(params);
        }

        if (tabValue === "2") {
            const params = {
                page: page,
                pageSize: pageSize,
                filtered: filtered,
                sorted: orderBy ? [{ id: orderBy, value: order }] : [],
            };
            fetchData2(params);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtered]);

    const exportStats = async () => {
        if (tabValue === "1") {
            const fechaFilterTab1 = filtered.find(item => item.id === "Fecha");

            setIsDownloading(true);
            try {
                const res = await MobilizationService.exportStatsByUser(fechaFilterTab1 ? { "filtered": [fechaFilterTab1] } : { "filtered": [] });
                const { results, message } = res;

                if (!results) {
                    throw new Error(message);
                }
            } catch (error) {
                Swal.fire({
                    title: error.message,
                    icon: "warning",
                });
            } finally {
                setIsDownloading(false);
            }
        } else if (tabValue === "2") {
            const fechaFilterTab2 = filtered.find(item => item.id === "Fecha");

            setIsDownloading(true);
            try {
                const res = await MobilizationService.exportStatsByUserDetails(fechaFilterTab2 ? { "filtered": [fechaFilterTab2] } : { "filtered": [] });
                const { results, message } = res;

                if (!results) {
                    throw new Error(message);
                }
            } catch (error) {
                Swal.fire({
                    title: error.message,
                    icon: "warning",
                });
            } finally {
                setIsDownloading(false);
            }
        }
    };

    return (
        <AdminLayout>
            <Container maxWidth="false">
                <ActionHeader title="Bingo: Listado de capturistas" isCustom>
                    <Button
                        onClick={() => setOpenDrawer(!openDrawer)}
                        aria-label="open Drawer"
                        endIcon={openDrawer ? <KeyboardDoubleArrowRight /> : <KeyboardDoubleArrowLeft />}
                    >
                        <Typography sx={{ fontWeight: 500 }}>Filtros</Typography>
                    </Button>
                </ActionHeader>

                <LoadingForm
                    loadinMessage={loadingForm.loadingMessage}
                    successMessage={loadingForm.successMessage}
                    isLoading={loadingForm.isLoading}
                    success={loadingForm.isSuccess}
                    isOpen={loadingForm.isOpen}
                    setIsOpen={() => { }}
                />
                <Grid container spacing={openDrawer ? 2 : 0} sx={{ flexDirection: { xs: "column", md: "row-reverse" } }}>
                    <Grid item xs={12} md={12}>
                        {
                        showMessage && (
                            <Alert severity="info" sx={{ mb: 2 }} onClose={() => { setShowMessage(false) }}>
                                En esta pantalla se muestra la cantidad de cuadernillos que los usuarios revisaron por día y la cantidad de Folios que asignaron.
                                <br/>Así mismo se encuentra el detalle por cuadernillo de los folios asignados y el estatus de la revisión 
                            </Alert>
                        )
                        }
                    </Grid>
                    <Grid item xs={12} md={openDrawer ? 4 : 0}>
                        <DrawerRelative
                            anchor="right"
                            open={openDrawer}
                            setOpen={setOpenDrawer}
                            title="Filtrar"
                            icon="person_search"
                            sxPaper={{ borderRadius: 3 }}
                            className="card-primary"
                            isSticky
                            screenHeight="md"
                        >
                            <Filter tabValue={tabValue} isLoading={isLoadingCatalogs} onSearch={onSearch} onClear={onClear} catalogs={catalogs} />
                        </DrawerRelative>
                    </Grid>

                    <Grid item xs={12} md={openDrawer ? 8 : 12}>
                        <Card className="card-primary">
                            <CardContent>
                                <Box sx={{ mt: 1, mb: 1 }}>
                                    <CustomTabs
                                        value={tabValue}
                                        setValue={setTabValue}
                                        color={"primary"}
                                        tabs={[
                                            {
                                                label: "Totales por usuario por fecha",
                                                value: "1",
                                                component: (
                                                    <Box>
                                                        {noData ?
                                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                <Button
                                                                    variant="outlined"
                                                                    color="primaryDark"
                                                                    startIcon={<Icon>download</Icon>}
                                                                    size="small"
                                                                    disabled={isDownload}
                                                                    onClick={exportStats}
                                                                >
                                                                    Exportar
                                                                </Button>
                                                            </Box>
                                                            : <></>
                                                        }

                                                        {isDownload && (
                                                            <Box marginBottom={2}>
                                                                <Download format={"xlsx"} isDownload={isDownload} />
                                                            </Box>
                                                        )}
                                                        <Grid container spacing={2} sx={{ mt: .5 }}>
                                                            <Grid item xs={12} lg={12}>
                                                                <CustomTable
                                                                    rows={dataTable}
                                                                    columns={TOTALES_POR_USUARIO_FECHA}
                                                                    total={total}
                                                                    page={page}
                                                                    pageSize={pageSize}
                                                                    isLoading={isLoading}
                                                                    handlePagination={handlePagination}
                                                                    handleSort={handleSort}
                                                                    orderBy={orderBy}
                                                                    summary={summary}
                                                                    summarySettings={{ start: 0, title: "Totales: ", align: "center" }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                ),
                                            },
                                            {
                                                label: "Consulta por casilla - usuario",
                                                value: "2",
                                                component: (
                                                    <Box>
                                                        {noData ?
                                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                <Button
                                                                    variant="outlined"
                                                                    color="primaryDark"
                                                                    startIcon={<Icon>download</Icon>}
                                                                    size="small"
                                                                    disabled={isDownload}
                                                                    onClick={exportStats}
                                                                >
                                                                    Exportar
                                                                </Button>
                                                            </Box>
                                                            : <></>
                                                        }

                                                        {isDownload && (
                                                            <Box marginBottom={2}>
                                                                <Download format={"xlsx"} isDownload={isDownload} />
                                                            </Box>
                                                        )}

                                                        <Grid container spacing={2} sx={{ mt: .5 }}>
                                                            <Grid item xs={12} lg={12}>
                                                                <CustomTable
                                                                    rows={dataTable}
                                                                    columns={CONSULTA_POR_CASILLA_O_USUARIO}
                                                                    total={total}
                                                                    page={page}
                                                                    pageSize={pageSize}
                                                                    isLoading={isLoading}
                                                                    handlePagination={handlePagination}
                                                                    handleSort={handleSort}
                                                                    orderBy={orderBy}
                                                                    disableCardType
                                                                    summary={summary}
                                                                    summarySettings={{ start: 99, title: "Totales: ", align: "center" }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                ),
                                            },
                                        ]}
                                    />
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </AdminLayout>
    );
};

export default BingoCapturistList;

import Yup from "@utils/Yupi18n";

const RegistrarPPM = Yup.object().shape({
  NombreCompleto: Yup.string().min(3).max(80).required("Nombre Completo es requerido").label("Nombre Completo"),
  idResponsabilidad: Yup.number().oneOf([0, 1, 2]).required("Responsabilidad es requerido").label("Responsabilidad"),
  Ubicacion: Yup.string().required("Ubicación es requerida").label("Ubicación"),
  imageName: Yup.string().required("Nombre de la imagen es requerido").label("Nombre de la Imagen"),
  files: Yup.array()
    .min(1)
    .max(1)
    .of(
      Yup.object().shape({
        file: Yup.string().required("Imagen es requerida"),
      })
    )
    .required("Debe incluir exactamente una imagen"),
});

export { RegistrarPPM };

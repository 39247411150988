
import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Swal from "sweetalert2";
import SupportService from "@services/SupportServices";
import moment from "moment";
import "moment/locale/es"; // Importar el idioma español si es necesario
import CustomTable from "@components/Tables/CustomTable";

const UsersActivationTable = (props) => {
  const {
    dataTable,
    total,
    page,
    pageSize,
    handleSort,
    handlePagination,
    isLoading,
    handleFilter,
    setLoadingForm,
    orderBy
  } = props;

  const [params, setParams] = useState({});

  const handleSave = async () => {
    if (Object.keys(params).length === 0) {
      // Evitar la ejecución si params está vacío
      return;
    }

    setLoadingForm({
      isLoading: true,
      isOpen: true,
      isSuccess: false,
      loadingMessage: "Cargando...",
    });

    try {
      const res = await SupportService.updateStatus(params);

      if (res.results) {
        Swal.fire({
          title: res.message,
          icon: "success",
        }).then((res) => {
          if (res) {
            // Actualizar tabla después de activar a algún usuario
            handleFilter("activate");
          }
        });
      } else {
        Swal.fire({
          title: res.message,
          icon: "warning",
        });
      }
    } catch (e) {
      Swal.fire({
        title: "Ha ocurrido un error",
        text: e.message,
        icon: "warning",
      });
    } finally {
      setLoadingForm({
        isLoading: false,
        isOpen: false,
        isSuccess: false,
        loadingMessage: "Cargando...",
      });
    }
  };

  useEffect(() => {
    handleSave();
    // eslint-disable-next-line
  }, [params]);

  const titulos = [
    { id: "NombreCompleto", label: "Nombre completo", type: "text" },
    { id: "Edad", label: "Edad", type: "number" },
    { id: "Municipio", label: "Municipio", type: "text" },
    { id: "Propietario", label: "Invitado por", type: "text" },
    { id: "FechaCreo", label: "Fecha de registro", type: "text" },
    { id: "Boton", label: "Button", sort: false },
  ];

  const modifiedData = dataTable.map((item) => ({
    NombreCompleto: `${item.Nombre} ${item.Paterno} ${item.Materno}`,
    ...item,
    FechaCreo: moment(item.FechaCreo).format("DD/MM/YYYY HH:mm"),
    Boton: (
      <Button
        onClick={() => handleButtonClick(item)}
        sx={{
          paddingX: { xs: 0.8, sm: "auto" },
          textTransform: "none",
        }}
        variant="contained"
        color="primaryDark"
      >
        Activar
      </Button>
    ),
  }));

  const handleButtonClick = (item) =>
    openActivationSwal(`${item.Nombre} ${item.Paterno}`, item.id);

  const openActivationSwal = (nombre, id) => {
    Swal.fire({
      title: `¿Estás seguro de querer activar la cuenta de ${nombre}</b>?`,
      showCancelButton: true,
      confirmButtonText: "Sí, Activar",
      cancelButtonText: "No, Cancelar",
      icon: "warning",
    }).then((result) => {
      if (result.isConfirmed) openConfirmationSwal(nombre, id);
    });
  };

  const openConfirmationSwal = (nombre, id) => {
    Swal.fire({
      title: `Activar cuenta de ${nombre}`,
      text: "Ingresa un motivo:",
      input: "text",
      confirmButtonText: "Activar",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        // Valores para activar un usuario
        const userStatusData = {
          id: id,
          idEstatus: 2,
          Motivo: result.value,
        };
        setParams(userStatusData);
      }
    });
  };

  return (
    <>
      <CustomTable
        rows={modifiedData}
        columns={titulos}
        total={total}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading}
        handlePagination={handlePagination}
        handleSort={handleSort}
        orderBy={orderBy}
        disableCardType
      />
    </>
  );
};

export default UsersActivationTable;
import {
  Box,
  Icon,
  TableCell,
  TableRow,
  Typography,
  TableBody as TableBodyMui,
  TextField,
  Stack,
  Button,
} from "@mui/material";
import { grey } from "@mui/material/colors";

import ContactsStack from "@components/ContactsStack/ContactsStack";

const TableBody = ({ data, dataFound }) => {
  return (
    <TableBodyMui>
      {data.length > 0 ? (
        data.map((row, index) => (
          <TableRow key={index}>
            <TableCell>
              <Stack direction={"column"}>
                <Typography variant="body2">{row.NombreCompleto}</Typography>
                <Typography variant="caption">
                  {row.CalleVive} {row.NumExtVive} {row.ColoniaVive}{" "}
                  {row.Municipio}
                </Typography>
              </Stack>
            </TableCell>
            <TableCell>
              <ContactsStack
                nombre={row.NombreCompleto}
                whatsapp={row.Celular}
                telefono={row.Telefono}
                telefono_mensaje={row.TelMensajes}
              />
            </TableCell>
            <TableCell>{row.Seccion}</TableCell>
            <TableCell>{row.NombreCasilla}</TableCell>
            <TableCell align="center">{row.FolioBingo}</TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={5}>
            <Box
              display={"flex"}
              alignContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <Icon sx={{ fontSize: "5rem", color: grey[500] }}>
                {dataFound ? "person_search" : "search_off"}
              </Icon>
              <Typography fontWeight={600} variant="h6" marginTop={1}>
                {dataFound
                  ? "Datos No Disponible"
                  : "No se econtraron resultados"}
              </Typography>
              <Typography variant="caption" marginTop={1}>
                {dataFound
                  ? " Utiliza el filtro  para realizar una búsqueda y obtener los resultados deseados."
                  : "Por favor, intenta realizar la búsqueda nuevamente, ya que no se encontraron datos con los criterios actuales"}
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      )}
    </TableBodyMui>
  );
};

export default TableBody;

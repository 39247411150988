import { PrivilegedRoute, ProtectedRoute } from "@middlewares";


import Eventos from "@pages/Events/Eventos";
import PreguntasPost from "@pages/Events/PreguntasPost";
import Invitados from "@pages/Events/Invitados";
import InvitadosEvent from "@pages/Events/InvitadosEvent";
import Staff from "@pages/Events/Staff";
import StaffEvent from "@pages/Events/StaffEvent";
import EventosPaseLista from "@pages/Events/EventosPaseLista";
import EventosPaseListaInfo from "@pages/Events/EventosPaseListaInfo";
import Noticias from "@pages/Noticias/Noticias";
import Registro from "@pages/Ppm/Registro";
import UploadInformation from "@pages/Certificados_ppm_foca/UploadInformation";
import { PAGINA_PPM } from "@data/constants/Noticias";

const ids = [12];


const PpmRoutes = [
  {
    path: "/ppm/eventos",
    element: (
      <PrivilegedRoute>
        <Eventos idsTipoActividad={ids} id="ppm" />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/ppm/eventos/preguntas",
    element: (
      <ProtectedRoute>
        <PreguntasPost idsTipoActividad={ids} id="ppm" />
      </ProtectedRoute>
    ),
  },
  {
    path: "/ppm/eventos/invitados",
    element: (
      <PrivilegedRoute>
        <Invitados idsTipoActividad={ids} id="ppm" />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/ppm/eventos/invitados/:id",
    element: (
      <ProtectedRoute>
        <InvitadosEvent />
      </ProtectedRoute>
    ),
  },
  {
    path: "/ppm/eventos/staff",
    element: (
      <PrivilegedRoute>
        <Staff idsTipoActividad={ids} id="ppm" />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/ppm/eventos/staff/:id",
    element: (
      <ProtectedRoute>
        <StaffEvent />
      </ProtectedRoute>
    ),
  },
  {
    path: "/ppm/eventos/pase-lista",
    element: (
      <PrivilegedRoute>
        <EventosPaseLista idsTipoActividad={ids} />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/ppm/eventos/pase-lista/:id",
    element: (
      <ProtectedRoute>
        <EventosPaseListaInfo />
      </ProtectedRoute>
    ),
  },
  {
    path: "/ppm/noticias",
    element: (
      <PrivilegedRoute>
        <Noticias page={PAGINA_PPM} />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/ppm/registro",
    element: (
      <PrivilegedRoute>
        <Registro />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/reconocimientos/upload",
    element: (
      <ProtectedRoute>
        <UploadInformation />
      </ProtectedRoute>
    ),
  },
];

export default PpmRoutes;

import React, { useState } from "react";
import {
	Button,
	Icon,
	IconButton,
	Tooltip
} from "@mui/material";
var CryptoJS = require("crypto-js");

const {
	REACT_APP_BASE_URL,
	REACT_APP_URL_IMAGES,
	REACT_APP_BASE_VERSION,
	REACT_APP_C27_KEY,
	REACT_APP_API_KEY,
	REACT_APP_DEBUG,
	REACT_APP_RECAPTCHA_SITE_KEY,
	REACT_APP_URL_FRONT,
} = process.env;

export const Version = `Ver ${REACT_APP_BASE_VERSION}`;

export const URL_PUBLICA_FRONT = `${REACT_APP_URL_FRONT}`;

export const RECAPTCHA_SITE_KEY = REACT_APP_RECAPTCHA_SITE_KEY;

export const BASE_GEOCODE_URL =
	"https://maps.googleapis.com/maps/api/geocode/json?key=";

export const GOOGLE_MAPS_KEY = "Llave de maps";

export const URL_PUBLICA = `${REACT_APP_BASE_URL}/`;
export const URL_IMAGES = `${REACT_APP_URL_IMAGES}/files`;

export const PUBLIC_API_KEY = REACT_APP_API_KEY;

export const DEBUG = REACT_APP_DEBUG.toLowerCase() === "true";

export const limpiarTelefono = (numero) => {
	// Eliminar caracteres no numéricos y espacios en blanco
	const numeroLimpio = numero.replace(/\D/g, "");

	// Obtener los últimos 10 dígitos
	const ultimosDiezDigitos = numeroLimpio.slice(-10);

	return ultimosDiezDigitos;
};

export function ClipboardCopy({ copyText }) {
	const [isCopied, setIsCopied] = useState(false);

	// This is the function we wrote earlier
	async function copyTextToClipboard(text) {
		if ("clipboard" in navigator) {
			return await navigator.clipboard.writeText(text);
		} else {
			return document.execCommand("copy", true, text);
		}
	}

	// onClick handler function for the copy button
	const handleCopyClick = () => {
		// Asynchronously call copyTextToClipboard
		copyTextToClipboard(copyText)
			.then(() => {
				// If successful, update the isCopied state value
				setIsCopied(true);
				setTimeout(() => {
					setIsCopied(false);
				}, 1500);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div>
			<Button
				variant="contained"
				size="small"
				endIcon={<Icon>copy_all</Icon>}
				onClick={handleCopyClick}
			>
				{isCopied ? "Copiado!" : "Copiar"}
			</Button>
		</div>
	);
}

export function ClipboardCopyIcon({ copyText }) {
	const [isCopied, setIsCopied] = useState(false);

	// This is the function we wrote earlier
	async function copyTextToClipboard(text) {
		if ("clipboard" in navigator) {
			return await navigator.clipboard.writeText(text);
		} else {
			return document.execCommand("copy", true, text);
		}
	}

	// onClick handler function for the copy button
	const handleCopyClick = () => {
		// Asynchronously call copyTextToClipboard
		copyTextToClipboard(copyText)
			.then(() => {
				// If successful, update the isCopied state value
				setIsCopied(true);
				setTimeout(() => {
					setIsCopied(false);
				}, 1500);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div>
			<Tooltip title="Copiar Celular" placement="top-start">
			<IconButton
				variant="contained"
				size="small"
				onClick={handleCopyClick}
			>
				{isCopied ? <Icon color="success">copy_all</Icon> : <Icon color="primary">copy_all</Icon>}
			</IconButton>
			</Tooltip>
		</div>
	);
}

export const numberWithCommas = (x) => {
	if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	else return "-";
};
export const generateRandomString = (num) => {
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let result1 = " ";
	const charactersLength = characters.length;
	for (let i = 0; i < num; i++) {
		result1 += characters.charAt(Math.floor(Math.random() * charactersLength));
	}

	return result1;
};

export const setVars = (id, Value) => {
	var ciphertext = CryptoJS.AES.encrypt(
		JSON.stringify(Value),
		REACT_APP_C27_KEY
	).toString();

	sessionStorage.setItem(id, ciphertext);

	var bytes = CryptoJS.AES.decrypt(ciphertext, REACT_APP_C27_KEY);
	var originalText = bytes.toString(CryptoJS.enc.Utf8);

	return originalText;
};

export const getVars = (id, defaultvar = {}) => {
	const data = sessionStorage.getItem(id);

	let originalText = null;
	if (data !== null) {
		var bytes = CryptoJS.AES.decrypt(data, REACT_APP_C27_KEY);
		originalText = bytes.toString(CryptoJS.enc.Utf8);
	}

	return originalText !== null ? JSON.parse(originalText) : defaultvar;
};

export const deleteVars = async (keep = []) => {
  const keysToKeep = ["Token", "sideBar", ...keep];

  // Itera a través del sessionStorage
  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);
    if (!keysToKeep.includes(key)) sessionStorage.removeItem(key);
  }

  // Es recomendable hacerlo de forma inversa.
  for (let i = sessionStorage.length - 1; i >= 0; i--) {
    const key = sessionStorage.key(i);
    if (!keysToKeep.includes(key)) sessionStorage.removeItem(key);
  }
};

const substitutionsAfterEncryption = new Map([
	["+", "-"],
	["/", "_"],
	["=", "~"],
]);
const substitutionsBeforeDecryption = new Map([
	["-", "+"],
	["_", "/"],
	["~", "="],
]);

export const encryptVars = (text) => {
	const encrypted = CryptoJS.AES.encrypt(
		JSON.stringify(text),
		REACT_APP_C27_KEY
	).toString();

	return encrypted.replace(
		/[+/=]/g,
		(match) => substitutionsAfterEncryption.get(match) ?? match
	);
};

export const decryptVars = (text, defaultvar) => {
	let decrypted = null;

	const toDecrypt = text.replace(
		/[-_~]/g,
		(match) => substitutionsBeforeDecryption.get(match) ?? match
	);

	if (toDecrypt !== null) {
		const bytes = CryptoJS.AES.decrypt(toDecrypt, REACT_APP_C27_KEY);
		decrypted = bytes.toString(CryptoJS.enc.Utf8);
	}

	return decrypted !== null ? JSON.parse(decrypted) : defaultvar;
};

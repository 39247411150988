import React, { useState } from "react";
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import CustomTable from "@components/Tables/CustomTable";

// Material UI
import {
  Icon,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@mui/material";

import AdaptiveCard from "@components/Card/AdaptiveCard";
import LastUpdatedCard from "@components/Activation/DashboardElectoral/LastUpdatedCard";
import BarColumnTriplePercent from "@components/Charts/BarColumnTriplePercent";
import DrawerRelative from "@components/Containers/DrawerRelative";
import FloatingButton from "@components/Button/FloatingButton";
import BasicLine from "@components/Charts/BasicLine";
import { useNavigate } from "react-router-dom";

const DashbordMovilizacion = () => {
  const navigate = useNavigate();
  const [bgColor, setBgColor] = useState(null);
  const [disableCardType, setDisableCardType] = useState(false);
  const [disableStriped, setDisableStriped] = useState(false);
  const [disableHover, setDisableHover] = useState(false);
  const [sticky, setSticky] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [nullData, setNullData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [orderBy, setOrderBy] = useState("");
  const [openDrawer, setOpenDrawer] = useState(true);
  const [loadingCards, setLoadingCards] = useState();
  const [bodyChart, setBodyChart] = useState({
    filtered: [],
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [],
  });
  const [dataChart, setDataChart] = useState({
    Fecha: "",
    Movilizacion: [],
  });
  const [databar, setDataBar] = useState({
    categories: ["R1", "R2", "R3", "R4", "R5", "R6", "R7"],
    series: [
      {
        name: "Meta",
        data: [50, 50, 50, 50, 50, 50, 50],
      },
      {
        name: "Avance",
        data: [5, 0, 18, 3, 7, 2, 6],
      },
    ],
    title: "AVANCE MOVILIZACIÓN POR ",
  });
  const [dataProgress, setDataProgress] = useState({
    title: "Avance acumulativo POR ",
    categories: [
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
    ],
    series: [
      {
        name: "R1",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "R2",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "R3",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "R4",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "R5",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "R6",
        data: [0, 146, 1242, 2352, 3435, 4308, 5041, 5054, 5054, 5054, 5054],
      },
      {
        name: "R7",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
  });

  const columnsPercent = [
    { id: "Region", label: "Región" },
    { id: "Municipio", label: "Municipio" },
    { id: "Compromisos", label: "Compromisos" },
    { id: "Movilizados", label: "Compromisos Movilizados" },
    { id: "row2", label: "Circular", type: "circular" },
    { id: "row3", label: "Linear", type: "linear", fixed: 3 },
  ];

  const rowsPercent = [
    { row1: 10, row2: 20, row3: 30 },
    { row1: 40, row2: 50, row3: 60 },
    { row1: 70, row2: 80, row3: 90 },
    { row1: 100, row2: 10, row3: 20 },
    { row1: 30, row2: 40, row3: 50 },
    { row1: 60, row2: 70, row3: 80 },
    { row1: 90, row2: 100, row3: 70 },
    { row1: 50, row2: 30, row3: 10 },
  ];

  const handleSticky = () => setSticky(!sticky);
  const handleIsLoading = () => setIsLoading(!isLoading);
  const handleNullData = () => setNullData(!nullData);

  const handleRefresh = () => {
    setData((prev) => ({ ...prev, page: 0, pageSize: 10, sorted: [] }));
    setDataChart((prev) => ({ ...prev }));
    setRefresh(true);
  };

  return (
    <AdminLayout>
      <ActionHeader title={"Dashboard Bingo Electoral"} isCustom={true}>
        <Button
          onClick={() => setOpenDrawer(!openDrawer)}
          aria-label="open Drawer"
          variant="contained"
          color="secondary"
          endIcon={
            openDrawer ?  <Icon>filter_list_off</Icon> : <Icon>filter_list</Icon> 
          }
        >
          <Typography sx={{ fontWeight: 500 }}>Filtros</Typography>
        </Button>
      </ActionHeader>

      <Grid
        container
        spacing={openDrawer ? 2 : 0}
        sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
      >
        <Grid item xs={12} md={openDrawer ? 8 : 12}>
          <Grid container spacing={2}>
            {/* <Grid
              item
              xs={12}
              md={openDrawer ? 12 : 6}
              lg={6}
              sx={{ paddingBottom: "20px" }}
            >
              <LastUpdatedCard
                dateHour={dataChart.Fecha || ""}
                handleClick={handleRefresh}
                refresh={refresh}
                columnIn={openDrawer ? "lg" : "md"}
              />
            </Grid> */}
            <Grid item xs={12} sm={6} md={openDrawer ? 6 : 4} lg={6}>
              <AdaptiveCard
                title="Avance de Movilización"
                icon="description"
                config={{
                  percent:
                    parseFloat(dataChart.Movilizacion?.PorcentajeAvance) || 0,
                }}
                leftConfig={{
                  title: "Meta",
                  data: parseFloat(dataChart.Movilizacion?.Total) || 0,
                }}
                rightConfig={{
                  title: "Avance",
                  data: parseFloat(dataChart.Movilizacion?.Avance) || 0,
                }}
                fixed
                loading={loadingCards}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card className="card-primary">
              <CardContent key={`Mobilization_SOCIAL_bars`}>
                <BarColumnTriplePercent
                  series={databar.series}
                  categories={databar.categories}
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card className="card-primary">
              <CardContent key={`Mobilization_SOCIAL_process`}>
                <BasicLine
                  title={{
                    primary: "",
                  }}
                  legend={{
                    layout: "horizontal",
                    align: "center",
                    verticalAlign: "bottom",
                  }}
                  series={dataProgress.series}
                  categories={dataProgress.categories}
                  initialPosition
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <CustomTable
              rows={nullData ? [] : rowsPercent}
              columns={columnsPercent}
              orderBy={orderBy}
              stickyHeader={sticky}
              maxHeight={200}
              backgroundColor={bgColor}
              disableCardType={disableCardType}
              disableHover={disableHover}
              disableStriped={disableStriped}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <DrawerRelative
            anchor="right"
            open={openDrawer}
            setOpen={setOpenDrawer}
            title={"Filtrar"}
            icon="person_search"
            titleComponentPlace="under"
            className="card-primary"
            sxPaper={{ borderRadius: 3 }}
            isSticky
          >
            {/*  <Filter
                onSearch={handleSearchFilter}
                onClear={handleClearFilter}
                changeTypeView={changeTypeViewFilter}
                refresh={refresh}
                setLoading={setLoading}
                catView={catView}
                setIdFilter={setIdFilter}
                ambitoUser={ambitoUser}
              /> */}
          </DrawerRelative>
        </Grid>
        {!openDrawer && (
          <FloatingButton
            onClick={() => setOpenDrawer(true)}
            label="Mostrar Filtros"
            sx={{ display: { xs: "none", md: "block" } }}
            icon={"filter_list"}
            color="#B159E3"
          />
        )}
      </Grid>
    </AdminLayout>
  );
};

export default DashbordMovilizacion;

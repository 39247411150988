import { useEffect, useState, useCallback } from "react";
import Swal from "sweetalert2";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import Filter from "@components/MapsModules/AdvanceSocial/Filter";
import Colors from "@components/Maps/InternalComponents/Cards/Colors";
import TableInfo from "@components/Maps/InternalComponents/Cards/TableInfo";
import CardInfoMini from "@components/Maps/InternalComponents/Cards/CardInfoMini";
import Map from "@components/Maps/CustomComponents/GenericMap";

// Servicios y utilidades
import AdvanceSocialServices from "@services/MapServices/AdvanceSocialServices";
import CatalogService from "@services/CatalogServices";
import { setVars } from "@utils/global";

const commonElements = [
  { title: "Estructura: ", name: "Alias", type: "text" },
  { title: "Meta:", name: "Meta", type: "int" },
  { title: "Avance:", name: "Avance", type: "int" },
  { title: "Av Esp:", name: "AvanceEsperado", type: "int" },
  { title: "% Avance:", name: "PorcentajeAvance", type: "percent" },
  { title: "% Av Esp:", name: "PorcentajeAvanceEsperado", type: "percent" },
];

const elements = {
  municipio: [{ title: "Municipio: ", name: "municipio", type: "text" }, ...commonElements],
  region: [{ title: "Región: ", name: "Region", type: "text" }, ...commonElements],
};

const infoGeneralElements = [
  { title: "Meta:", name: "MetaCompromisos", color: "#000", type: "text" },
  { title: "Av Esp:", name: "AvanceEsperado", color: "#000", type: "text" },
  { title: "Av Comp:", name: "AvanceCompromisos", color: "#000", type: "text" },
  { title: "Av Comp Únicos:", name: "AvanceCompromisosUnicos", color: "#000", type: "text" },
  { title: "% Av Comp Únicos:", name: "PorcentajeAvanceCompromisosUnicos", color: "#000", type: "percent" },
  { title: "Total Man. P1:", name: "TotalP1", color: "#000", type: "text" },
  { title: "Total Man. P2:", name: "TotalP2", color: "#000", type: "text" },
  { title: "Total Man. P3:", name: "TotalP3", color: "#000", type: "text" },
];

const colorPercent = [
  { title: "0-25%", color: "#FF5733", fontColor: "#FFFFFF" },
  { title: "25-50%", color: "#FFC300" },
  { title: "50-75%", color: "#FFFF00" },
  { title: "+> 100%", color: "#28B463" },
];

const Maps = () => {
  const [polygons, setPolygons] = useState({ type: "FeatureCollection", features: [] });
  /* const [ polygonsBlocks, setPolygonsBlocks] = useState(null); */

  const [table, setTable] = useState(null);

  const [isLoadingCatalogs, setIsLoadingCatalogs] = useState(true);
  const [catalogs, setCatalogs] = useState({});

  const [valuesInfoGeneral] = useState(null);
  const [shpInfoGeneral, setShpInfoGeneral] = useState("Region");

  const [currentFeature, setCurrentFeature] = useState(null);

  const handleFilterExect = (_filter) => {
    const filter_estructura = _filter.filter((item) => item.id === "mms.idEstructuraSocial");
    let _filterModified = _filter.map((item) => {
      const regex = /cat_secciones/gi;
      let itemModified = item;
      itemModified.id = item.id.replace(regex, "cm");
      return itemModified;
    });

    let filter_saved = _filterModified;
    const lastElement = _filterModified.slice(-1);
    _filterModified = lastElement;
    let shp = "region";
    setShpInfoGeneral("Region");
    switch (_filterModified[0].id) {
      case "cm.id":
        shp = "municipio";
        setShpInfoGeneral("Municipio");
        break;
      case "cm.idRegion":
      default:
        shp = "region";
        setShpInfoGeneral("Region");
    }
    if (lastElement[0].value === 0) {
      _filterModified = filter_saved.filter((item) => item.value !== lastElement[0].value);
    }

    const params = {
      color: 1,
      page: 0,
      pageSize: 10,
      filtered: filter_estructura.concat(_filterModified),
      shp,
    };

    setCurrentFeature(null);
    fetchMapData(params, AdvanceSocialServices.getShapes, setPolygons, setTable);
  };

  const loadCatalogs = useCallback(async () => {
    const params = [
      { id: "mis_segmentos", getAll: false, filtered: [{ id: "cat_social.Nivel", filter: "=", value: 1 }] },
      { id: "regiones_mujeres", getAll: false },
      { id: "municipios_mujeres", getAll: false },
    ];

    setIsLoadingCatalogs(true);
    try {
      const result = await CatalogService.getCatalogs(params);
      const { results, response, message } = result;
      if (results) {
        if (response.errors.length > 0) {
          Swal.fire({
            title: "Algunos catálogos NO pudieron ser cargados. Contacte al administrador",
            icon: "warning",
          });
        }

        const catalogsRes = response.catalogs;
        setCatalogs({
          municipios: catalogsRes.municipios_mujeres
            ? [{ value: 0, label: "TODOS" }].concat(catalogsRes.municipios_mujeres)
            : [],
          regiones: catalogsRes.regiones_mujeres
            ? [{ value: 0, label: "TODAS" }].concat(catalogsRes.regiones_mujeres)
            : [],
          estructuras: catalogsRes?.mis_segmentos,
        });
      } else Swal.fire({ title: message, icon: "warning" });
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setIsLoadingCatalogs(false);
    }
  }, []);

  useEffect(() => {
    setVars("filterSavedMap", {
      idEstructuraSocial: "",
      idRegion: "",
      idMunicipio: "",
      idPoligono: "",
      Seccion: "",
    });
    loadCatalogs();
    return () => loadCatalogs();
    // eslint-disable-next-line
  }, []);

  const handleClickFeature = (e, params) => {
    if (params.sameFeature) setCurrentFeature(null); // Se resetea al clickear el mismo feature
    else setCurrentFeature(e.target.feature.properties);
  };

  const FilterComponent = (
    <>
      <Filter
        catalogs={catalogs}
        loadingCatalogs={isLoadingCatalogs}
        handleFilter={handleFilterExect}
        region={1}
        municipio={1}
        width={"100%"}
      />
      {currentFeature && (
        <CardInfoMini
          properties={currentFeature}
          elements={elements[polygons?.shp ?? "region"]}
          title={"INFORMACIÓN"}
          toolTip
        />
      )}
      <TableInfo properties={table} elements={elements[polygons?.shp ?? "region"]} />
    </>
  );

  /* const handleViewDetail = (e) => {
    const params_blocks = {
      shp: "seccion",
      page: 0,
      pageSize: 10,
      filtered: [{ id: "cat_secciones.Seccion", filter: "=", value: e.Seccion }],
    };
    fetchMapData(params_blocks, AdvanceSocialServices.getBlocks, setPolygonsBlocks);

    const params_secc = {
      color: 0,
      page: 0,
      pageSize: 10,
      filtered: [{ id: "cat_secciones.Seccion", filter: "=", value: e.Seccion }],
    };
    fetchMapData(params_secc, AdvanceSocialServices.getSections, setPolygons);
  }; */

  const fetchMapData = async (params, API, setState, setTable) => {
    setState(null);
    if (setTable) setTable(null);
    try {
      const result = await API(params);
      const { results, response, message } = result;
      if (results) {
        setState(response.data);
        if (setTable) setTable(response.table);
      } else Swal.fire({ title: message, icon: "warning" });
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    }
  };

  const cardComponent = {
    component: <Colors elements={colorPercent} height={20} spacing={0.5} />,
    title: "Colores",
  };

  return (
    <AdminLayout delPadding>
      <Map
        data={polygons}
        drawerLeft={{ open: true, component: FilterComponent, width: { sm: 400, md: 450, lg: 500 } }}
        cardComponent={cardComponent}
        cardInfo={{
          initialValues: { title: "INFORMACIÓN", elements: elements[polygons?.shp ?? "region"] },
          // button: { handleClick: handleViewDetail },
        }}
        infoGeneral={{
          values: valuesInfoGeneral,
          initialValues: {
            title: "RESUMEN",
            elements: [
              { title: "center", name: shpInfoGeneral, color: "#000", type: "text" },
              ...infoGeneralElements,
            ],
          },
        }}
        handleClickFeature={handleClickFeature}
      />
    </AdminLayout>
  );
};

export default Maps;

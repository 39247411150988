import { useState } from "react";

import { Button, Container, Icon, Typography, Box, Stack } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  KeyboardDoubleArrowRight,
  KeyboardDoubleArrowLeft,
} from "@mui/icons-material";

import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import Filter from "@components/Activation/Capture/Simulacro/Filter";
import ListMobilization from "@components/Activation/Capture/Simulacro/ListMobilization";
import DrawerRelative from "@components/Containers/DrawerRelative";
import Download from "@components/Downloads/Download";
import CustomTabs from "@components/Tabs/CustomTabs";
import ListReported from "@components/Activation/Capture/Simulacro/ListReported";

import mobilization from "@services/MovilizationServices";
import Swal from "sweetalert2";
import middleware from "@middlewares/middleware";

const MovilizationCapture = () => {
  const baseData = {
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [],
  };

  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [dataMovilization, setDataMovilization] = useState([]);
  const [users, setUsers] = useState([]);
  const [paramsFilter, setParamsFilter] = useState({
    seccion: "",
    hora: "",
  });
  const [dataFound, setDataFound] = useState(true);
  const [selectTab, setSelectTab] = useState(
    middleware.checkMenuAction("Agregar") ? 0 : 1
  );
  //const [selectTab, setSelectTab] = useState(0);
  //const [dataNotReports, setDataNotReports] = useState(baseData);
  const [dataReports, setDataReports] = useState({
    ...baseData,
    idAmbitoAgrupador: 8,
  });
  const [openDrawer, setOpenDrawer] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [isDownload, setIsDownloading] = useState(false);

  const onChangeFilter = async (params) => {
    handleSearch(params);
    if (selectTab === 0) {
      handleGetDetailMobilizedByHour(params);
    }
  };
  const handleGetDetailMobilizedByHour = async (params) => {
    const { seccion } = params;
    try {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);

      const { results, response, message, success } =
        await mobilization.getMobilizersList({
          filtered: [
            {
              id: "Seccion",
              filter: "=",
              value: seccion,
            },
          ],
        });

      if (!results && !success) throw new Error(message);

      if (results && success) {
        const transFormedData = response.data.map((item) => ({
          ...item,
          NombreCompleto: item.NombreCompleto.toUpperCase(),
        }));

        setDataMovilization(transFormedData);
        //setDataFound(response.data.length > 0);
      } else {
        setDataMovilization([]);
        setDataFound(results);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  };

  const handleSearch = (filterValue) => {
    const filters = [
      {
        id: "idMunicipioReportes",
        key: "municipio",
      },
      {
        id: "idPoligono",
        key: "poligono",
      },
      {
        id: "Seccion",
        key: "seccion",
      },
    ];

    const filtered = filters
      .filter((filter) => filterValue[filter.key] > 0)
      .map((filter) => ({
        id: filter.id,
        filter: "=",
        value: filterValue[filter.key],
      }));

    setDataReports((prevState) => ({ ...prevState, filtered }));
    //setDataNotReports((prevState) => ({ ...prevState, filtered }));
    /* const filters = [
      { id: "idMunicipioReportes", key: "municipio" },
      { id: "idPoligono", key: "poligono" },
      { id: "Seccion", key: "seccion" },
    ];

    const filteredFirst = filters
      .filter((filter) => filterValue[filter.key] > 0)
      .map((filter) => {
        const { id, key } = filter;
        return { id: id, filter: "=", value: filterValue[key] };
      });

    if (selectTab === 1) {
      const { hora, MovilizacionReportada } = filterValue;

      filters.push(
        { id: "TienePaseLista", key: "TienePaseLista" },
        {
          id: "MovilizacionReportada",
          key: "MovilizacionReportada",
          filter: MovilizacionReportada === "SI" ? ">" : "=",
          value: 0,
        }
      );

      if (hora && MovilizacionReportada !== 0) {
        filters.push({
          id: hora,
          key: "hora",
          filter: MovilizacionReportada === "SI" ? "IS NOT" : "IS",
          value: null,
        });
      }
    }

    const filtered = filters
      .filter(
        (filter) =>
          filterValue[filter.key] > 0 || filterValue[filter.key].length
      )
      .map((val) => {
        const { id, key, filter, value } = val;
        const valueL = filterValue[key];

        return {
          id: id,
          filter: filter ?? "=",
          value: value !== undefined ? value : valueL,
        };
      });

    setDataReports((prev) => ({ ...prev, filtered }));
    setDataNotReports((prev) => ({ ...prev, filtered: filteredFirst })); */
  };

  const onClearFilter = () => {
    setDataMovilization([]);
    setDataFound(true);
    // setDataNotReports(baseData);
    setDataReports({ ...baseData, idAmbitoAgrupador: 8 });
  };

  const setMovilization = async ({ users }) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);

    try {
      const res = await mobilization.saveMobilizedSimulacrum({
        Seccion: paramsFilter.seccion,
        // Hora: paramsFilter.hora,
        Usuarios: users,
      });
      const { results, message } = res;
      if (results) {
        Swal.fire({
          title: message,
          icon: "success",
        });
        onChangeFilter(paramsFilter);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
      setRefresh(true);
    }
  };

  const handleChangeTab = (e, newValue) => {
    setSelectTab(newValue);
  };

  const handleExport = async () => {
    try {
      setIsDownloading(true);
      const result = await mobilization.exportDocumentsSimulacro({
        params: {},
      });
      const { results, message } = result;

      if (!results) throw new Error(message);
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <AdminLayout>
      <LoadingForm
        loadinMessage={"Cargando Datos..."}
        isLoading={isLoadigForm}
        success={isSuccessForm}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
      />
      <Container maxWidth={false}>
        <ActionHeader title={"Captura de movilización"} isCustom={true}>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            {middleware.checkMenuAction("Exportar") && (
              <Button
                variant="outlined"
                color="success"
                size="small"
                startIcon={<Icon>file_download</Icon>}
                onClick={handleExport}
              >
                Expotar resumen
              </Button>
            )}
            <Button
              onClick={() => setOpenDrawer(!openDrawer)}
              size="small"
              aria-label="open Drawer"
              endIcon={
                openDrawer ? (
                  <KeyboardDoubleArrowLeft />
                ) : (
                  <KeyboardDoubleArrowRight />
                )
              }
            >
              <Typography sx={{ fontWeight: 500 }}>FILTROS</Typography>
            </Button>
          </Stack>
        </ActionHeader>
        {isDownload && (
          <Box marginBottom={2} marginTop={2}>
            <Download format={"xlsx"} isDownload={isDownload} />
          </Box>
        )}
        <Grid2
          container
          spacing={openDrawer ? 2 : 0}
          sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
        >
          <Grid2 xs={12} md={3} lg={3}>
            <DrawerRelative
              anchor="left"
              open={openDrawer}
              setOpen={setOpenDrawer}
              title={"Filtros"}
              icon="filter_list"
              titleComponentPlace="under"
              className="card-primary"
              sxPaper={{ borderRadius: 3 }}
              isSticky
            >
              <Filter
                tab={selectTab}
                setParamsFilter={setParamsFilter}
                onChange={onChangeFilter}
                onClear={onClearFilter}
              />
            </DrawerRelative>
          </Grid2>
          <Grid2 xs={12} md={openDrawer ? 9 : 12}>
            <CustomTabs
              value={selectTab}
              setValue={setSelectTab}
              useNumbers
              tabs={[
                {
                  label: "CAPTURA DE MOVILIZACION",
                  value: 0,
                  display: middleware.checkMenuAction("Agregar"),
                  component: (
                    <ListMobilization
                      data={dataMovilization}
                      users={users}
                      paramsFilter={paramsFilter}
                      dataFound={dataFound}
                      setUsers={setUsers}
                      setMovilization={setMovilization}
                      getData={onChangeFilter}
                    />
                  ),
                },
                {
                  label: "REPORTE",
                  icon: "how_to_reg",
                  value: 1,
                  component: <ListReported dataParams={dataReports} />,
                },
              ]}
            />
          </Grid2>
        </Grid2>
      </Container>
    </AdminLayout>
  );
};

export default MovilizationCapture;

import React, { useState } from "react";

import {
  Box,
  TextField,
  Chip,
  Icon,
  FormHelperText,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Grid2 from '@mui/material/Unstable_Grid2';
import {
  isEmptyOrInvalidString,
  isTypePhone,
} from "@utils/validations";

const UserForm = (props) => {
  const {
    errors,
    touched,
    values,
    handleChange
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordR, setShowPasswordR] = useState(false);

  const hadleUsuario = (value) => {
    handleChange({
      target: {
        name: "Celular",
        value: isTypePhone(value)
          ? value
          : values.Celular,
      },
    })
    
    handleChange({
      target: {
        name: "PIN",
        value: isTypePhone(value.substring(0,5))
          ? value.substring(0,5)
          : values.PIN,
      },
    })
  };

  return (
    <Box sx={{ mt: 1, mb: 2 }}>
      <Grid2 xs={12} sm={12} md={12} lg={12}>
        <Chip icon={<Icon>person</Icon>} label="Datos de Usuario" />
      </Grid2>
      <Grid2 container spacing={3} sx={{ mt: 2 }}>
      <Grid2 xs={12} sm={12} md={4} lg={4}>
            <TextField
              error={
                !isEmptyOrInvalidString(errors.Celular)
              }
              helperText={
                errors.Celular &&
                errors.Celular
              }
              sx={{ width: "100%" }}
              required
              label="Usuario"
              type="text"
              name="Celular"
              onChange={(e) =>
                hadleUsuario(e.target.value)
              }
              onBlur={(e) =>
                hadleUsuario(e.target.value)
              }
              value={values.Celular}
              variant="outlined"
              size="small"
              className="fixed-input"
              placeholder="Celular"
            />
          </Grid2>
          <Grid2 xs={12} sm={12} md={3} lg={3}>
          <TextField
            error={!isEmptyOrInvalidString(errors.Password)}
            helperText={errors.Password && errors.Password}
            sx={{ width: "100%" }}
            required
            label="Contraseña"
            name="Password"
            onChange={(e) =>
              handleChange({
                target: {
                  name: "Password",
                  value: e.target.value,
                },
              })
            }
            onBlur={(e) =>
              handleChange({
                target: {
                  name: "Password",
                  value: e.target.value.trim(),
                },
              })
            }
            value={values.Password}
            variant="outlined"
            size="small"
            className="fixed-input"
            type={showPasswordR ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => {
                      setShowPasswordR(!showPasswordR);
                    }}
                  >
                    {showPasswordR ? (
                      <Icon>visibility_off</Icon>
                    ) : (
                      <Icon>visibility</Icon>
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormHelperText sx={{ textAlign: "end" }}>
            La contraseña debe contener mínimo 8 carácteres, una
            letra mayúscula, número y símbolo especial (* _ . @)
          </FormHelperText>
        </Grid2>
        <Grid2 xs={12} sm={12} md={3} lg={3}>
          <TextField
            error={!isEmptyOrInvalidString(errors.Confirm)}
            helperText={errors.Confirm && errors.Confirm}
            sx={{ width: "100%" }}
            required
            label="Repetir Contraseña"
            name="Confirm"
            onChange={(e) =>
              handleChange({
                target: {
                  name: "Confirm",
                  value: e.target.value,
                },
              })
            }
            onBlur={(e) =>
              handleChange({
                target: {
                  name: "Confirm",
                  value: e.target.value.trim(),
                },
              })
            }
            value={values.Confirm}
            variant="outlined"
            size="small"
            className="fixed-input"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    {showPassword ? (
                      <Icon>visibility_off</Icon>
                    ) : (
                      <Icon>visibility</Icon>
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid2>
        <Grid2 xs={6} sm={12} md={2} lg={2}>
          <TextField
            error={!isEmptyOrInvalidString(errors.PIN)}
            helperText={errors.PIN && errors.PIN}
            sx={{ width: "100%" }}
            required
            label="PIN"
            //type="text"
            type="tel"
            name="PIN"
            onChange={(e) =>
              handleChange({
                target: {
                  name: "PIN",
                  value: isTypePhone(e.target.value)
                    ? e.target.value
                    : values.PIN,
                },
              })
            }
            onBlur={(e) =>
              handleChange({
                target: {
                  name: "PIN",
                  value: isTypePhone(e.target.value)
                    ? e.target.value
                    : values.PIN,
                },
              })
            }
            value={values.PIN}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 5,
            }}
            className="fixed-input"
          />
          <FormHelperText sx={{ textAlign: "end" }}>
            PIN Para tener acceso a la app sin internet
          </FormHelperText>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default UserForm;

import { Button, Card, Container, Grid, Icon, Typography, Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import PropTypes from "prop-types";

import { EventosFilter } from "@data/interfaces/EventosInterfaces";
import { EventosFilterSchema } from "@data/schemas/EventosSchemas";

const PaseListaFilter = ({ onChangeFilter }) => {
  const formik = useFormik({
    initialValues: EventosFilter,
    validationSchema: EventosFilterSchema,
    onSubmit: (values) => handleFilter(values),
  });

  const handleFilter = ({ actividad }) => {
    let filtered = [];

    if (formik.values.actividad !== "") {
      filtered.push({ id: ["eventos.Actividad"], filter: "LIKE", value: actividad });
    }

    onChangeFilter(filtered);
  };

  const handleOnKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      formik.submitForm();
    }
  };

  const handleClearFilter = () => {
    formik.setValues({ ...formik, actividad: "" });
    onChangeFilter([]);
  };

  return (
    <Card className="card-primary">
      <Container maxWidth={false} sx={{ p: 2 }}>
        <Stack direction="row" spacing={1} marginBottom={2} alignItems="center">
          <Icon>tune</Icon>
          <Typography variant="subtitle1" fontWeight={600}>
            Filtros
          </Typography>
        </Stack>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={7} md={6} lg={6}>
            <TextField
              sx={{ width: "100%" }}
              label="Actividad"
              type="text"
              name="actividad"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onKeyPress={handleOnKeyPress}
              value={formik.values.actividad.toUpperCase()}
              variant="outlined"
              size="small"
              className="fixed-input"
            />
          </Grid>

          <Grid item xs={12} sm={5} md={6} lg={6} sx={{ display: "flex", gap: 1 }}>
            <Button variant="contained" color="primaryDark" onClick={formik.submitForm}>
              Filtrar
            </Button>
            <Button variant="text" color="primaryDark" onClick={handleClearFilter} sx={{ ml: 1 }}>
              Limpiar
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Card>
  );
};

PaseListaFilter.propTypes = {
  onChangeFilter: PropTypes.func.isRequired,
};

export default PaseListaFilter;

import Yup from "@utils/Yupi18n";

export const seccionSchema = Yup.number().required().integer().typeError();

export const bingoSchema = Yup.number()
  .integer("Ingrese un número entero")
  .min(1)
  .max(1000, "El número debe ser menor o igual a 1000");

export const casillaSchema = Yup.number().required();

export const handleKeyPress = (event) => {
  const charCode = event.which ? event.which : event.keyCode;
  if (charCode < 48 || charCode > 57) {
    event.preventDefault();
  }
};

export const handleKeyPress2 = (event) => {
  const inputValue = event.key;
  const regex = /^[a-zA-ZáéíóúüÁÉÍÓÚÜñÑ\s]*$/; // Expresión regular para permitir letras con acentos, la letra "ñ" y espacios

  if (!regex.test(inputValue)) {
    event.preventDefault();
  }
};

export const AddBingoSchema = Yup.object().shape({
  Seccion: Yup.number().required().integer().typeError().label("Sección"),
  idCasilla: Yup.number().required().integer().typeError().label("Casilla"),
  FolioBingo: Yup.number()
    .required()
    .integer("Ingrese un número entero")
    .min(1)
    .max(1000, "El número debe ser menor o igual a 1000"),
  OrigenCaptura: Yup.string().required(),
});

export const AddMovilizacionName = Yup.object().shape({
  Seccion: Yup.number().required().integer().typeError().label("Sección"),
  idCasilla: Yup.number().optional().integer().typeError().label("Casilla"),
  Nombre: Yup.string().optional().min(4).max(100).label("Nombre"),
  RangoInicial: Yup.string()
    .required()
    .matches(/^[a-zA-Z]$/, "El rango inicial debe ser una única letra")
    .label("Rango inicial"),
  RangoFinal: Yup.string()
    .required()
    .matches(/^[a-zA-Z]$/, "El rango final debe ser una única letra")
    .test(
      "is-greater",
      "El rango final debe ser mayor que el rango inicial",
      function (value) {
        const { RangoInicial } = this.parent;
        return value > RangoInicial;
      }
    )
    .label("Rango final"),
});

export const MovilizacionFilter = Yup.object().shape({
  Seccion: Yup.number().required().integer().typeError().label("Sección"),
  idCasilla: Yup.number().optional().integer().typeError().label("Casilla"),
  OrigenCaptura: Yup.string().required(),
});

export const HourMovilizationSchema = Yup.object().shape({
  Seccion: Yup.number().required().integer().label("Sección"),
  Hora: Yup.string().required(),
});

export const BingoFolioSchema = Yup.object().shape({
  Seccion: Yup.number().required().integer().label("Sección"),
  idCasilla: Yup.number().required().integer().label("Casilla"),
  Municipio: Yup.number().optional().integer().label("Municipio"),
  Poligono: Yup.number().optional().integer().label("Polígono"),
  idEstatus: Yup.number().required().oneOf([1, 2]).integer().label("Estatus"),
  RangoInicial: Yup.string()
    .when("idEstatus", {
      is: 1,
      then: Yup.string()
        .required()
        .matches(/^[a-zA-Z]$/, "El rango inicial debe ser una única letra"),
      otherwise: Yup.string()
        .optional()
        .nullable(true)
        .matches(/^[a-zA-Z]$/, "El rango inicial debe ser una única letra"),
    })
    .label("Rango inicial"),
  RangoFinal: Yup.string()
    .when("idEstatus", {
      is: 1,
      then: Yup.string()
        .required()
        .matches(/^[a-zA-Z]$/, "El rango final debe ser una única letra")
        .test(
          "is-greater",
          "El rango final debe ser mayor o igual que el rango inicial",
          function (value) {
            const { RangoInicial } = this.parent;
            return value >= RangoInicial;
          }
        ),
      otherwise: Yup.string()
        .optional()
        .nullable(true)
        .matches(/^[a-zA-Z]$/, "El rango final debe ser una única letra"),
    })
    .label("Rango final"),
});

export const FolioSaveValidate = Yup.object().shape({
  idCasilla: Yup.number().required().integer().label("Casilla"),
});

export const UpdateInfo = Yup.object().shape({
  Seccion: Yup.number().required().integer().label("Sección"),
});

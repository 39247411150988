import { useEffect, useState } from "react";
import { useCatalogs } from "@hooks/useCatalogs";
// Material UI
import { Card, CardContent, Container } from "@mui/material";
import Swal from "sweetalert2";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import FormTable from "@components/Metas/FormTable";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import ElectoralTable from "@components/Metas/ElectoralTable";

import CatalogSije from "@services/NumeraliaServices";
import middleware from "@middlewares/middleware";

const EstructuraElectoral = (props) => {
  const catalogsOptions = [
    { id: 'municipios_dl', getAll: false },
    { id: "perfil", filtered: [{ id: "idTipo", filter: "=", value: 2 }] },
    { id: 'df', getAll: false },
  ];
  const { catalogs, load } = useCatalogs({
    catalogsOptions,
    putDefaultOption: false,
  });

  const [_catalogs, setCatalogs] = useState({
    municipios_dl: [],
    perfil: [],
    df: [],
    municipios_df: []
  });

  const [loading, setLoading] = useState({
    isLoading: false,
    isOpen: false,
    isSuccess: false,
    loadingMessage: "",
    successMessage: "",
  });
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const fetchApi = async () => {
      await loadCatalogs();
    }

    fetchApi();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!load) {
      setCatalogs((prevState) => ({ ...prevState, ...catalogs }));
    }
    //eslint-disable-next-line
  }, [load])

  const loadCatalogs = async () => {
    const catalogsOptions = {
      catalogs: [{ id: "municipios_df" }],
    };

    try {
      const result = await CatalogSije.getCatalogsSije(catalogsOptions);
      if (result.results && result.response.catalogs.municipios_df) {
        setCatalogs((prevState) => ({
          ...prevState,
          municipios_df: result.response.catalogs.municipios_df.map(
            (item) => ({
              value: item.idDF,
              label: item.DistritoFederal,
              idMR: item.idMunicipioReportes,
            })
          )
        }));
      }
    } catch (errors) {
      Swal.fire({
        title: errors.message,
        icon: "warning",
      });
    }
  }

  const handleReloadTable = () => {
    setCounter(counter + 1);
  }

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title="Metas Estructura Electoral" />
        <Card className="card-primary">
          <CardContent>
            <LoadingForm
              loadinMessage={loading.loadingMessage}
              successMessage={loading.successMessage}
              isLoading={loading.isLoading}
              success={loading.isSuccess}
              isOpen={loading.isOpen}
            />

            {middleware.checkMenuAction("Agregar") &&
              (<FormTable
                catalogs={_catalogs}
                load={load}
                setLoading={setLoading}
                refreshTable={handleReloadTable}
              />
              )}

            <ElectoralTable
              catalogs={_catalogs}
              load={load}
              loading={loading}
              setLoading={setLoading}
              counter={counter}
            />
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
}

export default EstructuraElectoral;

import { useState, useEffect } from "react";

// Material UI
import { Box, Grid, Chip, Icon, TextField, InputAdornment, Button, Divider } from "@mui/material";

import ModalUserResponsibleList from "@components/Users/ModalUserResponsibleList";

// Utils
import { getVars } from "@utils/global";
// import middleware from "@middlewares/middleware";

const ResponsableList = (props) => {
  const { setResponsable } = props;

  const { user } = getVars("Token");
  const [openModal, setOpenModal] = useState(false);
  const [userOwner, setUserOwner] = useState({
    value: user.id,
    label: `${user.Nombre} ${user.Paterno} ${user.Materno}`,
    TieneUsuario: 1,
  });

  const resetUserOwner = () => {
    setUserOwner({
      value: user.id,
      label: `${user.Nombre} ${user.Paterno} ${user.Materno}`,
      TieneUsuario: 1,
    });
  };

  const SetOwner = (item) => {
    setUserOwner({
      value: item.value,
      label: item.label,
      TieneUsuario: item.TieneUsuario,
    });
  };

  useEffect(() => {
    setResponsable(userOwner);
    //eslint-disable-next-line
  }, [userOwner]);

  return (
    <Box>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12}>
          <Chip icon={<Icon>groups2</Icon>} label="Seleccione al Responsable de asignar la responsabilidad" />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            disabled
            label="Nombre del Responsable del Voluntario"
            value={userOwner.label}
            name="Nombre"
            fullWidth
            type="text"
            variant="outlined"
            size="small"
            InputProps={
              /* middleware.checkMenuAction("Capturista") */ true && {
                startAdornment: (
                  <InputAdornment position="start" sx={{ pl: 0, pr: 0 }}>
                    <Button color="warning" onClick={(e) => resetUserOwner()} sx={{ ml: 0, pl: 0 }}>
                      <Icon>restore</Icon>
                    </Button>
                    <Divider sx={{ height: 28 }} orientation="vertical" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <Button color="info" onClick={(e) => setOpenModal(true)} sx={{ mr: 0, pr: 0 }}>
                      <Icon>search</Icon>
                      <small>Buscar</small>
                    </Button>
                  </InputAdornment>
                ),
              }
            }
          />
        </Grid>
      </Grid>

      <ModalUserResponsibleList
        openModal={openModal}
        setOpenModal={setOpenModal}
        setResponsible={(e) => SetOwner(e)}
      />
    </Box>
  );
};

export default ResponsableList;

import { useEffect, useState } from "react";

// Material UI
import { Box, Button } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

// Componentes
import FilterCollapse from "@components/Filters/FilterCollapse";
import InputSelect from "@components/Selects/BasicSelect";

// Servicios
const FilterDataTable = (props) => {
  const { catalogs, load, isLoading, handleFilter } = props;
  const defaultOption = { value: 0, label: "TODOS" };
  const [isLoadingLocal, setIsLoadingLocal] = useState(false);

  //Estados de los filtros
  const [, setPerfiles] = useState([]);
  const [perfilesFilter, setPerfilesFilter] = useState([]);

  const [, setMunicipio] = useState([]);
  const [municipioFilter, setMunicipioFilter] = useState([]);

  const [originalDF, setOriginalDF] = useState([]);
  const [df, setDf] = useState([]);
  const [dfFilter, setDfFilter] = useState([]);

  //Cargando los catalogos
  const fetchData = async () => {
    setIsLoadingLocal(true);

    if (catalogs.perfil) {
      const dataPerfiles = [defaultOption, ...catalogs.perfil];
      setPerfiles(dataPerfiles);
      setPerfilesFilter(dataPerfiles);
    }

    if (catalogs.municipios_dl) {
      const dataMunicipiosDL = [defaultOption, ...catalogs.municipios_dl];
      setMunicipio(dataMunicipiosDL);
      setMunicipioFilter(dataMunicipiosDL);
    }

    if (catalogs.df) {
      const _originalDF = [{ value: 0, label: "TODOS" }].concat(catalogs.df);
      setOriginalDF(_originalDF);
      setDfFilter(_originalDF);
    }

    if (catalogs.municipios_df) {
      const dataMunicipiosDF = [
        { value: 0, label: "TODOS" },
        ...catalogs.municipios_df,
      ];
      setDf(dataMunicipiosDF);
      setIsLoadingLocal(false);
    }
  };

  useEffect(() => {
    if (catalogs) {
      fetchData();
    }
    //eslint-disable-next-line
  }, [catalogs]);

  //Definir un estado que contenga todos los valores seleccionados
  const [selectedValues, setSelectedValues] = useState({
    //Si le poner 0 pone por defecto el Total
    perfil: 0,
    municipio: 0,
    df: 0,
  });

  //Cuando cambia el filter, checa si hay uno y si si, pone por defecto el 1.
  useEffect(() => {
    if (dfFilter.length === 1) {
      setSelectedValues({
        ...selectedValues,
        df: dfFilter[0].value,
      });
    }
    //eslint-disable-next-line
  }, [dfFilter]);

  //Actualizar la función handleClearClick para restablecer todos estos valores a la vez
  const handleClearClick = () => {
    setSelectedValues({
      //Si le poner 0 pone por defecto el Total
      perfil: 0,
      municipio: 0,
      df: 0,
    });
    //Regresar al filtro de df los datos iniciales del catalogo, reseteando
    setDfFilter(df);
    //Limpiar se manda a la api sin nada
    handleFilter("clear");
  };

  const handleChangeMunicipio = (event) => {
    const idMunicipio = event.target.value;
    // Reinicia algunos parametros
    setSelectedValues({
      ...selectedValues,
      municipio: idMunicipio,
      df: 0,
    });

    if (idMunicipio > 0) {
      const dff = df.filter((item) => item.idMR === idMunicipio);
      setDfFilter([{ value: 0, label: "TODOS" }, ...dff]);
    } else {
      setDfFilter(originalDF);
    }
  };

  const handleChangeDf = (event) => {
    const idDF = event.target.value;
    // Reinicia algunos parametros
    setSelectedValues({
      ...selectedValues,
      df: idDF,
    });
  };

  return (
    <FilterCollapse isLoading={isLoading}>
      <Box display="flex" flexDirection="column" my={2}>
        <Grid2 container spacing={2} alignItems="center">
          <Grid2 item xs={12} sm={6} lg={4}>
            <InputSelect
              label="Perfil"
              name="perfil"
              options={perfilesFilter}
              value={selectedValues.perfil}
              onChange={(e) =>
                //Dentro de setSelectedValues, creamos un nuevo objeto con el operador de propagación
                setSelectedValues({ ...selectedValues, perfil: e.target.value })
              }
              isLoading={load}
              variant="outlined"
              sx={{ width: "100%" }}
            />
          </Grid2>

          <Grid2 item xs={12} sm={6} lg={4}>
            <InputSelect
              label="Municipio"
              name="municipio"
              options={municipioFilter}
              value={selectedValues.municipio}
              onChange={handleChangeMunicipio}
              isLoading={load}
              variant="outlined"
              sx={{ width: "100%" }}
            />
          </Grid2>

          <Grid2 item xs={12} sm={6} lg={4}>
            <InputSelect
              label="DF"
              name="df"
              options={dfFilter}
              //Para que cuando seleccionas algun muncipio con 1 solo df se ponga automatico el df y cuando tenga mas, se habilita para seleccionar
              // disabled={selectedValues.municipio === 0 || dfFilter.length === 1}
              onChange={handleChangeDf}
              value={selectedValues.df}
              isLoading={isLoadingLocal}
              variant="outlined"
              sx={{ width: "100%" }}
            />
          </Grid2>

          {/* Botones */}
          <Grid2 item xs={12} lg={4}>
            <Box>
              <Grid2 container spacing={2}>
                <Grid2 xs={12} sm={6}>
                  <Button
                    variant="contained"
                    color="primaryDark"
                    fullWidth
                    onClick={() => {
                      handleFilter("filter", selectedValues);
                    }}
                  >
                    Filtrar
                  </Button>
                </Grid2>
                <Grid2 xs={12} sm={6}>
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={handleClearClick}
                  >
                    Limpiar
                  </Button>
                </Grid2>
              </Grid2>
            </Box>
          </Grid2>
        </Grid2>
      </Box>
    </FilterCollapse>
  );
};

export default FilterDataTable;

import React from "react";
import {
  Card,
  CardContent,
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  CardActions,
} from "@mui/material";
import { URL_IMAGES } from "@utils/global";

const PositionCard = ({ title, data }) => {

  const {
    NombreCandidato,
    RutaLogo,
    TipoEleccion,
  } = data;

  return (
    <Card sx={{ textAlign: "center",overflow: "auto",border: '1px solid gray'  }}>
      <CardContent sx={{maxHeight:"100px" }}>
        <ListItem sx={{ textAlign: "center" }}>
          <ListItemAvatar>
            <Avatar src={`${URL_IMAGES}/${RutaLogo}`} alt={NombreCandidato} />
          </ListItemAvatar>
          <ListItemText
            primary={NombreCandidato}
            primaryTypographyProps={{ fontSize: "16px", fontWeight: "bold" }}
          />
        </ListItem>
      </CardContent>
      <CardActions>
        <Typography variant="caption" align="center" gutterBottom>
          {TipoEleccion}
        </Typography>
      </CardActions>
    </Card>
  );
};

export default PositionCard;
